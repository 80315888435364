
export function getCookie(){
    if (document.cookie.length > 0) {
        var arr = document.cookie.split(','); //这里显示的格式需要切割一下自己可输出看下
        var uid='';
        for (var i = 0; i < arr.length; i++) {
          var arr2 = arr[i].split('='); //再次切割
          if(i==0){
            uid=arr2[1];
          }else{
            var time=parseInt(getNowTime());
            if(arr2[1]<time){
              clearCookie();
              uid='';
            }
          }
        }
        return uid;

  }
  return false;
}
export function setCookie(uid,exdays=0){
   // clearCookie();
  if(exdays>0){
    var time=getNowTime() + 24 * 60 * 60  * exdays; //保存的天数
  }else{
    var time=getNowTime()+60*60;//如果没有勾选 默认一天
  }
  console.log(uid);
  window.document.cookie = "uid" + "=" + uid + ",expires=" + time;

}
//清楚token
export function clearCookie(){
  setCookie("", "", -1);
}
//获取时间戳
export function getNowTime(){
    let time = parseInt(new Date().getTime()/1000);
    return time;
}
// export default {
//   //设置
//   setCookie(uid,exdays){
//     var exdate=new Date();//获取时间
//     exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays); //保存的天数
//     window.document.cookie = "uid" + "=" + uid, + ";path=/;expires=" + exdate.toGMTString();
//   },
//   //读取cookie
//   getCookie: function() {
//     var uid=1;
//     return uid;

//       console.log(document.cookie);
//       if (document.cookie.length > 0) {
//           var arr = document.cookie.split('; '); //这里显示的格式需要切割一下自己可输出看下
//           // for (var i = 0; i < arr.length; i++) {
//           //     var arr2 = arr[i].split('='); //再次切割
//           //     //判断查找相对应的值
//           //     if (arr2[0] == 'userName') {
//           //       //  console.log(arr2[1])
//           //         this.loginForm.username = arr2[1]; //保存到保存数据的地方
//           //     } else if (arr2[0] == 'password') {
//           //       // console.log(arr2[1])
//           //         this.loginForm.password = arr2[1];
//           //     }
//           // }
//           // this.checked = true;
//           var uid=1;
//           return uid;
//       }
//   },
//   //清除cookie
//   clearCookie: function() {
//       this.setCookie("", "", -1); //修改2值都为空，天数为负1天就好了
//   }
// }
