<template>

  <div class="r-top">
    <div class="r-top-title">通知公告</div>
    <div class="r-top-list" v-for="item in news" @click="goTo('/newin',item.id)">
      <div class="r-top-time"><img src="~assets/new/sq.png" /><span>{{item.time}}</span></div>
      <div class="r-top-cot twohang">{{item.name}}</div>
    </div>
   <!-- <div class="r-top-list">
      <div class="r-top-time"><img src="~assets/new/sq.png" /><span>2022-09-08</span></div>
      <div class="r-top-cot">内容内容内容内容内容</div>
    </div>

    <div class="r-top-list">
      <div class="r-top-time"><img src="~assets/new/sq.png" /><span>2022-09-08</span></div>
      <div class="r-top-cot">内容内容内容内容内容</div>
    </div>

    <div class="r-top-list">
      <div class="r-top-time"><img src="~assets/new/sq.png" /><span>2022-09-08</span></div>
      <div class="r-top-cot">内容内容内容内容内容</div>
    </div> -->
  </div>
</template>

<script>
  import {
    useRouter
  } from 'vue-router';
  import {
    getHome,
    getnew
  } from 'network/home.js';
  export default {
    name: 'Journalism',
    props: [],
    data() {
      return {
        news:[]
      }
    },
    setup() {
      //跳转方法
      const router = useRouter();
      const goTo = (path, cs) => {
          var query={id:cs}
        console.log(query);

        console.log(query);
        router.push({
          path: path,
          query: query || {}
        })
      }

      return {
        goTo
      }

    },
    mounted() {
      this.getfl();
    },
    methods: {
      getfl(fnewfl_id) {
        var that = this;
        var data = {
          type: 2,
        };
        getnew(data).then(res => {
          console.log('最新公告');
          console.log(res);
          that.news = res.data.data.table.data;
        }).catch(err => {})

      },


    },
  }
</script>

<style lang="scss" scoped="scoped">
  .r-top {
    background: #fff;
    min-height: 6.25rem;
    margin-top: 0.625rem;
    padding: 1.225rem;

    .r-top-title {
      font-size: 1.175rem;
      font-weight: bold;
    }

    .r-top-list {
      margin-top: 1.225rem;
      cursor: pointer;
      .r-top-time {
        font-size: 0.875rem;
        color: #A7A7A7;
        display: flex;
        align-items: center;

        img {
          width: 0.8075rem;
          margin-right: 0.625rem;
        }
      }

      .r-top-cot {
        margin-left: 1.625rem;
        margin-top: 0.325rem;
        font-size: 0.875rem;
        line-height:1.675rem;

        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

      }

    }

    .r-bot {}

    @media screen and (max-width: 1300px) {
      .r-top-cot{
       text-align: left;
      }
    }

  }
  .r-top-list:hover{
    // background: linear-gradient(rgba(98, 186, 238, 0.1568627451) 0%, rgba(196, 196, 196, 0) 100%);
    color: #2E9BD6;
  }

</style>
