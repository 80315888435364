<template>

    <div class="cen-bot">

      <div v-show="list.length==0" style="text-align: center;">
        <img src="~assets/download/qst.png" style="    width: 33%;" />
        <div class="">暂无数据</div>
      </div>

      <div class="cen-bot-one" @click="goTo(tz,item.id)" v-for="item in list">
        <img class="sanbodei" :src="'https://ymedicine.jiujingwulian.com/public'+item.fimage" />
        <div class="rigg">
          <div class="bt">{{item.name}}</div>
          <div class="hang">
            <div class="h-count">{{item.ly0.name}}</div>
            <div class="h-count">{{item.ly1.name}}</div>
            <div class="h-count" v-show="p!=2">{{item.ly2.name}}</div>

            <div v-show="p==1" class="h-count">{{item.ly3.name}}</div>
            <div v-show="p==1" class="h-count">{{item.ly4.name}}</div>
            <div v-show="p==1" class="h-count">{{item.ly5.name}}</div>
            <div v-show="p==1" class="h-count">{{item.ly6.name}}</div>


          </div>
          <div class="fbsj">{{item.jianjie}}</div>
        </div>
        <div class="but">我感兴趣</div>

      </div>

     <!-- <div class="cen-bot-one">
        <img src="~assets/flnancing/ban.png" />
        <div class="rigg">
          <div class="bt">项目名称项目名称项目名称</div>
          <div class="hang">
            <div class="h-count">领域一</div>
            <div class="h-count">领域一</div>
            <div class="h-count">领域一</div>
          </div>
          <div class="fbsj">项目名称项目名称项目名称</div>
        </div>
        <div class="but">我感兴趣</div>
      </div>

      <div class="cen-bot-one">
        <img src="~assets/flnancing/ban.png" />
        <div class="rigg">
          <div class="bt">项目名称项目名称项目名称</div>
          <div class="hang">
            <div class="h-count">领域一</div>
            <div class="h-count">领域一</div>
            <div class="h-count">领域一</div>
          </div>
          <div class="fbsj">项目名称项目名称项目名称</div>
        </div>
        <div class="but">我感兴趣</div>
      </div>

      <div class="cen-bot-one">
        <img src="~assets/flnancing/ban.png" />
        <div class="rigg">
          <div class="bt">项目名称项目名称项目名称</div>
          <div class="hang">
            <div class="h-count">领域一</div>
            <div class="h-count">领域一</div>
            <div class="h-count">领域一</div>
          </div>
          <div class="fbsj">项目名称项目名称项目名称</div>
        </div>
         <div class="but">我感兴趣</div>
      </div> -->

    </div>


</template>

<script>

  import {useRouter} from 'vue-router';

  export default{
    name:'List',
    props: ['tz','list','p'],
    setup(){
      //跳转方法
      const router=useRouter();
      const goTo=(path,id)=>{
        // console.log(query);
        var query={id:id}
        console.log(query);
        router.push({
          path:path,
          query:query || {}
        })
      }

      return {goTo}

    },
  }
</script>

<style lang="scss" scoped="scoped">


    .cen-bot {
      margin-top: 0.9375rem;

      .cen-bot-one {
        background: #fff;
        padding: 1.875rem;
        border-radius: 0.625rem;
        display: flex;
        margin-bottom: 1rem;
        position: relative;
        cursor: pointer;
        .but {
          position: absolute;
          bottom: 0.625rem;
          padding: 1rem 4rem;
          background: #A8BF29;
          color: #fff;
          right: 2rem;
          bottom: 0.58rem;
          cursor: pointer;
        }

        img {
          width: 12.75rem;
          border-radius: 0.25rem;
        }

        .rigg {
          margin-left: 1.025rem;

          .bt {
            font-size: 1.3rem;
            font-weight: bold;

          }

          .fbsj {
            font-size: 0.9rem;
            color: #888888;
          }
        }

        .hang {
          display: flex;
          font-size: 0.975rem;
          align-items: center;
          margin-bottom: 0.9375rem;
          margin: 2.3rem 0;

          .h-title {
            font-weight: bold;
          }

          .h-count {
            padding: 0.525rem 1rem;
            background: #F5F5F5;
            color: #666666;
            margin-right: 0.9375rem;
            cursor: pointer;
          }

          .qb {
            background: #2E9BD6;
            color: #fff;
          }
        }
      }

      .cen-bot-one:hover {
        background: linear-gradient(rgba(98, 186, 238, 0.1568627451) 0%, rgba(196, 196, 196, 0) 100%);
        color: #2E9BD6;

        .but {
          background: #2E9BD6;
        }
      }

    }


  @media screen and (max-width: 1300px) {
    .cen-bot-one{
      padding: 0.875rem!important;
      flex-wrap: wrap;
    }
    .sanbodei{
      width: 100%!important;
    }
    .rigg{
      margin-top: 0.625rem!important;
      .bt{
        font-size: 1.2rem!important;
      }
      .hang{
        margin: 1.3rem 0!important;
        flex-wrap: wrap;
        margin-bottom: 0!important;
        .h-count{
          font-size: 0.9rem!important;
          margin-bottom: 0.625rem;
        }
      }
    }
    .but{

      bottom: 0.625rem!important;
      padding: 0.5rem 0.5rem!important;

      right: 1.2rem!important;
      top: 1.4375rem!important;
      bottom: auto!important;
      font-size: 0.9rem!important;

    }
  }





</style>
