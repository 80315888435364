import { request } from "./request.js";

export function getHome(params){
    return request(
      {
          url:'/public/index/index/index',
          method:'POST',
          params:{
              id:'1'
          }
      }
    )
}


export function getbanner(params){
  // console.log('ssss');
  // console.log(params);
    return request(
      {
          url:'/public/index/index/banner',
          method:'POST',
          params:params
      }
    )
}

export function getnew(params){
    console.log(params);
    return request(
      {
          url:'/public/index/index/new',
          method:'POST',
          params:params,
          // params
      }
    )
}

export function getonenew(params){
    console.log(params);
    return request(
      {
          url:'/public/index/index/onenew',
          method:'POST',
          params:params,
          // params
      }
    )
}
export function getffybnew(params){
    console.log(params);
    return request(
      {
          url:'/public/index/index/ffybnew',
          method:'POST',
          params:params,
          // params
      }
    )
}


export function getfmeeting(params){
    console.log(params);
    return request(
      {
          url:'/public/index/index/fmeeting',
          method:'POST',
          params:params,
          // params
      }
    )
}


export function getgrxx(params){
    console.log(params);
    return request(
      {
          url:'/public/index/index/grxx',
          method:'POST',
          params:params,
          // params
      }
    )
}
// 系统设置
export function getfshezhi(params){
    console.log(params);
    return request(
      {
          url:'/public/index/index/fshezhi',
          method:'POST',
          params:params,
          // params
      }
    )
}




// 系统设置
export function getshouquan(params){
    console.log(params);
    return request(
      {
          url:'/public/index/index/shouquan',
          method:'POST',
          params:params,
          // params
      }
    )
}



