import { request } from "./request.js";

//个人中心的信息
export function getgz(params){
    return request(
      {
          url:'/public/index/index/gz',
          method:'POST',
           params:params
      }
    )
}
//消息列表
export function getmessageall(params){
    return request(
      {
          url:'/public/index/index/messageall',
          method:'POST',
           params:params
      }
    )
}
//消息详情
export function getfmess(params){
    return request(
      {
          url:'/public/index/index/fmess',
          method:'POST',
           params:params
      }
    )
}
//申请入会
export function getsqrh(params){
    return request(
      {
          url:'/public/index/index/sqrh',
          method:'POST',
           params:params
      }
    )
}
//申请入会
export function getsqrh1(params){
    return request(
      {
          url:'/public/index/index/sqrh1',
          method:'POST',
           params:params
      }
    )
}

//会议报名记录

export function getfmeetingbmMy(params){
    return request(
      {
          url:'/public/index/index/fmeetingbmMy',

          method:'POST',
          params:params
      }
    )
}


// export function getfmeetingbmMylt(params){
//     return request(
//       {
//           url:'/public/index/index/fmeetingbmMylt',

//           method:'POST',
//           params:params
//       }
//     )
// }

//上传图片
export function upimg(params){
    return request(
      {
          url:'/public/index/index/upload',
          headers:{'Content-Type': 'multipart/form-data'},
          method:'POST',
          data:params
      }
    )
}

//上传健康码   // $type==0 会议 ==1 论坛
export function getscjkm(params){
    return request(
      {
          url:'/public/index/index/scjkm',
          method:'POST',
           params:params
      }
    )
}
//论坛
export function getfmeetingbmMylt(params){
    return request(
      {
          url:'/public/index/index/fmeetingbmMylt',
          method:'POST',
           params:params
      }
    )
}

//技术成果库
export function getftechnologyorder(params){
    return request(
      {
          url:'/public/index/index/ftechnologyorder',
          method:'POST',
           params:params
      }
    )
}
//需求商机
  //我的发布
export function getfbusinessinmy(params){
    return request(
      {
          url:'/public/index/index/fbusinessinmy',
          method:'POST',
           params:params
      }
    )
}
  //我的发布 下架
export function getxiajia(params){
    return request(
      {
          url:'/public/index/index/xiajia',
          method:'POST',
           params:params
      }
    )
}

  //我的发布 新增
export function getinsertFbusinessin(params){
    return request(
      {
          url:'/public/index/index/insertFbusinessin',
          method:'POST',
           params:params
      }
    )
}

  //我的发布 我感兴趣的
export function getfbusinessorder(params){
    return request(
      {
          url:'/public/index/index/fbusinessorder',
          method:'POST',
           params:params
      }
    )
}
//投融资
 //我的发布
export function getfinvestmentinmy(params){
    return request(
      {
          url:'/public/index/index/finvestmentinmy',
          method:'POST',
           params:params
      }
    )
}
 //我的发布 下架
export function getxiajiafinvestmentin(params){
    return request(
      {
          url:'/public/index/index/xiajiafinvestmentin',
          method:'POST',
           params:params
      }
    )
}

 //我的发布 新增
export function getinsertfinvestmentin(params){
    return request(
      {
          url:'/public/index/index/insertfinvestmentin',
          method:'POST',
           params:params
      }
    )
}
 //我的发布 新增
export function getfinvestmentorder(params){
    return request(
      {
          url:'/public/index/index/finvestmentorder',
          method:'POST',
           params:params
      }
    )
}


//修改个人资料
export function getxgxx(params){
    return request(
      {
          url:'/public/index/index/xgxx',
          method:'POST',
           params:params
      }
    )
}
//账号安全
//修改密码

export function getxgpassword(params){
    return request(
      {
          url:'/public/index/index/xgpassword',
          method:'POST',
           params:params
      }
    )
}

//修改绑定手机号
export function getxgphone(params){
    return request(
      {
          url:'/public/index/index/xgphone',
          method:'POST',
           params:params
      }
    )
}

export function getphoneUploadPas(params){
    return request(
      {
          url:'/public/index/index/phoneUploadPas',
          method:'POST',
           params:params
      }
    )
}


export function getinUsers(params){
    return request(
      {
          url:'/public/index/index/inUsers',
          method:'POST',
           params:params
      }
    )
}



export function getmessage(params){
    return request(
      {
          url:'/public/index/index/upmessage',
          method:'POST',
           params:params
      }
    )
}


export function huiyuan(params){
    return request(
      {
          url:'/public/index/index/huiyuan',
          method:'POST',
           params:params
      }
    )
}

//手机支付
export function getZhifuH5(params){
    return request(
      {
          url:'/public/index/index/getZhifuH5',
          method:'POST',
           params:params
      }
    )
}



