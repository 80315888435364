import { request } from "./request.js";

//发送验证码
export function send(params){
    return request(
      {
          ontentType: "application/x-www-form-urlencoded",
          url:'/public/index/index/send',
          method:'POST',
          params:params
      }
    )
}

//验证验证码
export function check(params){
    return request(
      {
          ontentType: "application/x-www-form-urlencoded",
          url:'/public/index/index/check',
          method:'POST',
          params:params
      }
    )
}
