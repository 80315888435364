<template>
  <div class="all">
    <Head :top="top" ></Head>
    <Banner :bn='bn'></Banner>
    <Bread :all="all"></Bread>
    <div class="a-cen">
      <div class="center">



      </div>
    </div>
    <foot></foot>
    <left></left>
  </div>
</template>

<script>

import {ref,onMounted} from 'vue';
import {getHome,getbanner} from 'network/home.js';
import {useRouter} from 'vue-router';


import Head from "components/content/headder/Head.vue";
import Foot from "components/content/footer/Foot.vue";
import Left from "components/content/left/Left.vue";
import Bread from "components/content/breadcrumb/Breadcrumb.vue";
import Banner from "components/content/banner/Banner.vue";


export default {
  name: 'New',
  data() {
    return {
      bn:'public/img/banner.387b0333.png',
      all: [
        {
            name: '您当前的位置:首页',
            url: '/'
          },

          {
            name: '资讯',
            url: '/New'
          },
        ]
    }
  },
  setup(){
    const top=2;
    const router=useRouter();
    const databa=ref([]);
    const ab='sb';
    console.log(ab);
    onMounted(()=>{

      getHome().then(res=>{
        console.log(res.data.data);
        databa.value=res.data;
        console.log(databa);

      }).catch(err=>{

      })

    })
    //跳转方法
    const goTo=(path,query)=>{
      router.push({
        path:path,
        query:query || {}
      })
    }

    return { databa,ab,goTo,top }
  },
  mounted(){
    var cs={i:0};
    var that=this;
    getbanner(cs).then(res => {
      that.banner=res.data;
    
    }).catch(err => {
    
    })
  },
  methods(){
      

  },
  components:{
    Head,
    Foot,
    Left,
    Bread,
    Banner
  },
}
</script>
<style lang="scss" scoped="scoped">
  // nth-child
  .a-cen{
    width: 100vw;
    background: #fff;
    padding: 1rem 0;
    .center{
      width: var(--allWidth);
      margin: 0 auto;
    }
  }
  .center{

  }
   @media screen and (max-width: 1300px) {
     
   }
</style>
