<template>
  
    <div class="allsa" v-show="list.length==0">
      <div  style="text-align: center;">
         <img src="~assets/download/qst.png" style="    width: 33%;" />
         <div class="">暂无数据</div>
       </div>
    </div>
</template>

<script>

  import {useRouter} from 'vue-router';

  export default{
    name:'null',
    props: ['list'],
    setup(){
      //跳转方法
      const router=useRouter();
      const goTo=(path,query)=>{
        console.log(query);
        query={name:'nn'}
        console.log(query);
        router.push({
          path:path,
          query:query || {}
        })
      }

      return {goTo}

    },
  }
</script>

<style lang="scss" scoped="scoped">
  .allsa{
    padding: 1.875rem 0;
  }
</style>
