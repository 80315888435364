<template>
  <div class="all">
    <Head :top="top" :grxxff="grxxff"></Head>
    <div class="bn">
      <img src="~assets/new/banner.png" />
    </div>
    <Bread :all="all"></Bread>
    <div class="a-cen">
      <div class="center">

        <div class="c-left">
            <div v-html="news.content" ></div>
        </div>
        <div class="c-right">
          <Notice :pd="pd" :getChildInfo="getChildInfo" ></Notice>
        </div>
      </div>
    </div>
    <foot></foot>
    <left></left>
  </div>
</template>

<script>
  import {
    ref,
    onMounted
  } from 'vue';
  import {
    getHome,
    getonenew
  } from 'network/home.js';
  import {   
    getonemeeting
  } from 'network/neeting.js';
  
  import {
    useRouter
  } from 'vue-router';


  import Head from "components/content/headder/Head.vue";
  import Foot from "components/content/footer/Foot.vue";
  import Left from "components/content/left/Left.vue";
  import Bread from "components/content/breadcrumb/Breadcrumb.vue";
  import Notice from "components/content/notice/Notice.vue";


  export default {
    name: 'New',

    data() {
      return {
        pd:2,
        news:{},
        bn: '~assets/new/banner.png',
        all: [{
            name: '您当前的位置:首页',
            url: '/'
          },
          {
            name: '会议论坛',
            url: '/meeting'
          },
          {
            name: '新闻详情名称',

          },
        ]
      }
    },
    setup() {

      const route = useRouter()
      console.log(route.query);

      const top = 3;
      const router = useRouter();
      const databa = ref([]);
      const ab = 'sb';
      console.log(ab);


      onMounted(() => {

        getHome().then(res => {
          console.log(res.data.data);
          databa.value = res.data;
          console.log(databa);

        }).catch(err => {

        })

      })
      //跳转方法
      const goTo = (path, query) => {
        router.push({
          path: path,
          query: query || {}
        })
      }

      return {
        databa,
        ab,
        goTo,
        top
      }
    },
    mounted() {
      // var url = decodeURI(window.location.search); //?id="123456"&name="www";
      // // var url = window.location.href;
      // console.log(url);
      // var object = {};
      // if (url.indexOf("?") != -1) //url中存在问号，也就说有参数。
      // {
      //   var str = url.substr(1); //得到?后面的字符串
      //   var strs = str.split("&"); //将得到的参数分隔成数组[id="123456",name="www"];
      //   for (var i = 0; i < strs.length; i++) {
      //     object[strs[i].split("=")[0]] = strs[i].split("=")[1]; //得到{id:'123456',name:'www'}
      //   }
      // }
      // console.log(object);
      // var id = object['id'];
      var id = this.GetUrlParam('id');
      console.log(id);
      this.qh(id);
      window.scrollTo( 0, 0 );
    },
    methods: {
      GetUrlParam(paraName) {
      var url = window.location.toString();
      var arrObj = url.split("?");
      if (arrObj.length > 1) {
        var arrPara = arrObj[1].split("&");
        var arr;
        for (var i = 0; i < arrPara.length; i++) {
          arr = arrPara[i].split("=");
          if (arr != null && arr[0] == paraName) {
            return arr[1];
          }
        }
        return "";
      } else {
        return "";
      }
    },
      grxxff(grxxs){
        this.grxx=grxxs;
        console.log(grxxs);
        console.log('zhende');
      },
      getChildInfo(ids){
        console.log('b');
        this.qh(ids);
      },
      qh(ids){

        var that = this;
        var data = {
          id:ids,
        };
        getonemeeting(data).then(res => {
          that.news = res.data.data;
          console.log('zx');
          var all=that.all;
          all[2]['name']=that.news.name;
          that.all=all;
          // console.log();
        }).catch(err => {})
      }
    },
    components: {
      Head,
      Foot,
      Left,
      Bread,
      Notice
    },
  }
</script>
<style lang="scss" scoped="scoped">
  .r-bot{
    margin-top: 0!important;
  }
  .a-cen {
    width: 100vw;
    background: #F5F6FA;

    .center {
      width: var(--allWidth);
      margin: 0 auto;
    }
  }

  .bn {
    width: 100%;

    img {
      width: 100%;
    }
  }

  .center {
    padding: 1.25rem 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .c-left {
      width: 70%;
      background: #fff;
      padding: 1.25rem;
      min-height: 50vh;


    }

    .c-right {
      width: 28%;

      .r-ban {
        width: 100%;

        img {
          width: 100%;
        }
      }

      .r-bot {
        background: #fff;
        min-height: 6.25rem;
        margin-top: 0.625rem;
        padding: 1.225rem;

        .r-top-title {
          font-size: 1.175rem;
          font-weight: bold;
        }
      }

      .r-top {
        background: #fff;
        min-height: 6.25rem;
        margin-top: 0.625rem;
        padding: 1.225rem;

        .r-top-title {
          font-size: 1.175rem;
          font-weight: bold;
        }

        .r-top-list {
          margin-top: 1.225rem;

          .r-top-time {
            font-size: 0.875rem;
            color: #A7A7A7;
            display: flex;
            align-items: center;

            img {
              width: 0.8075rem;
              margin-right: 0.625rem;
            }
          }

          .r-top-cot {
            margin-left: 1.625rem;
            margin-top: 0.325rem;
            font-size: 0.875rem;
          }

        }

        .r-bot {}
      }
    }


  }

  .l-bot {
    margin-top: 1.875rem;

    .z-n-one:hover {
      background: linear-gradient(rgba(98, 186, 238, 0.1568627450980392) 0%, rgba(196, 196, 196, 0) 100%);

      .znor-title {
        color: #2E9BD6;
      }
    }

    .z-n-one {
      padding: 0.825rem;
      padding-bottom: 1rem;
      border-bottom: 0.0625rem solid #DBDBDB;
      display: flex;
      align-items: center;
      // margin-bottom: 1rem;
      background: #fff;
      cursor: pointer;

      .zno-left {
        margin-right: 1.875rem;

        img {
          width: 9.875rem;
        }
      }

      .zno-right {
        .znor-title {
          font-size: 1.075rem;
          font-weight: bold;
          margin-bottom: 0.675rem;
        }

        .znor-content {
          font-size: 0.7rem;
          color: #888888;
          line-height: 1rem;
        }

        .rq {
          font-size: 0.7rem;
          color: #888888;
          margin-top: 0.625rem;
        }
      }
    }



    .z-n-one1 {
      padding: 0;
      padding-bottom: 0.8rem;
      align-items: normal;

      .zno-left {
        margin-right: 0.675rem;

        img {
          width: 7rem;
        }
      }

      // .zon-left{
      //   img{
      //     width: 10%!important;
      //   }
      // }
    }

    .zno-right {
      padding-top: 0.25rem;
    }
  }
  @media screen and (max-width: 1300px) {
    .c-left{
      width: 95%!important;
      margin: 0 auto;
    }
    .c-right{
      width: 95%!important;
      margin: 0 auto;
      margin-top: 0.625rem;
    }
  
  }
  
</style>
