<template>
  <div class="all">
    <!-- <Head :top="top" :grxxff="grxxff"></Head> -->
    <Head :top="top" :grxxff="grxxff" ></Head>
    <!-- <Banner :bn='bn'></Banner> -->
    <!-- <Bread :all="all"></Bread> -->
    <div class="a-cen">
      <div class="center">
        <Screen :list="list" :one="one" :two="two" :three="three"  ></Screen>
        <List :tz="tz" :list='finvestmentin'></List>


        <page :items='items'
        :litems='litems'
        :total="total"
        :per_page="per_page"
        :current_page="current_page"
        :last_page="last_page"
        :listcount='listcount'></page>


       <!-- <div class="cen-top">
          <div class="hang">
            <div class="h-title">所在领域：</div>
            <div class="h-count qb">领域一</div>
            <div class="h-count">领域一</div>
            <div class="h-count">领域一</div>
            <div class="h-count">领域一</div>
            <div class="h-count">领域一</div>
            <div class="h-count">领域一</div>
          </div>
          <div class="hang">
            <div class="h-title">所在领域：</div>
            <div class="h-count qb">领域一</div>
            <div class="h-count">领域一</div>
            <div class="h-count">领域一</div>
            <div class="h-count">领域一</div>
            <div class="h-count">领域一</div>
            <div class="h-count">领域一</div>
            <div class="h-count">领域一</div>
            <div class="h-count">领域一</div>
            <div class="h-count">领域一</div>
            <div class="h-count">领域一</div>
            <div class="h-count">领域一</div>
            <div class="h-count">领域一</div>
            <div class="h-count">领域一</div>
            <div class="h-count">领域一</div>
            <div class="h-count">领域一</div>
            <div class="h-count">领域一</div>
            <div class="h-count">领域一</div>
          </div>

          <div class="hang">
            <div class="h-title">所在领域：</div>
            <div class="h-count qb">领域一</div>
            <div class="h-count">领域一</div>
            <div class="h-count">领域一</div>
            <div class="h-count">领域一</div>
            <div class="h-count">领域一</div>
            <div class="h-count">领域一</div>
          </div>
        </div>

       -->
             <!-- <div class="cen-bot">
                <div class="cen-bot-one" @click="goTo('/financingDe')">
                  <img src="~assets/flnancing/ban.png" />
                  <div class="rigg">
                    <div class="bt">项目名称项目名称项目名称</div>
                    <div class="hang">
                      <div class="h-count">领域一</div>
                      <div class="h-count">领域一</div>
                      <div class="h-count">领域一</div>
                    </div>
                    <div class="fbsj">项目名称项目名称项目名称</div>
                  </div>
                  <div class="but">我感兴趣</div>

                </div>

                <div class="cen-bot-one">
                  <img src="~assets/flnancing/ban.png" />
                  <div class="rigg">
                    <div class="bt">项目名称项目名称项目名称</div>
                    <div class="hang">
                      <div class="h-count">领域一</div>
                      <div class="h-count">领域一</div>
                      <div class="h-count">领域一</div>
                    </div>
                    <div class="fbsj">项目名称项目名称项目名称</div>
                  </div>
                  <div class="but">我感兴趣</div>
                </div>

                <div class="cen-bot-one">
                  <img src="~assets/flnancing/ban.png" />
                  <div class="rigg">
                    <div class="bt">项目名称项目名称项目名称</div>
                    <div class="hang">
                      <div class="h-count">领域一</div>
                      <div class="h-count">领域一</div>
                      <div class="h-count">领域一</div>
                    </div>
                    <div class="fbsj">项目名称项目名称项目名称</div>
                  </div>
                  <div class="but">我感兴趣</div>
                </div>

                <div class="cen-bot-one">
                  <img src="~assets/flnancing/ban.png" />
                  <div class="rigg">
                    <div class="bt">项目名称项目名称项目名称</div>
                    <div class="hang">
                      <div class="h-count">领域一</div>
                      <div class="h-count">领域一</div>
                      <div class="h-count">领域一</div>
                    </div>
                    <div class="fbsj">项目名称项目名称项目名称</div>
                  </div>
                   <div class="but">我感兴趣</div>
                </div>

              </div>
       -->

      </div>
    </div>
    <foot></foot>
    <left></left>
  </div>
</template>

<script>
  import {
    ref,
    onMounted
  } from 'vue';
  import {
    getHome
  } from 'network/home.js';


  import {
    useRouter
  } from 'vue-router';


  import Head from "components/content/headder/Head.vue";
  import Foot from "components/content/footer/Foot.vue";
  import Left from "components/content/left/Left.vue";
  import Bread from "components/content/breadcrumb/Breadcrumb.vue";
  import Banner from "components/content/banner/Banner.vue";

  import Screen from "./ChildComps/Screen.vue";
  import List from "./ChildComps/List.vue";

  import {getfinvestment,getfinvestmentin} from 'network/financing.js'

  import Page from "components/content/page/Page.vue";



  export default {
    name: 'New',
    data() {
      return {

        onecs:'',
        twocs:'',
        threecs:'',

        items:[
        ],
        litems:[
        ],
        page:0,
        total:'',
        per_page:'',
        current_page:'',
        last_page:'',

        finvestmentin:[],
        item:[],
        one:[],
        two:[],
        three:[],
        tz:'/financingDe',
        bn: 'public/img/banner.387b0333.png',
        all: [{
            name: '您当前的位置:首页',
            url: '/'
          },

          {
            name: '资讯',
            url: '/New'
          },
        ]
      }
    },
    setup() {
      const top = 7;
      const router = useRouter();
      const databa = ref([]);
      const ab = 'sb';
      console.log(ab);
      onMounted(() => {

        getHome().then(res => {
          console.log(res.data.data);
          databa.value = res.data;
          console.log(databa);

        }).catch(err => {

        })

      })
      //跳转方法
      const goTo = (path, query) => {
        router.push({
          path: path,
          query: query || {}
        })
      }

      return {
        databa,
        ab,
        goTo,
        top
      }
    },
    mounted() {
      this.getdata();
      this.list();
      window.scrollTo( 0, 0 );
    },
    methods: {
      grxxff(grxxs){
        this.grxx=grxxs;
        console.log(grxxs);
        console.log('zhende');
      },
      getdata(){
        var that = this;
        var data = {

        };
        getfinvestment(data).then(res => {
          console.log(res);
          console.log('zs');
          that.one=res.data.data.one;
          that.two=res.data.data.two;
          that.three=res.data.data.three;


        }).catch(err => {})

      },

      listcount(page){
        this.page=page;
        this.list(0,0,0,1);
      },

      list(one,two,three,pd=0){
        var that = this;
         if(pd==0){
           this.onecs=one;
           this.twocs=two;
           this.threecs=three;
         }

        var data = {
          one:this.onecs,
          two:this.twocs,
          three:this.threecs,
          page:this.page,
          sl:5
        };
        getfinvestmentin(data).then(res => {
        console.log(res);
        console.log('1');
        that.finvestmentin=res.data.data.table.data;


        that.total=res.data.data.table.total;
        that.per_page=res.data.data.table.per_page;
        that.current_page=res.data.data.table.current_page;
        that.last_page=res.data.data.table.last_page;
        that.items=res.data.data.items;
        that.litems=res.data.data.litems;




        }).catch(err => {})





      },

    },
    components: {
      Head,
      Foot,
      Left,
      Bread,
      Screen,
      List,
      Page,
      Banner
    },
  }
</script>
<style lang="scss" scoped="scoped">
  // nth-child
  .a-cen {
    width: 100vw;
    background: #F5F6FA;
    padding: 1rem 0;

    .center {
      width: var(--allWidth);
      margin: 0 auto;
    }
  }

  .center {

    .cen-top {
      background: #fff;
      padding: 1.25rem;

      .hang {
        display: flex;
        font-size: 0.975rem;
        align-items: center;
        margin-bottom: 0.9375rem;
        flex-wrap: wrap;
        .h-title {
          font-weight: bold;
          margin-bottom: 1rem;
        }

        .h-count {
          padding: 0.525rem 1rem;
          background: #F5F5F5;
          color: #666666;
          margin-right: 0.9375rem;
          cursor: pointer;
          margin-bottom: 1rem;
          border-radius: 0.36rem;
        }

        .qb {
          background: #2E9BD6;
          color: #fff;
        }
      }
    }

    .cen-bot {
      margin-top: 0.9375rem;

      .cen-bot-one {
        background: #fff;
        padding: 1.875rem;
        border-radius: 0.625rem;
        display: flex;
        margin-bottom: 1rem;
        position: relative;
        cursor: pointer;
        .but {
          position: absolute;
          bottom: 0.625rem;
          padding: 1rem 4rem;
          background: #A8BF29;
          color: #fff;
          right: 3rem;
          bottom: 2rem;
          cursor: pointer;
        }

        img {
          width: 12.75rem;
        }

        .rigg {
          margin-left: 1.025rem;

          .bt {
            font-size: 1.3rem;
            font-weight: bold;
          }

          .fbsj {
            font-size: 0.9rem;
            color: #888888;
          }
        }

        .hang {
          display: flex;
          font-size: 0.975rem;
          align-items: center;
          margin-bottom: 0.9375rem;
          margin: 2.3rem 0;

          .h-title {
            font-weight: bold;
          }

          .h-count {
            padding: 0.525rem 1rem;
            background: #F5F5F5;
            color: #666666;
            margin-right: 0.9375rem;
            cursor: pointer;
          }

          .qb {
            background: #2E9BD6;
            color: #fff;
          }
        }
      }

      .cen-bot-one:hover {
        background: linear-gradient(rgba(98, 186, 238, 0.1568627451) 0%, rgba(196, 196, 196, 0) 100%);
        color: #2E9BD6;

        .but {
          background: #2E9BD6;
        }
      }

    }


  }
  @media screen and (max-width: 1300px) {
    .h-count{
      margin-right: 0.4rem!important;
      padding: 0.525rem 1rem!important;
      font-size: 0.9rem!important;
      margin-bottom: 0.8rem!important;
    }

  }
</style>
