import { request } from "./request.js";


export function getfinvestment(params){
    console.log(params);
    return request(
      {
          url:'/public/index/index/finvestment',
          method:'POST',
          params:params,
      }
    )
}


export function getfinvestmentin(params){
    console.log(params);
    return request(
      {
          url:'/public/index/index/finvestmentin',
          method:'POST',
          params:params,
      }
    )
}


export function getfinvestmentxq(params){
    console.log(params);
    return request(
      {
          url:'/public/index/index/finvestmentxq',
          method:'POST',
          params:params,
      }
    )
}


export function getinsertfinvestmentxq(params){
    console.log(params);
    return request(
      {
          url:'/public/index/index/insertfinvestmentxq',
          method:'POST',
          params:params,
      }
    )
}

