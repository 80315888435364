<template>
  <div class="cen-top">
    <div class="hang" v-if="one">
      <div class="h-title">所在领域：</div>
      <div class="h-count" @click="qhone(0)" :class="oneid==0?'qb':''">全部</div>
      <div class="h-count " @click="qhone(item.id)" :class="oneid==item.id?'qb':''" v-for="(item,index) in one">
        {{item.name}}</div>
      <!-- <div class="h-count">领域一</div>
      <div class="h-count">领域一</div>
      <div class="h-count">领域一</div>
      <div class="h-count">领域一</div>
      <div class="h-count">领域一</div> -->
    </div>
    <div class="hang" v-if="two">
      <div class="h-title">所在地区：</div>
      <div class="h-count" @click="qhtwo(0)" :class="twoid==0?'qb':''">全部</div>
      <div class="h-count " @click="qhtwo(item.id)" :class="twoid==item.id?'qb':''" v-for="(item,index) in two">
        {{item.name}}</div>

    </div>

    <div class="hang" v-if="three">
      <div class="h-title">所在阶段：</div>
      <div class="h-count" @click="qhthree(0)" :class="threeid==0?'qb':''">全部</div>
      <div class="h-count " @click="qhthree(item.id)" :class="threeid==item.id?'qb':''" v-for="(item,index) in three">
        {{item.name}}</div>

    </div>


    <!-- 类别:0=技术领域,1=成熟阶段,2=技术来源,3=相关专利,4=重要奖项,5=相关鉴定,6=技术单位 -->
    <div class="hang" v-if="four">
      <div class="h-title">技术领域：</div>
      <div class="h-count" @click="qhfour(0)" :class="fourid==0?'qb':''">全部</div>
      <div class="h-count " @click="qhfour(item.id)" :class="fourid==item.id?'qb':''" v-for="(item,index) in four">
        {{item.name}}</div>

    </div>
    <div class="hang" v-if="five">
      <div class="h-title">成熟阶段：</div>
      <div class="h-count" @click="qhfive(0)" :class="fiveid==0?'qb':''">全部</div>
      <div class="h-count " @click="qhfive(item.id)" :class="fiveid==item.id?'qb':''" v-for="(item,index) in five">
        {{item.name}}</div>

    </div>

    <div class="hang" v-if="sex">
      <div class="h-title">技术来源：</div>
      <div class="h-count" @click="qhsex(0)" :class="sexid==0?'qb':''">全部</div>
      <div class="h-count " @click="qhsex(item.id)" :class="sexid==item.id?'qb':''" v-for="(item,index) in sex">
        {{item.name}}</div>

    </div>

    <div class="hang" v-if="event">
      <div class="h-title">相关专利：</div>
      <div class="h-count" @click="qhevent(0)" :class="eventid==0?'qb':''">全部</div>
      <div class="h-count " @click="qhevent(item.id)" :class="eventid==item.id?'qb':''" v-for="(item,index) in event">
        {{item.name}}</div>

    </div>

    <div class="hang" v-if="eight">
      <div class="h-title">重要奖项：</div>
      <div class="h-count" @click="qheight(0)" :class="eightid==0?'qb':''">全部</div>
      <div class="h-count " @click="qheight(item.id)" :class="eightid==item.id?'qb':''" v-for="(item,index) in eight">
        {{item.name}}</div>

    </div>

    <div class="hang" v-if="nine">
      <div class="h-title">相关鉴定：</div>
      <div class="h-count" @click="qhnine(0)" :class="nineid==0?'qb':''">全部</div>
      <div class="h-count " @click="qhnine(item.id)" :class="nineid==item.id?'qb':''" v-for="(item,index) in nine">
        {{item.name}}</div>

    </div>

    <div class="hang" v-if="ten">
      <div class="h-title">技术单位：</div>
      <div class="h-count" @click="qhten(0)" :class="tenid==0?'qb':''">全部</div>
      <div class="h-count " @click="qhten(item.id)" :class="tenid==item.id?'qb':''" v-for="(item,index) in ten">
        {{item.name}}</div>

    </div>

    <div class="hang" v-if="one1">
      <div class="h-title">所在行业：</div>
      <div class="h-count" @click="qhone1(0)" :class="one1id==0?'qb':''">全部</div>
      <div class="h-count " @click="qhone1(item.id)" :class="one1id==item.id?'qb':''" v-for="(item,index) in one1">
        {{item.name}}</div>

    </div>

    <div class="hang" v-if="two1">
      <div class="h-title">所在地区：</div>
      <div class="h-count" @click="qhtwo1(0)" :class="two1id==0?'qb':''">全部</div>
      <div class="h-count " @click="qhtwo1(item.id)" :class="two1id==item.id?'qb':''" v-for="(item,index) in two1">
        {{item.name}}</div>

    </div>


  </div>

</template>

<script>
  import {
    useRouter
  } from 'vue-router';

  export default {
    name: 'Screen',
    data() {
      return {
        one1id:0,
        two1id:0,
        oneid: 0,
        twoid: 0,
        threeid: 0,
        fourid: 0,
        fiveid: 0,
        sexid: 0,
        eventid: 0,
        eightid: 0,
        nineid: 0,
        tenid: 0,

      }
    },

    props: ['one1','two1','one', 'three', 'two', 'list', 'four', 'five', 'sex', 'event', 'eight', 'nine', 'ten'],
    setup() {
      //跳转方法
      const router = useRouter();
      const goTo = (path, query) => {
        console.log(query);
        query = {
          name: 'nn'
        }
        console.log(query);
        router.push({
          path: path,
          query: query || {}
        })
      }

      return {
        goTo
      }

    },
    methods: {
      qhone(num) {
        this.oneid = num;
        this.fhhq(num, '', '')
      },
      qhtwo(num) {
        this.twoid = num;
        this.fhhq('', num, '')
      },
      qhthree(num) {
        this.threeid = num;
        this.fhhq('', '', num)
      },
      fhhq(one, two, three) {
        this.list(this.oneid, this.twoid, this.threeid);
      },


      qhfour(num) {
        this.fourid = num;
        this.fhhq1(num, '', '')
      },
      qhfive(num) {
        this.fiveid = num;
        this.fhhq1('', num, '')
      },
      qhsex(num) {
        this.sexid = num;
        this.fhhq1('', '', num)
      },
      qhevent(num) {
        this.eventid = num;
        this.fhhq1('', '', '',num)
      },
      qheight(num) {
        this.eightid = num;
           this.fhhq1('', '','', '',num)
      },
      qhnine(num) {
        this.nineid = num;
           this.fhhq1('', '','','','',num)
      },
      qhten(num) {
        this.tenid = num;
          this.fhhq1('', '','','','','',num)
      },

      fhhq1(four,five,sex,event,eight,nine,ten) {
        this.list(this.fourid,this.fiveid,this.sexid,this.eventid,this.eightid,this.nineid,this.tenid);
      },

      qhone1(num){
        this.one1id = num;
        this.fhhq2(num,'')
      },
      qhtwo1(num){
        this.two1id = num;
        this.fhhq2('', num)
      },
      fhhq2(nine,ten) {
        this.list(this.one1id,this.two1id);
      },
    }
  }
</script>

<style lang="scss" scoped="scoped">
  .cen-top {
    background: #fff;
    padding: 1.25rem;

    .hang {
      display: flex;
      font-size: 0.975rem;
      align-items: center;
      margin-bottom: 0.9375rem;
      flex-wrap: wrap;

      .h-title {
        font-weight: bold;
        margin-bottom: 1rem;
      }

      .h-count {
        padding: 0.525rem 1rem;
        background: #F5F5F5;
        color: #666666;
        margin-right: 0.9375rem;
        cursor: pointer;
        margin-bottom: 1rem;
        border-radius: 0.36rem;
      }

      .qb {
        background: #2E9BD6;
        color: #fff;
      }
    }
  }
  
  @media screen and (max-width: 1300px) {
    .h-count{
      margin-right: 0.4rem!important;
      padding: 0.525rem 1rem!important;
      font-size: 0.9rem!important;
      margin-bottom: 0.8rem!important;
    }
  
  }
  
</style>
