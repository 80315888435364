<template>
    <div class="left" @click="fenxiang()">
      <div class="one op">
        <div class="">
          分享至
        </div>
        <img src="~assets/meeting/fxz.png" />
      </div>

    </div>
    <div class="yuan" v-show="pdshow==false" @click="canzhan()">
      <img src="~assets/meeting/yuan.png" />
    </div>
    <div class="ys" v-show="pdshow==false" @click="canzhan()" >我要参展</div>

    <!-- v-show="pdshow==false"
    v-show="pdshow==false" -->
</template>

<script>

  import {useRouter} from 'vue-router';
  import wx from "weixin-js-sdk";

  import {
     getSing,
   } from 'network/neeting.js';
  export default{
    name:'Left',
    props: ['can','pdshow','fff'],
    setup(){
      //跳转方法
      const router=useRouter();
      const goTo=(path,query)=>{
        console.log(query);
        query={name:'nn'}
        console.log(query);
        router.push({
          path:path,
          query:query || {}
        })
      }

      return {goTo}

    },

    methods: {
      canzhan(){
        console.log('1');
        this.can();
      },

      getShareInfo() {
        //获取url链接（如果有#需要这么获取）
        var url = encodeURIComponent(window.location.href.split("#")[0]);
      //获取url链接（如果没有#需要这么获取）
       // var url = encodeURIComponent(window.location.href);
        getSing(url).then(res => {
          wx.config({
            debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
            appId: res.data.appId, // 必填，公众号的唯一标识
            timestamp: parseInt(res.data.timestamp), // 必填，生成签名的时间戳
            nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
            signature: res.data.signature, // 必填，签名
            jsApiList: [
              "updateAppMessageShareData",
              "updateTimelineShareData"
            ] // 必填，需要使用的 JS 接口列表
          });
          wx.ready(() => {
            var shareData = {
              title: "每日新闻",
              desc: "2022年12月20日21:47:55每日新闻",
              link: window.location.href,
              imgUrl: "https://blogobs.88688.team/blog/l-logo-y.jpg"
            };
            //自定义“分享给朋友”及“分享到QQ”按钮的分享内容
            wx.updateAppMessageShareData(shareData);
            //自定义“分享到朋友圈”及“分享到 QQ 空间”按钮的分享内容（1.4.0）
            wx.updateTimelineShareData(shareData);
          });
          //错误了会走 这里
          wx.error(function (res) {
            console.log("微信分享错误信息", res);
          });
        });
      },
      fenxiang(){
       this.xy=!this.xy;

       this.fff(this.xy);
      },
    }
  }
</script>

<style lang="scss" scoped="scoped">
  .ys{
     position: fixed;
     top: 53.8%;
     right: 1.5rem;
     z-index: 9999;
     transform: translate(0, -50%);
     width: 2.875rem;
     font-size: 1.2rem;
     letter-spacing: 0.2rem;
     cursor: pointer;
  }
  .yuan{
    cursor: pointer;
    position: fixed;
    top: 54%;
    right: 0;
    z-index: 9999;
    transform: translate(0,-50%);
    img{
      width: 6.25rem;
    }
  }
  .left{
    position: fixed;
    top: 40%;
    right: 0;
    z-index: 9999;
    transform: translate(0,-50%);
    .one{
      background: #fff;
      cursor: pointer;
      text-align: center;
      padding: 1.05rem 1.05rem;
      font-size: 0.6125rem;
      // box-sizing: 0.0625rem 0.0625rem 0.125rem rgba(0,0,0,0.3);
      box-shadow: -0.17rem 0.15rem 0.625rem rgba(0,0,0,0.26);
      // box-shadow: -0.37rem ​0.43rem 0.62rem rgba(0,0,0,0.26);
      // rgba(0,0,0,0.2);
      img{
         width: 2.275rem;
         margin-top: 0.525rem;
      }
    }
  }
  .od{
    margin-top: 1.075rem;
  }
  .op{
    margin-bottom: 1.075rem;
  }

  @media screen and (max-width: 1300px) {
      .left{
        display: none;
      }
      .ys{
        font-size: 0.9rem!important;

          top: 91.5%!important;
          right: 0.78rem!important;


          letter-spacing: 0.2rem;
          cursor: pointer;

      }
      .yuan {
        top: 92%!important;
        img{
          width: 5.25rem!important;
        }
      }

  }
</style>
