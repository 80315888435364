<template>
  <div class="all">
    <Head :top="top" :qhs="qh" :grxxff="grxxff"></Head>
    <!-- <AboutView></AboutView> -->

    <Bread :all="all"></Bread>
    <!--  <div class="z-o">
          <div class="zo-top">
      </div>
    </div> -->
    <div class="zong">
      <div class="zo-cen">
        <div class="zoc-left">
          <div class="zocl-top ">联系我们</div>
          <div class="zocl-cen" @click="qh(0)" :class="v==0?'bj':''">平台简介</div>
          <div class="zocl-cen" @click="qh(1)" :class="v==1?'bj':''">联合体地址</div>
          <div class="zocl-cen" @click="qh(2)" :class="v==2?'bj':''">隐私协议</div>
          <!-- bs==1?'bs':'' -->

        </div>
        <div class="zoc-right" v-if="v==0">
          <div class="top-bt">
            <div class="tbt">平台简介</div>
            <div class="tnr">Platform Introduction</div>
          </div>
          <div class="" v-html="nrxq.ptcontent"></div>
        </div>
        <div class="zoc-right ld" v-if="v==1">

          <div class="top-bt">
            <div class="tbt">联合体地址</div>
            <div class="tnr">Alliance Address</div>
          </div>


          <div class="" v-html="nrxq.adcontent"></div>
         <!-- <div class="app-container">
            <div style="background-color: #ffffff;">
              <div id="container"></div>
            </div>
          </div> -->



        </div>

        <div class="zoc-right ld" v-if="v==2">
          <div class="top-bt">
            <div class="tbt">隐私协议</div>
            <div class="tnr">Privacy Agreement</div>
          </div>
          <!-- <div class="">asdasd1as32d123as12d3asdasd1as32d123as12d3</div> -->
          <div class="" v-html="nrxq.yxcontent"></div>
        </div>



      </div>
    </div>


    <foot></foot>
    <left></left>
  </div>
</template>

<script>
  const ready = ({
    BMap,
    map
  }) => {
    //  对地图进行自定义操作
  };

  import AMapLoader from '@amap/amap-jsapi-loader';
  /*在Vue3中使用时,需要引入Vue3中的shallowRef方法(使用shallowRef进行非深度监听,
  因为在Vue3中所使用的Proxy拦截操作会改变JSAPI原生对象,所以此处需要区别Vue2使用方式对地图对象进行非深度监听,
  否则会出现问题,建议JSAPI相关对象采用非响应式的普通对象来存储)*/
  import {
    shallowRef
  } from '@vue/reactivity';



  import {
    ref,
    onMounted
  } from 'vue';
  import {
    getHome
  } from 'network/home.js';
  import {
    useRouter
  } from 'vue-router';
  import {getfus} from 'network/us.js';
  // import { BaiduMap } from 'vue-baidu-map-3x'
  import Head from "components/content/headder/Head.vue";
  import Foot from "components/content/footer/Foot.vue";
  import Left from "components/content/left/Left.vue";

  import Bread from "components/content/breadcrumb/Breadcrumb.vue";
  import Map from "components/content/map/Map.vue";

  // import {
  //   AboutView
  // } from '../AboutView.vue';
  export default {
    name: 'HomeView',
    data() {
      return {
        nrxq:{},
        ak: '2rRRzsZa0yBl4ng9Qouw57Bt2IoMULtY',
        center: {
          lng: 0,
          lat: 0
        },
        zoom: 3,
        bs: 0,
        v: 0,

        // bs: 2,
        // v: 0,
        all: [{
            name: '您当前的位置:首页',
            url: '/'
          },
          {
            name: '关于联合体',
            url: '/about?i=1'
          },
          {
            name: '联合体简介',
            url: '/about?i=1'
          },
        ]
      }
    },
    mounted() {
      console.log('sbc');
      // let _this = this;
      // this.$nextTick(() => {

      //   baiduMap(_this.ak).then((mymap) => {
      //     // 创建地图实例
      //     let map = new BMap.Map("map");
      //     let point = new BMapGL.Point(119.949506, 29.089524);
      //     map.centerAndZoom(point, 10); //设置缩放级别
      //   })
      // };



    },

    setup() {
      const top = 10;
      const router = useRouter();
      const databa = ref([]);
      const ab = 'sb';
      console.log(ab);

      onMounted(() => {
        // var _this=this;
        // baiduMap('TmT50SOR89GCHLOQxvlSQF1BN7BtZ5V7').then((mymap) => {
        //   // 创建地图实例
        //   let map = new BMap.Map("map");
        //   let point = new BMapGL.Point(119.949506, 29.089524);
        //   map.centerAndZoom(point, 10); //设置缩放级别
        // })



        getHome().then(res => {
          console.log(res.data.data);
          databa.value = res.data;
          console.log(databa);

        }).catch(err => {

        })

      })
      //跳转方法
      const goTo = (path, query) => {
        router.push({
          path: path,
          query: query || {}
        })
      }

      return {
        databa,
        ab,
        goTo,
        Map,
        top
      }
    },
    mounted() {
 window.scrollTo( 0, 0 );
      // var url = decodeURI(window.location.search); //?id="123456"&name="www";
      // // var url = window.location.href;
      // console.log(url);
      // var object = {};
      // if (url.indexOf("?") != -1) //url中存在问号，也就说有参数。
      // {
      //   var str = url.substr(1); //得到?后面的字符串
      //   var strs = str.split("&"); //将得到的参数分隔成数组[id="123456",name="www"];
      //   for (var i = 0; i < strs.length; i++) {
      //     object[strs[i].split("=")[0]] = strs[i].split("=")[1]; //得到{id:'123456',name:'www'}
      //   }
      // }
      // console.log(object);
      // var fl = object['fl'];
      var fl = this.GetUrlParam('fl');
      if(fl){
        this.qh(fl);
      }


      const path = ref([]);
      const current_position = ref([]);
      window._AMapSecurityConfig = {
        securityJsCode: '8e920f73eb2e6880a92ea6662eefc476',
      }
      AMapLoader.load({
        key: "c6ee811a8c4b9b8bc88ec5fb53067283", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        // plugins:[''], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then((AMap) => {
        const map = new AMap.Map("container", { //设置地图容器id
          viewMode: "3D", //是否为3D地图模式
          zoom: 13, //初始化地图级别
          center: [113.808299, 34.791787], //初始化地图中心点位置
        });
        // 添加插件
        AMap.plugin(["AMap.ToolBar", "AMap.Scale", "AMap.HawkEye", "AMap.Geolocation", "AMap.MapType",
          "AMap.MouseTool"
        ], function() {
          //异步同时加载多个插件
          // 添加地图插件
          map.addControl(new AMap.ToolBar()); // 工具条控件;范围选择控件
          map.addControl(new AMap.Scale()); // 显示当前地图中心的比例尺
          map.addControl(new AMap.HawkEye()); // 显示缩略图
          map.addControl(new AMap.Geolocation()); // 定位当前位置
          map.addControl(new AMap.MapType()); // 实现默认图层与卫星图,实时交通图层之间切换

          // 以下是鼠标工具插件
          const mouseTool = new AMap.MouseTool(map);
          // mouseTool.rule();// 用户手动绘制折线图,测量距离
          mouseTool.measureArea(); // 测量面积
        });
        // 单击
        map.on('click', (e) => {
          // lng ==> 经度值  lat => 维度值
          current_position.value = [e.lnglat.lng, e.lnglat.lat];
          path.value.push([e.lnglat.lng, e.lnglat.lat]);
          // addMarker();
          // addPolyLine();
        })


        // 实例化点标记
        // 第一种(封成函数来触发)
        function addMarker() {
          const marker = new AMap.Marker({
            icon: "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png",
            position: current_position.value, // 这里我们通过上面的点击获取经纬度坐标,实时添加标记
            // 通过设置 offset 来添加偏移量
            offset: new AMap.Pixel(-26, -54),
          });
          marker.setMap(map);
        }
        // 第二种 直接写死 position 的经纬度值
        /*const marker = new AMap.Marker({
            icon: "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png",
            position: [113.808299,34.791787],
            // 通过设置 offset 来添加偏移量
            offset: new AMap.Pixel(-26, -54),
        });
        marker.setMap(map);*/


        // 折线
        function addPolyLine() {
          const polyline = new AMap.Polyline({
            path: path.value,
            isOutline: true,
            outlineColor: "#ffeeff",
            borderWeight: 1,
            strokeColor: "#3366FF",
            strokeOpacity: 0.6,
            strokeWeight: 5,
            // 折线样式还支持 'dashed'
            strokeStyle: "solid",
            // strokeStyle是dashed时有效
            // strokeDasharray: [10, 5],
            lineJoin: "round",
            lineCap: "round",
            zIndex: 50,
          });
          map.add([polyline]);
        }



      }).catch(e => {
        console.log(e);
      })
      this.getfus1();

    },
    methods: {
      GetUrlParam(paraName) {
      var url = window.location.toString();
      var arrObj = url.split("?");
      if (arrObj.length > 1) {
        var arrPara = arrObj[1].split("&");
        var arr;
        for (var i = 0; i < arrPara.length; i++) {
          arr = arrPara[i].split("=");
          if (arr != null && arr[0] == paraName) {
            return arr[1];
          }
        }
        return "";
      } else {
        return "";
      }
    },
      grxxff(grxxs){
        this.grxx=grxxs;
        console.log(grxxs);
        console.log('zhende');
      },
    getfus1(ids){

      var that = this;
      var data = {

      };
      getfus(data).then(res => {

        console.log('zx');
        console.log(res);
        that.nrxq = res.data.data;



      }).catch(err => {})
    },

      handler({
        BMap,
        map
      }) {
        console.log(BMap, map)
        this.center.lng = 116.404
        this.center.lat = 39.915
        this.zoom = 15
      },
      qh(it) {
        console.log(it);

        this.v = it;
        this.bs = 2;
      },
      bsa(bbs) {
        this.bs = bbs;
      }
    },
    components: {
      Head,
      Foot,
      Left,
      // AboutView,
      Bread
    },
  }
</script>
<style lang="scss" scoped="scoped">
  .zong {
    width: 100vw;
    background: #F5F6FA;

    .zo-cen {
      width: var(--allWidth);
      margin: 0 auto;
      display: flex;

      .zoc-left {
        width: 14%;
        border-left: 0.125rem dotted #D7D7D7;


        .zocl-top {
          font-size: 1rem;
          font-weight: bold;
          padding: 1.625rem;
        }

        .zocl-cen {
          padding: 1.35rem;
          font-size: 0.9125rem;
          cursor: pointer;
        }

        .zocl-foot {
          display: none;
          width: 100%;
          padding: 0.925rem 1.3rem;
          background: #fff;
          font-size: 0.8125rem;
          border-bottom: 0.0625rem dotted #EEEEEE;
          cursor: pointer;
        }

        .xs {
          display: block;
        }

        .bsx {
          color: #2E9BD6;
        }

        .bj {
          background: url(assets/about/fw.png) no-repeat;
          background-size: 100% 100%;
          color: #fff;
        }

      }

      .zoc-right {
        width: 86%;
        padding: 1.875rem;
        background: #fff;
        margin: 1.875rem;
        position: relative;
        min-height: 50rem;

        //列表
        .z-bt {
          border-bottom: 0.0625rem solid #DBDBDB;
          padding-bottom: 1.875rem;
          font-weight: bold;
          font-size: 1.2rem;

          img {
            width: 1.075rem;
            margin-right: 0.625rem;
          }
        }

        .z-nrk {
          padding: 1rem;

          .z-n-one {
            padding-bottom: 1rem;
            border-bottom: 0.0625rem solid #DBDBDB;
            display: flex;
            align-items: center;
            margin-bottom: 1rem;
            cursor: pointer;

            .zno-left {
              margin-right: 1.875rem;

              img {
                width: 8.875rem;
              }
            }

            .zno-right {
              .znor-title {
                font-size: 1.075rem;
                font-weight: bold;
                margin-bottom: 1.075rem;
              }

              .znor-content {
                font-size: 0.8rem;
                color: #888888;
              }
            }
          }

        }

        //列表结束
        .shude {
          position: absolute;
          top: 17.875rem;
          right: 4.875rem;
          height: 35.65rem;
          border-left: 0.065rem dotted #D7D7D7;
          margin: 0 auto;
          width: 0.0625rem;
        }

        .top-bt {
          border-bottom: 0.0625rem solid #DBDBDB;
          padding-bottom: 1.875rem;
          text-align: center;

          .tbt {
            font-size: 1.875rem;
            font-weight: bold;
          }

          .tnr {
            color: #9A9A9A;
            font-size: 0.8125rem;
            margin-top: .875rem;
          }
        }

        .hx {
          display: flex;
          align-items: center;
          justify-content: center;

          .h-nr {
            text-align: center;
            margin-top: 1.875rem;

            .h-t {
              font-size: 1.1375rem;
              font-weight: bold;
              margin-bottom: 0.925rem;

            }

            .h-b {
              padding: 0.8rem 1.25rem;
              border: 0.0625rem solid #AFC81D;
              background: #F1F9E9;

            }
          }

          .xian {
            width: 17%;
            height: 0.0625rem;
            border-bottom: 0.065rem dotted #BABABA;
            margin-top: 4rem;
          }

        }

        .jxi {
          display: flex;
          align-items: center;
          justify-content: center;

          .sxian {
            height: 6.25rem;
            width: 0.0625rem;
            border-left: 0.065rem dotted #BABABA;
            // margin-top: 4rem;
          }

          .xian1 {
            width: 17%;
            height: 6.25rem;
            border-bottom: 0.065rem dotted #BABABA;
            margin-top: -6rem;
          }

          .mishuchu {
            padding: 0.625rem 1.875rem;
            background: #F0F8FD;
            border: 0.125rem solid #2E9BD6;

          }

          .bs {
            padding: 0.625rem 1.875rem;
            background: rgba(0, 0, 0, 0);
            border: 0.125rem solid rgba(0, 0, 0, 0);
            ;
            color: #FFFFFF;
          }

        }



        .jb {
          .cws {
            width: 9rem;
            text-align: center;
            background: #F1F9E9;
            padding: 0.75rem 0;
            border: 0.125rem solid #A8BF29;
          }
        }

        .dbsn {
          margin-left: 7.5rem;

          .fhz {
            background: #F0F8FD;
            border: 0.0625rem solid #2E9BD6;
            text-align: center;
            padding: 0.8125rem 0;
            width: 9rem;
            margin-right: 0.825rem;
          }

          .fhz1 {
            width: 9rem;
            margin-right: 0.825rem;
            padding: 0.8125rem 0;
            text-align: center;

            .thl {
              // margin-bottom: 0.625rem;
              padding: 0.5125rem 0.8rem;
              border: 0.0625rem solid #B3B3B3;
              font-size: 0.8125rem;


            }

            .thlxxian {
              height: 1.25rem;
              border-left: 0.065rem dotted #D7D7D7;
              margin: 0 auto;
              width: 0.0625rem;

            }
          }

          .jd {
            border: 0.125rem dotted #D7D7D7;
            width: 5rem;
            padding: 0.8125rem 0;
            font-size: 0.8125rem;

            .jhl {
              padding: 0.5125rem 0;
            }
          }

          // .zj{
          //   margin-right: 4.3rem;
          // }
        }

        .ygdh {
          margin-top: 1.875rem;

          .nr {
            font-size: 1.125rem;
            text-align: center;
            width: 9rem;
            border: 0.125rem solid #D7D7D7;
            padding: 1rem;

          }

          .xians {
            height: 0.0625rem;
            width: 70%;
            border-bottom: 0.065rem dotted #BABABA;
            width: 78.4%;
          }
        }

      }
    }
  }

  .zd {
    padding: 0 !important;
  }

  .bs {
    background: rgba(0, 0, 0, 0) !important;
    border: 0.125rem solid rgba(0, 0, 0, 0) !important;
    color: rgba(0, 0, 0, 0) !important;
  }

  .jb {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .jbm {}

  .xian1 {
    width: 13%;
    height: 6.25rem;
    border-bottom: 0.065rem dotted #BABABA;
    margin-top: -6rem;
  }

  .xian1ban {
    width: 6%;
    height: 6.25rem;
    border-bottom: 0.065rem dotted #BABABA;
    margin-top: -6rem;
  }

  .shu {
    height: 3.25rem;
    width: 0.0625rem;
    border-left: 0.065rem dotted #BABABA;
  }

  .jbmz {
    justify-content: left;
  }

  .bv {
    margin-left: 22.075rem;
  }

  .on {
    margin-right: 19.53%;
  }

  .jx {
    // margin: 0 10rem;
    // margin-right: 11.3rem;
    // margin-right: 13.63rem;
    // margin-left: 12.5rem;
    margin-right: 19.53%;
  }

  .th {}

  .zb {
    margin: 0;
  }

  .yb {
    margin: 0;
  }

  .bb {
    margin-right: 6rem;
  }

  .shsha {
    margin-left: 12rem;
  }

  .szaa {
    margin-left: 12rem;

    .aa1 {
      width: 12%;

    }

    .aa2 {
      width: 27.5%;
    }
  }

  .bsa {
    width: 27% !important;
  }

  .xian1zj {
    width: 10%;
    border-bottom: 0.065rem dotted #BABABA;
    height: 6.25rem;
    margin-top: -9.5rem;
  }

  .ba {
    border: 0.065rem solid #FFFFFF !important;
    background: linear-gradient(to top, #EF8C09, #FFCE96) !important;
    color: #fff;
    box-shadow: 0.1875rem 3px 5px rgba(0, 0, 0, 0.3);
  }

  .bsb {
    border: 0.065rem solid #FFFFFF !important;
    background: linear-gradient(to top, #1C81BD, #81D0FF) !important;
    color: #fff;
    box-shadow: 0.1875rem 3px 5px rgba(0, 0, 0, 0.3);
  }

  .dj {
    cursor: pointer;
  }

  .map {
    width: 100%;
    height: 18.75rem;
  }
  
  @media screen and (max-width: 1300px) {
    .zo-cen{
      width: 100%!important;
      flex-wrap: wrap;
      .zoc-left{
        width: 95%!important;
        margin: 0 auto;
        .zocl-cen{
          display: inline-block;
          width: 30%;
          text-align: center;
          background: #b3b4b5;
          color: #fff;
          border-radius: 1.9375rem;
          padding: 0.7rem!important;
          margin-right: 5%;
        }
        .zocl-cen:nth-child(4n){
          margin-right: 0%;
        }
      }
    }
    .zoc-right{
      width: 95%!important;
      padding: 0.6rem 0.6rem!important;
      background: #fff;
      margin: 0 auto!important;
      position: relative;
      min-height: 50rem;
      margin-top: 18px;
      margin-top: 0.625rem;
      .top-bt{
  
      }
      .hx{
        .h-nr{
          .h-t{
                font-size: 1rem!important;
          }
          .h-b{
            font-size: 0.6rem!important;
          }
        }
  
      }
      .jxi{
  
        .mishuchu {
            width: 20%!Important;
            font-size: 1rem;
            padding: 0 0.875rem!important;
            text-align: center;
            margin: 0;
        }
  
        .xian1 {
          width: 19%!Important;
          font-size: 0;
          padding: 0;
          margin: 0;
        }
        .bs{
          // width: 10%!important;
        }
        .sxian{
          width: 2%;
        }
  
  
      }
  
  
    }
    .xian1zj{
      width: 0%!important;
    }
    .jbmz{
      .bv{
        margin-left: 12.075rem;
      }
      .xian1zj {
        width: 6%!important;
      }
  
    }
    .szaa,.shsha{
      margin-left: 5rem!important;
    }
  
    .dbsn{
      margin-left: 0rem!important;
      .fhz{
        width: 7rem!important;
        margin-right: 0.825rem;
        font-size: 0.6rem!important;
      }
      .fhz1 {
        width: 7rem!important;
        font-size: 0.6rem!important;
      }
      .jd{
        margin-top: 24px;
        width: 3rem!important;
  
      }
    }
    .shude {
      top: 16.175rem!important;
      right: 0.675rem!important;
      height: 138.65rem!important;
    }
    .ygdh{
      .nr{
            font-size: 0.6rem!important;
      }
      .xians{
            height: 1.3625rem!important;
      }
    }
    .zoc-left{
      margin-bottom: 0.625rem!important;
    }
  }
  .zocl-cen:hover{
    color: #2E9BD6;
  }
</style>
