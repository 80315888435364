<template>
  <div class="all">
    <Head :top="top" :grxxff="grxxff"></Head>
    <div class="bn">
      <img src="~assets/new/banner.png" />
    </div>
    <Bread :all="all"></Bread>
    <div class="a-cen">
      <div class="center">
        <div class="c-left">
          <!-- <iframe :src="govurl" frameborder="0" :height="screenHeight - 84"></iframe> -->
          <div class="fwb" v-html="news.content"></div>
          <LeftChild
            :dianzan="dianzan"
            :zan="news.zan"
            :fff="fenxiangs"
          ></LeftChild>
        </div>

        <div class="c-right">
          <Notice :pd="pd" :getChildInfo="getChildInfo"></Notice>
        </div>
      </div>
    </div>
    <el-dialog :title="title" align-center width="25rem" v-model="xy">
      <!-- width="80%" -->
      <div class="ewm">
        <div class="tp">
          <img
            :src="'https://ymedicine.jiujingwulian.com/public' + news.ewmimage"
          />
        </div>
        <div class="dibu">
          <ul>
            <li><div>打开微信，点击底部的“发现”</div></li>
            <li><div>使用“扫一扫”即可选择将网页分享至朋友圈。</div></li>
          </ul>
        </div>
      </div>
    </el-dialog>

    <!-- <div >测试</div> -->
    <!-- el-backtop :bottom="100">
        <div
          style="
            height: 100%;
            width: 100%;
            background-color: var(--el-bg-color-overlay);
            box-shadow: var(--el-box-shadow-lighter);
            text-align: center;
            line-height: 40px;
            color: #1989fa;
          "
        >
          UP
        </div>
      </el-backtop> -->

    <foot></foot>
    <left></left>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { getHome, getonenew } from "network/home.js";
import { useRouter } from "vue-router";

import { ElMessage, ElMessageBox } from "element-plus";

import { newdianzan } from "network/neeting.js";

import Head from "components/content/headder/Head.vue";
import Foot from "components/content/footer/Foot.vue";
import Left from "components/content/left/Left.vue";
import Bread from "components/content/breadcrumb/Breadcrumb.vue";
import Notice from "components/content/notice/Notice.vue";

import LeftChild from "./ChildComps/Left.vue";
import Dialog from "components/content/dialog/Dialog.vue";

export default {
  name: "New",

  data() {
    return {
      govurl:
        "https://uland.taobao.com/coupon/edetail?spm=a2e1u.27659560.d1661852343037.5.7cc0ae1bNaV3A3&e=loSo%2FT3PF3UNfLV8niU3R2SW7JwcTWmj384G9Q2tXS8jwsU0fvr4dmU8anpql0vQvcIg4QaNWWySLoZp8blMnbYmgEUy%2BMIqNg4Gqf8CT4BnmB%2Fzds2ljV9pxKQXLD0okUECLKtT4Bjw1oHTOj1iB6I2E5x5XKgqCzdc8AehVecAwH0cOugOctrnn2pXDyl8AATL9bnEEXJ94mfWPWHaKZsxeJWIPFv7VNQ7itDMiL1Q7t%2BNebBYmMlMTjSQsK4p5bgGAUYJbG0%3D&app_pvid=59590_33.5.2.55_880_1676970321468&ptl=floorId%3A61783%3Bapp_pvid%3A59590_33.5.2.55_880_1676970321468%3Btpp_pvid%3A96775ce7-a4c8-4808-becc-b7b37e7ff2b3&union_lens=lensId%3AOPT%401662614242%402127862a_0a65_1831b88064b_03f3%40024LN1IxZAiVJmYH9pyBNF0J%40eyJmbG9vcklkIjo2MTc4M30ie%3Bscm%3A1007.15348.115058.0_61783_96775ce7-a4c8-4808-becc-b7b37e7ff2b3%3Brecoveryid%3A201_33.7.200.130_11018271_1676970320582%3Bprepvid%3A201_33.7.200.130_11018271_1676970320582&pid=mm_12408063_2637500334_112315000180",
      screenHeight: "",
      xy: false,
      title: "分享至微信朋友圈",
      pd: 2,
      news: {},
      bn: "~assets/new/banner.png",
      all: [
        {
          name: "您当前的位置:首页",
          url: "/",
        },
        {
          name: "资讯",
          url: "/New",
        },
        {
          name: "新闻详情名称",
        },
      ],
    };
  },
  setup() {
    const route = useRouter();
    console.log(route.query);

    const top = 31;
    const router = useRouter();
    const databa = ref([]);
    const ab = "sb";
    console.log(ab);

    onMounted(() => {
      getHome()
        .then((res) => {
          console.log(res.data.data);
          databa.value = res.data;
          console.log(databa);
        })
        .catch((err) => {});
    });
    //跳转方法
    const goTo = (path, query) => {
      router.push({
        path: path,
        query: query || {},
      });
    };

    return {
      databa,
      ab,
      goTo,
      top,
    };
  },
  mounted() {
    this.screenHeight = document.body.clientHeight;
    window.onresize = () => {
      this.screenHeight = document.body.clientHeight;
    };
    var id = this.GetUrlParam("id");

    console.log(id);
    console.log(222222);
    this.qh(id);

    window.scrollTo(0, 0);
  },
  methods: {
    GetUrlParam(paraName) {
      var url = window.location.toString();
      var arrObj = url.split("?");
      if (arrObj.length > 1) {
        var arrPara = arrObj[1].split("&");
        var arr;
        for (var i = 0; i < arrPara.length; i++) {
          arr = arrPara[i].split("=");
          if (arr != null && arr[0] == paraName) {
            return arr[1];
          }
        }
        return "";
      } else {
        return "";
      }
    },

    dianzan() {
      //点赞
      console.log(this.grxx.id);
      if (!this.grxx.id) {
        ElMessageBox.confirm("您未登录是否登录?", "提示", {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          type: "提示",
        })
          .then(() => {
            this.$router.push("/login");
          })
          .catch(() => {
            ElMessage({
              type: "info",
              message: "取消成功",
            });
          });

        return false;
      }

      var that = this;
      var data = {
        uid: this.grxx.id,
        nid: this.news.id,
      };
      // this.news.zan=this.news.zan+1;
      newdianzan(data)
        .then((res) => {
          // console.log(res.data);
          // console.log(res);
          if (res.data == "成功") {
            that.news.zan = that.news.zan + 1;
          }
        })
        .catch((err) => {});
    },
    grxxff(grxxs) {
      this.grxx = grxxs;
      console.log(grxxs);
      console.log("zhende");
    },
    getChildInfo(ids) {
      console.log("b");
      this.qh(ids);
    },
    fenxiangs(lx) {
      console.log("1");
      this.xy = !this.xy;
      console.log(this.xy);
    },
    qh(ids) {
      if (ids) {
        var k_host = window.location.host;
        var K_http=document.location.protocol;
        console.log(k_host);
        console.log(K_http);
        window.location.href = K_http+"//"+k_host+"/#/newin?id=" + ids;
      }
      var that = this;
      var data = {
        id: ids,
      };
      getonenew(data)
        .then((res) => {
          that.news = res.data.data;
          console.log("zx");
          var all = that.all;
          all[2]["name"] = that.news.name;
          that.all = all;
          // console.log();
        })
        .catch((err) => {});
    },
  },
  components: {
    Head,
    Foot,
    Left,
    LeftChild,
    Bread,
    Dialog,
    Notice,
  },
};
</script>
<style lang="scss" scoped="scoped">
.r-bot {
  margin-top: 0 !important;
}
.a-cen {
  width: 100vw;
  background: #f5f6fa;

  .center {
    width: var(--allWidth);
    margin: 0 auto;
  }
}

.bn {
  width: 100%;

  img {
    width: 100%;
  }
}

.center {
  padding: 1.25rem 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .c-left {
    width: 70%;
    background: #fff;
    padding: 1.25rem;
    min-height: 50vh;
    position: relative;
    .fwb {
      width: 80%;
      margin: 0 auto;
    }
  }

  .c-right {
    width: 28%;

    .r-ban {
      width: 100%;

      img {
        width: 100%;
      }
    }

    .r-bot {
      background: #fff;
      min-height: 6.25rem;
      margin-top: 0.625rem;
      padding: 1.225rem;

      .r-top-title {
        font-size: 1.175rem;
        font-weight: bold;
      }
    }

    .r-top {
      background: #fff;
      min-height: 6.25rem;
      margin-top: 0.625rem;
      padding: 1.225rem;

      .r-top-title {
        font-size: 1.175rem;
        font-weight: bold;
      }

      .r-top-list {
        margin-top: 1.225rem;

        .r-top-time {
          font-size: 0.875rem;
          color: #a7a7a7;
          display: flex;
          align-items: center;

          img {
            width: 0.8075rem;
            margin-right: 0.625rem;
          }
        }

        .r-top-cot {
          margin-left: 1.625rem;
          margin-top: 0.325rem;
          font-size: 0.875rem;
        }
      }

      .r-bot {
      }
    }
  }
}

.l-bot {
  margin-top: 1.875rem;

  .z-n-one:hover {
    background: linear-gradient(
      rgba(98, 186, 238, 0.1568627450980392) 0%,
      rgba(196, 196, 196, 0) 100%
    );

    .znor-title {
      color: #2e9bd6;
    }
  }

  .z-n-one {
    padding: 0.825rem;
    padding-bottom: 1rem;
    border-bottom: 0.0625rem solid #dbdbdb;
    display: flex;
    align-items: center;
    // margin-bottom: 1rem;
    background: #fff;
    cursor: pointer;

    .zno-left {
      margin-right: 1.875rem;

      img {
        width: 9.875rem;
      }
    }

    .zno-right {
      .znor-title {
        font-size: 1.075rem;
        font-weight: bold;
        margin-bottom: 0.675rem;
      }

      .znor-content {
        font-size: 0.7rem;
        color: #888888;
        line-height: 1rem;
      }

      .rq {
        font-size: 0.7rem;
        color: #888888;
        margin-top: 0.625rem;
      }
    }
  }

  .z-n-one1 {
    padding: 0;
    padding-bottom: 0.8rem;
    align-items: normal;

    .zno-left {
      margin-right: 0.675rem;

      img {
        width: 7rem;
      }
    }

    // .zon-left{
    //   img{
    //     width: 10%!important;
    //   }
    // }
  }

  .zno-right {
    padding-top: 0.25rem;
  }
}
@media screen and (max-width: 1300px) {
  .c-left {
    width: 95% !important;
    margin: 0 auto;
  }
  .c-right {
    width: 95% !important;
    margin: 0 auto;
    margin-top: 0.625rem;
  }
}

.ewm {
  width: 90%;
  margin: 0 auto;
}
.tp {
  text-align: center;
  width: 60%;
  margin: 0 auto;
  margin-bottom: 1.875rem;
  img {
    width: 100%;
  }
}
.dibu {
  div {
    margin-bottom: 0.625rem;
    // text-emphasis: circle #f00;
  }
}
iframe {
  display: block;
  background: white;
  border: none;
  width: 100%;
}
</style>
