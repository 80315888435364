import { request } from "./request.js";

export function getffyb(params){
    return request(
      {
          url:'/public/index/index/ffyb',
          method:'POST',
          params:params
      }
    )
}
