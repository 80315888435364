<template>
  <div class="all">
    <Head :top="top" :grxxff="grxxff"></Head>
    <div class="center">
      <div class="c-all">
        <MyLeft :topleft="topleft"></MyLeft>

        <div class="c-right">
          <div class="c-top" >
            <img  @click="fanhui()" src="~assets/about/fh.png" />
            <div class="zhongjian">
              <div class="z-one" @click="qh(0)" :class="zt==0?'zs':''">未读消息</div>
              <div class="z-one" @click="qh(1)" :class="zt==1?'zs':''">已读消息</div>
              <div class="z-one" @click="qh(2)" :class="zt==2?'zs':''">全部</div>
            </div>
          </div>

          <div class="list">
            <div class="l-one" @click="goTo('/',item.type,item.fl_id)" v-for="item in message">
              <div class="l-left">
                <img :src="'https://ymedicine.jiujingwulian.com/public'+item.headimage" />
                <div class="l-nr">
                  <div class="l-n-bt">{{item.uname}}</div>
                  <div class="l-n-nr">{{item.name}}</div>
                </div>
              </div>
              <div class="l-right ">
                <img src="../../assets/my/shijian.png" />
                <div>{{item.time}}</div>
              </div>
            </div>
            <null :list='message' ></null>
                        
            <page :items='items'
            :litems='litems'
            :total="total"
            :per_page="per_page"
            :current_page="current_page"
            :last_page="last_page"
            :listcount='listcount'></page>


<!--
            <div class="l-one">
              <div class="l-left">
                <img src="../../assets/my/tx.png" />
                <div class="l-nr">
                  <div class="l-n-bt">管理员</div>
                  <div class="l-n-nr">您的投融资项目发布已通过审核，请注意查看。</div>
                </div>
              </div>
              <div class="l-right ">
                <img src="../../assets/my/shijian.png" />
                <div>2022年11月21日 13:32</div>
              </div>
            </div>



            <div class="l-one">
              <div class="l-left">
                <img src="../../assets/my/tx.png" />
                <div class="l-nr">
                  <div class="l-n-bt">管理员</div>
                  <div class="l-n-nr">您的投融资项目发布已通过审核，请注意查看。</div>
                </div>
              </div>
              <div class="l-right ">
                <img src="../../assets/my/shijian.png" />
                <div>2022年11月21日 13:32</div>
              </div>
            </div>


            <div class="l-one">
              <div class="l-left">
                <img src="../../assets/my/tx.png" />
                <div class="l-nr">
                  <div class="l-n-bt">管理员</div>
                  <div class="l-n-nr">您的投融资项目发布已通过审核，请注意查看。</div>
                </div>
              </div>
              <div class="l-right ">
                <img src="../../assets/my/shijian.png" />
                <div>2022年11月21日 13:32</div>
              </div>
            </div>


            <div class="l-one">
              <div class="l-left">
                <img src="../../assets/my/tx.png" />
                <div class="l-nr">
                  <div class="l-n-bt">管理员</div>
                  <div class="l-n-nr">您的投融资项目发布已通过审核，请注意查看。</div>
                </div>
              </div>
              <div class="l-right ">
                <img src="../../assets/my/shijian.png" />
                <div>2022年11月21日 13:32</div>
              </div>
            </div> -->

          </div>




        </div>

      </div>
    </div>


    <foot></foot>
    <left></left>
  </div>
</template>

<script>
  import {
    ref,
    onMounted
  } from 'vue';
  import {
    getHome
  } from 'network/home.js';
  import {
    useRouter
  } from 'vue-router';


 import {
    getmessageall
  } from 'network/my.js';



  import Head from "components/content/headder/Head.vue";
  import Foot from "components/content/footer/Foot.vue";
  import Left from "components/content/left/Left.vue";

  import MyLeft from "views/my/ChildComps/MyLeft.vue";
  import Page from "components/content/page/Page.vue";
  import Null from "components/content/errornull/null.vue";
  export default {
    name: 'My',
    data() {
      return {

        items:[
        ],
        litems:[
        ],
        page:0,
        total:'',
        per_page:'',
        current_page:'',
        last_page:'',


        zt: 0,
        // shzt: 1, //shzt 0= 待审核,1=已驳回
        topleft:0,//左边
        message:[],
        radio1: 1,
        textarea2: '',
        options: [{
          value: '城市',
          lable: '0'
        }, ],
        name: '',
        grxx: {},
      }
    },
    setup() {
      const top = 0;
      const router = useRouter();
      const databa = ref([]);
      const ab = 'sb';
      console.log(ab);
      onMounted(() => {

        getHome().then(res => {
          console.log(res.data.data);
          databa.value = res.data;
          console.log(databa);

        }).catch(err => {

        })

      })
      //跳转方法
      const goTo = (path, query,id) => {
        // 项目:0=会议,1=论坛,2=技术成果库,3=需求商机,4=投融资
        if(query=='0'){
          path='';
        }else if(query=='1'){
          path='';
        }else if(query=='2'){
          path='';
        }else if(query=='3'){
          path='';
        }else if(query=='4'){
          path='';
        }
        var list={
          id:id,
        }
        router.push({
          path: path,
          query: list || {}
        })
      }

      return {
        databa,
        ab,
        goTo,
        top
      }
    },
    mounted() {


      // var url = decodeURI(window.location.search); //?id="123456"&name="www";
      // // var url = window.location.href;
      // console.log(url);
      // var object = {};
      // if (url.indexOf("?") != -1) //url中存在问号，也就说有参数。
      // {
      //   var str = url.substr(1); //得到?后面的字符串
      //   var strs = str.split("&"); //将得到的参数分隔成数组[id="123456",name="www"];
      //   for (var i = 0; i < strs.length; i++) {
      //     object[strs[i].split("=")[0]] = strs[i].split("=")[1]; //得到{id:'123456',name:'www'}
      //   }
      // }
      // console.log(object);
      // var lx = object['lx'];
      var lx = this.GetUrlParam('lx');

      this.zt = lx;
      this.getdata(this.zt);

    },


    methods: {

      GetUrlParam(paraName) {
      var url = window.location.toString();
      var arrObj = url.split("?");
      if (arrObj.length > 1) {
        var arrPara = arrObj[1].split("&");
        var arr;
        for (var i = 0; i < arrPara.length; i++) {
          arr = arrPara[i].split("=");
          if (arr != null && arr[0] == paraName) {
            return arr[1];
          }
        }
        return "";
      } else {
        return "";
      }
    },

      listcount(page){
        this.page=page;
        this.getdata(this.zt);
      },


      getdata(lx){

        console.log(this.grxx.id);
        console.log('jll');

        var that = this;
        var data = {
          status:lx,
          uid:this.grxx.id,
          page:this.page,
          sl:8
        };
        getmessageall(data).then(res => {

          // console.log('zx');
          // console.log(res);
          // that.message=res.data.message;

        that.message=res.data.message.table.data;

        that.total=res.data.message.table.total;
        that.per_page=res.data.message.table.per_page;
        that.current_page=res.data.message.table.current_page;
        that.last_page=res.data.message.table.last_page;
        that.items=res.data.message.items;
        that.litems=res.data.message.litems;





        }).catch(err => {})
      },


      grxxff(grxxs) {
        this.grxx = grxxs;
        console.log(grxxs);
        console.log('zhende');
        this.getdata(this.zt);
      },
      qh(zts){
        this.zt=zts;
        this.page=0;
        this.getdata(zts);
      },
      fanhui() {
        window.history.go(-1);
      }
    },
    components: {
      Head,
      Foot,
      Left,
      Page,
      Null,
      MyLeft
    },
  }
</script>
<style lang="scss" scoped="scoped">
  .all {
    background: #f5f6fa;
  }

  .center {
    .c-all {
      width: var(--allWidth);
      margin: 0 auto;
      margin-top: 1.275rem;
      display: flex;

      .c-right {
        margin-left: 1.275rem;
        background: #fff;
        width: 90%;
        margin-bottom: 3.125rem;

        .c-top {
          font-weight: bold;
          padding:0 1.275rem;
          border-bottom: 0.0625rem solid #E8E8E8;
          cursor: pointer;

          img {
            vertical-align: middle;
            width: 1.0875rem;
            margin-right: 0.625rem;
          }
          .zhongjian{
            display: inline-block;
            margin-left: 16rem;
            div{
               display: inline-block;
               width: 4.875rem;
               padding: 1.275rem 0rem;
               text-align: center;
               margin: 0 2rem;
               cursor: pointer;
            }
            .zs{
              border-bottom: 0.125rem solid #2E9BD6;
            }
          }
          span {
            vertical-align: middle;
          }
        }
        .list{
            // padding: 1.875rem;
            .l-one{
              border-bottom: 0.0625rem solid #E8E8E8;
              padding: 1rem 2rem;
              display: flex;
              align-items: center;
              justify-content: space-between;
              cursor: pointer;
              .l-left{
                width: 60%;
                display: flex;
                img{
                  width: 4rem;
                  margin-right: 0.625rem;
                }
                .l-nr{
                  .l-n-bt{
                    font-weight: bold;
                    margin-bottom: 0.75rem;
                    margin-top: 0.5rem;
                  }
                  .l-n-nr{
                    color: #888888;
                  }
                }
              }
              .l-right{
                display: flex;
                align-items: center;
                color: #999999;
                img{
                  width: 0.875rem;
                  margin-right: 0.625rem ;
                }

              }
            }
        }

      }
    }

  }


  @media screen and (max-width: 1300px) {
     .c-all{
       width: 95%!important;
       margin: 0 auto;
       flex-wrap: wrap;
     }
     .c-right{
       width: 100%!important;
       margin: 0 auto!important;
     }

     .lefts{
       width: 100%;
     }

     .c-right{
      .center{
           margin: 0rem !important;
           padding: 0.75rem!important;
      }
     }

     .c-bt{
       display: flex;
     }
     .c-lt{
       padding: 0.65rem 0!important;
       width: 36%;
       text-align: center;
       margin-bottom: 0.625rem;
     }
     .c-right{
       .c-top{
         padding: 0.8rem!important;
         .zhongjian{
           margin: 0!important;
           padding: 0;
           display: flex!important;
           .z-one{
             padding: 0.675rem 0rem!important;
             width: 60%!important;
             font-size: 0.95rem!important;
             margin: 0!important;
             margin-bottom: 0.625rem!important;
           }
         }
       }
     }
    .l-one{
      flex-wrap: wrap;
      .l-left{
        width: 100%!important;
      }
      .l-right{
        width: 100%;
        margin-top: 10px;
        justify-content: flex-end;
      }

    }



  }


</style>
