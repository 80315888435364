<template>
  <div class="l-top-list">
    <div class="l-top-one" @click="goTo('/meetingDe',item.id,item.lx)" v-for="item in list">
      <img :src="'https://ymedicine.jiujingwulian.com/public'+item.fimage"  />
      <div class="one-title">{{item.name}}</div>
      <div class="one-nrjh">
        <div class="one-nr" >举办时间：{{item.ktime}}~{{item.stime}}</div>
        <div class="one-nr" v-show="feqh==1">举办城市：{{item.cityname}}</div>
        <div class="one-nr" v-show="feqh==0">举办城市：{{item.cityname}}</div>
      </div>
      <div class="toubu" :style="item.sjnr=='已结束'?' background:#949494;':item.sjnr=='进行中'?'background:#2E9BD6;':''"  >{{item.sjnr}}</div>
    </div>
    <!-- <div class="l-top-one">
      <img src="~assets/meeting/banne1.png" />
      <div class="one-title">江苏医药研发展会</div>
      <div class="one-nrjh">
        <div class="one-nr">展出时间：2022.09.11~2022.09.14</div>
        <div class="one-nr">展出城市：苏州市</div>
      </div>
    </div>
    <div class="l-top-one">
      <img src="~assets/meeting/banne1.png" />
      <div class="one-title">江苏医药研发展会</div>
      <div class="one-nrjh">
        <div class="one-nr">展出时间：2022.09.11~2022.09.14</div>
        <div class="one-nr">展出城市：苏州市</div>
      </div>
    </div>
    <div class="l-top-one">
      <img src="~assets/meeting/banne1.png" />
      <div class="one-title">江苏医药研发展会</div>
      <div class="one-nrjh">
        <div class="one-nr">展出时间：2022.09.11~2022.09.14</div>
        <div class="one-nr">展出城市：苏州市</div>
      </div>
    </div>
    <div class="l-top-one">
      <img src="~assets/meeting/banne1.png" />
      <div class="one-title">江苏医药研发展会</div>
      <div class="one-nrjh">
        <div class="one-nr">展出时间：2022.09.11~2022.09.14</div>
        <div class="one-nr">展出城市：苏州市</div>
      </div>
    </div>
    <div class="l-top-one">
      <img src="~assets/meeting/banne1.png" />
      <div class="one-title">江苏医药研发展会</div>
      <div class="one-nrjh">
        <div class="one-nr">展出时间：2022.09.11~2022.09.14</div>
        <div class="one-nr">展出城市：苏州市</div>
      </div>
    </div> -->


  </div>




</template>

<script>
  import {
    useRouter
  } from 'vue-router';

  export default {
    name: 'List',
    props: ['i','list','feqh'],
    setup() {
      //跳转方法
      const router = useRouter();
      const goTo = (path, id,lx) => {
        var query = {
          id: id,
          lx:lx
        }
        console.log(query);
        console.log(query);
        router.push({
          path: path,
          query: query || {}
        })
      }

      return {
        goTo
      }

    },
  }
</script>

<style lang="scss" scoped="scoped">

   .l-top-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 1.125rem;
      .l-top-one:hover{
        color: #2E9BD6;
        background: #d7e9fd;
      }
    .l-top-one {
      position: relative;
      .toubu{
        background: #F9950E;
        position: absolute;
        top: 0;
        left: 0;
        padding: 0.625rem;
        color: #fff;
        font-size: 0.8125rem;
        border-bottom-right-radius: 0.325rem;
      }
      margin-right: 2.66%;
      width: 23%;
      margin-bottom: 1.25rem;
      border: 0.0625rem solid #DADADA;
      cursor: pointer;
      border-top-right-radius: 4.5rem;
      border-top-left-radius: 4.5rem;
      img {
        width: 100%;
      }

      .one-title {
        margin: 0.625rem 0.9rem;
        font-size: 1.0625rem;
        font-weight: bold;
      }

      .one-nrjh {
        font-size: 0.8625rem;
        color: #949494;
        margin: 1.325rem 0.9rem;

        .one-nr {
          margin-top: 0.425rem;
          line-height: 1.3rem;
        }
      }

    }

    .l-top-one:nth-child(4n) {
      margin-right: 0;
    }


  }
  @media screen and (max-width: 1300px) {
    .one-title{
      margin: 0.225rem 0.6rem!important;
      font-size: 0.9rem!important;
    }
    .one-nrjh{
      font-size: 0.6625rem!important;
      margin: 0.325rem 0.54rem!important;
    }
    .l-top-one{
      width: 49%!important;
      margin-right: 2%!important;
    }
    .l-top-one:nth-child(2n){
      margin-right: 0%!important;
    }

  }

</style>
