import { request } from "./request.js";

export function fmeetingyhqshezhi(params){
    return request(
      {
          url:'/public/index/index/fmeetingyhqshezhi',
          method:'POST',
          params:params
      }
    )
}

export function fmeetingyhqshezhilq(params){
    return request(
      {
          url:'/public/index/index/fmeetingyhqshezhilq',
          method:'POST',
          params:params
      }
    )
}


// export function getfabout(params){
//     return request(
//       {
//           url:'/public/index/index/fabout',
//           method:'POST',
//           params:params
//       }
//     )
// }
