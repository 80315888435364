import { request } from "./request.js";

export function getfmeetingshezhi(params){
    return request(
      {
          url:'/public/index/index/fmeetingshezhi',
          method:'POST',
          params:params
      }
    )
}

export function getfmeetingtj(params){
    return request(
      {
          url:'/public/index/index/fmeetingtj',
          method:'POST',
          params:params
      }
    )
}


export function getmeetingDe(params){
    return request(
      {
          url:'/public/index/index/meetingDe',
          method:'POST',
          params:params
      }
    )
}

export function getfmeetingnew(params){
    return request(
      {
          url:'/public/index/index/fmeetingnew',
          method:'POST',
          params:params
      }
    )
}

export function getonemeeting(params){
    return request(
      {
          url:'/public/index/index/onemeeting',
          method:'POST',
          params:params
      }
    )
}




export function getfmeetingfp(params){
    return request(
      {
          url:'/public/index/index/fmeetingfp',
          method:'POST',
          params:params
      }
    )
}


export function getfmeetingfpselect(params){
    return request(
      {
          url:'/public/index/index/fmeetingfpselect',
          method:'POST',
          params:params
      }
    )
}

export function getinfmeetingfp(params){
    return request(
      {
          url:'/public/index/index/infmeetingfp',
          method:'POST',
          params:params
      }
    )
}

export function getupfmeetingfpstatus(params){
    return request(
      {
          url:'/public/index/index/upfmeetingfpstatus',
          method:'POST',
          params:params
      }
    )
}

export function getfmeetingyhq(params){
    return request(
      {
          url:'/public/index/index/fmeetingyhq',
          method:'POST',
          params:params
      }
    )
}

export function getpay(params){
    return request(
      {
          url:'/public/index/index/pay',
          method:'POST',
          params:params
      }
    )
}

export function getfmeetingfpfind(params){
    return request(
      {
          url:'/public/index/index/fmeetingfpfind',
          method:'POST',
          params:params
      }
    )
}

export function getSing(params){
    return request(
      {
          url:'/public/index/index/getSing',
          method:'POST',
          params:params
      }
    )
}

export function newdianzan(params){
    return request(
      {
          url:'/public/index/index/newdianzan',
          method:'POST',
          params:params
      }
    )
}

