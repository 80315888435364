<template>
  <div class="all">
    <Head :top="top" :grxxff="grxxff"></Head>
    <div class="center">
      <div class="c-all">
        <MyLeft :topleft="topleft"></MyLeft>
        <div class="c-right" v-show="lbie==1">
          <div class="c-top" @click="fanhui()"><img src="~assets/about/fh.png" /><span>公告详情</span></div>
          <div class="dbu" v-html="content"></div>
        </div>

        <div class="c-right" v-show="lbie==0">
          <div class="c-top">我的资料</div>

          <div class="c-r-cen">

            <div class="c-r-r-left">
              <img class="ysy" v-show="grxx.openid" :src="grxx.headimage" />
              <img class="ysy" v-show="!grxx.openid" :src="'https://ymedicine.jiujingwulian.com/public'+grxx.headimage" />
              <!-- src="~assets/my/tx.png" -->
              <div class="xm">{{grxx.name}}</div>
              <div class="c-c-all">
                <div class="c-rrone" @click="goTo('/myMeans','')"><img src="~assets/my/bi.png" />完善信息</div>
                <div class="c-rrone op" v-if="grxx.wenzi=='个人会员申请表' && grxx.stauts=='4'">个人会员</div>
                <div class="c-rrone op" v-if="grxx.wenzi!='个人会员申请表' && grxx.stauts=='4'">会员单位</div>
                <div class="c-rrone op" v-if="grxx.stauts!='4'">普通用户</div>
                <div class="c-rrone oo" v-if="grxx.stauts!='4'" @click="goTo('/myRuhui','')">申请入会</div>
                <div class="c-rrone oo" v-if="grxx.stauts=='4'" @click="goTo('/myRuhui','')">入会资料</div>
              </div>
              <div class="c-c-bo">
                <div class="c-c-c-on" @click="goTo('/mymessage','0')"><span>{{fmessage0}}</span>
                  <div>未读消息</div>
                </div>
                <div class="c-c-c-on" @click="goTo('/mymessage','1')"><span class="er">{{fmessage1}}</span>
                  <div>已读消息</div>
                </div>
                <div class="c-c-c-on" @click="goTo('/mymessage','2')"><span class="san">{{fmessage2}}</span>
                  <div>全部消息</div>
                </div>
              </div>
            </div>

            <div class="c-r-r-Right">

              <el-carousel indicator-position="outside">
                <el-carousel-item v-for="item in banner" :key="item">
                  <div class="zj">
                    <img :src="'https://ymedicine.jiujingwulian.com/public'+item.bimage" />
                    <!-- <div class="dibu">中国生物医药产业链创新与转化联合体平台发布会...</div> -->
                  </div>
                </el-carousel-item>
              </el-carousel>

            </div>


          </div>

          <div class="gy">
            <div class="g-left">
              <div class="g-l-top">通知公告
                <div class="gd" @click="liebao()">查看更多
                  <img class="gd-one" src="~assets/home/gd.png">
                  <img class="gd-two" src="~assets/home/gd.png" />
                </div>
              </div>
              <div class="g-l-le">
                <div class="g-l-o onehang" @click="xiangqing(item)" v-for="(item,index) in fmess" v-show="index<8"><img
                    src="~assets/my/yuan.png"  />{{item.title}}</div>
                <!-- <div class="g-l-o"><img src="~assets/my/yuan.png" />公告标题公告标题公告标题公告标题公告标题</div>
                <div class="g-l-o"><img src="~assets/my/yuan.png" />公告标题公告标题公告标题公告标题公告标题</div>
                <div class="g-l-o"><img src="~assets/my/yuan.png" />公告标题公告标题公告标题公告标题公告标题</div>
                <div class="g-l-o"><img src="~assets/my/yuan.png" />公告标题公告标题公告标题公告标题公告标题</div>
                <div class="g-l-o"><img src="~assets/my/yuan.png" />公告标题公告标题公告标题公告标题公告标题</div>
                <div class="g-l-o"><img src="~assets/my/yuan.png" />公告标题公告标题公告标题公告标题公告标题</div>
                <div class="g-l-o"><img src="~assets/my/yuan.png" />公告标题公告标题公告标题公告标题公告标题</div> -->
              </div>
            </div>
            <div class="g-right">
              <div class="g-l-top">关于联合体</div>
              <div class="g-l-le">
                <div class="g-l-lleft">
                  <img class="gyewm" src="~assets/my/ewm.png" />
                  <div class="xm"><img
                      :src="'https://ymedicine.jiujingwulian.com/public'+shezhi.gimage" /><span>微信扫一扫</span></div>
                  <div class="xms">关注联合体公众号</div>
                </div>
                <div class="shu"></div>
                <div class="g-l-lleft">
                  <img class="gyewm" src="~assets/my/ewm.png" />
                  <div class="xm"><img
                      :src="'https://ymedicine.jiujingwulian.com/public'+shezhi.kimage" /><span>微信扫一扫</span></div>
                  <div class="xms sx">添加客服微信</div>
                </div>
              </div>
            </div>
          </div>







        </div>

        <div class="c-right" v-show="lbie==3">
          <div class="c-top" @click="fanhui()"><img src="~assets/about/fh.png" /><span>公告详情</span></div>
          <div class="gy" style="margin-top: 1.875rem;" >

            <div class="g-left" style="width: 100%;">
              <div class="g-l-top">通知公告
              </div>
              <div class="g-l-le">
                <div class="g-l-o onehang" @click="xiangqing(item)" v-for="item in fmess">
                  <img src="~assets/my/yuan.png" />{{item.title}}
                  <span class="llstime">{{item.time}}</span>
                </div>
              </div>
            </div>

          </div>

        </div>

      </div>
    </div>


    <foot></foot>
    <left></left>
  </div>
</template>

<script>
  import {
    ref,
    onMounted
  } from 'vue';
  import {
    getHome,
    getfshezhi
  } from 'network/home.js';
  import {
    useRouter
  } from 'vue-router';


  import {
    getgz
  } from 'network/my.js';


  import Head from "components/content/headder/Head.vue";
  import Foot from "components/content/footer/Foot.vue";
  import Left from "components/content/left/Left.vue";

  import MyLeft from "views/my/ChildComps/MyLeft.vue";


  export default {
    name: 'My',
    data() {
      return {
        shezhi: {},
        lbie: 0,
        content: '',
        grxx: {},
        banner: {},
        fmessage0: '',
        fmessage1: '',
        fmessage2: '',
        fmess: {},

      }
    },

    setup() {
      const top = 0;
      const topleft = 0;
      const router = useRouter();
      const databa = ref([]);
      const ab = 'sb';
      console.log(ab);
      onMounted(() => {

        getHome().then(res => {
          console.log(res.data.data);
          databa.value = res.data;
          console.log(databa);

        }).catch(err => {

        })

      })
      //跳转方法
      const goTo = (path, quy) => {
        var query = {
          lx: quy,
        }
        router.push({
          path: path,
          query: query || {}
        })
      }

      return {
        topleft,
        databa,
        ab,
        goTo,
        top
      }
    },
    mounted() {
      console.log(this.grxx);
      console.log('login');
      this.getdata();
      this.fshezhi();

    },
    methods: {
      fshezhi() {
        var that = this;
        var data = {

        };
        getfshezhi(data).then(res => {
          console.log('zd');
          console.log(res);
          that.shezhi = res.data.data;
        }).catch(err => {})
      },

      fanhui() {
        this.lbie = 0
      },
      xiangqing(item) {
        this.content = item.content;
        this.lbie = 1;
      },
      liebao(){
        this.lbie = 3;
      },
      getdata() {

        if (!this.grxx) {
          this.$router.push('login');
        }

        console.log(this.grxx.id);
        console.log('jll');
        var that = this;
        var data = {
          uid: this.grxx.id
        };
        getgz(data).then(res => {
          console.log('zx');
          console.log(res);
          that.banner = res.data.banner;
          that.fmessage0 = res.data.fmessage0;
          that.fmessage1 = res.data.fmessage1;
          that.fmessage2 = res.data.fmessage2;
          that.fmess = res.data.fmess;


        }).catch(err => {})
      },

      grxxff(grxxs) {
        this.grxx = grxxs;
        if (!this.grxx) {
          console.log('sbaa');
          this.$router.push('login');
        }
        console.log(grxxs);
        console.log('zhende');
        this.getdata();
      },
      message() {

      }

    },
    components: {

      Head,
      Foot,
      Left,
      MyLeft
    },
  }
</script>
<style lang="scss" scoped="scoped">
  .all {
    background: #f5f6fa;
  }

  .center {
    .c-all {
      width: var(--allWidth);
      margin: 0 auto;
      margin-top: 1.275rem;
      display: flex;

      .c-right {
        margin-left: 1.275rem;
        background: #fff;
        width: 90%;
        margin-bottom: 3.125rem;

        .c-top {
          font-weight: bold;
          padding: 1.275rem;
          border-bottom: 0.0625rem solid #E8E8E8;

          img {
            vertical-align: middle;
            width: 1.0875rem;
            margin-right: 0.625rem;
            cursor: pointer;
          }

          span {
            vertical-align: middle;
            cursor: pointer;
          }
        }

        .dbu {
          padding: 1.875rem;
        }

        .c-r-cen {
          padding: 0 1.875rem;
          display: flex;
          justify-content: space-between;

          .c-r-r-left {
            width: 40%;
            padding: 2.575rem 0;
            text-align: center;

            .xm {
              margin-top: 0.875rem;
            }

            .c-c-all {
              margin-top: 1.875rem;
              display: flex;
              justify-content: center;

              .c-rrone {
                padding: 0.3125rem 0.825rem;
                font-size: 0.8125rem;
                color: #747474;
                border: 0.0625rem solid #747474;
                display: inline-block;
                border-radius: 1.875rem;
                display: flex;

                img {
                  width: 0.75rem;
                  margin-right: 0.5rem;
                }

                margin-right: 10px;
                cursor: pointer;
              }

              .op {
                background: #2E9BD6;
                border: 0.0625rem solid #2E9BD6;
                color: #fff;
              }

              .oo {
                background: #AFC81D;
                border: 0.0625rem solid #AFC81D;
                color: #fff;
              }

            }


            .c-c-bo {
              margin-top: 2.5rem;
              display: flex;
              align-items: center;
              justify-content: space-around;


              .c-c-c-on {
                border: 0.0625rem solid #E5E5E5;
                padding:1rem 2.2rem;
                cursor: pointer;

                span {
                  display: inline-block;
                  margin-bottom: 0.625rem;
                  font-size: 1.6rem;
                  color: #FF9135;
                }

                div {
                  font-size: 0.8rem;
                }

                .er {
                  color: #808080;
                }

                .san {
                  color: #2E9BD6;
                }
              }
            }
          }


          .c-r-r-Right {
            width: 58%;

            .el-carousel {
              height: 24.075rem;
              position: relative;

              div {
                height: 24.075rem;
              }
            }

            .el-carousel__container {
              height: auto !important;
              // height: 24.075rem!important;
            }

            .el-carousel__indicators {
              width: auto;
              position: absolute;
              right: -6.75rem;
              bottom: 0.5rem;
            }


            .zj {
              width: 100%;

              img {
                width: 100%;
                height: 100%;
              }
            }


          }
        }


        .gy {
          padding: 0 1.875rem;
          display: flex;
          justify-content: space-between;
          margin-top: 0;
          margin-bottom: 1.875rem;

          .g-left {
            width: 40%;

            .g-l-top {
              background: #2E9BD6;
              padding: 0.825rem;
              color: #fff;
            }

            .g-l-le {
              border: 0.0625rem solid #E8E8E8;

              .g-l-o:hover {
                color: #2E9BD6;
              }

              .g-l-o {
                padding: 0.725rem;
                font-size: 0.65rem;
                display: flex;
                align-items: center;
                cursor: pointer;

                img {
                  margin-right: 0.325rem;
                  width: 0.425rem;
                }
              }
            }
          }

          .g-right {
            width: 58%;

            .g-l-top {
              background: #AFC81D;
              padding: 0.825rem;
              color: #fff;
            }

            .g-l-le {
              border: 0.0625rem solid #E8E8E8;
              display: flex;
              justify-content: center;
              align-items: center;

              .shu {
                background: #E5E5E5;
                width: 0.0625rem;
                height: 3.625rem;
              }

              .g-l-lleft {
                text-align: center;
                width: 50%;
                padding: 1.25rem 0 0.8rem;

                img {
                  width: 8.75rem;
                  margin-bottom: 1.475rem;
                }

                .xm {
                  display: flex;
                  justify-content: center;

                  // align-items: center;
                  img {
                    width: 1.275rem;
                    margin-right: 0.3125rem;
                  }
                }

                .xms {
                  width: 97%;
                  text-align: center;
                  margin: 0 auto;
                  padding: 0.625rem 0;
                  background: #F9FAF1;
                  font-size: 0.8125rem;
                  letter-spacing: 0.1875rem;
                  margin-top: 0.8rem;
                }

                .sx {
                  background: #F4FBFF;
                }
              }
            }

          }
        }
      }

    }

  }

  .mobile {
    display: none;
  }

  @media screen and (max-width: 1300px) {
    .c-all {
      width: 95% !important;
      margin: 0 auto;
      flex-wrap: wrap;
    }

    .c-right {
      width: 100% !important;
      margin: 0 auto !important;
    }

    .lefts {
      width: 100%;

    }

    .c-r-cen {
      padding: 0 0.55rem !important;
      flex-wrap: wrap !important;

      .c-r-r-left {
        width: 100% !important;
        padding: 0.8rem 0 !important;

        .xm {
          margin-top: 0.3rem !important;
        }

        .c-c-all {
          margin-top: 0.8rem !important;
        }

        .c-c-bo {
          margin-top: 1.2rem !important;
        }
      }

      .c-r-r-Right {
        width: 100% !important;
        margin-bottom: 0.875rem !important;
      }
    }

    .gy {
      flex-wrap: wrap;
      padding: 0 0.55rem !important;

      .g-left {
        width: 100% !important;
        margin-bottom: 0.625rem;
      }

      .g-right {
        width: 100% !important;
      }
    }

    .el-carousel__container {
      height: 14.075rem !important;
    }

    .el-carousel {
      height: 14.075rem !important;
      position: relative;

      div {
        height: 14.075rem !important;
      }
    }

    .g-l-le {
      flex-wrap: wrap;

      .g-l-lleft {
        width: 100%;
      }
    }

    .c-c-c-on {
      padding: 1rem !important;
    }

    .g-l-lleft {
      width: 71% !important;

      .gyewm {
        width: 100% !important;
      }
    }
  }


  .c-rrone:hover {
    background: #26fef2 !important;
    color: #FFFFFF !important;
    border: 0.0625remsolid #26fef2 !important;
  }

  .c-c-c-on:hover {
    span {
      color: #2E9BD6 !important;
    }

  }

  .g-l-top {
    position: relative;
  }

  .gd {
    display: flex;
    align-items: center;
    color: #fff;
    padding: 0.625rem 1.25rem;
    font-size: 0.925rem;
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0.375rem, -50%);

    img {
      width: 1rem;
    }
  }

  .g-l-o {
    position: relative;
  }

  .llstime {
    position: absolute;
    top: 50%;
    right: 0.625rem;
    transform: translate(0, -50%);
  }
  .ysy{
    border-radius: 50%;
  }
</style>
