<template>
   <div class="bn">
     <img :src="bn" />
 <!--    <img src="~assets/users/banner.png" />

     <img src="../../../assets/cloud/banner.png" /> -->
   </div>
</template>

<script>

  import {useRouter} from 'vue-router';

  export default{
    name:'Banner',
    props: ['bn'],
    setup(){
      //跳转方法
      const router=useRouter();
      const goTo=(path,query)=>{
        console.log(query);
        query={name:'nn'}
        console.log(query);
        router.push({
          path:path,
          query:query || {}
        })
      }

      return {goTo}

    },
  }
</script>

<style lang="scss" scoped="scoped">
.bn{
    width: 100%;
    img{
      width: 100%;
    }
  }
</style>
