<template>
  <div class="all">
    <Head :top="top" :grxxff="grxxff"></Head>
    <div class="center">
      <div class="c-all">
        <MyLeft :topleft="topleft"></MyLeft>

        <div class="c-right">
          <div class="c-top" @click="fanhui()"><img src="~assets/about/fh.png" /><span>项目管理</span></div>
          <div class="zhongjians">
            <div class="z-one " @click="switchbig(0)" :class="bigcss==0?'bs':''" >我的发布</div>
            <div class="z-one" @click="switchbig(1)" :class="bigcss==1?'bs':''">我感兴趣</div>
          </div>
          <div class="center" >
            <div class="c-bt" v-show="bigcss==0">
              <div class="c-lt" @click="qh(0)" :class="zt==0?'zs':''">待审核</div>
              <div class="c-lt" @click="qh(1)" :class="zt==1?'zs':''">上架中</div>
              <div class="c-lt" @click="qh(2)" :class="zt==2?'zs':''">已下架</div>
              <div class="fb" @click="xiangqing1()">+我要发布</div>
            </div>
            <div class="c-bt" v-show="bigcss==1">
              <div class="c-lt" @click="qh1(0)" :class="zt1==0?'zs':''">待审核</div>
              <div class="c-lt" @click="qh1(1)" :class="zt1==1?'zs':''">待洽谈</div>
              <div class="c-lt" @click="qh1(2)" :class="zt1==2?'zs':''">已完成</div>
            </div>
            <div class="dibu">
              <div class="one" v-for="item in fbusinessin">
                <div class="o-title">记录编号：{{item.id}}</div>
                <div class="o-nr">
                  <img class="tbimg" :src="'https://ymedicine.jiujingwulian.com/public'+item.fimage" />
                  <div class="o-le">
                    <div class="o-l-title onehang">{{item.name}}</div>
                    <div class="hy">
                      <div class="h-one" v-for="item1 in item.hangye">{{item1.name}}</div>
                      <!-- <div class="h-one">行业</div>
                      <div class="h-one">行业</div> -->
                    </div>
                    <div class="o-l-bt">
                      <div class="ones">
                        <img src="../../assets/home/dws.png" />
                        <div class="ones-one">城市:{{item.diqu}}</div>
                      </div>
                      <div class="shu">|</div>
                      <div class="ones">
                        <div class="ones-fy">阶段：{{item.jieduan}}</div>
                      </div>
                      <div class="shu">|</div>
                      <div class="ones">
                        <img src="../../assets/my/sjs.png" />
                        <div class="ones-nrs">更新时间：{{item.time}}</div>
                      </div>

                    </div>
                  </div>
                </div>
                <div class="zt">{{item.zt}}</div>
                <div class="dws" v-show="bigcss==0">

                  <!-- <el-button class="bj2" @click="qiandao()">未签到 <img src="../../assets/my/qb.png" /></el-button> -->
                  <el-button type="primary" v-show="item.type==0 && item.status==1 " @click="xiajia(item)" class="bj1"><img src="../../assets/my/xiajia.png" style="margin-right: 0.225rem;" />下架</el-button>
                  <el-button type="primary" v-show="item.type==1 && item.status==1 " @click="shangjia(item)" class="bj1"><img src="../../assets/my/shangjia.png" style="margin-right: 0.225rem;" />上架</el-button>
                  <el-button class="bj2" @click="xiangqing(item)">查看详情 >></el-button>
                </div>
                <div class="dws" v-show="bigcss==1">

                  <!-- <el-button class="bj2" @click="qiandao()">未签到 <img src="../../assets/my/qb.png" /></el-button> -->
                  <!-- <el-button class="bj2" @click="xiangqing(item)">查看详情 >></el-button> -->
                  <el-button type="primary"  @click="xiangqing(item)" class="bj1">我的预留信息</el-button>
                </div>



              </div>
              <null :list='fbusinessin' ></null>
              <page :items='items'
               :litems='litems'
               :total="total"
               :per_page="per_page"
               :current_page="current_page"
               :last_page="last_page"
               :listcount='listcount'></page>




            </div>

            <el-dialog class="zd"  :width="w50" :show-close="false" align-center v-model="dialogTableVisible" title="">
              <template #header="{ close, titleId, titleClass }">
                <div class="qd-top">
                  <div class="xian"></div>
                  <div class="qd-bt">入场签到</div>
                  <div class="xian"></div>
                </div>
              </template>
              <div class="ewm1">
                <img src="../../assets/my/ewm.png" />
                <div class="e-bt">请微信扫码进入移动端完成签到操作</div>
              </div>
            </el-dialog>

            <el-dialog class="zd"  :width="w50" :show-close="false" align-center v-model="dialogTableVisible1" title="">
              <template #header="{ close, titleId, titleClass }">
                <div class="qd-top">
                  <div class="xian"></div>
                  <div class="qd-bt">上传健康信息</div>
                  <div class="xian"></div>
                </div>
              </template>
              <div class="ewm">
                <div>
                  <el-upload class="avatar-uploader"
                    action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15" :show-file-list="false"
                    :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                    <img src="../../assets/my/jia.png" class="avatar" />
                  </el-upload>
                  <div class="e-bt">健康码</div>
                </div>

                <div>
                  <el-upload class="avatar-uploader"
                    action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15" :show-file-list="false"
                    :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                    <img src="../../assets/my/jia.png" class="avatar" />
                  </el-upload>
                  <div class="e-bt">健康码</div>
                </div>

              </div>
              <div class="db">
                <el-button type="primary" class="baocun">保存</el-button>
              </div>
            </el-dialog>

              <el-dialog class="ddb zd"  :width="w50" @opened="show()"  @closed="hide()"  v-model="dialogTableVisible2" :show-close="false">
                <div class="top">
                  <div class="">新增项目管理<div class="gb right"><img @click="dialogTableVisible2=false" src="../../assets/my/guanbi.png" /></div></div>
                </div>
                <div class="hang">
                  <div class="lxr">
                    <div class="bt">所属区域：</div>
                    <!-- 类型:0=所在领域,1=所在地区,2=所在阶段 -->
                    <el-select v-model="value1" class="m-2 shuru" multiple placeholder="请选择" size="large">
                      <el-option
                        v-for="item in fbusiness1"
                        :key="item.id"
                        :label="item.name"
                        :value="item.name"
                      />
                    </el-select>



                  </div>
                  <div class="lxr">
                    <div class="bt">所属阶段：</div>
                    <el-select v-model="value2" class="m-2 shuru" multiple placeholder="请选择" size="large">
                      <el-option
                        v-for="item in fbusiness2"
                        :key="item.id"
                        :label="item.name"
                        :value="item.name"
                      />
                    </el-select>
                  </div>
                  <div class="lxr">
                    <div class="bt">所属领域：</div>
                    <el-select v-model="value0" class="m-2 shuru" multiple placeholder="请选择" size="large">
                      <el-option
                        v-for="item in fbusiness0"
                        :key="item.id"
                        :label="item.name"
                        :value="item.name"
                      />
                    </el-select>
                  </div>
                </div>
                <div class="hang">
                  <div class="lxr">
                    <div class="bt">标题：</div>
                    <el-input class="shuru" v-model="name" placeholder="请输入" />
                  </div>
                  <div class="lxr">
                    <div class="bt">联系人：</div>
                    <el-input class="shuru" v-model="lxrname" placeholder="请输入" />
                  </div>
                  <div class="lxr">
                    <div class="bt">联系电话：</div>
                    <el-input class="shuru" v-model="phone" placeholder="请输入" />
                  </div>
                </div>

                <div class="hang dibu1">
                  <div class="lxr" style="    align-items: flex-start;">
                    <div class="bt">封面图：</div>
                   <!-- <el-upload class="avatar-uploader"
                      action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15" :show-file-list="false"
                      :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                      <img src="../../assets/my/sc1.png" class="avatar" />
                    </el-upload> -->
                    <div class="shuru lefts" v-show="bigcss==0">

                      <input type="file" id="back" ref="backfile" @change="handleFile" />
                      <img :src="xcm" />
                      <!--<img src="../../assets/my/sc1.png" class="avatar" /> -->
                    </div>
                  </div>

                </div>
                <div class="hang">
                  <div class="h-title">项目背景</div>
                </div>
                <div class="hang">
               <!--    <el-input
                     v-model="bjcontent"
                     :autosize="{ minRows: 2, maxRows: 4 }"
                     type="textarea"
                     placeholder="请输入"
                   /> -->

                   <div id="editorElem" style="margin: 0% 4% 3% 2%;">

                   </div>

                </div>

                <div class="hang">
                  <div class="h-title">项目概况</div>
                </div>
                <div class="hang">
                   <!-- <el-input
                     v-model="gkcontent"
                     :autosize="{ minRows: 2, maxRows: 4 }"
                     type="textarea"
                     placeholder="请输入"
                   /> -->

                   <div id="editorElem1" style="margin: 0% 4% 3% 2%;">

                   </div>
                </div>


                <div class="hang">
                  <div class="h-title">项目核心优势</div>
                </div>
                <div class="hang">
                 <!--  <el-input
                     v-model="kxcontent"
                     :autosize="{ minRows: 2, maxRows: 4 }"
                     type="textarea"
                     placeholder="请输入"
                   /> -->
                   <div id="editorElem2" style="margin: 0% 4% 3% 2%;">

                   </div>
                </div>


               <!-- <div class="hang">
                  <div class="h-title">图片资料</div>
                </div>
                <div class="hang" style="flex-wrap: wrap;">

                  <div class="shuru lefts" style="    width: 15%;" v-for="(item,k) in tplist">
                    <img  :src="item" class="avatar" @click="remove(k)" />
                  </div>

                  <div class="shuru lefts" style="    width: 15%;">
                    <input type="file" id="back" ref="backfile" @change="handleFile1" />
                    <img :src="xcm1" />
                  </div>
                </div> -->



                <div class="dban" v-show="bigcss==0">
                   <el-button class="anniu bs1" type="primary" @click="open()">保存</el-button>
                </div>


              </el-dialog>




          </div>





        </div>

      </div>
    </div>


    <foot></foot>
    <left></left>
  </div>
</template>

<script>
  import {
    ref,
    onMounted
  } from 'vue';
  import {
    getHome
  } from 'network/home.js';
  import {
    useRouter
  } from 'vue-router';

  import {
    getfinvestmentinmy,
    getinsertfinvestmentin,

    getxiajiafinvestmentin,
    getfinvestmentorder,
    upimg,




    getfbusinessinmy,
    getinsertFbusinessin,
    getxiajia,
    getfbusinessorder,
    getmessage
  } from 'network/my.js';

    import {
      ElMessage,
      ElMessageBox
    } from 'element-plus';
    import {
      Action
    } from 'element-plus';


  import Head from "components/content/headder/Head.vue";
  import Foot from "components/content/footer/Foot.vue";
  import Left from "components/content/left/Left.vue";

  import MyLeft from "views/my/ChildComps/MyLeft.vue";
  import Page from "components/content/page/Page.vue";
  import Null from "components/content/errornull/null.vue";

  import EWangEditor from "wangeditor";
  import {reactive} from "vue";

  export default {
    name: 'My',
    data() {
      return {
        w50:'60%',
        editor:[],
        getPlan:[],
        editor1:[],
        getPlan1:[],
        editor2:[],
        getPlan2:[],

        items:[
        ],
        litems:[
        ],
        page:0,
        total:'',
        per_page:'',
        current_page:'',
        last_page:'',


        xcm:'https://ymedicine.jiujingwulian.com/public/img/sc1.png',
        bcxcm:'',
        xcm1:'https://ymedicine.jiujingwulian.com/public/img/sc1.png',
        bcxcm1:'',
        tplist:[
          // 'https://ymedicine.jiujingwulian.com/public/img/sc1.png','https://ymedicine.jiujingwulian.com/public/img/sc1.png',
          // 'https://ymedicine.jiujingwulian.com/public/img/sc1.png','https://ymedicine.jiujingwulian.com/public/img/sc1.png',
          // 'https://ymedicine.jiujingwulian.com/public/img/sc1.png','https://ymedicine.jiujingwulian.com/public/img/sc1.png',
          // 'https://ymedicine.jiujingwulian.com/public/img/sc1.png','https://ymedicine.jiujingwulian.com/public/img/sc1.png',
          // 'https://ymedicine.jiujingwulian.com/public/img/sc1.png','https://ymedicine.jiujingwulian.com/public/img/sc1.png',
          // 'https://ymedicine.jiujingwulian.com/public/img/sc1.png','https://ymedicine.jiujingwulian.com/public/img/sc1.png'
        ],
        tplist1:[
          // 'https://ymedicine.jiujingwulian.com/public/img/sc1.png','https://ymedicine.jiujingwulian.com/public/img/sc1.png'
        ],
        list:{},
        fbusinessin:{},
        dialogTableVisible: false,
        dialogTableVisible1: false,
        dialogTableVisible2: false,
        bigcss:0,
        zt: 0,
        zt1:0,
        shzt: 1, //shzt 0= 待审核,1=已驳回
        topleft: 6, //左边
        radio1: 1,
        textarea2: '',
        options: [{
          value: '城市',
          lable: '0'
        }, ],
        name: '',
        lxrname:'',
        phone:'',
        bjcontent:'',
        gkcontent:'',
        kxcontent:'',
        zid:'',
        phone:'',
        grxx: [],


         fbusiness0:[],
         fbusiness1:[],
         fbusiness2:[],
         value0:'',
         value1:'',
         value2:''

      }
    },
    setup() {
      const top = 0;
      const router = useRouter();
      const databa = ref([]);
      const ab = 'sb';
      console.log(ab);
      onMounted(() => {

        getHome().then(res => {
          console.log(res.data.data);
          databa.value = res.data;
          console.log(databa);

        }).catch(err => {

        })

      })
      //跳转方法
      const goTo = (path, query) => {
        router.push({
          path: path,
          query: query || {}
        })
      }

      return {
        databa,
        ab,
        goTo,
        top
      }
    },
    mounted() {
      this.getdata(this.zt);
    },

    methods: {

      // 点击修改计划
      modify () {
         this.dialogFormVisible_ = true
       },
       // 确定修改课程计划
       modifyplan () {
         this.dialogFormVisible_ = false
         // 把富文本的数据存到仓库里面
         this.getPlan.plan = this.editor.txt.html()
         // alert(this.editor.txt.html())
         // 提交修改/富文本！！！
       },
           // 对编辑器进行加载
       show () {
         var editor = new EWangEditor('#editorElem')
         this.editor = editor
         // 配置菜单栏，删减菜单，调整顺序
         editor.config.menus = [
           'head',  // 标题
           'bold',  // 粗体
           'fontSize',  //字号
           'fontName',  //字体
           'italic',  // 斜体
           'underline',  //下划线
           'strikeThrough',  //删除线
           'indent',  //缩进
           'lineHeight',  //行高
           'foreColor',  //文字颜色
           'backColor',  //文字背景颜色
           'link',  //链接，插入一个链接地址，如果填写了描述，则高亮显示描述。若没有，则高亮显示链接
           'list',  // 序列（有序列表、无序列表）
           'todo',  //待办事项
           'justify',  // 对齐方式
           'quote',  //引用
           'emoticon',  //表情
           'image',  //插入图片
           // 'video',  //插入视频
           'table',  //表格
           'code',  //代码
           'splitLine',  //分割线
           'undo',  //撤销
           'redo' //恢复
         ]

         editor.config.uploadImgServer ="https://ymedicine.jiujingwulian.com/public/index/index/upimg";
         //设置上传图片http请求参数名，
         editor.config.uploadFileName = 'file';
         //设置请求头  解决跨域问题
         // editor.config.uploadImgHeaders = {
         //   'Access-Control-Allow-Origin': 'origin'
         // }
         // 一次最多上传图片的数量
         //editor.config.uploadImgMaxLength = 1;
         // 限制上传图片格式
         editor.config.uploadImgAccept = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];
         // 开启本地上传视频(这是后端上传链接)
         // editor.config.uploadVideoServer = "https://ymedicine.jiujingwulian.com/uploadForEditor";

         editor.create()
         // this.editor.txt.html(this.getPlan.plan)
         this.editor.txt.html(this.bjcontent)


         var editor1 = new EWangEditor('#editorElem1')
         this.editor1 = editor1
         editor1.config.menus = [
           'head',  // 标题
           'bold',  // 粗体
           'fontSize',  //字号
           'fontName',  //字体
           'italic',  // 斜体
           'underline',  //下划线
           'strikeThrough',  //删除线
           'indent',  //缩进
           'lineHeight',  //行高
           'foreColor',  //文字颜色
           'backColor',  //文字背景颜色
           'link',  //链接，插入一个链接地址，如果填写了描述，则高亮显示描述。若没有，则高亮显示链接
           'list',  // 序列（有序列表、无序列表）
           'todo',  //待办事项
           'justify',  // 对齐方式
           'quote',  //引用
           'emoticon',  //表情
           'image',  //插入图片
           // 'video',  //插入视频
           'table',  //表格
           'code',  //代码
           'splitLine',  //分割线
           'undo',  //撤销
           'redo' //恢复
         ]
         editor1.config.uploadImgServer ="https://ymedicine.jiujingwulian.com/public/index/index/upimg";

         editor1.config.uploadFileName = 'file';

         editor1.config.uploadImgAccept = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];
         editor1.create()
         this.editor1.txt.html(this.gkcontent)

         var editor2 = new EWangEditor('#editorElem2')
         this.editor2 = editor2
         editor2.config.menus = [
           'head',  // 标题
           'bold',  // 粗体
           'fontSize',  //字号
           'fontName',  //字体
           'italic',  // 斜体
           'underline',  //下划线
           'strikeThrough',  //删除线
           'indent',  //缩进
           'lineHeight',  //行高
           'foreColor',  //文字颜色
           'backColor',  //文字背景颜色
           'link',  //链接，插入一个链接地址，如果填写了描述，则高亮显示描述。若没有，则高亮显示链接
           'list',  // 序列（有序列表、无序列表）
           'todo',  //待办事项
           'justify',  // 对齐方式
           'quote',  //引用
           'emoticon',  //表情
           'image',  //插入图片
           // 'video',  //插入视频
           'table',  //表格
           'code',  //代码
           'splitLine',  //分割线
           'undo',  //撤销
           'redo' //恢复
         ]
         editor2.config.uploadImgServer ="https://ymedicine.jiujingwulian.com/public/index/index/upimg";

         editor2.config.uploadFileName = 'file';

         editor2.config.uploadImgAccept = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];
         editor2.create()
         this.editor2.txt.html(this.kxcontent)



       },

       // 关闭时清空数据避免重叠
       hide() {
         // var editor = new EWangEditor('#editorElem')
         // this.editor = editor

         this.editor.destroy();
         this.editor1.destroy();
         this.editor2.destroy();
          // this.editor.txt.html('');

       },


      xiajia(item){

        ElMessageBox.confirm(
            '是否确认下架?',
            '提示', {
              confirmButtonText: '确认',
              cancelButtonText: '取消',
              type: '提示',
            }
          )
          .then(() => {
            ElMessage({
              type: 'success',
              message: '下架成功',
            })

            this.xiugai(item,'1');

          })
          .catch(() => {
            ElMessage({
              type: 'info',
              message: '取消成功',
            })
          })

      },
      shangjia(item){
        ElMessageBox.confirm(
            '是否确认上架?',
            '提示', {
              confirmButtonText: '确认',
              cancelButtonText: '取消',
              type: '提示',
            }
          )
          .then(() => {
            ElMessage({
              type: 'success',
              message: '上架成功',
            })

            this.xiugai(item,'0');
          })
          .catch(() => {
            ElMessage({
              type: 'info',
              message: '取消成功',
            })
          })

      },
      xiugai(item,type){

        var that = this;
        var data = {
          id: item.id,
          type:type,
        };
        console.log(data);
        getxiajiafinvestmentin(data).then(res => {
          console.log('zx');
          // that.fbusinessin = res.data.fbusinessin;
          // console.log(that.fmeetingbm);
          if(type==0){
            that.getdata('1');
            that.zt=1;
          }else{
            that.getdata('2');
            that.zt=2;
          }
        }).catch(err => {})

      },

      listcount(page){
        this.page=page;
        if(this.bigcss==0){
           this.getdata(this.zt);
        }else{
           this.getdata1(this.zt1);
        }

        // this.getdata(this.zt);
      },

      getdata(ids){
        var that = this;
        var data = {
          type: ids,
          status:'0',
          uid: this.grxx.id,
          page:this.page,
          sl:5
        };
        getfinvestmentinmy(data).then(res => {
          console.log('zx');
          // that.fbusinessin = res.data.finvestmentin;

          that.fbusinessin = res.data.finvestmentin.table.data;
          that.total=res.data.finvestmentin.table.total;
          that.per_page=res.data.finvestmentin.table.per_page;
          that.current_page=res.data.finvestmentin.table.current_page;
          that.last_page=res.data.finvestmentin.table.last_page;
          that.items=res.data.finvestmentin.items;
          that.litems=res.data.finvestmentin.litems;


          that.fbusiness0 = res.data.fbusiness0;
          that.fbusiness1 = res.data.fbusiness1;
          that.fbusiness2 = res.data.fbusiness2;
          // console.log(that.fmeetingbm);

        }).catch(err => {})
      },
      getdata1(ids){
        var that = this;
        var data = {
          type: ids,
          status:ids,
          uid: this.grxx.id,
          page:this.page,
          sl:5
        };
        getfinvestmentorder(data).then(res => {
          console.log('zx');
          // that.fbusinessin = res.data.finvestmentorder;
          that.fbusinessin = res.data.finvestmentorder.table.data;
          that.total=res.data.finvestmentorder.table.total;
          that.per_page=res.data.finvestmentorder.table.per_page;
          that.current_page=res.data.finvestmentorder.table.current_page;
          that.last_page=res.data.finvestmentorder.table.last_page;
          that.items=res.data.finvestmentorder.items;
          that.litems=res.data.finvestmentorder.litems;
          // console.log(that.fmeetingbm);

        }).catch(err => {})
      },
      grxxff(grxxs) {
        this.grxx = grxxs;
        console.log(grxxs);
        console.log('zhende');
        this.getdata(this.zt);
      },
      fanhui() {
        window.history.go(-1);
      },
      qiandao() {
        this.dialogTableVisible = true;
      },
      jkzl(){
        this.dialogTableVisible1 = true;
      },
      isMobile() {
       　　let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
       　　return flag;
       },
      xiangqing1(){
        if (this.isMobile()) {
            // alert("移动端");
            //移动端跳转路由
            this.w50='90%';
          } else {
            // alert("pc端");
      　　　　//Pc端跳转路由
            this.w50='60%';
          }
          
         this.zid='';
         this.name='';
         this.phone='';
         this.bjcontent='';
         this.lxrname='';
         this.gkcontent='';
         this.kxcontent='';


         this.value0='';
         this.value1='';
         this.value2='';



         this.xcm='https://ymedicine.jiujingwulian.com/public/img/sc1.png';
         this.bcxcm='';

         this.tplist=[];
         this.tplist1=[];


         this.dialogTableVisible2 = true;
      },
      xiangqing(item){
        
        if (this.isMobile()) {
          // alert("移动端");
          //移动端跳转路由
          this.w50='90%';
        } else {
          // alert("pc端");
    　　　　//Pc端跳转路由
          this.w50='60%';
        }
            
            
        this.zid=item.id;
        this.list=item;

        this.name=item.name;
        this.phone=item.phone;
        this.bjcontent=item.bjcontent;

        this.lxrname=item.lxrname;
        this.gkcontent=item.gkcontent;
        this.kxcontent=item.kxcontent;


        this.xcm=item.xcm;
        this.bcxcm=item.fimage;

        this.tplist=item.tplist;
        this.tplist1=item.tplist1;



        this.value0=item.finvestment0_nr;
        this.value1=item.finvestment1_nr;
        this.value2=item.finvestment2_nr;

        console.log(item);

        this.dialogTableVisible2 = true;
        //处理消息队列

        var that=this;
        var data = {
          fusers_id: this.grxx.id,
          type:'4',
          fid:this.zid
        };
        getmessage(data).then(res => {
          console.log('zx');
          console.log(res);
          // that
        }).catch(err => {})




      },
      switchbig(bigcss){
        this.bigcss=bigcss;
        this.zt=0;
        this.zt1=0;
        this.page=0;
        if(bigcss==0){
           this.getdata(this.zt);
        }else{
           this.getdata1(this.zt1);
        }

      },
      qh(zt){
        this.zt=zt;
        this.page=0;
        this.getdata(this.zt);
      },
      qh1(zt1){
        this.zt1=zt1;
        this.page=0;
        this.getdata1(this.zt1);
      },
      handleFile(){

        let filePaths = window.event.target.files;
        this.upimg(0,filePaths);
      },
      handleFile1(){

        let filePaths = window.event.target.files;
        this.upimg(1,filePaths);
      },
      upimg(type,filePaths){

        console.log(filePaths);
        let file = filePaths[0];
        var data = new FormData();
        data.append("file",file);
        data.append("userName","laoliu");
        var that=this;
        upimg(data).then(res => {
          console.log('zx');
          console.log(type);
          // that.fmeetingbm = res.data.fmeetingbm;
          console.log(res);
          if(type==0){
            that.xcm='https://ymedicine.jiujingwulian.com/public'+res.data;
            that.bcxcm=res.data;
          }else{
            // that.xcm1='https://ymedicine.jiujingwulian.com/public'+res.data;
            // that.bcxcm1=res.data;
            var tplist=that.tplist;
            tplist.push('https://ymedicine.jiujingwulian.com/public'+res.data);
            var tplist1=that.tplist1;
            tplist1.push(res.data);
            this.tplist=tplist;
            this.tplist1=tplist1;
          }

          // that
        }).catch(err => {})

      },
      open() {

         this.bjcontent=this.editor.txt.html();
         this.gkcontent=this.editor1.txt.html();
         this.kxcontent=this.editor2.txt.html();

        // name:this.name,
        // phone:this.phone,
        // fimage:this.bcxcm,
        // bjcontent:this.bjcontent,
        // gkcontent:this.bcxcm1,

        if (!this.value1) {
          ElMessage({
            type: 'info',
            message: '请选择区域',
          })
          return false;
        }
        if (!this.value2) {
          ElMessage({
            type: 'info',
            message: '请选择阶段',
          })
          return false;
        }

        if (!this.value0) {
          ElMessage({
            type: 'info',
            message: '请选择领域',
          })
          return false;
        }

        if (!this.name) {
          ElMessage({
            type: 'info',
            message: '请输入标题',
          })
          return false;
        }

        if (!this.lxrname) {
          ElMessage({
            type: 'info',
            message: '请输入联系人',
          })
          return false;
        }
        if (!this.phone) {
          ElMessage({
            type: 'info',
            message: '请输入联系电话',
          })
          return false;
        }
        const reg = /^[1][1,2,3,4,5,6,7,8,9][0-9]{9}$/;
        if ((!reg.test(this.phone)) && this.phone != '') {
          ElMessage({
            type: 'info',
            message: '请输入正确的手机号',
          })
          return false;
        }
        if (!this.bcxcm) {
          ElMessage({
            type: 'info',
            message: '请上传封面图',
          })
          return false;
        }

        if (!this.bjcontent) {
          ElMessage({
            type: 'info',
            message: '请输入项目背景',
          })
          return false;
        }
        if (!this.gkcontent) {
          ElMessage({
            type: 'info',
            message: '请输入项目概况',
          })
          return false;
        }
        if (!this.kxcontent) {
          ElMessage({
            type: 'info',
            message: '请输入项目核心优势',
          })
          return false;
        }
        // if (this.tplist1.length==0) {
        //   ElMessage({
        //     type: 'info',
        //     message: '请上传图片资料',
        //   })
        //   return false;
        // }






        ElMessageBox.confirm(
            '是否确认提交?',
            '提示', {
              confirmButtonText: '确认',
              cancelButtonText: '取消',
              type: '提示',
            }
          )
          .then(() => {
            ElMessage({
              type: 'success',
              message: '提交成功',
            })
            this.insera();
          })
          .catch(() => {
            ElMessage({
              type: 'info',
              message: '取消成功',
            })
          })
      },
      remove(index){
        ElMessageBox.confirm(
            '是否确认删除此图?',
            '提示', {
              confirmButtonText: '确认',
              cancelButtonText: '取消',
              type: '提示',
            }
          )
          .then(() => {
            ElMessage({
              type: 'success',
              message: '删除成功',
            })
            this.tplist.splice(index,1);
            this.tplist1.splice(index,1);
            // this.$delete(this.tplist, index);
            // this.$delete(this.tplist1, index);

            // delete this.tplist[index]
            // this.$set(this.tplist)
            // console.log(this.tplist)

            // delete this.tplist1[index]
            // this.$set(this.tplist1)
            // console.log(this.tplist1)


          })
          .catch(() => {
            ElMessage({
              type: 'info',
              message: '取消成功',
            })
          })
      },
      insera(){
        var gkcontent='';
        var tplist1=this.tplist1;
        for(var i=0;i<tplist1.length;i++){
          gkcontent+=tplist1[i]+',';
        }

        var fbusiness0='';var fbusiness1='';var fbusiness2='';
        var f0data=this.value0;var f1data=this.value1;var f2data=this.value2;
        for(var i=0;i<f0data.length;i++){
          fbusiness0+=f0data[i]+',';
        }
        for(var i=0;i<f1data.length;i++){
          fbusiness1+=f1data[i]+',';
        }
        for(var i=0;i<f2data.length;i++){
          fbusiness2+=f2data[i]+',';
        }

        var that=this;
        var data = {
          fbusiness0:fbusiness0,
          fbusiness1:fbusiness1,
          fbusiness2:fbusiness2,
          name:this.name,
          lxrname:this.lxrname,
          phone:this.phone,
          fimage:this.bcxcm,
          bjcontent:this.bjcontent,
          gkcontent:this.gkcontent,
          kxcontent:this.kxcontent,

          uid: this.grxx.id,
          zid:this.zid
        };
        console.log(data);
        // return false;
        getinsertfinvestmentin(data).then(res => {
          console.log('zx');
          console.log(res);
          that.getdata(that.zt);
          that.dialogTableVisible2 = false;
          // that
        }).catch(err => {})

      }



    },
    components: {
      Head,
      Foot,
      Left,
      Page,
      Null,
      MyLeft
    },
  }
</script>
<style lang="scss" scoped="scoped">
  .all {
    background: #f5f6fa;
  }

  .center {
    .c-all {
      width: var(--allWidth);
      margin: 0 auto;
      margin-top: 1.275rem;
      display: flex;

      .c-right {
        margin-left: 1.275rem;
        background: #fff;
        width: 90%;
        margin-bottom: 3.125rem;

        .c-top {
          font-weight: bold;
          padding: 1.275rem;
          border-bottom: 0.0625rem solid #E8E8E8;
          cursor: pointer;

          img {
            vertical-align: middle;
            width: 1.0875rem;
            margin-right: 0.625rem;
          }

          span {
            vertical-align: middle;
          }
        }
        .zhongjians{
          margin:0.6rem 1.875rem 0 1.875rem;
          display: flex;
          .z-one{
            padding:0.8rem 0.6rem;
            margin-right: 0.625rem;
            cursor: pointer;
          }
          .bs{
            border-bottom: 0.2625rem solid #2E9BD6;
          }

        }

        .center {

          margin: 1.875rem;
          margin-top: 1rem;
          .c-bt {
            position: relative;
            .fb{
              position: absolute;
              right: 0;
              top:50%;
              transform: translate(0,-50%);
              background: #AFC81D;
              color: #fff;
              padding: 0.6rem 1.2rem;
              cursor: pointer;
              font-size: 0.9rem;
            }
            .c-lt {
              font-size: 1rem;
              padding: .675rem 2.5rem;
              border: 0.0625rem solid #E9E9E9;
              display: inline-block;
              cursor: pointer;
            }

            .zs {
              background: #2E9BD6;
              color: #fff;
            }
          }

          .dibu {
            padding: 0.8 25rem;
            border: 0.0625rem solid #E3E3E3;

            .one {
              position: relative;
              border-bottom: 0.0625rem solid #E3E3E3;

              .zt {
                color: #F31F1F;
                position: absolute;
                top: 1.325rem;
                right: 1.325rem;
                font-size: 1rem;
                font-weight: bold;
              }

              .dws {
                color: #F31F1F;
                position: absolute;
                bottom: 1.325rem;
                right: 0.925rem;
                font-size: 1rem;
                font-weight: bold;

                .down {
                  margin-bottom: 0.625rem;
                }

                .bj1 {
                  background: #2E9BD6;
                }

                .bj2 {
                  // background: #fff;
                  // color: #000000;
                  border: 0.0625rem solid #B3B3B3;
                }

                .yqd {
                  border: 0.0625rem solid #2E9BD6;
                  color: #2E9BD6;
                }
              }

              padding: 1.075rem;

              .o-title {
                margin-bottom: 0.925rem;
              }

              .o-nr {
                display: flex;

                img {
                  width: 5.275rem;
                  margin-right: 0.8125rem;
                }

                .o-le {
                  .hy{
                    display: flex;
                    align-items: center;
                    .h-one{
                      background: #F0EFEF;
                      font-size: 0.6rem;
                      padding:0.3rem 0.925rem;
                      margin-right: 0.625rem;
                      border-radius: 0.125rem;
                    }
                    margin-bottom: 0.8rem;
                    margin-top: 1rem;
                  }
                  .o-l-title {
                    font-weight: bold;

                  }

                  .o-l-bt {
                    display: flex;
                    align-items: center;
                    color: #4E4E4E;
                    font-size: 0.3375rem;

                    .ones {
                      display: flex;
                      align-items: center;

                      img {
                        width: 0.875rem;
                        margin-right: 0.3125rem;
                      }

                      .ones-fy {
                        color: #FF2323;
                      }

                      .ones-nrs {
                        color: #2E9BD6;
                      }
                    }

                    .shu {
                      margin: 0 0.275rem;
                      color: #C0C0C0;
                    }
                  }
                }
              }
            }
          }
        }

      }
    }

  }

  .qd-top {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.3rem;
    font-weight: bold;

    .qd-bt {
      margin: 0 1.25rem;
    }

    .xian {
      width: 40%;
      height: 0.125rem;
      background: #D2D2D2;
    }


  }

  .ewm {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    img {
      width: 10.875rem;
      margin-bottom: 1.875rem;
    }

    .e-bt {
      font-size: 1.125rem;
      color: #1B1B1B;
    }
  }
  .ewm1 {
    text-align: center;
    // display: flex;
    // align-items: center;
    // justify-content: space-evenly;
    img {
      width: 10.875rem;
      margin-bottom: 1.875rem;
    }

    .e-bt {
      font-size: 1.125rem;
      color: #1B1B1B;
    }
  }

  .db{
    text-align: center;
    margin-top: 2.875rem;
  }
  .baocun{
    margin: 0 auto;
    padding: 1.375rem 6rem;
    font-size: 1.1875rem;
    background: #2E9BD6;
  }

  .my-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #2E9BD6;
    color: #fff;
  }
  .ddb{
    position: relative;
    z-index: 9999999999999999999;
    .top{
      position: absolute;
      top: 0;
      left: 0;
      background: #2E9BD6;
      color: #FFFFFF;
      width: 100%;
      padding: 1.075rem;
      font-size: 1.2rem;
    }
    .hang{
      display: flex;
      margin-top: 0.625rem;
      .h-title{
        font-weight: bold;
        color: #000000;
        border-left: 0.225rem solid #2E9BD6;
        padding-left: 0.625rem;
      }
    }
    .dibu1{
      border-bottom: 0.0625rem solid #E6E6E6!important;
      padding-bottom: 1.875rem;
    }

    .lxr{
      width: 30%;
      display: flex;
      align-items: center;
      // display: inline-block;
      margin-right: 3%;
      .bt{
        width: 8.5rem;
        text-align: left;
      }
      .shuru{
        width: 100%;
      }
    }

  }
  .dban{
    text-align: center;
    margin-top: 2.175rem;
    .anniu{
      width: 30%;
      text-align: center;
      padding: 1.4rem 0;
      font-size: 1rem;
    }
    .bs{
      background: #AFC81D;
      border: 0.0625rem solid #AFC81D;
      margin-right: 2.275rem;
      img{
        width: 1rem;
        margin-right: 0.625rem;
      }
    }
    .bs1{
      background: #2E9BD6;
    }
  }
  .gb{
    cursor: pointer;
  }
  .zd{
    z-index: 9999999999999999999999999999999999!important;
  }

  .lefts{
    // width: 20%;
    margin-right: 0.625rem;
    position:  relative;
    img{
        width: 100%;
    }
    input{
      font-size: 0;
      width: 100%;
      position: absolute;
      height: 80%;
      top: 0;
      left: 0;
      background: rgba(0,0,0,0);
      border: 0;
      line-height: 0;
      outline: 0;
      opacity: 0;

    }
  }


  @media screen and (max-width: 1300px) {
    .c-all{
      width: 95%!important;
      margin: 0 auto;
      flex-wrap: wrap;
    }
    .c-right{
      width: 100%!important;
      margin: 0 auto!important;
    }

    .lefts{
      width: 100%;

    }
    .c-right{
     .center{
          margin: 0rem !important;
          padding: 0.75rem!important;
     }
    }
    .c-bt{
      display: flex;
    }
    .c-lt{
      padding: 0.65rem 0!important;
      width: 36%;
      text-align: center;
      margin-bottom: 0.625rem;
    }

    .o-nr{
      flex-wrap: wrap;
      .tbimg{
        width: 100%!important;
        margin: 0;
        margin-top: 0.625rem;
      }
    }
    .o-l-title{
      margin-bottom: 0.75rem!important;
      margin-top: 0.75rem!important;
      font-size: 1rem!important;
    }
    .dws{
      position: relative!important;
      bottom: 0!important;
      right: 0!important;
      display: flex;
      font-size: 0.75rem;
      margin-top: 0.75rem;
      .el-button{
        font-size: 0.75rem;
      }
    }
    .o-l-bt{
      flex-wrap: wrap;
    }
    .ones{
      margin-bottom: 0.625rem;
    }
    .shu{
      margin-bottom: 0.725rem!important;
    }
    .el-button+.el-button {
        margin-left: 0.25rem;
    }
    .zd{
      width: 80%!important;
    }
    .qd-bt{
      font-size: 1rem;
    }
    .ewm{
     .lefts{
       width: 47%!important;
       img{
         margin-bottom: 0.5rem;
       }
     }

    }
    .db{
       margin-top: 1rem;
     }

     .d-o-bt{
       width: 100%!important;
       margin-bottom: 0.625rem;
     }
     .ddgy-one{
       margin-bottom: 0!important;
     }
     .td{
           width: 40%!important;
           padding: 0 0.25rem!important;
           font-size: 0.75rem!important;
     }
     .anniu {
       width: 45%!important;
       font-size: 1rem!important;
     }
     .bs{
       margin-right: 3%!important;
     }
     .dban{
       margin-top: 1rem;
     }

    .fb{
      top: -70%!important;
    }
    .hang{
      flex-wrap: wrap;
      .lxr{
        width: 100%;
        margin-right: 0;
        margin-bottom: 0.9375rem;
        .bt{
          text-align: right;
          width: 6rem;
        }
      }
      .shuru {
        width: 100%!important;
      }
      .h-title{
        font-size: 0.9rem!important;
      }
    }
    .anniu{
      padding: 1.3rem 1rem;
      font-size: 0.9rem!important;
      width: 94%!important;

    }
    .ddb{
      .top{
        font-size: 1rem;
        padding: 0.8rem;
      }
    }


  }
</style>
