<template>
  <div class="all">
    <Head :top="top" :grxxff="grxxff"></Head>
    <div class="center">
      <div class="c-all">
        <MyLeft></MyLeft>

        <div class="c-right">
          <div class="c-top" @click="fanhui()"><img src="~assets/about/fh.png" /><span>入会资料</span></div>

          <div class="c-r-cen">
            <div class="c-r-top">
              <div class="c-r-pp " :class="zt>=0?'zd':''">
                <img src="~assets/my/x4.png" />
                <div>填写入会申请</div>
              </div>
              <img src="~assets/my/jiantou.png" class="jiantou" />
              <div class="c-r-pp" :class="zt>=1?'zd':''">
                <img src="~assets/my/x3.png" v-show="zt>=1" />
                <img src="~assets/my/w1.png" v-show="zt<1" />
                <div>等待平台审核</div>
              </div>
              <img src="~assets/my/jiantou.png" class="jiantou" />
              <div class="c-r-pp" :class="zt>=2?'zd':''">
                <img src="~assets/my/x2.png" v-show="zt>=2" />
                <img src="~assets/my/w2.png" v-show="zt<2" />
                <div>缴纳会员会费</div>
              </div>
              <img src="~assets/my/jiantou.png" class="jiantou" />
              <div class="c-r-pp" :class="zt>=3?'zd':''">
                <img src="~assets/my/x1.png" v-show="zt>=3" />
                <img src="~assets/my/w3.png" v-show="zt<3" />
                <div>入会通过完成</div>
              </div>
            </div>





          </div>

          <div class="from" v-show="zt=='0' || zt=='3'">
            <div class="bj" v-show="zt=='3'"></div>
            <div class="tg" v-show="zt=='3'">
              <img src="~assets/my/tg.png">
            </div>
            <div class="one">
              <span class="xingming"><span class="xing">*</span>真实姓名:</span>
              <div class="kd">
                <el-input v-model="name" size="large" placeholder="请输入" clearable />
              </div>
            </div>
            <!-- <div class="one">
              <span class="xingming"><span class="xing">*</span>性别:</span>
              <div class="kd">
                <el-radio-group v-model="gender" class="ml-4">
                  <el-radio label="1" size="large">男</el-radio>
                  <el-radio label="2" size="large">女</el-radio>
                </el-radio-group>
              </div>
            </div> -->
<!--            <div class="one">
              <span class="xingming"><span class="xing">*</span>所在地区:</span>
              <div class="kd">
              <el-cascader :options='options' :placeholder="zw" style="width: 100%;" class="ml-4"  v-model="seLectedoptions1" @change="addressChange" ></el-cascader>
 -->                <!--默认使用-->

                <!--带isall参数和leave参数示例-->


                <!--
                <el-select v-model="value" class="m-2" placeholder="选择省" size="large">
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
                <el-select v-model="value" class="m-2" placeholder="选择市" size="large">
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
                <el-select v-model="value" class="m-2" placeholder="选择区/县" size="large">
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
                -->
<!--
              </div>
            </div> -->
<!--
            <div class="one">
              <span class="xingming"><span class="xing">*</span>邮箱:</span>
              <div class="kd">
                <el-input v-model="email" size="large" placeholder="请输入" clearable />
              </div>
            </div> -->







      <!--      <div class="one">
              <span class="xingming"><span class="xing"></span>Q Q:</span>
              <div class="kd">
                <el-input v-model="qq" size="large" placeholder="请输入" clearable />
              </div>
            </div> -->
            <div class="one">
              <span class="xingming"><span class="xing">*</span>手机号:</span>
              <div class="kd">
                <el-input v-model="phone" size="large" placeholder="请输入" clearable />
              </div>
            </div>
            <div class="one" v-show="zt!='3'">
              <span class="xingming"><span class="xing">*</span>申请类型:</span>
              <div class="kd">
                <el-select v-model="value1" @change="onChange" style="width: 100%;" class="m-2 shuru" placeholder="请选择" size="large">
                  <el-option
                    v-for="item in fbusiness1"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </div>
            </div>
            <div class="one">
              <span class="xingming"><span class="xing">*</span>申请表:</span>
              <div class="kd">
                <el-input v-model="wenzi" disabled="disabled" size="large" placeholder="请输入" clearable />
                <div class="xiazia" @click="don" v-show="zt!='3'">
                  <img src="../../assets/my/xzs.png"/>
                  点击下载
                </div>
              </div>
              <!-- color#FF6A0F -->
              <!-- back  background: rgb(255, 250, 246);-->


            <!--  -->
            </div>
            <div class="one" v-show="zt!='3'">
              <span class="xingming"><span class="xing"></span></span>
              <div class="kd">
                <div class="dibu" v-html="shezhi.smcontent">

                </div>
              </div>
            </div>
            <div class="one" v-show="zt!='3'">
              <span class="xingming"><span class="xing"></span></span>
              <div class="kd">
                <el-checkbox type="checkbox" v-model="checked2"   size="large" label="我已阅读并同意"  />
                <span class="lx" @click="xieyi">《中国生物医药产业链创新与转化联合体会员单位及个人会员资格要求》</span>
              </div>
            </div>

            <el-dialog
               v-model="xy"
               title=""
               width="80%"
               align-center>
               <div class="ewm">
                <div v-html="shezhi.zgcontent" ></div>
               </div>
             </el-dialog>

            <!-- <div class="one">
              <span class="xingming"><span class="xing"></span>固定电话:</span>
              <div class="kd">
                <el-input v-model="gphone" size="large" placeholder="请输入" clearable />
              </div>
            </div>
            <div class="one">
              <span class="xingming"><span class="xing">*</span>职位:</span>
              <div class="kd">
                <el-input v-model="zhiwei" size="large" placeholder="请输入" clearable />
              </div>
            </div>
 --><!--
            <div class="one">
              <span class="xingming"><span class="xing"></span>个人简介:</span>
              <div class="kd">
                <el-input v-model="jianjie" :autosize="{ minRows:1, maxRows: 8 }" type="textarea"
                  placeholder="请输入" />
              </div>
            </div> -->
            <div class="one" v-show="zt=='0'">
              <span class="xingming"><span class="xing"></span></span>
              <div class="kd">
                <el-button type="primary" @click="open()" class="baocun">保存</el-button>
              </div>
            </div>


          </div>


          <!-- shzt 0= 待审核,1=已驳回 -->
          <div class="shenhe" v-show="zt=='1' && shzt==0">
            <div class="sh-img">
              <img src="~assets/my/cg.png" />
            </div>
            <div class="sh-title">恭喜您，您的资料已经提交成功</div>
            <div class="sh-jianjie">请等待审核！</div>
            <!-- <div>
              <el-button type="primary" class="baocun">立即支付</el-button>
            </div> -->
          </div>

          <div class="shenhe" v-show="zt=='1' && shzt==1">
            <div class="sh-img">
              <img src="~assets/my/cw.png" />
            </div>
            <div class="sh-title">抱歉，您的申请已被驳回</div>
            <div class="sh-jianjie">请修改后重新提交！</div>
            <div>
              <el-button type="primary" @click="fhxg()" class="baocun">修改申请表</el-button>
            </div>
          </div>

          <div class="huifei" v-show="zt=='2'">
            <div class="h-dengji">
              <div class="h-d-title">申请等级:</div>
              <div class="h-d-con">{{wenzi}}</div>
            </div>
            <div class="h-dengji">
              <div class="h-d-title">应缴会费:</div>
              <div class="h-d-con1">￥{{price}}</div>
            </div>
            <el-button type="primary" @click="zhifu()" class="baocun td">立即支付</el-button>
          </div>


        </div>

      </div>
    </div>


    <foot></foot>
    <left></left>
<!-- width="80%" -->
    <el-dialog
       v-model="pdshow"
       title="支付确认"

      :width="w50"
       align-center>
       <div class="ewm">
         <img class="tp" src="~assets/meeting/wechat.png" />
         <img class="eee" :src="ewms" />
         <div class="e-price">￥{{price}}</div>
         <div class="dbs">
           <img class="saosao" src="~assets/meeting/scan.png" />
           <div class="e-span">请使用微信扫一扫扫描二维码支付</div>
         </div>
       </div>
     </el-dialog>
   <!--
    <Pay :pdshows="pdshow" :je="price" :ewms="ewms"></Pay> -->
  </div>
</template>

<script>
  import {
    ref,
    onMounted
  } from 'vue';
  import {
    getHome,
    getgrxx
  } from 'network/home.js';
  import {
    useRouter
  } from 'vue-router';

 import {
    getsqrh,
    getsqrh1,
    huiyuan,
    getZhifuH5
  } from 'network/my.js';

  import {
    ElMessage,
    ElMessageBox
  } from 'element-plus';
  import {
    Action
  } from 'element-plus';



  import Head from "components/content/headder/Head.vue";
  import Foot from "components/content/footer/Foot.vue";
  import Left from "components/content/left/Left.vue";
  import Pay from "components/content/pay/pay.vue";

  import MyLeft from "views/my/ChildComps/MyLeft.vue";


  // import { getData } from "@/api/lineCity";
  // import { EluiChinaAreaDht } from 'elui-china-area-dht'
  import {regionData,CodeToText} from 'element-china-area-data';

  import {
    getfshezhi
  } from 'network/home.js';


  export default {
    name: 'My',
    data() {
      return {
        timer: null  ,// 定时器名称

        shezhi:{},
        xy:false,
        w50:'50%',
        checked2: false,
        wenzi:'',
        value1:'',
        fbusiness1:[
          {
            id:'1',
            name:'4'
          }
        ],

        pdshow:false,
        ewms:'',
        options:regionData,//不带全部的一级联动//
        // seLectedoptions1:"请选择",//或者写字符串，不影响数组格式
        seLectedoptions1:[
          '110009','110109','110101'
          ], //这里给一个默认省X
        price:0,
        zt: 0,
        shzt: 0, //shzt 0= 待审核,1=已驳回
        zw:'请选择',
        radio1: 1,
        textarea2: '',
        // options: [{
        //   value: '城市',
        //   lable: '0'
        // }, ],
        url:'',
        name: '',
        gender:0,
        address:'',
        email:'',
        qq:'',
        phone:'',
        gphone:'',
        zhiwei:'',
        jianjie:'',
        Mobile:false,
        grxx: [],
      }
    },
    setup() {
      const top = 0;
      const router = useRouter();
      const databa = ref([]);
      const ab = 'sb';
      console.log(ab);
      onMounted(() => {

        getHome().then(res => {
          console.log(res.data.data);
          databa.value = res.data;
          console.log(databa);

        }).catch(err => {

        })

      })
      //跳转方法
      const goTo = (path, query) => {
        router.push({
          path: path,
          query: query || {}
        })
      }

      return {
        databa,
        ab,
        goTo,
        top
      }
    },
    beforeDestroy() {
      alert('1');
      if(this.timer) {
         clearInterval(this.timer);
      }

    },
    deactivated: function () {
        // 每次离开当前界面时，清除定时器
        alert('1');
        clearInterval(this.timer);
        this.timer = null
    },


    mounted() {


      if (this.isMobile()) {
        // alert("移动端");
        //移动端跳转路由
        this.Mobile=true;
      } else {
        // alert("pc端");
  　　　　//Pc端跳转路由
        this.Mobile=false;
      }


      var that = this;
      var data = {

      };
      getfshezhi(data).then(res => {
        // console.log('zd');
        // console.log(res);
        that.shezhi=res.data.data;
      }).catch(err => {})


      var that = this;
      var data = {
      };
      huiyuan(data).then(res => {

        that.fbusiness1 = res.data;
        console.log('’new'+res.data);

        console.log('’new'+that.fbusiness1);
      }).catch(err => {})


      window.scrollTo( 0, 0 );
    },

    methods: {
      getZhifuH51(){

        var that = this;
        var data = {
          uid:this.grxx.id,
        };
        getZhifuH5(data).then(res => {
          console.log(res);
          console.log(res.data.code_url);
          // window.open(res.data.code_url);
          alert(res.data.code_url);
          window.location.href=res.data.h5_url;
        }).catch(err => {})

     },
     isMobile() {
      　　let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      　　return flag;
      },

      xieyi(){
        this.xy = true;
      },
      don(){
        if(this.url){
          window.location.href='https://ymedicine.jiujingwulian.com/public'+this.url;
        }

      },

      addressChange (arr) {
      // console.log(this.selectedoptions) // proxy [0: '120000', 1: '20100, 2: '120101]//
      console.log(arr) // Proxy f0: 120000，1: 120100，2: 1201011 和上句#
      var addressText=CodeToText[arr[0]]+CodeToText[arr[1]]+CodeToText[arr[2]];
      console.log(addressText);
      this.address=addressText;
      // var addressText=CodeToText[arr@]]+CodeToText[arr[1]]+CodeToTextarr[2]];
      // console.log(addressText) // addressText为el-cascader的值北京市 市X 朝阳X
      },


      onChange(val){
        console.log(val);
        var fbusiness1=this.fbusiness1;
        for(var i=0;i<fbusiness1.length;i++){
          if(fbusiness1[i].id==val){
             this.wenzi=fbusiness1[i].name;
             this.url=fbusiness1[i].file;
          }
        }

        // const chinaData = new EluiChinaAreaDht.ChinaArea().chinaAreaflat
        // const province = chinaData[e[0]]
        // const city = chinaData[e[1]]
        // const area = chinaData[e[2]]
        // console.log(province, city ,area)

      },

      open() {
        console.log(this.grxx);
        if (!this.grxx.id) {
          this.$router.push('/login')
        } else {
          this.dialogTableVisible2 = true;
        }


        if (!this.name) {
          ElMessage({
            type: 'info',
            message: '请输入姓名',
          })
          return false;
        }
        // if (!this.gender) {
        //   ElMessage({
        //     type: 'info',
        //     message: '请选择性别',
        //   })
        //   return false;
        // }
        // if (!this.address) {
        //   ElMessage({
        //     type: 'info',
        //     message: '请选择地址',
        //   })
        //   return false;
        // }
        // if (!this.email) {
        //   ElMessage({
        //     type: 'info',
        //     message: '请输入邮箱',
        //   })
        //   return false;
        // }


        // const reg1 =/^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
        // if ((!reg1.test(this.email)) && this.email != '') {
        //   ElMessage({
        //     type: 'info',
        //     message: '请输入正确的邮箱',
        //   })
        //   return false;
        // }

        if (!this.phone) {
          ElMessage({
            type: 'info',
            message: '请输入手机号',
          })
          return false;
        }
        const reg = /^[1][1,2,3,4,5,6,7,8,9][0-9]{9}$/;
        if ((!reg.test(this.phone)) && this.phone != '') {
          ElMessage({
            type: 'info',
            message: '请输入正确的手机号',
          })
          return false;
        }
        // if (!this.zhiwei) {
        //   ElMessage({
        //     type: 'info',
        //     message: '请输入职位',
        //   })
        //   return false;
        // }

        if (!this.wenzi) {
          ElMessage({
            type: 'info',
            message: '请选择申请类型',
          })
          return false;
        }


        if (!this.checked2) {
          ElMessage({
            type: 'info',
            message: '请阅读并同意《中国生物医药产业链创新与转化联合体会员单位及个人会员资格要求》',
          })
          return false;
        }







        ElMessageBox.confirm(
            '是否确认提交?',
            '提示', {
              confirmButtonText: '确认',
              cancelButtonText: '取消',
              type: '提示',
            }
          )
          .then(() => {
            ElMessage({
              type: 'success',
              message: '提交成功',
            })
            this.tijiao();
          })
          .catch(() => {
            ElMessage({
              type: 'info',
              message: '取消成功',
            })
          })
      },

      tijiao(){
        console.log(this.grxx.id);
        console.log('jll');

        var that = this;
        var data = {
          wenzi:that.wenzi,
          name:that.name,
          gender:that.gender,
          address:that.address,
          email:that.email,
          qq:that.qq,
          phone:that.phone,
          gphone:that.gphone,
          zhiwei:that.zhiwei,
          jianjie:that.jianjie,
          uid:this.grxx.id,
          status:1,
        };
        getsqrh(data).then(res => {

          console.log('zx');
          console.log(res);
          that.zt=1;


        }).catch(err => {})

      },
      zhifu(){

        if (this.isMobile()) {
            // this.Mobile=true;
            this.getZhifuH51();
            //去支付
          } else {
            // this.Mobile=false;
            this.pdshow=true;
          }

          if (this.isMobile()) {
            // alert("移动端");
            //移动端跳转路由
            this.w50='90%';
          } else {
            // alert("pc端");
      　　　　//Pc端跳转路由
            this.w50='50%';
          }
          clearInterval(this.timer)
          var i=0;

          var that = this;
          var data = {
            uid:this.grxx.id
          };


          this.timer = setInterval( () => {
            console.log('开始定时...每过一秒执行一次'+i)


            getgrxx(data).then(res => {
              // console.log(res);
              var pd=res.data.data.stauts;
              if(pd==4){
                // window.location.reload();
                that.zt=3;
                clearInterval(this.timer);
                 that.pdshow=false;
              }

            }).catch(err => {})



            i++;
            if(i==360){
              console.log('停止了');
              clearInterval(this.timer);
               this.pdshow=false;

                  // that.zt=3;
                  // clearInterval(this.timer);
                  // that.pdshow=false;


            }
          }, 1000);
          // 通过$once来监听定时器
          // 在beforeDestroy钩子触发时清除定时器




        // ElMessageBox.confirm(
        //     '是否支付'+this.grxx.price+'元?',
        //     '提示', {
        //       confirmButtonText: '确认',
        //       cancelButtonText: '取消',
        //       type: '提示',
        //     }
        //   )
        //   .then(() => {
        //     ElMessage({
        //       type: 'success',
        //       message: '提交成功',
        //     })
        //     // this.tijiao();
        //     console.log(this.grxx.id);
        //     console.log('jll');

        //     var that = this;
        //     var data = {
        //       status:4,
        //       uid:this.grxx.id,

        //     };
        //     getsqrh1(data).then(res => {

        //       console.log('zx');
        //       console.log(res);
        //       that.zt=3;


        //     }).catch(err => {})

        //   })
        //   .catch(() => {
        //     ElMessage({
        //       type: 'info',
        //       message: '取消成功',
        //     })
        //   })


      },
      fhxg(){

        console.log(this.grxx.id);
        console.log('jll');

        var that = this;
        var data = {
          status:0,
          uid:this.grxx.id,

        };
        getsqrh1(data).then(res => {

          console.log('zx');
          console.log(res);
          that.zt=0;


        }).catch(err => {})

      },





      grxxff(grxxs) {
        this.grxx = grxxs;
        // 状态:0=未提交,1=待审核,2=已审核,3=已驳回,4=已支付
        if(this.grxx.stauts==1){
          this.zt=this.grxx.stauts;
          this.shzt=0;
        }else if(this.grxx.stauts==2){
          this.zt=this.grxx.stauts;
        }else if(this.grxx.stauts==3){
          this.zt=1;
          this.shzt=1;
        }else if(this.grxx.stauts==4){
          this.zt=3;
        }
        this.price=this.grxx.price;

        this.name=this.grxx.name;
        this.gender=this.grxx.gender;
        // this.name=this.grxx.name;
        // this.seLectedoptions1=
        this.email=this.grxx.email;
        this.qq=this.grxx.qq;
        this.phone=this.grxx.phone;
        this.wenzi=this.grxx.wenzi;
        this.gphone=this.grxx.gphone;
        this.zhiwei=this.grxx.zhiwei;
        this.jianjie=this.grxx.jianjie;
        this.zw=this.grxx.address;
        this.address=this.grxx.address;

        this.name=this.grxx.name;
        this.ewms='https://ymedicine.jiujingwulian.com/public/'+this.grxx.zfimage;

        console.log(grxxs);
        console.log('zhende');
      },
      fanhui() {
        window.history.go(-1);
      }
    },
    components: {
      // EluiChinaAreaDht,
      Head,
      Foot,
      Left,
      Pay,
      MyLeft
    },
  }
</script>
<style lang="scss" scoped="scoped">
  .all {
    background: #f5f6fa;
  }

  .center {
    .c-all {
      width: var(--allWidth);
      margin: 0 auto;
      margin-top: 1.275rem;
      display: flex;

      .c-right {
        margin-left: 1.275rem;
        background: #fff;
        width: 90%;
        margin-bottom: 3.125rem;

        .c-top {
          font-weight: bold;
          padding: 1.275rem;
          border-bottom: 0.0625rem solid #E8E8E8;
          cursor: pointer;

          img {
            vertical-align: middle;
            width: 1.0875rem;
            margin-right: 0.625rem;
          }

          span {
            vertical-align: middle;
          }
        }

        .c-r-cen {
          padding: 0 1.875rem;
          display: flex;
          justify-content: space-between;

          .c-r-r-left {
            width: 40%;
            padding: 2.575rem 0;
            text-align: center;

            .xm {
              margin-top: 0.875rem;
            }

            .c-c-all {
              margin-top: 1.875rem;
              display: flex;
              justify-content: center;

              .c-rrone {
                padding: 0.3125rem 0.825rem;
                font-size: 0.8125rem;
                color: #747474;
                border: 0.0625rem solid #747474;
                display: inline-block;
                border-radius: 1.875rem;
                display: flex;

                img {
                  width: 0.75rem;
                  margin-right: 0.5rem;
                }

                margin-right: 10px;
                cursor: pointer;
              }

              .op {
                background: #2E9BD6;
                border: 0.0625rem solid #2E9BD6;
                color: #fff;
              }

              .oo {
                background: #AFC81D;
                border: 0.0625rem solid #AFC81D;
                color: #fff;
              }

            }


            .c-c-bo {
              margin-top: 3.0rem;
              display: flex;
              align-items: center;
              justify-content: space-around;


              .c-c-c-on {
                border: 0.0625rem solid #E5E5E5;
                padding: 2.2rem;

                span {
                  display: inline-block;
                  margin-bottom: 0.625rem;
                  font-size: 1.6rem;
                  color: #FF9135;
                }

                div {
                  font-size: 0.8rem;
                }

                .er {
                  color: #808080;
                }

                .san {
                  color: #2E9BD6;
                }
              }
            }
          }


          .c-r-r-Right {
            width: 58%;

            .el-carousel {
              height: 24.075rem;
              position: relative;

              div {
                height: 24.075rem !important;
              }
            }

            .el-carousel__container {
              // height: 24.075rem!important;
            }

            .el-carousel__indicators {
              width: auto;
              position: absolute;
              right: -6.75rem;
              bottom: 0.5rem;
            }


            .zj {
              width: 100%;

              img {
                width: 100%;
                height: 100%;
              }
            }


          }

          .c-r-top {
            background: #F7FCFF;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            padding: 1.875rem 0;
            margin-top: 0.825rem;

            .jiantou {
              margin: 0 0.325rem;
              margin-top: -1.25rem;
            }

            .c-r-pp {
              text-align: center;
              font-size: 0.8125rem;
              color: #686868;
              font-weight: bold;

              div {
                margin-top: 0.625rem;
              }
            }

            .zd {
              color: #2E9BD6;
            }
          }
        }


        .gy {
          padding: 0 2.875rem;
          display: flex;
          justify-content: space-between;

          .g-left {
            width: 40%;

            .g-l-top {
              background: #2E9BD6;
              padding: 0.825rem;
              color: #fff;
            }

            .g-l-le {
              border: 0.0625rem solid #E8E8E8;

              .g-l-o {
                padding: 0.725rem;
                font-size: 0.65rem;
                display: flex;
                align-items: center;

                img {
                  margin-right: 0.325rem;
                  width: 0.425rem;
                }
              }
            }
          }

          .g-right {
            width: 58%;

            .g-l-top {
              background: #AFC81D;
              padding: 0.825rem;
              color: #fff;
            }

            .g-l-le {
              border: 0.0625rem solid #E8E8E8;
              display: flex;
              justify-content: center;
              align-items: center;

              .shu {
                background: #E5E5E5;
                width: 0.0625rem;
                height: 3.625rem;
              }

              .g-l-lleft {
                text-align: center;
                width: 50%;
                padding: 1.08rem 0;

                img {
                  width: 8.75rem;
                  margin-bottom: 1.475rem;
                }

                .xm {
                  display: flex;
                  justify-content: center;

                  // align-items: center;
                  img {
                    width: 1.275rem;
                    margin-right: 0.3125rem;
                  }
                }

                .xms {
                  width: 97%;
                  text-align: center;
                  margin: 0 auto;
                  padding: 0.625rem 0;
                  background: #F9FAF1;
                  font-size: 0.8125rem;
                  letter-spacing: 0.1875rem;
                  margin-top: 0.8rem;
                }

                .sx {
                  background: #F4FBFF;
                }
              }
            }

          }
        }
      }

    }

  }

  .from {
    width: 80%;
    margin: 0 auto;
    margin-top: 1.875rem;
    padding-bottom: 1.875rem;
    position: relative;

    .bj {
      background: rgba(0, 0, 0, 0);
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 9999999;
    }

    .tg {
      position: absolute;
      z-index: 99999999;
      bottom: 1.875rem;
      right: 8.875rem;

      img {
        width: 8rem;
      }
    }

    .one {
      display: flex;
      align-items: center;
      margin-bottom: 1.075rem;

      .xing {
        color: red;
      }

      .xingming {
        margin-right: 0.625rem;
        width: 6.5rem;
        text-align: right;
        display: inline-block;
      }

      .m-2 {
        margin-right: 1rem;
      }

      .kd {
        width: 80%;
        position: relative;
        .xiazia{
          cursor: pointer;
          img{
            margin-right: 0.325rem;
          }
          position:absolute;
          background: #717171;

          right: 0;
          top: 48%;
          transform: translate(0, -50%);
          color: #fff;
          padding: 0.5625rem;
          display: flex;
          align-items: center;
          font-size: 0.875rem;

        }
      }

    }
    .dibu{
      color: #FF6A0F;
      background: rgb(255, 250, 246);
      padding:0.875rem;
    }
  }

  .baocun {
    padding: 1.2rem 4.325rem;
    background: #2E9BD6;
  }

  .shenhe {
    text-align: center;
    margin-top: 1.875rem;

    .sh-img {
      margin-bottom: 1.25rem;

      img {
        width: 3.8rem;
      }
    }

    .sh-title {
      margin-bottom: 1.25rem;
    }

    .sh-jianjie {
      color: #7D7D7D;
      font-size: 0.9375rem;
      margin-bottom: 1.25rem;
    }
  }

  .huifei {
    width: 60%;
    margin: 0 auto;
    margin-top: 1.875rem;
    text-align: center;

    .h-dengji {
      border-top: 0.0625rem solid #E8E8E8;
      border-bottom: 0.0625rem solid #E8E8E8;
      padding: 1.875rem;

      div {
        display: inline-block;
      }

      font-size: 1.125rem;

      .h-d-title {
        margin-right: 0.625rem;
      }

      .h-d-con {
        // font-size: 1.2rem;
        font-weight: bold;
        // width: 3.875rem;
      }

      .h-d-con1 {
        font-weight: bold;
        width: 3.875rem;
        color: #F63434;
      }
    }

    .td {
      margin-top: 1.875rem;
      padding: 1.6rem 5.325rem;
      font-weight: bold;
      font-size: 1rem;
    }
  }

  @media screen and (max-width: 1300px) {
     .c-all{
       width: 95%!important;
       margin: 0 auto;
       flex-wrap: wrap;
     }
     .c-right{
       width: 100%!important;
       margin: 0 auto!important;
     }

     .lefts{
       width: 100%;
     }

     .c-right{
      .center{
           margin: 0rem !important;
           padding: 0.75rem!important;
      }
     }

     .c-bt{
       display: flex;
     }
     .c-lt{
       padding: 0.65rem 0!important;
       width: 36%;
       text-align: center;
       margin-bottom: 0.625rem;
     }

     .from{
           width: 95%!important;
     }
     .ddb{
       .top{
         font-size: 1rem;
         padding: 0.8rem;
       }
     }

     .c-r-cen{
       padding: 0rem!important;
       .c-r-top{
         padding: 0.875rem!important;
       }
       .c-r-pp{
         width: 20%!important;
         font-size: 0.75rem!important;
         img{
           width: 100%!important;
         }
       }
       .jiantou{
         width: 5%!important;
       }
     }
     .tg{
       bottom: 2.875rem!important;
       right: -0.125rem!important;

     }
    .shenhe,.huifei{
      margin-bottom: 2rem!important;
    }
    .huifei{
      width: 90%;
    }

  }


  .ewm{
    text-align: center;
    .tp{
      display: block;
      margin: 0 auto;
      margin-bottom: 1.625rem;
      width: 5.875rem;
    }
    .eee{
        min-width: 33%;
    }
    .e-price{
      color: red;
      font-size: 1.6rem;
      margin-top: 0.625rem;
    }
    .dbs{
      background: #00c800;
      margin-top: 0.875rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      padding: 0.75rem 0;
      .saosao{
        width: 1.6875rem;
        margin-right: 0.625rem;
      }
      .e-span{
          max-width: 60%;
          text-align: left;
      }
    }

  }

  .lx{
    cursor: pointer;
  }


</style>
