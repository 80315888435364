<template>
  <div class="all">
    <Head :top="top" :grxxff="grxxff"></Head>
    <div class="a-cen">
      <div class="center">
        <Screen :list="list"
        :four='four'
        :five="five" :sex='sex' :event='event' :eight='eight' :nine='nine' :ten='ten'
        ></Screen>
        <List :tz="tz" :list='finvestmentin' :p="p"></List>

        <page :items='items'
        :litems='litems'
        :total="total"
        :per_page="per_page"
        :current_page="current_page"
        :last_page="last_page"
        :listcount='listcount'></page>

      </div>
    </div>
    <foot></foot>
    <left></left>
  </div>
</template>

<script>
  import {
    ref,
    onMounted
  } from 'vue';
  import {
    getHome
  } from 'network/home.js';

  import {
    getfinvestment,
    getfinvestmentin,
    getfinvestmentxq
  } from 'network/cooperation.js';

  import {
    useRouter
  } from 'vue-router';


  import Head from "components/content/headder/Head.vue";
  import Foot from "components/content/footer/Foot.vue";
  import Left from "components/content/left/Left.vue";
  import Bread from "components/content/breadcrumb/Breadcrumb.vue";
  import Banner from "components/content/banner/Banner.vue";

  import Screen from "../financing/ChildComps/Screen.vue";
  import List from "../financing/ChildComps/List.vue";

  import Page from "components/content/page/Page.vue";


  export default {
    name: 'New',
    data() {
      return {
        fourcs:'',
        fivecs:'',
        sexcs:'',

        eventcs:'',
        eightcs:'',
        ninecs:'',
        tencs:'',


        items:[
        ],
        litems:[
        ],
        page:0,
        total:'',
        per_page:'',
        current_page:'',
        last_page:'',




        p:1,
        finvestmentin:[],
        four:[],
        five:[],
        sex:[],

        event:[],
        eight:[],
        nine:[],
        ten:[],
        tz:'/cooperationDe',
        bn: 'public/img/banner.387b0333.png',
        all: [{
            name: '您当前的位置:首页',
            url: '/'
          },

          {
            name: '资讯',
            url: '/New'
          },
        ]
      }
    },
    setup() {
      const top = 9;
      const router = useRouter();
      const databa = ref([]);
      const ab = 'sb';
      console.log(ab);
      onMounted(() => {

        getHome().then(res => {
          console.log(res.data.data);
          databa.value = res.data;
          console.log(databa);

        }).catch(err => {

        })

      })
      //跳转方法
      const goTo = (path, query) => {
        router.push({
          path: path,
          query: query || {}
        })
      }

      return {
        databa,
        ab,
        goTo,
        top
      }
    },

    mounted() {
      this.getdata();
      this.list();
      window.scrollTo( 0, 0 );
    },

    methods: {
      grxxff(grxxs){
        this.grxx=grxxs;
        console.log(grxxs);
        console.log('zhende');
      },
        getdata(){
        var that = this;
        var data = {
        };
        getfinvestment(data).then(res => {
          console.log(res);
          console.log('zs');
          that.four=res.data.data.four;
          that.five=res.data.data.five;
          that.sex=res.data.data.sex;
          that.event=res.data.data.event;
          that.eight=res.data.data.eight;
          that.nine=res.data.data.nine;
          that.ten=res.data.data.ten;


        }).catch(err => {})

      },

      listcount(page){
        this.page=page;
        this.list(0,0,0,0,0,0,0,1);
      },

      list(four,five,sex,event,eight,nine,ten,pd=0){
        var that = this;

        if(pd==0){
           this.fourcs=four;
           this.fivecs=five;
           this.sexcs=sex;

           this.eventcs=event;
           this.eightcs=eight;
           this.ninecs=nine;
           this.tencs=ten;
        }


        var data = {
          four:this.fourcs,
          five:this.fivecs,
          sex:this.sexcs,

          event:this.eventcs,
          eight:this.eightcs,
          nine:this.ninecs,
          ten:this.tencs,
          sl:5,
          page:this.page
        };
        getfinvestmentin(data).then(res => {
        console.log(res);
        console.log('1');
        that.finvestmentin=res.data.data.table.data;

        that.total=res.data.data.table.total;
        that.per_page=res.data.data.table.per_page;
        that.current_page=res.data.data.table.current_page;
        that.last_page=res.data.data.table.last_page;
        that.items=res.data.data.items;
        that.litems=res.data.data.litems;





        }).catch(err => {})

      },
    },
    components: {
      Head,
      Foot,
      Left,
      Bread,
      Screen,
      List,
      Page,
      Banner
    },
  }
</script>
<style lang="scss" scoped="scoped">
  // nth-child
  .a-cen {
    width: 100vw;
    background: #F5F6FA;
    padding: 1rem 0;

    .center {
      width: var(--allWidth);
      margin: 0 auto;
    }
  }

  .center {

  }



</style>
