import { request } from "./request.js";

export function getfzhuanjia(params){
    return request(
      {
          url:'/public/index/index/fzhuanjia',
          method:'POST',
          params:params
      }
    )
}


export function getfzhuanjialy(params){
    return request(
      {
          url:'/public/index/index/fzhuanjialy',
          method:'POST',
          params:params
      }
    )
}

export function getfjigou(params){
    return request(
      {
          url:'/public/index/index/fjigou',
          method:'POST',
          params:params
      }
    )
}
