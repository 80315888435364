<template>
  <div class="all">
    <Head :top="top" ref="head" :grxxff="grxxff"></Head>

    <div class="i-center">
      <div class="center">
        <div class="c-top">
          <div class="c-left">
            <el-carousel indicator-position="outside">
              <el-carousel-item v-for="item in banner" :key="item">
                <div class="zj" @click="tiaozhuan(item.url)">
                  <img :src="'http://ymedicine.jiujingwulian.com/public'+item.bimage" />
                  <div class="dibu">{{item.title}}</div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>

          <div class="c-right" >
            <div class="c-r-t" @click="goTo('/newin',onenew.id)">{{onenew.name}}</div>
            <div class="c-r-nr twohang" @click="goTo('/newin',onenew.id)">{{onenew.title}}</div>
            <div class="c-r-sj" @click="goTo('/newin',onenew.id)">{{onenew.time}}</div>
            <div class="xians"></div>
            <div class="lb">
              <div class="on" v-for="(item,key) in topnew" :key="item" @click="goTo('/newin',item.id)" v-show="key>0">
                <img src="~assets/home/yjt.png" />
                <span>{{item.name}}</span>
              </div>
              <!-- <div class="on">
                <img src="~assets/home/yjt.png" />
                <span>内容内容内容内容内容内容内容内容内容内容内容内容内容</span>
              </div>
              <div class="on">
                <img src="~assets/home/yjt.png" />
                <span>内容内容内容内容内容内容内容内容内容内容内容内容内容</span>
              </div>
              <div class="on">
                <img src="~assets/home/yjt.png" />
                <span>内容内容内容内容内容内容内容内容内容内容内容内容内容</span>
              </div>
              <div class="on">
                <img src="~assets/home/yjt.png" />
                <span>内容内容内容内容内容内容内容内容内容内容内容内容内容</span>
              </div> -->
            </div>
            <div class="gd right" style="border-radius:1.9375rem;" @click="goTo('/new','')">
              <span>更多</span>
              <img src="~assets/home/jia.png" />
            </div>
          </div>
        </div>



      </div>
    </div>

    <div class="i-center1">
      <div class="center">
        <div class="zjr">
          <div class="c-top">
           <!-- {{qh}}
            {{newfl0.id}}
            {{newfl1.id}} -->
            <div class="c-t-one " @click="qhli(newfl0.id)" :class="qh==newfl0.id?'bs1':''" >
              <img v-show="qh==newfl0.id" src="~assets/home/xw.png" ><img v-show="qh!=newfl0.id" src="~assets/home/xw1.png" ><span>{{newfl0.name}}</span>
              <div class="hang" v-show="qh==newfl0.id"></div>
            </div>
            <div class="shu">|</div>
            <div class="c-t-one" @click="qhli(newfl1.id)" :class="qh==newfl1.id?'bs1':''">
              <img v-show="qh==newfl1.id" src="~assets/home/hye.png"><img v-show="qh!=newfl1.id" src="~assets/home/hye1.png"><span>{{newfl1.name}}</span>
              <div class="hang" v-show="qh==newfl1.id"></div>
            </div>
            <div class="dixian"></div>
            <div class="gd" @click="goTo('/new','')">查看更多
            <img class="gd-one" src="~assets/home/gd.png">
            <img class="gd-two" src="~assets/home/gd1.png" />
            </div>


          </div>
          <div class="c-cen">
            <div class="hy">
              <div class="h-one" v-for="item in newtwo"  @click="goTo('/newin',item.id)" >
                <img :src="'http://ymedicine.jiujingwulian.com/public'+item.fimage" />
                <div class="zw ">
                  <span class="onehang">
                  {{item.name}}
                  </span>
                </div>
              </div>
              <!-- <div class="h-one">
                <img src="../assets/home/1s.png" />
                <div class="zw">
                  源生医药公司
                </div>
              </div> -->
            </div>
          </div>

          <div class="c-bot">
            <div class="lb">
              <div class="on" v-for="item in newqt" @click="goTo('/newin',item.id)">
                <img src="~assets/home/yjt.png" />
                <span class="onehang wit">{{item.name}}</span>
                <div class="right">{{item.time}}</div>
              </div>
              <!-- <div class="on">
                <img src="~assets/home/yjt.png" />
                <span>内容内容内容内容内容内容内容内容内容内容内容内容内容</span>
                <div class="right">2022-09-10</div>
              </div>
              <div class="on">
                <img src="~assets/home/yjt.png" />
                <span>内容内容内容内容内容内容内容内容内容内容内容内容内容</span>
                <div class="right">2022-09-10</div>
              </div>
              <div class="on">
                <img src="~assets/home/yjt.png" />
                <span>内容内容内容内容内容内容内容内容内容内容内容内容内容</span>
                <div class="right">2022-09-10</div>
              </div>
              <div class="on">
                <img src="~assets/home/yjt.png" />
                <span>内容内容内容内容内容内容内容内容内容内容内容内容内容</span>
                <div class="right">2022-09-10</div>
              </div>
              <div class="on">
                <img src="~assets/home/yjt.png" />
                <span>内容内容内容内容内容内容内容内容内容内容内容内容内容</span>
                <div class="right">2022-09-10</div>
              </div>
              <div class="on">
                <img src="~assets/home/yjt.png" />
                <span>内容内容内容内容内容内容内容内容内容内容内容内容内容</span>
                <div class="right">2022-09-10</div>
              </div> -->
            </div>
          </div>

        </div>

        <div class="zjr1">
          <div class="c-top">
            <span>便捷服务</span><img src="~assets/home/fk.png">
          </div>
          <div class="zj">
            <div class="z-one" @click="goTo1('/login','2')">
              <img src="../assets/home/1.png" />
              <div class="z-zw">会员申请</div>
            </div>
            <div class="z-one" @click="goTo('/cloud','')" >
              <img src="../assets/home/2.png" />
              <div class="z-zw">风云榜</div>
            </div>
            <div class="z-one" @click="goTo('/cooperation','')" >
              <img src="../assets/home/3.png" />
              <div class="z-zw">技术库</div>
            </div>
            <div class="z-one" @click="goTo('/download','')">
              <img src="../assets/home/4.png" />
              <div class="z-zw">下载中心</div>
            </div>
          </div>
          <div class="c-top">
            <span>用户登录</span><img src="~assets/home/fk.png">
          </div>
          <div class="mima" v-show="grxx.name==''">
            <div class="m-one">

              <div class="m-o-left " @click="panbie=0;ls=2" :class="panbie==0?'ts':''">
                <!-- ts -->
                账号密码登录
                <div class="m-shu top " :class="panbie==0?'':'yc'" ></div>
              </div>
              <div class="m-o-left " @click="panbie=1;ls=2" :class="panbie==1?'ts':''">
                手机验证码登录
                <div class="m-shu top " :class="panbie==1?'':'yc'"></div>
              </div>
              <div class="m-o-left " @click="saoma()" :class="panbie==2?'ts':''">
                微信扫码登录
                <div class="m-shu top " :class="panbie==2?'':'yc'"></div>
              </div>
            </div>
            <div class="m-o-right" v-show="panbie==0">
              <div class="kuang">
                <input placeholder="账号/手机号" v-model="account" />
              </div>
              <div class="kuang">
                <input placeholder="请输入密码" v-model="password" />
              </div>
              <div class="denglu" @click="denglu()">立即登录</div>
              <div class="right">
                <div class="r-cs" @click="goTo1('/login','2')" >免费注册</div>
                <div class="r-shu">|</div>
                <div class="r-cs" @click="goTo1('/login','1')">找回密码</div>
              </div>
            </div>
            <div class="m-o-right" v-show="panbie==1">
              <div class="kuang">
                <input placeholder="账号/手机号" v-model="dlphone" />
              </div>
              <div class="kuang">
                <input placeholder="请输入验证码" v-model="yzm" />
                <el-button class="yzm" v-show="yzm2show" @click="qddsq(2)" type="primary">获取</el-button>
                <el-button class="yzm" v-show="!yzm2show" type="primary">{{yzm2}}秒</el-button>

              </div>
              <div class="denglu" @click="yzmdenglu()">立即登录</div>
              <div class="right">
                <div class="r-cs" @click="goTo1('/login','2')">免费注册</div>
                <div class="r-shu">|</div>
                <div class="r-cs" @click="goTo1('/login','1')">找回密码</div>
              </div>
            </div>

            <div class="m-o-right"  id="weixin" :class="panbie==2?'wd':''" v-show="ls==0">
              <!-- <img class="ewms" src="~assets/my/ewm1s.png" /> -->
            </div>




          </div>

          <div class="mima" v-show="grxx.name!=''">

            <div class="m-all">
              <!-- <img src="../assets/login/touxiang.png"/> -->
              <img  v-show="grxx.openid" :src="grxx.headimage" />
              <img  v-show="!grxx.openid" :src="'http://ymedicine.jiujingwulian.com/public'+grxx.headimage" />
              <div class="m-name">{{grxx.name}}</div>
              <div class="dibu">
                <div class="d-one" @click="goTo('/myMeans','')"><img src="~assets/login/xg.png" /><span>完善信息</span></div>
                <div class="d-one pt">普通会员</div>
                <div class="d-one sqrh" @click="goTo('/myRuhui','')">申请入会</div>
              </div>
            </div>

          </div>


        </div>
      </div>
    </div>



    <div class="i-center1 bs">
      <div class="center">
        <div class="zjr">
          <div class="c-top">

            <div class="c-t-one " @click="qhliltfe(fel0.id)" :class="feqh==fel0.id?'bs':''" >
              <img v-show="feqh==fel0.id" src="~assets/home/xw.png">
              <img v-show="feqh!=fel0.id" src="~assets/home/xw1.png">
              <span>{{fel0.name}}</span>
               <div class="hang" v-show="feqh==fel0.id"></div>
            </div>
            <div class="shu">|</div>
            <div class="c-t-one" @click="qhliltfe(fel1.id)" :class="feqh==fel1.id?'bs':''" >
              <img v-show="feqh==fel1.id" src="~assets/home/hye.png">
              <img v-show="feqh!=fel1.id" src="~assets/home/hye1.png">
              <span>{{fel1.name}}</span>
               <div class="hang" v-show="feqh==fel1.id"></div>
            </div>
            <div class="dixian"></div>
            <div class="gd" @click="goTo('/meeting','')">查看更多
            <img class="gd-one" src="~assets/home/gd.png">
            <img class="gd-two" src="~assets/home/gd1.png">
            </div>


          </div>
          <div class="c-cen">
            <div class="hy"  @click="goTo('/meetingDe',fttwo.id)">
              <div class="h-one">
                <img :src="'http://ymedicine.jiujingwulian.com/public'+fttwo.fimage"  />
              </div>
              <div class="h-one">
                <div class="h-title">{{fttwo.name}}</div>
                <div class="h-con twohang">{{fttwo.jianjie}}</div>

                <div class="h-con1">展会时间：{{fttwo.ktime}}-{{fttwo.stime}}</div>
                <div class="h-con1">展会城市：{{fttwo.cityname}}</div>
                <div class="anns">
                <!--  <img class="bsa" src="~assets/home/zuo.png" />
                  <img src="~assets/home/you.png" /> -->
                </div>
              </div>
            </div>
          </div>

          <div class="c-bot">
            <div class="lb">
              <div class="on" v-for="item in ftqt" @click="goTo('/meetingDe',item.id)" >
                <img src="~assets/home/yjt.png" />
                <span class="twohang ">{{item.name}}</span>
                <div class="right">{{item.ktime}}</div>
              </div>
              <!-- <div class="on">
                <img src="~assets/home/yjt.png" />
                <span>内容内容内容内容内容内容内容内容内容内容内容内容内容</span>
                <div class="right">2022-09-10</div>
              </div>
              <div class="on">
                <img src="~assets/home/yjt.png" />
                <span>内容内容内容内容内容内容内容内容内容内容内容内容内容</span>
                <div class="right">2022-09-10</div>
              </div>
              <div class="on">
                <img src="~assets/home/yjt.png" />
                <span>内容内容内容内容内容内容内容内容内容内容内容内容内容</span>
                <div class="right">2022-09-10</div>
              </div>
              <div class="on">
                <img src="~assets/home/yjt.png" />
                <span>内容内容内容内容内容内容内容内容内容内容内容内容内容</span>
                <div class="right">2022-09-10</div>
              </div>
              <div class="on">
                <img src="~assets/home/yjt.png" />
                <span>内容内容内容内容内容内容内容内容内容内容内容内容内容</span>
                <div class="right">2022-09-10</div>
              </div> -->

            </div>
          </div>

        </div>

        <div class="zjr1">
          <div class="c-top">
            <span>通知公告</span><img src="~assets/home/fk.png">
          </div>
          <div class="zj">
            <div class="z-one1" v-for="item in ggnew" @click="goTo('/newin',item.id)">
              <div class="z-o-left">
                <span class="h1">{{item.sjzs}}</span>
                <span class="xx">{{item.sj}}</span>
              </div>
              <div class="z-o-right">
                <div class="z-o-r twohang">{{item.name}}</div>
                <div class="z-o-ti">{{item.time}}</div>
              </div>
            </div>

           <!-- <div class="z-one1">
              <div class="z-o-left">
                <span class="h1">13</span>
                <span class="xx">2022-19</span>
              </div>
              <div class="z-o-right">
                <div class="z-o-r">新闻标题新闻标题新闻标题新闻标题新闻新闻标题新闻标题新闻...</div>
                <div class="z-o-ti">2022-09-07</div>
              </div>
            </div>
            <div class="z-one1">
              <div class="z-o-left">
                <span class="h1">13</span>
                <span class="xx">2022-19</span>
              </div>
              <div class="z-o-right">
                <div class="z-o-r">新闻标题新闻标题新闻标题新闻标题新闻新闻标题新闻标题新闻...</div>
                <div class="z-o-ti"><img src="~assets/home/sj.png" />2022-09-07</div>
              </div>
            </div>

            <div class="z-one1">
              <div class="z-o-left">
                <span class="h1">13</span>
                <span class="xx">2022-19</span>
              </div>
              <div class="z-o-right">
                <div class="z-o-r">新闻标题新闻标题新闻标题新闻标题新闻新闻标题新闻标题新闻...</div>
                <div class="z-o-ti">2022-09-07</div>
              </div>
            </div>

            <div class="z-one1">
              <div class="z-o-left">
                <span class="h1">13</span>
                <span class="xx">2022-19</span>
              </div>
              <div class="z-o-right">
                <div class="z-o-r">新闻标题新闻标题新闻标题新闻标题新闻新闻标题新闻标题新闻...</div>
                <div class="z-o-ti">2022-09-07</div>
              </div>
            </div> -->



          </div>

        </div>
      </div>
    </div>



    <foot></foot>
    <left></left>

  </div>



</template>

<script>
  import {
    send,
    check
  } from 'network/send.js';
  import {
    ElMessage,
    ElMessageBox
  } from 'element-plus';
  import {
    Action
  } from 'element-plus';

  import {
    getlogin
  } from 'network/login.js';

  import {
    setCookie,
    getCookie,
    clearCookie
  } from "components/common/common.js";
  import {
    ref,
    onMounted,

    computed
  } from 'vue';
  import {
    getshouquan,
    getHome,
    getbanner,
    getnew,
    getgrxx,
    getfmeeting
  } from 'network/home.js';
  import {
    useRouter
  } from 'vue-router';


  import Head from "components/content/headder/Head.vue";
  import Foot from "components/content/footer/Foot.vue";
  import Left from "components/content/left/Left.vue";

  export default {
    name: 'HomeView',
    data() {
      return {
        ls:0,
        yzm2show: true,
        yzm2: 60,

        panbie:0,
        account:'',
        password:'',

        grxx:[],
        qh:0,
        bn:'public/img/banner.387b0333.png',
        topnew:[],
        newfl0:[],
        newfl1:[],
        onenew:{},
        ggnew:[],
        banner: [
        ],
        feqh:0,
        fel0:[],
        fel1:[],
        fttwo:{},
        ftqt:[],
        curl:'',
        fimage:'',
        dlphone:'',
        yzm:'',
      }
    },
    setup() {
      const top = 1;
      const router = useRouter();
      const databa = ref([]);
      const banner = ref([]);
      const ab = 'sb';

      const goods=ref({
        new:{page:0,list:[]},
        huiyi:{page:0,list:[]},
        banner:{list:[]}
      })
      const currentType=ref('banner');
      const bannerList=computed(()=>{
        console.log('2');
        return goods[currentType].list;
      })
      // console.log('1');
      // console.log(bannerList);
      // console.log(goods);
      onMounted(() => {
        // var cs={i:0};
        // getbanner(cs).then(res => {
        //   goods.banner.list=res.data;
        // }).catch(err => {

        // })
        // getHome().then(res => {
        //   databa.value = res.data;
        // }).catch(err => {
        // })

      })
      //跳转方法
      const goTo = (path, cs) => {
       var query={id:cs}

       router.push({
         path: path,
         query: query
       })
      }

      const goTo1=(path,zt)=>{
        var query={zt:zt}
        router.push({
          path:path,
          query:query || {}
        })
      }



      return {
        goods,
        banner,
        databa,
        ab,
        goTo,
        goTo1,
        top
      }
    },
    mounted(){


      // code=0810hbGa1fQQKE0U73Ia17ffgY00hbGe&state=undefined


      // var url = decodeURI(window.location.search); //?id="123456"&name="www";

      // console.log(url);
      // var object = {};
      // if (url.indexOf("?") != -1) //url中存在问号，也就说有参数。
      // {
      //   var str = url.substr(1); //得到?后面的字符串
      //   var strs = str.split("&"); //将得到的参数分隔成数组[id="123456",name="www"];
      //   for (var i = 0; i < strs.length; i++) {
      //     object[strs[i].split("=")[0]] = strs[i].split("=")[1]; //得到{id:'123456',name:'www'}
      //   }
      // }
      // console.log(object);
      // var code= object['code'];
      // var state = object['state'];

      var code=  this.GetUrlParam("code");
      var state=  this.GetUrlParam("state");
      if(code){
        this.nickname(code);
      }




      var cs={i:0};
      var that=this;
      getbanner(cs).then(res => {
        that.banner=res.data;

      }).catch(err => {

      })

      var data={type:1};
      // [];
      // data['type']=1;
      getnew(data).then(res => {
        that.topnew = res.data.data.table.data;
        console.log('0');
        console.log(that.topnew[0]);
        var li=[];
        if(!that.topnew[0]){
          li['name']='暂无';
          li['title']='暂未设置首页新闻';
          li['time']='----';
        }else{
          li=that.topnew[0];
        }

        that.onenew=li;
        // console.log(that.onenew);
      }).catch(err => {
      })



      this.qhlist('');
      this.gglist();

      this.qhlistfe();
      window.scrollTo( 0, 0 );
    },
    methods:{
      GetUrlParam(paraName) {
      var url = window.location.toString();
      var arrObj = url.split("?");
      if (arrObj.length > 1) {
        var arrPara = arrObj[1].split("&");
        var arr;
        for (var i = 0; i < arrPara.length; i++) {
          arr = arrPara[i].split("=");
          if (arr != null && arr[0] == paraName) {
            return arr[1];
          }
        }
        return "";
      } else {
        return "";
      }
    },
      saoma() {
        // panbie=2;ls=0
         this.panbie = 2;
         this.ls=0;
         console.log('11');
         // // 微信登录第一步：申请微信登录二维码
         let _this = this;
         let obj = new WxLogin({
            href:'https://ymedicine.jiujingwulian.com/public/css/cs1.css',
            self_redirect: false,
            id: 'weixin',
            appid: "wxdf42d3604b518081",
            scope: 'snsapi_login',
            redirect_uri: 'http://cbiita.com/', // 这里跳的是扫码成功后,账户操作的地址
          })
          // this.isShowForm=false;
          console.log(obj);


      },

      nickname(code){

        var that=this;
        var data = {
          code: code,
        };
        getshouquan(data).then(res => {
          console.log(res);
          // res.data.id=8;
          console.log('登录成功sadsadasd'+res.data.id);
          sessionStorage.setItem("uid",res.data.id);
          setCookie(res.data.id);


          

          that.$refs.head.getgrxx2();

        
          that.grxxff(res.data);
          this.$router.push('/')


        }).catch(err => {})






      },

      gbdsq() {
        clearInterval(this.timeInter); //关闭
        this.yzm2show = true;
        this.yzm2 = 60;

      },
      kqsj2() {
        this.yzm2 = this.yzm2 - 1;
        if (this.yzm2 <= 0) {
          this.gbdsq();

        }
      },
      qddsq(type) {
        this.gbdsq();


        if (!this.dlphone) {
          ElMessage({
            type: 'info',
            message: '请输入手机号',
          })
          return false;
        }
        const reg = /^[1][1,2,3,4,5,6,7,8,9][0-9]{9}$/;
        if ((!reg.test(this.dlphone)) && this.dlphone != '') {
          ElMessage({
            type: 'info',
            message: '请输入正确的手机号',
          })
          return false;
        }


        this.yzm2show = false;
        this.fasong(this.dlphone);

        this.timeInter = setInterval(() => {
          this.kqsj2();
        }, 1000)


      },
      fasong(phone) {

        var data = {
          mobile: phone,
        };
        send(data).then(res => {
          console.log(res);
        }).catch(err => {})

      },


      denglu(){
         // console.log(this.$refs.head);
         // this.$refs.Head.getgrxx2();

          var that = this;
          var lx=this.lx;
          console.log(lx);
          // if(lx==0){
            if(!this.account){
              // alert('请输入账号！');
              ElMessage({
                type: 'info',
                message: '请输入账号！',
              })

              return false;
            }
            if(!this.password){
              // alert('请输入密码！');
              ElMessage({
                type: 'info',
                message: '请输入密码！',
              })

              return false;
            }
          // }
          var data = {
            account:this.account,
            password:this.password
          };

          const router=useRouter();

          getlogin(data).then(res => {
            console.log(res);
            if(!res.data.data){
             ElMessage({
               type: 'info',
               message: '账号或者密码错误',
             })
             return false;
            }else{


              console.log('登录成功');
              sessionStorage.setItem("uid",res.data.data.id);
              console.log('登录成功-1');
              // if(that.checked1==true){
              //   setCookie(res.data.data.id,7);
              // }else{
                setCookie(res.data.data.id);
                // console.log(this.Head);
                that.$refs.head.getgrxx2();
                that.grxxff(res.data.data);

              // }
                  // console.log('登录成功0');
                // that.$router.push('/home');
                console.log('登录成功1');

              // router.push({
              //   path:'/',
              //   query:{}
              // })
              // console.log('登录成功1s');
            }
          }).catch(err => {})


      },

      yzmdenglu(){

        if (!this.dlphone) {
          // alert('请输入账号！');
          ElMessage({
            type: 'info',
            message: '请输入手机号',
          })
          return false;
        }
        if (!this.yzm) {
          // alert('请输入密码！');
          ElMessage({
            type: 'info',
            message: '请输入验证码',
          })
          return false;
        }
        var data = {
          phone: this.dlphone,
          dlyzm: this.yzm,
          type: 1
        };


        var data1s = {
          mobile: this.dlphone,
          captcha: this.yzm,
        };

        const router = useRouter();

        check(data1s).then(res1 => {
          console.log(res1);
          if (res1.data.msg == '验证码不正确') {
            ElMessage({
              type: 'info',
              message: '验证码不正确',
            })
            return false;
          } else {

            getlogin(data).then(res => {
              console.log(res);
              if (!res.data.data) {
                // alert('用户名或密码错误！');
                ElMessage({
                  type: 'info',
                  message: '手机号或者验证码错误',
                })
                return false;
              } else {
                console.log('登录成功');
                sessionStorage.setItem("uid", res.data.data.id);

                // if(that.checked1==true){
                //   setCookie(res.data.data.id,7);
                // }else{
                  setCookie(res.data.data.id);
                // }

                // this.$router.push('/')
                that.$refs.head.getgrxx2();
                that.grxxff(res.data.data);

                // router.push({
                //   path:'/',
                //   query:{}
                // })
                // console.log('登录成功1s');
              }
            }).catch(err => {})


          }
        }).catch(err => {})


      },

      grxxff(grxxs){
        this.grxx=grxxs;
        console.log(grxxs);
        console.log('zhende');
      },
     tiaozhuan(url){
       console.log(url);
       window.location.href=url;
     },
      bfsp(curl,fimage){
        this.centerDialogVisible=true;
        this.curl=curl;
        this.fimage=fimage;
      },
      qhli(tt){
        console.log('1');
        this.qhlist(tt);
      },

      qhlist(fnewfl_id){
        var that=this;
        var data={type:0,fnewfl_id:fnewfl_id};
        console.log(data);
        getnew(data).then(res => {
          that.newfl0=res.data.list[0];
          that.newfl1=res.data.list[1];

          if(fnewfl_id){
            that.qh=fnewfl_id;
          }else{
            that.qh=that.newfl0.id;
          }
          var newtwo=[];
          var newqt=[];
          var ddd=res.data.data.table.data;
          console.log(ddd);
          console.log('111');
          var j=0;
          var i=0;
          for(i=0;i<ddd.length;i++){

            // console.log(ddd[i]);
            console.log(i);

            if(i<=1){
              newtwo[i]=ddd[i];
            }else{
              newqt[j]=ddd[i];
              j++;
            }
          }
          console.log(newtwo);

          that.newtwo=newtwo;
          that.newqt=newqt;

        }).catch(err => {
        })

      },

      gglist(){
        var data={type:2,page:0,sl:4};
        var that=this;
        getnew(data).then(res => {
          that.ggnew = res.data.data.table.data;
          console.log(that.ggnew);
          console.log('fanyi');


        }).catch(err => {
        })
      },



      qhliltfe(tt){
        console.log('2');
        this.qhlistfe(tt);
      },

      qhlistfe(fnewfl_id){

        var that=this;
        var data={type:0,fmeetingfl_id:fnewfl_id,page:0,sl:5};
        console.log(data);
        getfmeeting(data).then(res => {
          that.fel0=res.data.list[0];
          that.fel1=res.data.list[1];

          if(fnewfl_id){
            that.feqh=fnewfl_id;
          }else{
            that.feqh=that.fel0.id;
          }



          var ddd= res.data.data.table.data;
          var newtwo={};
          var newqt=[];
          var j=0;
          var i=0;
          for(i=0;i<ddd.length;i++){

            // console.log(ddd[i]);
            console.log(i);

            if(i<=0){
              newtwo=ddd[i];
            }else{
              newqt[j]=ddd[i];
              j++;
            }
          }


          that.fttwo=newtwo;
          console.log('会议');
          console.log(that.fttwo);
          that.ftqt=newqt;

        }).catch(err => {
        })

      },


    },
    components: {
      Head,
      Foot,
      Left
    },
  }
</script>



<style lang="scss" >
  .i-center {
    .center {
      width: var(--allWidth);
      margin: 0 auto;

      .c-top {
        margin-top: 1.875rem;
        display: flex;
        margin-bottom: 1.625rem;

        .c-left {
          min-height: 27.4375rem;
          width: 68%;

          .zj {
            cursor: pointer;
            width: 100%;
            position: relative;

            img {
              width: 100%;
            }

            .dibu {
              background: rgba(0, 0, 0, 0.63);
              color: #fff;
              position: absolute;
              bottom: 0.0625rem;
              padding: 0.875rem;
              width: 100%;
              font-size: 1rem;
            }
          }

          .el-carousel {
            height: 27.4375rem;
            position: relative;

            .el-carousel__container {
              height: 27.4375rem;
            }

            .el-carousel__indicators {
              width: auto;
              position: absolute;
              right: -6.75rem;
              bottom: 0.5rem;
            }
          }
        }

        .c-right {
          width: 32%;
          background: #F6FBFE;
          min-height: 10vh;
          padding: 1.875rem 1.875rem 0.6rem 1.875rem;

          .c-r-t {
            font-size: 1.375rem;
            color: #318FC6;
            cursor: pointer;
                line-height: 1.9rem;
          }

          .c-r-nr {
            font-size: 0.8125rem;
            width: 98%;
            margin: 0 auto;
            margin-top: 0.725rem;
            color: #6B6B6B;
            line-height: 0.975rem;
            cursor: pointer;
                line-height: 1.2rem;
          }

          .c-r-sj {
            font-size: 0.8125rem;
            color: #318FC6;
            margin: 1.25rem 0;
            cursor: pointer;
          }

          .xians {
            width: 100%;
            height: 0.125rem;
            background: #E6E6E6;
          }

          .lb {
            margin-top: 1.25rem;
            font-size: 0.8125rem;

            .on {
              display: flex;
              align-items: center;
              margin-bottom: 1rem;
              span{
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
              }
              img {
                width: 0.6rem;
                margin-right: 0.225rem;
              }
            }

          }

          .gd {
            background: #318FC6;
            display: flex;
            align-items: center;
            color: #fff;
            margin-top: 1.25rem;
            padding: 0.625rem 1.25rem;
            font-size: 0.925rem;
            cursor: pointer;
            img {
              width: 1rem;
            }
          }
        }

      }
    }
  }

  .on:hover {
    color: #318FC6;
    cursor: pointer;
    .right{
      color: #318FC6!important;
    }
  }

  .i-center1 {
    background: #F5F8FA;
    padding: 1.25rem 0;

    .center {
      width: var(--allWidth);
      margin: 0 auto;
      display: flex;

      .zjr {
        width: 66%;

        .c-top {
          width: 100%;
          display: flex;
          align-items: center;
          position: relative;

          .dixian {
            position: absolute;
            bottom: -0.825rem;
            left: 0%;
            width: 100%;
            height: 0.125rem;
            background: #EFEFEF;
            z-index: 9;
          }

          .gd {
            position: absolute;
            top: 50%;
            right: 0%;
            z-index: 9;
            transform: translate(0, -50%);
            display: flex;
            align-items: center;
            font-size: 0.75rem;
            cursor: pointer;
            img {
              width: 0.75rem;
              margin-left: 0.325rem;
            }
          }

          .shu {
            margin: 0 1.275rem;
          }

          .c-t-one {
            display: flex;
            align-items: center;
            font-size: 0.975rem;
            position: relative;
            cursor: pointer;
            .hang {
              width: 100%;
              height: 0.125rem;
              background: #318FC6;
              position: absolute;
              bottom: -0.825rem;
              left: 50%;
              transform: translate(-50%, 0%);
              z-index: 9;
            }

            img {
              width: 1.1rem;
              margin-right: 0.375rem;
            }

          }

          .bs {
            color: #318FC6;
          }
          .bs1 {
            color: #318FC6;
          }


        }

        .c-cen {
          width: 100%;
          display: flex;
          align-items: center;
          position: relative;

          .hy {
            margin-top: 1.875rem;
            width: 100%;
            font-size: 0;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            .h-one:hover{
              .zw{
                  background: #2E9BD6;
                  color: #fff;
                }
              }
            .h-one {
              width: 48%;
              cursor: pointer;
              img {
                width: 100%;
              }

              .zw {
                font-size: 0.9125rem;

                text-align: center;
                padding: 1.075rem 2rem;
                background: #F0F0F0;

              }



              .h-title{
                font-size: 1.375rem;
                color: #318FC6;
              }
              .h-con{
                color: #6B6B6B;
                font-size: 0.8125rem;
                margin-top: 2.15rem;
                margin-bottom:2.15rem;
              }
              .h-con1{
                color: #2E9BD6;
                margin-bottom: 0.8375rem;
                font-size: 0.8125rem;
              }
              .anns{
                margin-top: 1.825rem;
                .bsa{
                    margin-right: 1.675rem;
                }
                img{
                  width: 1.1rem;
                  cursor: pointer;
                }
              }
            }
          }

        }

        .c-bot {
          width: 100%;


          .lb {
            margin-top: 1.25rem;
            font-size: 0.8125rem;

            .on {
              display: flex;
              align-items: center;
              margin-bottom: 1.3rem;
              position: relative;

              .right {
                position: absolute;
                right: 0rem;
                top: 50%;
                transform: translate(0, -50%);
                color: #A1A1A1;
              }

              img {
                width: 0.6rem;
                margin-right: 0.225rem;
              }
            }

          }

        }
      }

      .zjr1 {
        width: 34%;
        padding: 0 1.875rem;

        // background: #9A9A9A;
        .c-top {
          display: flex;
          align-items: center;

          img {
            width: 1.125rem;
            margin-left: 0.4375rem;
          }
        }

        .zj {
          margin-top: 1.8125rem;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-evenly;
          margin-bottom: 0.75rem;

          .z-one {
            position: relative;
            width: 47%;
            margin-bottom: 0.325rem;
            cursor: pointer;
            img {
              width: 100%;
            }

            .z-zw {
              position: absolute;
              font-size: 1rem;
              color: #fff;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              font-size: 1.6rem;
              width: 100%;
              text-align: center;
            }
          }


          .z-one1:hover{


            .z-o-left{
               background: #2E9BD6!important;
               color: #fff!important;
               border: 0.0625rem solid #2E9BD6;

            }
            .z-o-right{
              color: #2E9BD6!important;
            }

          }
          .z-one1{
            width: 100%;
            margin-top: 0.625rem;
            padding: 0 0.8125rem;
            display: flex;
            align-items: center;
            cursor: pointer;


            .z-o-left{
              width: 23%;
              background: #fff;
              color: #8C8C8C;
              border: 0.0625rem solid #8C8C8C;
              padding: 0.625rem;
              text-align: center;
              .h1{
                display: block;
                font-size: 1.8rem;
                margin-bottom: 0.0625rem;
              }
              .xx{
                font-size: 0.8125rem;
              }
            }
            margin-bottom: 1.075rem;
            .z-o-right{
              width: 77%;
              margin-left: 0.825rem;
              font-size: 0.8125rem;
              .z-o-ti{
                display: flex;
                align-items: center;
                img{
                  width: 0.675rem;
                  margin-right: 0.325rem;
                }
              }
              .z-o-r{
                margin-bottom: 0.625rem;
                line-height: 1.3rem;
              }
            }
          }


        }

        .mima {
          background: #fff;
          margin-top: 0.6rem;
          display: flex;

          .m-one {
            // padding: 1.875rem 0.625rem;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            position: relative;
            width: 33%;



            .top {
              top: 0;
            }

            .cep {}

            .bto {
              bottom: 0;
            }
            // .m-o-left:hover{
            //   background: url(assets/home/jx.png) no-repeat;
            //   .yc{
            //     display: block!important;
            //   }
            // }
            // .bs{
            //   background: url(assets/home/jx.png) no-repeat;
            //   .yc{
            //     display: block!important;
            //   }
            // }
            .m-o-left {
              width: 100%;
              text-align: center;
              padding: 1.4rem 1.675rem;
              background-size: 100% 100%;
              cursor: pointer;
              position: relative;
              .m-shu {
                width: 0.325rem;
                height: 5.0rem;
                background: #2E9BD6;
                position: absolute;
                left: 0;

              }
              .yc{
                  display: none;
              }

            }

            .ts {
              background: url(assets/home/jx.png) no-repeat;
            }



          }


          .m-o-right {
            padding: 0.75rem;

            .kuang {
              margin-bottom: 0.75rem;

              input {
                padding: 1rem;
                border: 0;
                background: #F5F5F5;
                text-align: center;
              }
            }

            .denglu {
              background: #F49E01;
              color: #fff;
              padding: 1rem 0;
              text-align: center;
              font-size: 0.875rem;
              cursor: pointer;
            }

            .right {
              width: 100%;
              font-size: 0.475rem;
              display: flex;
              margin-top: 0.875rem;
              color: #A7A7A7;
              justify-content: center;
              .r-cs{
                cursor: pointer;
              }
              .r-shu {
                margin: 0 0.6375rem;
              }
            }

          }


          .m-all{
            background: #fff;
            width: 100%;
            min-height: 20vh;
            text-align: center;
            padding:1.875rem 0;
            img{
              width: 4.175rem;
              cursor: pointer;
              border-radius: 300%;
            }
            .m-name{
              cursor: pointer;
              font-size: 1.125rem;
              font-weight: bold;
              margin-top: 0.3875rem;
            }
            .dibu{
              display: flex;
              align-items: center;
              margin-top: 1.0625rem;
              justify-content: space-evenly;
              width: 80%;
              margin: 0 auto;
              margin-top: 1.0625rem;
              .d-one{
                // width: 33.333%;
                 text-align: center;
                 padding: 0.375rem 0.8rem;
                 border: 0.0625rem solid #747474;
                 font-size: 0.875rem;
                 border-radius: 2.375rem;

                 img{
                   width: 0.8rem;
                   vertical-align: middle;
                   margin-right: 0.125rem;
                 }
                 span{
                   vertical-align: middle;
                 }
                 cursor: pointer;
              }
              .pt{
                background: #2E9BD6;
                border: 0.0625rem solid #2E9BD6;
                color: #fff;

              }
              .sqrh{
                background: #AFC81D;
                border: 0.0625rem solid #AFC81D;
                color: #fff;
              }


            }

          }
        }
      }
    }
  }

  .bs{
    background: #fff;
  }


  @media screen and (max-width: 1300px) {
    .c-r-sj{
     margin: 0.25rem  0!important;
    }
    .h-title{
        font-size: 1rem!important;
    }
    .h-con,.h-con1,.xx{
      font-size: 0.6rem!important;
    }
    .right{
      margin-top: 0.25rem!important;
    }
    .center{
      width: 100%!important;
      flex-wrap: wrap;
      .c-top{
        flex-wrap: wrap;
        margin-top: 0rem!important;
        .c-left{
          width: 100%!important;
          min-height: 14.4375rem!important;
          .el-carousel{
            height: 14.4375rem!important;
            .el-carousel__container {
              height: 14.4375rem!important;
            }
          }
          .zj{
            height: 14.4375rem!important;
          }
        }
        .c-right{
          padding: 0.625rem!important;
          width: 100%!important;
          .gd{
            margin-top: 0.25rem;
            margin-bottom: 0.25rem;
          }
          .c-r-t{
            font-size: 1rem!important;
          }
        }
      }
      .zjr{
         width: 93% !important;
         margin: 0 auto;
         .c-top{
           justify-content: center;
           .shu{
               margin: 0 4.275rem!important;
           }
           .gd{
             display: none!important;
           }
         }
         .c-cen{
           .hy{
                 flex-wrap: wrap!important;
             .h-one{
                   width: 100%!important;
                   margin-bottom: 0.625rem;
               .h-con{
                 margin-top: 0.5rem!important;
                 margin-bottom:0.5rem!important;
               }
             }
           }
         }

      }
      .zjr1{
         width: 93% !important;
         margin: 0 auto;
        padding: 0!important;
      }
    }
    .lb{
     .on{
       .wit{
         width: 70%!important;
       }
     }
    }


  }
  .gd-two{
    display: none;
  }
  .gd:hover{
    color: #2E9BD6;
    .gd-one{
      display:none;
    }
    .gd-two{
      display: block;
    }
  }
  .d-one:hover{
    background:#26fef2!important;
    color: #FFFFFF!important;
    border: 0.0625remsolid #26fef2!important;
  }
  .ewms{
    width: 100%;
  }
  .kuang{
    position: relative;
      .yzm{
        position: absolute;
        right: 0;
        padding: 1.45rem 0.8rem;
      }

  }
  #weixin{


    // width: 13.6875rem;
    // width: 246px;
    //     height: 241px;
  }
  .wd{
     width: 60%;
     height: 15.125rem;
  }
</style>
