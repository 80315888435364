<template>
    <div class="left">
      <div class="one op" @click="fanhui()">
        <img src="../../../assets/home/fh.png" />
        <div class="">
          返回
        </div>
      </div>
      <div class="one">
        <img src="../../../assets/home/gzh.png" />
        <div class="">
          公众号
        </div>
       <div class="baidi ys">
          <img :src="'https://ymedicine.jiujingwulian.com/public'+shezhi.gimage" />
        </div>
      </div>
      <div class="one">
        <img src="../../../assets/home/lxwm.png" />
        <div class="">
          联系我们
        </div>
        <div class="baidi1 ys">
          <img src="../../../assets/home/lxr.png" />
          <div class="dhbt">联系电话</div>
          <div class="dhdh">{{shezhi.phone}}</div>
        </div>
      </div>
      <div class="one od" @click="fhdb()">
         <!-- <el-backtop :right="100" :bottom="100" /> -->
        <img src="../../../assets/home/fhtb.png" />
        <div class="">
          回顶部
        </div>
      </div>
    </div>
</template>

<script>

  import {useRouter} from 'vue-router';
  import {
    getfshezhi
  } from 'network/home.js';


  export default{
    name:'left',
    data() {
         return {
           shezhi:{},


         }
     },

    props: [],
    setup(){
      //跳转方法
      const router=useRouter();
      const goTo=(path,query)=>{
        console.log(query);
        query={name:'nn'}
        console.log(query);
        router.push({
          path:path,
          query:query || {}
        })
      }

      return {goTo}

    },
    mounted() {
    	this.getfl();
    },
    methods: {
      getfl(){
        var that = this;
        var data = {

        };
        getfshezhi(data).then(res => {
          // console.log('zd');
          // console.log(res);
          that.shezhi=res.data.data;
        }).catch(err => {})
      },
      fanhui(){
       window.history.go(-1);
      },
      fhdb(){
        window.scrollTo( 1000, 0 );
      }

    }
  }
</script>

<style lang="scss" scoped="scoped">
  .left{
    position: fixed;
    top: 50%;
    right: 0.625rem;
    z-index: 999999999999999999;
    transform: translate(0,-50%);
    box-shadow: -0.17rem 0.15rem 0.625rem rgb(0 0 0 / 26%);
    border-radius: 1.1875rem;
    // overflow: hidden;
    // background: #fff;

    .one:hover{
      .ys{
        display: inline-block!important;
      }
    }
    .one{
      background: #fff;
      cursor: pointer;
      text-align: center;
      padding: 1.05rem 0.8rem;
      font-size: 0.6125rem;
      // box-sizing: 0.0625rem 0.0625rem 0.125rem rgba(0,0,0,0.3);
      // box-shadow: -0.17rem 0.15rem 0.625rem rgba(0,0,0,0.26);
      // box-shadow: -0.37rem ​0.43rem 0.62rem rgba(0,0,0,0.26);
      // rgba(0,0,0,0.2);
      position: relative;
      img{
        width: 1.275rem;
        margin-bottom: 0.325rem;
      }
      .ys{
         display: none;
      }
      .baidi{
        // display: none;
        position: absolute;
        right: 103%;
        background: #fff;
        padding: 0.875rem;
        top: 0;

        box-shadow: -0.17rem 0.15rem 0.625rem rgb(0 0 0 / 26%);
        img{
          width: 6.75rem;
        }
      }
      .baidi1{
        width: 8.875rem;
        // display: none;
         position: absolute;
         right: 103%;
         background: #fff;
         padding: 0.775rem;
         top: 0;
         box-shadow: -0.17rem 0.15rem 0.625rem rgb(0 0 0 / 26%);
        img{
          width: 2.75rem;
        }
        .dhbt{
          margin-bottom: 0.625rem;
          font-size: .9rem;
        }
        .dhdh{
         color: #F9950E;
         font-size: 1.025rem;
         font-weight: bold;

        }
      }
    }
  }
  .od{
    // margin-top: 1.075rem;
    border-bottom-right-radius: 1.1875rem;
    border-bottom-left-radius: 1.1875rem;
  }
  .op{
    // margin-bottom: 1.075rem;
    border-top-right-radius: 1.1875rem;
    border-top-left-radius: 1.1875rem;
  }

  @media screen and (max-width: 1300px) {
    .left{
      display: none;
    }
  }

</style>
