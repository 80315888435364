<template>
  <div class="all">
    <Head :top="top" :grxxff="grxxff"></Head>
    <Banner :bn='bn'></Banner>
    <Bread :all="all"></Bread>
    <div class="a-cen">
      <div class="center">

        <div class="zong">
          <div class="zo-cen">
            <div class="zoc-right" >
              <div class="z-nrk">
                <div class="z-nrk-left"><img src="~assets/work/list.png" /></div>
                <div class="z-nrk-right">
                  <div class="z-n-r-title">《足迹》第10集：忆“大山”</div>
                  <div class="z-n-r-nr1 gby"><span>发布于  2022-09-01</span></div>
                  <div class="z-n-r-nr1"><span>11月3日，国家科技部中国生物技术发展中心发布“2021年中国生物医药园区发展竞争力排行榜”； “排行榜”分为“园区综合竞争力排名”及“园区单项竞争力排名”。 “单项竞争力排名”包含环境竞争力、产业竞争力、技术竞争力、人才竞争力及合作竞争力5项。
本次排行榜对全国211个生物医药产业园区进行竞争力排名。 </span></div>
                </div>
              </div>
              <div class="z-nrk-con">简介内容简介内容简介内容简介内容简介内容简介内容简介内容简介内容简介内容简介内容简介内容简介内容简介内容简介内容简介内容简介内容简介内容简介内容简介内容简介内容简介内容简介内容简介内容简介内容</div>
            </div>




          </div>
        </div>


      </div>
    </div>
    <foot></foot>
    <left></left>
  </div>
</template>

<script>
  import {
    ref,
    onMounted
  } from 'vue';
  import {
    getHome
  } from 'network/home.js';
  import {
    useRouter
  } from 'vue-router';


  import Head from "components/content/headder/Head.vue";
  import Foot from "components/content/footer/Foot.vue";
  import Left from "components/content/left/Left.vue";
  import Bread from "components/content/breadcrumb/Breadcrumb.vue";
  import Banner from "components/content/banner/Banner.vue";

  import Journalism from 'components/content/journalism/Journalism.vue'
  import Notice from "components/content/notice/Notice.vue";
  import List from "components/content/list/List.vue";

  export default {
    name: 'New',
    data() {
      return {
        v: 0,
        i: 1,
        bn: 'public/img/banner.387b0333.png',
        all: [{
            name: '您当前的位置:首页',
            url: '/'
          },

          {
            name: '党建工作',
            url: '/Work'
          },
          {
            name: '详情',
            url: '/New'
          },
        ]
      }
    },
    setup() {
      const top = 8;
      const router = useRouter();
      const databa = ref([]);
      const ab = 'sb';
      console.log(ab);
      onMounted(() => {

        getHome().then(res => {
          console.log(res.data.data);
          databa.value = res.data;
          console.log(databa);

        }).catch(err => {

        })

      })
      //跳转方法
      const goTo = (path, query) => {
        router.push({
          path: path,
          query: query || {}
        })
      }

      return {
        databa,
        ab,
        goTo,
        top
      }
    },
    methods: {
      grxxff(grxxs){
        this.grxx=grxxs;
        console.log(grxxs);
        console.log('zhende');
      },
      
      qh(it) {
        console.log(it);
        this.v = it;
      },
    },
    components: {
      Head,
      Foot,
      Left,
      Bread,
      Notice,
      Journalism,
      List,
      Banner
    },
  }
</script>
<style lang="scss" scoped="scoped">
  .a-cen {
    width: 100vw;
    background: #F5F6FA;
    padding: 1rem 0;

    .center {
      width: var(--allWidth);
      margin: 0 auto;

      .zo-cen {
        width: var(--allWidth);
        margin: 0 auto;
        display: flex;



        .zoc-right {
          width: 100%;
          padding: 1.875rem;
          background: #fff;
          // margin:0 1.875rem  1.875rem  1.875rem;
          position: relative;
          min-height: 50rem;

          //列表
          .z-bt {
            border-bottom: 0.0625rem solid #DBDBDB;
            padding-bottom: 1.875rem;
            font-weight: bold;
            font-size: 1.2rem;
            img{
              width: 1.075rem;
              margin-right: 0.625rem;
            }
          }
          .z-nrk-con{
            padding: 0.625rem;
          }
          .z-nrk {
            padding: 1rem;
            border-bottom: 0.0625rem solid #DBDBDB;
            display: flex;
           .z-nrk-left{
             margin-right: 2rem;
            img{
              width: 30.875rem;
            }
           }
           .z-nrk-right{
            .z-n-r-title{
              font-size: 1.225rem;
              padding-bottom: 1.25rem;
              // border-bottom: 0.35rem solid #2E9BD6;
              display: inline-block;
              margin-bottom: 0.625rem;
            }
            .gby{
              margin-bottom: 3.875rem;
            }
            .z-n-r-nr1{
              font-size: 0.9125rem;
              margin-top: 0.625rem;
              span{
                color: #888888;
              }
            }
           }

          }


          //列表结束


        }
      }


    }
  }

  .center {}
</style>
