<template>
  <div class="all">
    <Head :top="top" :grxxff="grxxff"></Head>
    <Banner :bn='bn'></Banner>
    <!-- <Bread :all="all"></Bread> -->
    <div class="a-cen">
      <div class="center">
        <div class="wo-cen-top">
          <div class="w-c-one bs">志愿服务</div>
          <div class="w-c-one">志愿服务</div>
          <div class="w-c-one">志愿服务</div>
          <div class="w-c-one">志愿服务</div>
        </div>
        <div class="wo-cen-cen">
          <div class="w-c-cleft">
            <img src="../../assets/work/left.png" />
          </div>
          <div class="w-c-cright">
            <div class="w-c-ctitle">中宣部印发《通知》 开展"强国复兴有我"群众性主题宣传教育活动</div>
            <div class="w-c-ccontent">
              新疆工作在党和国家工作全局中具有特殊重要的战略地位新疆工作在党和国家工作全局中具有特殊重要的战略地位新疆工作在党和国家工作全局中具有特殊重要的战略地位新疆工作在党和国家工作全局中具有特殊重要的战略地位新疆工作在党和国家工作全局中具有特殊重要的战略地位新疆工作在党和国家工作全局中具有特殊重要的战略地位新疆工作在党和国家工作全局中具有特殊重要的战略地位新疆工作在党和国家工作全局中具有特殊重要的战略地位新疆工作在党和国家工作全局中具有特殊重要的战略地位新疆工作在党和国家工作全局中具有特殊重要的战略地位新疆工作在党和国家工作全局中具有特殊重要的战略地位新疆工作在党和国家工作全局中具有特殊重要的战略地位新疆工作在党和国家工作全局中具有特殊重要的战略地位新疆工作在党和国家工作全局中具有特殊重要的战略地位新疆工作在党和国家工作全局中具有特殊重要的战略地位新疆工作在党和国家工作全局中具有特殊重要的战略地位新疆工作在党和国家工作全局中具有特殊重要的战略地位新疆工作在党和国家工作全局中具有特殊重要的战略地位新疆工作在党和国家工作全局中具有特殊重要的战略地位新疆工作在党和国家工作全局中具有特殊重要的战略地位新疆工作在党和国家工作全局中具有特殊重要的战略地位
            </div>
            <div class="xq">了解详情>></div>
          </div>
        </div>

        <div class="zjimg">
          <img src="~assets/work/banner1.png" />
        </div>
        <div class="z-bot">
          <div class="z-bot-one" @click="goTo('/workDe')">
            <img src="~assets/work/list.png" />
            <div class="xm">
              <div class="rq">
                <div class="n">2022-06</div>
                <div class="y">23</div>
              </div>
              <div class="zw">
                <div class="zw-t">《足迹》第10集：忆“”</div>
                <div class="zw-pg">9登门拜访的第一个人就是贾大山..</div>
              </div>
            </div>
          </div>

          <div class="z-bot-one">
            <img src="~assets/work/list.png" />
            <div class="xm">
              <div class="rq">
                <div class="n">2022-06</div>
                <div class="y">23</div>
              </div>
              <div class="zw">
                <div class="zw-t">《足迹》第10集：忆“”</div>
                <div class="zw-pg">9登门拜访的第一个人就是贾大山..</div>
              </div>
            </div>
          </div>

          <div class="z-bot-one">
            <img src="~assets/work/list.png" />
            <div class="xm">
              <div class="rq">
                <div class="n">2022-06</div>
                <div class="y">23</div>
              </div>
              <div class="zw">
                <div class="zw-t">《足迹》第10集：忆“”</div>
                <div class="zw-pg">9登门拜访的第一个人就是贾大山..</div>
              </div>
            </div>
          </div>


          <div class="z-bot-one">
            <img src="~assets/work/list.png" />
            <div class="xm">
              <div class="rq">
                <div class="n">2022-06</div>
                <div class="y">23</div>
              </div>
              <div class="zw">
                <div class="zw-t">《足迹》第10集：忆“”</div>
                <div class="zw-pg">9登门拜访的第一个人就是贾大山..</div>
              </div>
            </div>
          </div>

          <div class="z-bot-one">
            <img src="~assets/work/list.png" />
            <div class="xm">
              <div class="rq">
                <div class="n">2022-06</div>
                <div class="y">23</div>
              </div>
              <div class="zw">
                <div class="zw-t">《足迹》第10集：忆“”</div>
                <div class="zw-pg">9登门拜访的第一个人就是贾大山..</div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
    <foot></foot>
    <left></left>
  </div>
</template>

<script>

import {ref,onMounted} from 'vue';
import {getHome} from 'network/home.js';
import {useRouter} from 'vue-router';


import Head from "components/content/headder/Head.vue";
import Foot from "components/content/footer/Foot.vue";
import Left from "components/content/left/Left.vue";
import Bread from "components/content/breadcrumb/Breadcrumb.vue";
import Banner from "components/content/banner/Banner.vue";


export default {
  name: 'New',
  data() {
    return {
      bn:'public/img/banner.387b0333.png',
      all: [
        {
            name: '您当前的位置:首页',
            url: '/'
          },

          {
            name: '资讯',
            url: '/New'
          },
        ]
    }
  },
  setup(){
    const top=8;
    const router=useRouter();
    const databa=ref([]);
    const ab='sb';
    console.log(ab);
    onMounted(()=>{

      getHome().then(res=>{
        console.log(res.data.data);
        databa.value=res.data;
        console.log(databa);

      }).catch(err=>{

      })

    })
    //跳转方法
    const goTo=(path,query)=>{
      router.push({
        path:path,
        query:query || {}
      })
    }

    return { databa,ab,goTo,top }
  },
  methods: {
    grxxff(grxxs){
        this.grxx=grxxs;
        console.log(grxxs);
        console.log('zhende');
      },
  },
  components:{
    Head,
    Foot,
    Left,
    Bread,
    Banner
  },
}
</script>
<style lang="scss" scoped="scoped">
  // nth-child
  .a-cen{
    width: 100vw;
    background: #F5F6FA;
    padding: 1rem 0;
    .center{
      width: var(--allWidth);
      margin: 0 auto;
    }
  }
  .center{

    .wo-cen-top{
      background: #fff;
      padding: 1.175rem;
      display: flex;
      .w-c-one{
        padding:0.9rem 1.25rem;
        font-size: 0.925rem;
        border: 0.0625rem solid #E5E5E5;
      }
      .bs{
        color: #fff;
        background: #2E9BD6;
        border: 0.0625rem solid #2E9BD6;
      }
    }

    .wo-cen-cen{
       background: #fff;
       padding: 1.175rem;
       display: flex;
       .w-c-cleft{
          width: 50%;
          img{
            width: 100%;
          }
       }
       .w-c-cright{
          width: 48%;
          margin-left: 2%;
          .w-c-ctitle{
            font-size: 1.0625rem;
            font-weight: bold;
            margin-bottom: 1.25rem;
          }
          .w-c-ccontent{
            font-size: 0.9rem;
            color: #888888;
            padding: 0 0.625rem;
          }
          .xq{
            display: inline-block;
            padding: 0.625rem 1.25rem;
            color: #FFFFFF;
            background: rgb(46, 155, 214);
            border-radius: 2px;
          }
       }
    }
    .zjimg{
      width: var(--allWidth);
      margin: 1.25rem auto;
      img{
        width: 100%;
      }

    }

    .z-bot{
      display: flex;
      flex-wrap: wrap;
      .z-bot-one{

        width: 23%;
        margin-right: 2.599%;
        display: inline-block;
        border-radius: 18.95px;
        overflow: hidden;
        padding-bottom: 0.625rem;
        img{
          width: 100%;
        }
        margin-bottom: 0.625rem;

      }
      .z-bot-one:nth-child(4n){
         margin-right: 0%;
      }
      .xm{
        background: #fff;
        padding: 0.825rem;
        display: flex;
        align-items: center;
        .zw{
          width: 70%;
        }
        .rq{
          width: 22%;
          padding: 0.3125rem 00;
          background: #f4f4f4;
          border: 0.0625rem solid #E0E0E0;
          font-size: 0.55rem;
          text-align: center;
          margin-right: 3%;
          .n{
            color: #006CAA;
          }
          .y{
              font-size: 1.0625rem;
              color: #000000;
              font-weight: bold;
              margin-top: 0.625rem;
          }
        }
        .zw{
          .zw-t{
            font-size: 0.875rem;
            font-weight: bold;
          }
          .zw-pg{
            margin-top: 0.625rem;
            color: #6A6A6A;
            font-size: 0.75rem;
          }
        }
      }
    }
  }
</style>
