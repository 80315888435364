import { request } from "./request.js";

export function getfaboutshezhi(params){
    return request(
      {
          url:'/public/index/index/faboutshezhi',
          method:'POST',
          params:params
      }
    )
}


export function getfabout(params){
    return request(
      {
          url:'/public/index/index/fabout',
          method:'POST',
          params:params
      }
    )
}

