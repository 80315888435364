<template>
  <div class="top-control">
    <div class="h-top">

      <div class="t-top">
        <div class="t-center">
          <div class="t-span">{{shezhi.title}}</div>
          <div class="right" v-show="grxx.name==''">
            <span @click="goTo('/login','')">登录</span>|
            <span @click="goTo1('/login','2')">免费注册</span>|
            <span @click="goTo1('/login','1')">找回密码</span>|
          </div>
          <div class="right" v-show="grxx.name!=''">
            <span class="bbs" @click="goTo('/my','')">您好：</span>
            <span class="ys" @click="goTo('/my','')">{{grxx.name}}</span>
            <span @click="beacklogin()">退出登录</span>
            <span  @click="goTo1('/login','1')">找回密码</span>
          </div>

        </div>
      </div>

      <div class="t-cen">
        <!-- <img class="t-img1" @click="goTo1('/')" :src="~assets/home/logo0.png" /> -->
        <img class="t-img1" @click="goTo1('/')" :src="
                  'https://ymedicine.jiujingwulian.com/public' +
                  shezhi.logotop
                " />
        
        <img class="t-img2" @click="goTo1('/')" src="~assets/home/logo1.png" />
        <!-- <div  style="font-size: 12px;">{{sousu}}</div> -->
        <div class="sousuo">
          <input placeholder="请输入关键词" v-model="sousuo" />
          <div class="sanniu right" v-show="top!=3" @click="goTo('/new',sousuo,'2')"><img
              src="~assets/home/sousuo.png" />搜索</div>
          <div class="sanniu right" v-show="top==3" @click="qhs()"><img src="~assets/home/sousuo.png" />搜索</div>

        </div>
      </div>

    </div>




  </div>

  <div class="shouji">
    <div class="shou-top">
      <div class="shou-title">{{shezhi.title}}</div>
      <div class="opp" v-show="grxx.name==''"  @click="goTo('/login','')">登录</div>
      <div class="opp" v-show="grxx.name==''" @click="goTo1('/login','2')">免费注册</div>
      <div class="opp" v-show="grxx.name==''" @click="goTo1('/login','1')">找回密码</div>

      <span v-show="grxx.name!=''" class="bbs" @click="goTo('/my','')">您好：</span>
      <span v-show="grxx.name!=''" class="ys" @click="goTo('/my','')">{{grxx.name}}</span>
      <span v-show="grxx.name!=''" class="ys" @click="beacklogin()">退出登录</span>
      <span v-show="grxx.name!=''" class="bbs" @click="goTo1('/login','1')">找回密码</span>

    </div>
    <div class="xm">
      <img class="t-img1" src="~assets/home/logo0.png" />
      <img class="t-img2" src="~assets/home/logo1.png" />
      <div class="sousuo">
        <input placeholder="请输入关键词" v-model="sousuo" />
        <div class="sanniu right" v-show="top!=3" @click="goTo('/new',sousuo,'2')"><img
            src="~assets/home/sousuo.png" />搜索</div>
        <div class="sanniu right" v-show="top==3" @click="qhs()"><img src="~assets/home/sousuo.png" />搜索</div>
      </div>
      <img class="dwss" @click="sh=true" src="~assets/home/cbian.png" />
    </div>

    <div class="m-bj" v-show="sh" @click="sh=false"></div>
    <div class="m-left" v-show="sh" >
      <div class="m-list">
        <div class="m-one" @click="goTo('/','')"><span>首页</span></div>
        <div class="m-one">
          <div class="m-tit">
            <span @click="goTo('/about','')">关于联合体</span>
            <img v-show="o1" @click="o1=false" src="~assets/home/sjt.png" />
            <img v-show="!o1" @click="o1=true" src="~assets/home/sjtf.png" />
          </div>
        </div>
        <div class="m-xlist" v-show="!o1">
          <div v-show="top!=2" @click="goTo('/about','0','1')" class="m-x-one">联合体介绍</div>
          <div v-show="top!=2" @click="goTo('/about','1','1')" class="m-x-one">领导简介</div>
          <div v-show="top!=2" @click="goTo('/about','2','1')" class="m-x-one">组织架构</div>

          <div v-show="top==2" @click="qh(0)" class="m-x-one">领导简介</div>
          <div v-show="top==2" @click="qh(1)" class="m-x-one">联合体简介</div>
          <div v-show="top==2" @click="qh(2)" class="m-x-one">组织架构</div>
        </div>

        <div class="m-one">
          <div class="m-tit">
            <span @click="goTo('/new','')">新闻资讯</span>
            <img v-show="o2" @click="o2=false" src="~assets/home/sjt.png" />
            <img v-show="!o2" @click="o2=true" src="~assets/home/sjtf.png" />
          </div>

        </div>
        <div class="m-xlist" v-show="!o2">
          <div class="m-x-one" v-show="top!=3" @click="goTo('/new',item.id,'0')" v-for="item in newfl">{{item.name}}</div>
          <div class="m-x-one" v-show="top==3" @click="qh(item.id)" v-for="item in newfl">{{item.name}}</div>
        </div>


        <div class="m-one"><span @click="goTo('/users','')">会员体系</span></div>
        <div class="m-one">
          <div class="m-tit">
            <span @click="goTo('/meeting','')">会议论坛</span>
            <img v-show="o3" @click="o3=false" src="~assets/home/sjt.png" />
            <img v-show="!o3" @click="o3=true" src="~assets/home/sjtf.png" />
          </div>

        </div>
        <div class="m-xlist" v-show="!o3">
          <div v-show="top!=5" @click="goTo('/meeting','0','1')" class="m-x-one">最新会议</div>
          <div v-show="top!=5" @click="goTo('/meeting','1','1')" class="m-x-one">推荐论坛</div>

          <div v-show="top==5" @click="qh(0)" class="m-x-one">最新会议</div>
          <div v-show="top==5" @click="qh(1)" class="m-x-one">推荐论坛</div>
        </div>

        <div class="m-one">
          <div class="m-tit">
            <span @click="goTo('/expert','')">专家库</span>
            <img v-show="o4" @click="o4=false" src="~assets/home/sjt.png" />
            <img v-show="!o4" @click="o4=true" src="~assets/home/sjtf.png" />
          </div>

        </div>
        <div class="m-xlist" v-show="!o4">
          <div class="m-x-one" v-show="top!=6" @click="goTo('/expert','0','1')">专家库</div>
          <div class="m-x-one" v-show="top!=6" @click="goTo('/expert','1','1')">机构库</div>

          <div class="m-x-one" v-show="top==6" @click="qh(0)">专家库</div>
          <div class="m-x-one" v-show="top==6" @click="qh(1)">机构库</div>

        </div>


        <div class="m-one"><span @click="goTo('/financing','')"  >投融资</span></div>

        <div class="m-one">
          <div class="m-tit">
            <span @click="goTo('/cooperation','')">合作交流</span>
            <img v-show="o5" @click="o5=false" src="~assets/home/sjt.png" />
            <img v-show="!o5" @click="o5=true" src="~assets/home/sjtf.png" />
          </div>

        </div>
        <div class="m-xlist" v-show="!o5">
          <div class="m-x-one" @click="goTo('/cooperation','0')">技术成果</div>
          <div class="m-x-one" @click="goTo('/merchant','1')">需求商机</div>
        </div>

        <div class="m-one">
          <div class="m-tit">
            <span @click="goTo('/us','')">联系我们</span>
            <img v-show="o6" @click="o6=false" src="~assets/home/sjt.png" />
            <img v-show="!o6" @click="o6=true" src="~assets/home/sjtf.png" />
          </div>

        </div>
        <div class="m-xlist" v-show="!o6">
          <div class="m-x-one" v-show="top!=10" @click="goTo('/us','0','1')">平台简介</div>
          <div class="m-x-one" v-show="top!=10" @click="goTo('/us','1','1')">联合体地址</div>
          <div class="m-x-one" v-show="top!=10" @click="goTo('/us','2','1')">隐私协议</div>


          <div class="m-x-one" v-show="top==10" @click="qh(0)">平台简介</div>
          <div class="m-x-one" v-show="top==10" @click="qh(1)">联合体地址</div>
          <div class="m-x-one" v-show="top==10" @click="qh(2)">隐私协议</div>

        </div>


      </div>
    </div>





  </div>

  <div class="h-center">
    <div class="h-cen">
      <div @click="goTo('/','')" :class="top==1?'bs':''" class="h-c-one">首页</div>
      <div :class="top==2?'bs':''" class="h-c-one" >
        <span @click="goTo('/about','')">关于联合体</span>
       <!-- <transition name="moveCartoon" appear>
            
            <h1 v-show="bol">组件动画效果</h1>
        </transition> @mouseover="bol=false" @mouseout="bol=true"
    <transition name="fade" appear>   </transition> -->

          <div class="chijing" v-show="bol"  style="height: auto;">
            <div v-show="top!=2" @click="goTo('/about','0','1')" class="one">联合体介绍</div>
            <div v-show="top!=2" @click="goTo('/about','1','1')" class="one">领导简介</div>
            <div v-show="top!=2" @click="goTo('/about','2','1')" class="one">组织架构</div>

            <div v-show="top==2" @click="qh(0)" class="one">领导简介</div>
            <div v-show="top==2" @click="qh(1)" class="one">联合体简介</div>
            <div v-show="top==2" @click="qh(2)" class="one">组织架构</div>

          </div>

      </div>
      <div :class="top==3 || top==31 ?'bs':''" class="h-c-one">
        <span @click="goTo('/new','')">新闻资讯</span>
        <div class="chijing">
          <div class="one" v-show="top!=3" @click="goTo('/new',item.id,'0')" v-for="item in newfl">{{item.name}}</div>

          <div class="one" v-show="top==3" @click="qh(item.id)" v-for="item in newfl">{{item.name}}</div>

        </div>
      </div>
      <div @click="goTo('/users','')" :class="top==4?'bs':''" class="h-c-one">会员体系</div>
      <div :class="top==5?'bs':''" class="h-c-one">
        <span @click="goTo('/meeting','')">会议论坛</span>
        <div class="chijing">
          <div v-show="top!=5" @click="goTo('/meeting','0','1')" class="one">最新会议</div>
          <div v-show="top!=5" @click="goTo('/meeting','1','1')" class="one">推荐论坛</div>

          <div v-show="top==5" @click="qh(0)" class="one">最新会议</div>
          <div v-show="top==5" @click="qh(1)" class="one">推荐论坛</div>

        </div>
      </div>
      <div :class="top==6?'bs':''" class="h-c-one">
        <span @click="goTo('/expert','')">专家库</span>
        <div class="chijing">
          <div class="one" v-show="top!=6" @click="goTo('/expert','0','1')">专家库</div>
          <div class="one" v-show="top!=6" @click="goTo('/expert','1','1')">机构库</div>

          <div class="one" v-show="top==6" @click="qh(0)">专家库</div>
          <div class="one" v-show="top==6" @click="qh(1)">机构库</div>

        </div>
      </div>
      <div @click="goTo('/financing','')" :class="top==7?'bs':''" class="h-c-one">投融资</div>
      <!-- <div @click="goTo('/work','')" :class="top==8?'bs':''"  class="h-c-one">党建工作</div> -->
      <div :class="top==9?'bs':''" class="h-c-one">
        <span @click="goTo('/cooperation','')">合作交流</span>
        <div class="chijing">
          <div class="one" @click="goTo('/cooperation','0')">技术成果</div>
          <div class="one" @click="goTo('/merchant','1')">需求商机</div>
        </div>
      </div>
      <div :class="top==10?'bs':''" class="h-c-one">
        <span @click="goTo('/us','')">联系我们</span>
        <div class="chijing">
          <div class="one" v-show="top!=10" @click="goTo('/us','0','1')">平台简介</div>
          <div class="one" v-show="top!=10" @click="goTo('/us','1','1')">联合体地址</div>
          <div class="one" v-show="top!=10" @click="goTo('/us','2','1')">隐私协议</div>


          <div class="one" v-show="top==10" @click="qh(0)">平台简介</div>
          <div class="one" v-show="top==10" @click="qh(1)">联合体地址</div>
          <div class="one" v-show="top==10" @click="qh(2)">隐私协议</div>
        </div>
      </div>

    </div>



  </div>
</template>

<script>
  import {
    useRouter
  } from 'vue-router';

  import {
    ElMessage,
    ElMessageBox
  } from 'element-plus';
  import {
    Action
  } from 'element-plus';

  import {
    setCookie,
    getCookie,
    clearCookie
  } from "components/common/common.js";


  import {
    getnew,
    getgrxx,
    getfshezhi
  } from 'network/home.js';

  export default {
    name: 'Head',
    data() {
      return {
        bol:false,
        sh:false,
        o1:true,
        o2:true,
        o3:true,
        o4:true,
        o5:true,
        o6:true,
        o7:true,
        o8:true,
        o9:true,

        shezhi: {},
        grxx: [],
        sousuo: '',
        newfl: []
      }
    },
    props: ["top", 'qhs', 'sousu', 'nr', 'grxxff'],
    setup() {
      //跳转方法
      const router = useRouter();
      // var sousuo=this.sousuo;
      // this.sousuo=this.sousu;
      var that = this;
      const goTo = (path, fl, lx) => {
        console.log(lx);
        console.log(fl);
        if (lx == 1) {
          var query = {
            fl: fl
          }
        } else if (lx == 2) {
          sessionStorage.setItem("sousuo", fl); //存储
          var query = {
            sousuo: fl
          }
        } else {
          var query = {
            id: fl
          }
        }

        console.log(query);
        console.log(path);
        router.push({
          path: path,
          query: query || {}
        })

      }
      const goTo1 = (path, zt) => {
        var query = {
          zt: zt
        }
        router.push({
          path: path,
          query: query || {}
        })
      }




      return {
        goTo,
        goTo1
      }

    },
    mounted() {
      this.getfl();
      var ss = sessionStorage.getItem('sousuo');
      if (ss === 'undefined') {
        ss = '';
      }
      this.sousuo = ss ? ss : '';

      var uid = getCookie();
      // sessionStorage.getItem('uid');
      if (uid === 'undefined') {
        uid = '';
      }

      // if (uid) {
        this.getgrxx1(uid);
      // }


      // sessionStorage.getItems("sousuo");
      console.log(this.sousuo);
      console.log(this.sousu);
      console.log('ss');
      this.fshezhi();
    },
    methods: {
      beacklogin() {

        ElMessageBox.confirm(
            '是否确认退出登录?',
            '提示', {
              confirmButtonText: '确认',
              cancelButtonText: '取消',
              type: '提示',
            }
          )
          .then(() => {
            ElMessage({
              type: 'success',
              message: '退出成功',
            })
            clearCookie();
            sessionStorage.setItem("uid", '');
            this.grxx = [];
            this.grxxff([]);

            this.$router.push('/login')




          })
          .catch(() => {
            ElMessage({
              type: 'info',
              message: '取消成功',
            })
          })


      },
      getgrxx1(uid) {
        var that = this;
        var data = {
          uid: uid
        };
        getgrxx(data).then(res => {
          console.log('zd');
          console.log(res.data.data);
          that.grxx = res.data.data;
          if(that.grxx.name==''){
            that.getgrxx2();
          }else{
            that.grxxff(that.grxx);
          }

        }).catch(err => {})

      },
      getgrxx2() {
        var that = this;
        var uid = sessionStorage.getItem('uid');
        var data = {
          uid: uid
        };
        getgrxx(data).then(res => {
          console.log('asdasd');
          console.log(res);
          console.log(uid);
          that.grxx = res.data.data;
          if(that.grxx.name==''){

          }
          that.grxxff(that.grxx);
        }).catch(err => {})

      },

      getgrxx3(uid) {
        var that = this;
        // var uid = sessionStorage.getItem('uid');
        var data = {
          uid: uid
        };
          console.log('授权的登录冲'+uid);
        getgrxx(data).then(res => {
          console.log('授权的登录冲');
          console.log(res);
          that.grxx = res.data.data;
          if(that.grxx.name==''){

          }
          that.grxxff(that.grxx);
        }).catch(err => {})

      },

      fshezhi() {
        var that = this;
        var data = {

        };
        getfshezhi(data).then(res => {
          console.log('zd');
          console.log(res);
          that.shezhi = res.data.data;

        }).catch(err => {})
      },
      // sousuonew(){
      qhs() {

        sessionStorage.setItem("sousuo", this.sousuo); //存储
        // sessionStorage.getItems("sousuo");//按可以进行取值
        this.nr(this.sousuo);
      },
      // },
      getfl() {
        var that = this;
        var data = {
          type: 0,
          limit: 100
        };
        getnew(data).then(res => {
          that.newfl = res.data.list;
        }).catch(err => {})

      },

      qh(num) {
        this.qhs(num);
      }
    }

  }
</script>

<style lang="scss" scoped="scoped">
  body{
    overflow-y: hidden;
  }
  .bbs {
    margin-right: 0.25rem !important;
  }

  .ys {
    color: #AFC81D;
    margin-right: 1.25rem !important;
  }

  .h-top {
    width: 100vw;
    min-height: 10vh;
    background: url(assets/home/top.png) no-repeat;
    background-size: 100% 100%;

    .t-top {
      background: rgba(255, 255, 255, 0.4);
      padding: 0.625rem 0.3125rem;

      .t-center {
        width: var(--allWidth);
        margin: 0 auto;
        font-size: 0.875rem;

        .t-span {
          color: rgb(113, 113, 113);
          display: inline-block;
        }

        .right {
          span {
            margin-right: 0.625rem;
            cursor: pointer;
          }
        }
      }
    }

    .t-cen {
      width: var(--allWidth);
      margin: 0 auto;
      padding: 1.875rem 0;
      display: flex;
      align-items: center;

      .t-img1 {
        width: 20vw;
        margin-right: 2vw;
        cursor: pointer;
      }

      .t-img2 {
        width: 10vw;
        cursor: pointer;
      }

      position: relative;

      .sousuo {
        position: absolute;
        top: 50%;
        right: 1.125rem;
        transform: translate(0, -50%);
        width: 15vw;
        display: flex;

        input {
          width: 70%;
          padding: 0.75rem 0.625rem;
          border-radius: 1.875rem;
          border: 0.125rem solid #AFC81D;
          border-right: 0;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          font-size: 0.8125rem;
          outline: 0;
        }

        .sanniu {
          width: 30%;
          background: #AFC81D;
          font-size: 0.8em;
          padding: 0.75rem 0.3125rem;
          border: 0.125rem solid #AFC81D;
          border-top-right-radius: 30px;
          border-bottom-right-radius: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          text-align: center;

          img {
            margin-right: 3px;
          }

          cursor: pointer;
        }

      }
    }
  }

  .h-center {
    width: 100vw;
    background: #2E9BD6;
    color: #fff;
    position: sticky;
    top: 0;
    z-index: 999;

    .h-cen {
      width: var(--allWidth);
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      .bs {
        background: #0976B2;
      }

      .h-c-one:hover {

        background: #0976B2;

        .chijing {
          display: block !important;
          height: auto;

          overflow: hidden;
        }

      }

      .one:hover {
        background: #0976B2 !important;
      }


      .h-c-one {
        padding: 1.25rem 0;
        width: 9.125rem;
        text-align: center;
        font-size: 1rem;

        cursor: pointer;
        position: relative;

        span {
          padding: 1.25rem 1.5rem;
          height: 100%;
          width: 100%;
        }

        .chijing {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;

          overflow-y: hidden;
          transform: translate(0, 100%);

          .one {
            padding: 1.25rem 0;
            background: #2E9BD6;
          }
        }

        .chijing {
          // display: none;
          height: 0;
          // transition: all 3s;
          // transition-property:height;
          // transition-duration: 2s;
          // animation:height 3s;
          border-bottom-left-radius: 0.575rem;
          border-bottom-right-radius: 0.575rem;
        }
      }

    }
  }
  .shouji{
    display:none;
  }

  @media screen and (max-width: 1300px) {
    .bbs{
      font-size: 0.8rem!important;
    }
    .ys{
      margin-right: 0.4rem !important;
      font-size: 0.8rem!important;
    }
    .h-top,
    .h-center {
      display: none;
    }
    .shouji{
       display: block;
       .shou-top{
         background: rgba(255,255,255,0.1);
         padding: 0.375rem;
         display: flex;
         align-items: center;
         justify-content: center;
         .shou-title{
           max-width: 40%;
           font-size: 0.65rem;
           line-height: 1.2rem;
         }
         .opp{
            font-size: 0.65rem;
            margin-left: 0.5rem;
            background: #318FC6;
            color: #fff;
            padding:0.5rem 0.3125rem;
            border-radius: 0.3125rem;
            width: 6rem;
            text-align: center;
         }
       }
       .xm{
         .dwss{
          position: absolute;
          top: 15%;
          right: 5%;
          width: 2.75rem;
         }
         width: 100%;
         background: url(assets/home/top.png) no-repeat;
         background-size: 100% 100%;
         padding: 1.175rem 0.6rem 4.5rem 0.6rem;
         position: relative;
         .t-img1{
            width: 49%;
         }
         .t-img2{
            width: 28%;
         }


         .sousuo {
           position: absolute;
           bottom: 0.625rem;
           left: 4%;
           width: 92%;
           display: flex;
           align-items: center;
           .right{
             margin-top: 0!important;
          }
           input {
             width: 70%;
             padding: 0.75rem 0.625rem;
             border-radius: 1.875rem;
             border: 0.125rem solid #AFC81D;
             border-right: 0;
             border-top-right-radius: 0;
             border-bottom-right-radius: 0;
             font-size: 0.8125rem;
             outline: 0;
           }

           .sanniu {
             width: 30%;
             background: #AFC81D;
             font-size: 0.8em;
             padding: 0.75rem 0.3125rem;
             border: 0.125rem solid #AFC81D;
             border-top-right-radius: 30px;
             border-bottom-right-radius: 30px;
             display: flex;
             align-items: center;
             justify-content: center;
             color: #fff;
             text-align: center;

             img {
               margin-right: 3px;
             }

             cursor: pointer;
           }

         }



       }


    }

    .m-bj{
      background: rgba(0,0,0,0.3);
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 99999999999;
    }
    .m-left{
      width: 60%;
      height: 100%;
      background: #fff;
      position: fixed;
      top: 0;
      right: 0;
      z-index: 99999999999;
      .m-list{
        padding: 1.25rem;
        .m-one{
          font-size: 1rem;
          padding:0.8rem 1.25rem;
          border-bottom: 0.0625rem solid #EBEBEB;
          .m-tit{
            position: relative;
            img{
              width: 1rem;
              position: absolute;
              right:0;
              top:50%;
              transform: translate(0,-50%);
            }
          }

        }
        .m-xlist{
          .m-x-one{
            text-align: center;
            padding: 0.875rem 0;
            background: #EFF5F9;
          }
        }

      }
    }



  }
  .right>span:hover{
    color: #2E9BD6;
  }

  .fade-enter-active {
    animation: bounce-in 6s linear;
    animation-fill-mode:both;
  }
  // .fade-leave-active {
  //   animation: bounce-in 0.5s reverse;
  // }
  @keyframes bounce-in {
    from {
     height: 0;
    }

    to {
      height: auto;
    }
  }
  /* 类名要对应回 name 的属性值 */
  .moveCartoon-enter-active {
      animation: move 1s;
  }
  .moveCartoon-leave-active {
      animation: move 1s reverse;
  }

  @keyframes move {
      from {
          transform: translateX(-100%);
      }
      to {
          transform: translate(0);
      }
  }

</style>
