<template>
    <div class="">
      {{xy}}
      {{panduan}}
     <el-dialog

        :title="title"

        align-center
        width="25rem"
         v-model="value"
         :before-close="fh()"
        >
        <!-- width="80%" -->
        <div class="ewm">
          <div class="tp">
            <img src="~assets/my/ewm.png" />
          </div>
         <div  class="dibu">
           <ul>
             <li><div>打开微信，点击底部的“发现”</div></li>
             <li><div>使用“扫一扫”即可选择将网页分享至朋友圈。</div></li>
           </ul>


         </div>
        </div>
      </el-dialog>
    </div>
</template>

<script>
 import {
    ElMessage,
    ElMessageBox
  } from 'element-plus';
  import {useRouter} from 'vue-router';

  export default{


    name:'Dialog',
    props: ['title','xy'],

    emits: ['update:xy'],
     computed: {
        value: {
          get() {
            return this.xy
          },
          set(value) {
            this.$emit('update:xy', value)
          }
        }
      },
    data() {
      return {
        panduan:this.xy,
      }
    },
    methods: {
      fh(){
        console.log('aas');
       this.$emit('update:xy', false)
      }

    },

    setup(){
      //跳转方法
      const router=useRouter();
      const goTo=(path,query)=>{
        console.log(query);
        query={name:'nn'}
        console.log(query);
        router.push({
          path:path,
          query:query || {}
        })
      }

      return {goTo}

    },
  }
</script>

<style lang="scss" scoped="scoped">
  .ewm{
    width: 90%;
    margin: 0 auto;

  }
  .tp{
    text-align: center;
    width: 60%;
    margin: 0 auto;
    margin-bottom: 1.875rem;
    img{
      width: 100%;
    }
  }
  .dibu{
    div{
      margin-bottom: 0.625rem;
      // text-emphasis: circle #f00;
    }
  }
</style>
