
import { request } from "./request.js";


export function getfus(params){
    console.log(params);
    return request(
      {
          url:'/public/index/index/fus',
          method:'POST',
          params:params,
      }
    )
}
