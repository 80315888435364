<template>
  <div class="r-bot">
    <div class="l-bot">

      <div class="z-n-one z-n-one1" v-show="tz=='/meetingNewDe'"  v-for="(item,index) in list"  @click="goTo(tz,item.id)" >

        <div class="zno-left">
          <img :src="'https://ymedicine.jiujingwulian.com/public'+item.fimage" />
        </div>
        <div class="zno-right zno-right1">
          <div class="znor-title onehang">{{item.name}}</div>
          <div class="znor-content twohang">{{item.title?item.title:item.jianjie}}</div>
          <div class="rq" v-if="i==0">{{item.time}}</div>
          <div class="but" v-if="i==1" >了解详情>></div>
        </div>
      </div>
      <div class="z-n-one z-n-one1" v-show="tz!='/meetingNewDe'" v-for="(item,index) in list"  @click="qiehuanan(item)">

        <div class="zno-left">
          <img :src="'https://ymedicine.jiujingwulian.com/public'+item.fimage" />
        </div>
        <div class="zno-right zno-right1">
          <div class="znor-title onehang">{{item.name}}</div>
          <div class="znor-content twohang">{{item.title?item.title:item.jianjie}}</div>
          <div class="rq" v-if="i==0">{{item.time}}</div>
          <div class="but" v-if="i==1" >了解详情>></div>
        </div>
      </div>
<!--
     <div class="z-n-one z-n-one1">
       <div class="zno-left">
         <img src="~assets/about/fmt.png" />
       </div>
       <div class="zno-right zno-right1">
         <div class="znor-title">标题</div>
         <div class="znor-content">内容内容内容内容内内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内内容内容内容内容内内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容容内容内容内容内容内容容内容内容内容内容容内容内容内容内容内容</div>
         <div class="rq" v-if="i==0">2022-02-02</div>
         <div class="but" v-if="i==1">了解详情>></div>
       </div>
     </div>

     <div class="z-n-one z-n-one1">
       <div class="zno-left">
         <img src="~assets/about/fmt.png" />
       </div>
       <div class="zno-right zno-right1">
         <div class="znor-title">标题</div>
         <div class="znor-content">内容内容内容内容内内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内内容内容内容内容内内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容容内容内容内容内容内容容内容内容内容内容容内容内容内容内容内容</div>
         <div class="rq" v-if="i==0">2022-02-02</div>
         <div class="but" v-if="i==1">了解详情>></div>
       </div>
     </div>


     <div class="z-n-one z-n-one1">
       <div class="zno-left">
         <img src="~assets/about/fmt.png" />
       </div>
       <div class="zno-right zno-right1">
         <div class="znor-title">标题</div>
         <div class="znor-content">内容内容内容内容内内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内内容内容内容内容内内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容容内容内容内容内容内容容内容内容内容内容容内容内容内容内容内容</div>
         <div class="rq" v-if="i==0">2022-02-02</div>
         <div class="but" v-if="i==1">了解详情>></div>
       </div>
     </div>


     <div class="z-n-one z-n-one1">
       <div class="zno-left">
         <img src="~assets/about/fmt.png" />
       </div>
       <div class="zno-right zno-right1">
         <div class="znor-title">标题</div>
         <div class="znor-content">内容内容内容内容内内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内内容内容内容内容内内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容容内容内容内容内容内容容内容内容内容内容容内容内容内容内容内容</div>
         <div class="rq" v-if="i==0">2022-02-02</div>
         <div class="but" v-if="i==1">了解详情>></div>
       </div>
     </div>
 -->

    </div>


  </div>

</template>

<script>
  import {
    useRouter
  } from 'vue-router';

  export default {
    name: 'List',
    props: ['i','tz','list','qiehuan'],
    setup() {
      //跳转方法
      const router = useRouter();
      const goTo = (path, id) => {

        var query = {
          id: id
        }
        console.log(query);
        router.push({
          path: path,
          query: query || {}
        })
      }

      return {
        goTo
      }

    },
    methods:{
      qiehuanan(item){
        this.qiehuan(item);
        // this.getChildInfo(tid);
      }

    }
  }
</script>

<style lang="scss" scoped="scoped">


 .but{
   border: 0.0625rem solid #D3D3D3;
      background: #fff;
      color: #B1B1B1;
      padding: 0.325rem 1.025rem;
      display: inline-block;
      margin-top: 6px;
      font-size: 12px;
          position: absolute;
          bottom: 0.4rem;
 }

  .r-bot {
    background: #fff;
    min-height: 6.25rem;
    margin-top: 0.625rem;
    padding: 0rem;


    .l-bot {
      // margin-top: 1.875rem;

      .z-n-one:hover {
        background: linear-gradient(rgba(98, 186, 238, 0.1568627450980392) 0%, rgba(196, 196, 196, 0) 100%);

        .znor-title {
          color: #2E9BD6;
        }

        .but{
          border: 0.0625rem solid #2E9BD6;
             background: #2E9BD6;
             color: #fff;
             padding: 0.325rem 1.025rem;
             display: inline-block;
             margin-top: 6px;
             font-size: 12px;
        }

      }

      .z-n-one {
        padding: 0.825rem;
        padding-bottom: 1rem;
        border-bottom: 0.0625rem solid #DBDBDB;
        display: flex;
        align-items: center;
        // margin-bottom: 1rem;
        background: #fff;
        cursor: pointer;
        // margin-bottom: 1rem;
        .zno-left {
          margin-right: 1.875rem;

          img {

            width: 12.875rem;
            height: 7.875rem;
            border-radius: 0.25rem;

            // 12.875rem
          }
        }

        .zno-right {
          position: relative;
          width: 100%;
          .znor-title {
            font-size: 1.075rem;
            font-weight: bold;
            margin-bottom: 0.675rem;
            width: 100%;
          }

          .znor-content {
            font-size: 0.7rem;
            color: #888888;
            line-height: 1rem;
          }

          .rq {
            font-size: 0.7rem;
            color: #888888;
            margin-top: 0.625rem;
          }
        }
      }



      .z-n-one1 {
        padding: 1rem;
        padding-bottom: 0.8rem;
        align-items: normal;

        .zno-left {
          margin-right: 0.675rem;

          // img {
          //   width: 7rem;
          // }
        }

        // .zon-left{
        //   img{
        //     width: 10%!important;
        //   }
        // }
      }

      .zno-right {
        padding-top: 0.25rem;
      }
    }

  }
  @media screen and (max-width: 1300px) {
     .z-n-one1 {
       padding: 0.3rem!important;
       padding-bottom: 0.3rem!important;

     }
     .zno-left{
       margin-right: 0.375rem!important;
       img{
         width: 9.875rem !important;
         height: 5.875rem!important;
       }
     }
     .znor-title{
       font-size: 0.875rem!important;
       margin-bottom: 0.675rem!important;
     }
     .but{
       bottom: 0.1rem!important;
     }

     .but{
       padding: 0.325rem!important;
       width: 7rem!important;
       text-align: center;
       margin: 0.25rem 1.625rem 0.25rem 0!important;
     }
    .zno-right{
      text-align: left;
     }
  }
</style>
