<template>
  <div class="all">
    <Head :top="top" :grxxff="grxxff"></Head>
    <div class="center">
      <div class="c-all">
        <MyLeft :topleft="topleft"></MyLeft>

        <div class="c-right">
          <div class="c-top" @click="fanhui()"><img src="~assets/about/fh.png" /><span>账号安全</span></div>


          <div class="alls">
            <div class="one">
              <div class="o-title">账户：{{grxx.account}}</div>
              <!-- <div class="o-anu">修改密码</div> -->
              <el-button type="primary" class="ab" @click="xgmm()">修改密码</el-button>
            </div>
            <div class="one">
              <div class="o-title">手机号：{{phone}}</div>
              <!-- <div class="o-anu">修改绑定手机号</div> -->
              <el-button type="primary" class="ab" @click="xgmm1()">修改绑定手机号</el-button>
            </div>
            <div class="one">
              <div class="o-title">绑定微信：已绑定微信号</div>
              <!-- <div class="o-anu">解除绑定</div> -->
              <el-button type="primary" class="ab" @click="xgmm2()">解除绑定</el-button>
            </div>


            <el-dialog class="ddb zd"  :width="w50"  v-model="dialogTableVisible2" :show-close="false">
              <div class="top">
                <div class="">修改密码<div class="gb right"><img @click="dialogTableVisible2=false" src="../../assets/my/guanbi.png" /></div></div>
              </div>
              <div class="hang">
                <div class="h-bt">绑定手机号:</div>
                <div class="h-nr">{{phone}}</div>
              </div>
              <div class="hang">
                <div class="h-bt">验证码:</div>
                <div class="h-nr"><el-input v-model="yzm" placeholder="请输入" clearable /></div>
                <div class="anniu">
                  <el-button class="anniu bs" v-show="yzm1show" type="primary" @click="qddsq(1)"  >获取验证码</el-button>
                  <el-button class="anniu bs" v-show="!yzm1show" type="primary"  >{{yzm1}}</el-button>
                </div>
              </div>
              <div class="hang">
                <div class="h-bt">新密码:</div>
                <div class="h-nr"><el-input type="password" v-model="newpassword" placeholder="请输入" clearable /></div>
              </div>
              <div class="hang">
                <div class="h-bt">确认新密码:</div>
                <div class="h-nr"><el-input type="password" v-model="newpassword1" placeholder="请输入" clearable /></div>
              </div>

              <div class="dban">
                 <el-button class="anniu bs" type="primary" @click="updatepassword()" >确定</el-button>
                 <el-button class="anniu bs1" @click="dialogTableVisible2=false">取消</el-button>
                  <!-- <el-button>Default</el-button> -->
              </div>


            </el-dialog>

            <el-dialog class="ddb zd"  :width="w50"  v-model="dialogTableVisible3" :show-close="false">
              <div class="top">
                <div class="">修改绑定手机号<div class="gb right"><img @click="dialogTableVisible3=false" src="../../assets/my/guanbi.png" /></div></div>
              </div>
              <div class="hang">
                <div class="h-bt">绑定手机号:</div>
                <div class="h-nr">{{phone}}</div>
              </div>
              <div class="hang">
                <div class="h-bt">验证码:</div>
                <div class="h-nr"><el-input v-model="yzm1" placeholder="请输入" clearable /></div>
                <div class="anniu">
                  <el-button class="anniu bs" v-show="yzm2show" @click="qddsq(2)"  type="primary">获取验证码</el-button>
                  <el-button class="anniu bs" v-show="!yzm2show" type="primary"  >{{yzm2}}</el-button>
                </div>
              </div>
              <div class="hang">
                <div class="h-bt">新手机号:</div>
                <div class="h-nr"><el-input v-model="newphone" placeholder="请输入" clearable /></div>
              </div>
              <div class="hang">
                <div class="h-bt">验证码:</div>
                <div class="h-nr"><el-input v-model="yzm2" placeholder="请输入" clearable /></div>
                <div class="anniu">
                  <el-button class="anniu bs" v-show="yzm3show" @click="qddsq(3)"  type="primary"  >获取验证码</el-button>
                  <el-button class="anniu bs" v-show="!yzm3show"   type="primary"  >{{yzm3}}</el-button>
                </div>
              </div>

              <div class="dban">
                 <el-button class="anniu bs" type="primary" @click="upphone()" >确定</el-button>
                 <el-button class="anniu bs1" @click="dialogTableVisible3=false">取消</el-button>
                  <!-- <el-button>Default</el-button> -->
              </div>


            </el-dialog>

            <el-dialog class="ddb zd"  :width="w50"  v-model="dialogTableVisible4" :show-close="false">
              <div class="top">
                <div class="">修改绑定微信<div class="gb right"><img @click="dialogTableVisible4=false" src="../../assets/my/guanbi.png" /></div></div>
              </div>
              <div class="hang">
                <div class="h-bt">绑定手机号:</div>
                <div class="h-nr">{{phone}}</div>
              </div>
              <div class="hang">
                <div class="h-bt">验证码:</div>
                <div class="h-nr"><el-input v-model="input" placeholder="请输入" clearable /></div>
                <div class="anniu">
                  <el-button class="anniu bs" type="primary" @click="qiandao" >获取验证码</el-button>
                </div>
              </div>
              <div class="hang" >
                <div class="h-bt">新微信扫码:</div>
                <div class="h-nr">
                  <img src="../../assets/my/ewm1s.png" />
                </div>
              </div>


              <div class="dban">
                 <el-button class="anniu bs" type="primary" @click="qiandao" >确定</el-button>
                 <el-button class="anniu bs1" @click="dialogTableVisible4=false">取消</el-button>
                  <!-- <el-button>Default</el-button> -->
              </div>


            </el-dialog>



          </div>


        </div>

      </div>
    </div>


    <foot></foot>
    <left></left>
  </div>
</template>

<script>
  import {
    ref,
    onMounted
  } from 'vue';
  import {
    getHome
  } from 'network/home.js';

  import {
    getxgpassword,
    getxgphone,
  } from 'network/my.js';


  import {
    useRouter
  } from 'vue-router';

  import {
     ElMessage,
     ElMessageBox
  } from 'element-plus';
  import {
     Action
  } from 'element-plus';


  import Head from "components/content/headder/Head.vue";
  import Foot from "components/content/footer/Foot.vue";
  import Left from "components/content/left/Left.vue";

  import MyLeft from "views/my/ChildComps/MyLeft.vue";

  import {
    send,
    check
  } from 'network/send.js';


  export default {
    name: 'My',
    data() {
      return {
        w50:'40%',
        yzm1show: true,
        yzm1: 60,

        yzm2show: true,
        yzm2: 60,

        yzm3show: true,
        yzm3: 60,


        yzm:'',
        newpassword:'',
        newpassword1:'',
        yzm1:'',
        yzm2:'',
        newphone:'',

        zt:0,
        shzt:1,//shzt 0= 待审核,1=已驳回
        topleft:8,//左边
        dialogTableVisible2:false,
        dialogTableVisible3:false,
        dialogTableVisible4:false,
        radio1: 1,
        textarea2:'',
        options:[
          {
            value:'城市',
            lable:'0'
          },
        ]
        ,
        name: '',
        grxx: [],
      }
    },
    setup() {
      const top = 0;
      const router = useRouter();
      const databa = ref([]);
      const ab = 'sb';
      console.log(ab);
      onMounted(() => {

        getHome().then(res => {
          console.log(res.data.data);
          databa.value = res.data;
          console.log(databa);

        }).catch(err => {

        })

      })
      //跳转方法
      const goTo = (path, query) => {
        router.push({
          path: path,
          query: query || {}
        })
      }

      return {
        databa,
        ab,
        goTo,
        top
      }
    },
    methods: {

      qddsq(type) {
        this.gbdsq();
        if (type == 1) {

          if (!this.phone) {
            ElMessage({
              type: 'info',
              message: '请输入手机号',
            })
            return false;
          }
          const reg = /^[1][1,2,3,4,5,6,7,8,9][0-9]{9}$/;
          if ((!reg.test(this.phone)) && this.phone != '') {
            ElMessage({
              type: 'info',
              message: '请输入正确的手机号',
            })
            return false;
          }


          this.yzm1show = false;
          this.fasong(this.phone);


        }
        if (type == 2) {

          if (!this.phone) {
            ElMessage({
              type: 'info',
              message: '请输入手机号',
            })
            return false;
          }
          const reg = /^[1][1,2,3,4,5,6,7,8,9][0-9]{9}$/;
          if ((!reg.test(this.phone)) && this.phone != '') {
            ElMessage({
              type: 'info',
              message: '请输入正确的手机号',
            })
            return false;
          }




          this.yzm2show = false;
          this.fasong(this.phone);
        }
        if (type == 3) {

          if (!this.newphone) {
            ElMessage({
              type: 'info',
              message: '请输入手机号',
            })
            return false;
          }
          const reg = /^[1][1,2,3,4,5,6,7,8,9][0-9]{9}$/;
          if ((!reg.test(this.newphone)) && this.newphone != '') {
            ElMessage({
              type: 'info',
              message: '请输入正确的手机号',
            })
            return false;
          }


          this.yzm3show = false;
          this.fasong(this.newphone);
        }
        this.timeInter = setInterval(() => {
          if (type == 1) {
            this.kqsj1();
          }
          if (type == 2) {
            this.kqsj2();
          }
          if (type == 3) {
            this.kqsj3();
          }
        }, 1000)


      },
      fasong(phone) {
        return false;
        var data = {
          mobile: phone,
        };
        send(data).then(res => {
          console.log(res);
        }).catch(err => {})

      },
      yanzheng(phone, captcha) {
        var data = {
          mobile: phone,
          captcha: captcha,
        };
        check(data).then(res => {
          console.log(res);
          if (res.msg == '验证码不正确') {
            console.log('cwcwcw');
          } else {
            console.log('zqzq');
          }
        }).catch(err => {})
      },

      kqsj1() {
        this.yzm1 = this.yzm1 - 1;
        if (this.yzm1 <= 0) {
          this.gbdsq();

        }
      },
      kqsj2() {
        this.yzm2 = this.yzm2 - 1;
        if (this.yzm2 <= 0) {
          this.gbdsq();
        }
      },
      kqsj3() {
        this.yzm3 = this.yzm3 - 1;
        if (this.yzm3 <= 0) {
          this.gbdsq();
        }
      },
      gbdsq() {
        clearInterval(this.timeInter); //关闭
        this.yzm1show = true;
        this.yzm1 = 60;
        this.yzm2show = true;
        this.yzm2 = 60;
        this.yzm3show = true;
        this.yzm3 = 60;

      },




      isMobile() {
       　　let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
       　　return flag;
       },

      xgmm(){
        if (this.isMobile()) {
          // alert("移动端");
          //移动端跳转路由
          this.w50='90%';
        } else {
          // alert("pc端");
    　　　　//Pc端跳转路由
          this.w50='40%';
        }


        this.dialogTableVisible2=true;
      },
      xgmm1(){

        if (this.isMobile()) {
          // alert("移动端");
          //移动端跳转路由
          this.w50='90%';
        } else {
          // alert("pc端");
    　　　　//Pc端跳转路由
          this.w50='40%';
        }




        this.dialogTableVisible3=true;
      },
      xgmm2(){
        if (this.isMobile()) {
          // alert("移动端");
          //移动端跳转路由
          this.w50='90%';
        } else {
          // alert("pc端");
    　　　　//Pc端跳转路由
          this.w50='40%';
        }



        this.dialogTableVisible4=true;
      },
      grxxff(grxxs) {
        this.grxx = grxxs;
        this.phone=grxxs.phone;
        console.log(grxxs);
        console.log('zhende');
      },
      fanhui(){
        window.history.go(-1);
      },
      upphone(){

        if (!this.yzm1) {
          ElMessage({
            type: 'info',
            message: '请输入绑定手机号验证码',
          })
          return false;
        }
        if (!this.newphone) {
          ElMessage({
            type: 'info',
            message: '请输入新手机号',
          })
          return false;
        }
        const reg = /^[1][1,2,3,4,5,6,7,8,9][0-9]{9}$/;
        if ((!reg.test(this.newphone)) && this.newphone != '') {
          ElMessage({
            type: 'info',
            message: '请输入正确的手机号',
          })
          return false;
        }

        if (!this.yzm2) {
          ElMessage({
            type: 'info',
            message: '请输入新手机号验证码',
          })
          return false;
        }


        ElMessageBox.confirm(
            '是否确认提交修改?',
            '提示', {
              confirmButtonText: '确认',
              cancelButtonText: '取消',
              type: '提示',
            }
          )
          .then(() => {
            ElMessage({
              type: 'success',
              message: '提交成功',
            })


            var data1s = {
              mobile: this.phone,
              captcha: this.yzm1,
            };

            check(data1s).then(res1 => {
              console.log(res1);
              if (res1.data.msg == '验证码不正确') {
                ElMessage({
                  type: 'info',
                  message: '验证码不正确',
                })
                return false;
              } else {



                  var data1s1 = {
                    mobile: this.newphone,
                    captcha: this.yzm2,
                  };

                  check(data1s1).then(res12 => {
                    console.log(res12);
                    if (res12.data.msg == '新手机号验证码不正确') {
                      ElMessage({
                        type: 'info',
                        message: '新手机号验证码不正确',
                      })
                      return false;
                    } else {


                      this.updatephone();

                    }

                  }).catch(err => {})





              }

            }).catch(err => {})




          })
          .catch(() => {
            ElMessage({
              type: 'info',
              message: '取消成功',
            })
          })

      },

      updatepassword(){
        if (!this.yzm) {
          ElMessage({
            type: 'info',
            message: '请输入验证码',
          })
          return false;
        }
        if (!this.newpassword) {
          ElMessage({
            type: 'info',
            message: '请输入新密码',
          })
          return false;
        }
        if (!this.newpassword1) {
          ElMessage({
            type: 'info',
            message: '请输入确认新密码',
          })
          return false;
        }
        if (this.newpassword!=this.newpassword1) {
          ElMessage({
            type: 'info',
            message: '两次输入密码不一致',
          })
          return false;
        }
        var that=this;
        ElMessageBox.confirm(
            '是否确认提交?',
            '提示', {
              confirmButtonText: '确认',
              cancelButtonText: '取消',
              type: '提示',
            }
          )
          .then(() => {
            ElMessage({
              type: 'success',
              message: '提交成功',
            })


            var data1s = {
              mobile: this.phone,
              captcha: this.yzm,
            };

            check(data1s).then(res1 => {
              console.log(res1);
              if (res1.data.msg == '验证码不正确') {
                ElMessage({
                  type: 'info',
                  message: '验证码不正确',
                })
                return false;
              } else {


                  that.updateps();

              }

            }).catch(err => {})



          })
          .catch(() => {
            ElMessage({
              type: 'info',
              message: '取消成功',
            })
          })



      },
      updatephone(){

        var that=this;
        var data = {
          yzm1:this.yzm1,
          yzm2:this.yzm2,
          newphone:this.newphone,
          uid: this.grxx.id,
        };
        console.log(data);
        // return false;
        getxgphone(data).then(res => {
          console.log('zx');
          console.log(res);
          that.phone=this.newphone;
          that.dialogTableVisible3=false;
          // that.getdata(that.zt);
          // that.dialogTableVisible2 = false;
          // that
        }).catch(err => {})

      },

      updateps(){

        var that=this;
        var data = {
          yzm:this.yzm,
          password:this.newpassword,
          uid: this.grxx.id,
        };
        console.log(data);
        // return false;
        getxgpassword(data).then(res => {
          console.log('zx');
          console.log(res);
          that.dialogTableVisible2=false;
          // that.getdata(that.zt);
          // that.dialogTableVisible2 = false;
          // that
        }).catch(err => {})


      },
    },
    components: {
      Head,
      Foot,
      Left,
      MyLeft
    },
  }
</script>
<style lang="scss" scoped="scoped">
  .ab{
    background: #2E9BD6;
  }
  .alls{
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    margin-top: 1.875rem;
    justify-content: space-around;
    .one{
      text-align: center;
      display: flex;
      width: 30%;
      padding-bottom: 0.875rem;
      border-bottom: 0.0625rem solid #D2D2D2;
      justify-content: space-around;
      align-items: center;
      .o-anu{
        background: #2E9BD6;
        padding: 0.325rem 0.6rem;
        border-radius: 0.125rem;
        color: #fff;
        font-size: 0.875rem;
      }
    }
  }

  .all {
    background: #f5f6fa;
  }

  .center {
    .c-all {
      width: var(--allWidth);
      margin: 0 auto;
      margin-top: 1.275rem;
      display: flex;

      .c-right {
        margin-left: 1.275rem;
        background: #fff;
        width: 90%;
        margin-bottom: 3.125rem;

        .c-top {
          font-weight: bold;
          padding: 1.275rem;
          border-bottom: 0.0625rem solid #E8E8E8;
          cursor: pointer;
          img {
            vertical-align: middle;
            width: 1.0875rem;
            margin-right: 0.625rem;
          }

          span {
            vertical-align: middle;
          }
        }

      }
    }

  }

  .ddb{
    position: relative;
    z-index: 9999999999999999999;
    .top{
      position: absolute;
      top: 0;
      left: 0;
      background: #2E9BD6;
      color: #FFFFFF;
      width: 100%;
      padding: 1.075rem;
      font-size: 1.2rem;
    }
    .dbx{
      border-bottom: 0.0625rem solid #E6E6E6;
    }
    .ddgy{
      margin-top: 1.075rem;
      .d-bt{
        font-weight: bold;
        font-size: 1rem;
        margin-bottom: 1.025rem;
        color: #000000;
      }
      .ddgy-one{
        margin-bottom: 0.75rem;
        .d-o-bt{
          width: 50%;
          display: inline-block;
          color: #383838;
        }
      }

      .table{
        border: 0.0625rem solid #D5D5D5;
        .tr{
          text-align: center;
          display: flex;
          background: #F8F8F8;
          padding: 0.675rem;
          .td{
            width: 40%;
            padding:0 20px;
          }
        }
        .tr1{
          text-align: center;
          display: flex;
          padding: 0.675rem;
          .td{
            width: 40%;
            padding:0 20px;
          }
        }
      }
    }
  }
  .dban{
    text-align: center;
    margin-top: 2.175rem;
    .anniu{
      width: 30%;
      text-align: center;
      padding: 1.4rem 0;
      font-size: 1rem;
    }
    .bs{
      background: #2E9BD6;
      border: 0.0625rem solid #2E9BD6;
      margin-right: 2.275rem;
      img{
        width: 1rem;
        margin-right: 0.625rem;
      }
    }
    .bs1{
      // background: #2E9BD6;
    }
  }
  .gb{
    cursor: pointer;
  }
  .zd{
    z-index: 9999999999999999999999999999999999!important;
  }
  .hang{
    width: 80%;
    margin: 0 auto;
    // text-align: center;
    display: flex;
    align-items: center;
    color: #000000;
    position: relative;
    .anniu{
      position: absolute;
      right: 0.825rem;
      top: 50%;
      transform: translate(-0,-50%);
      background: #2E9BD6;
    }
    .h-bt{
      width: 30%;
      text-align: right;
    }
    .h-nr{
      width: 80%;
      padding:0.8rem 1.875rem;
    }
  }


   @media screen and (max-width: 1300px) {
      .c-all{
        width: 95%!important;
        margin: 0 auto;
        flex-wrap: wrap;
      }
      .c-right{
        width: 100%!important;
        margin: 0 auto!important;
      }

      .lefts{
        width: 100%;
      }

      .c-right{
       .center{
            margin: 0rem !important;
            padding: 0.75rem!important;
       }
      }

      .c-bt{
        display: flex;
      }
      .c-lt{
        padding: 0.65rem 0!important;
        width: 36%;
        text-align: center;
        margin-bottom: 0.625rem;
      }
      .alls{
        flex-wrap: wrap;
        width: 95%;
        margin: 0 auto;
        padding: 5%;
        .one{
          width: 100%;
          margin-bottom: 1.875rem;
          justify-content: space-between;
        }
      }

      // .from{
      //       width: 95%!important;
      // }
      .hang{
        width: 100%;
        .h-bt{
         width: 34%;
        }
        .h-nr{
          padding: 0.8rem 0.2rem!important;
        }
      }
      .anniu {
        font-size: 0.5rem!important;
        right: 0!important;
        padding: 0 0.5rem!important;
      }

      .ddb{
        .top{
          font-size: 1rem;
          padding: 0.8rem;
        }
      }


   }



</style>
