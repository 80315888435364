<template>
  <div class="all">
    <Head :top="top" :grxxff="grxxff1"></Head>
    <Bread :all="all"></Bread>
    <div class="a-cen">
      <div class="center">

        <div class="zong">
          <div class="zo-cen">
            <div class="zoc-right" >
              <div class="z-nrk">
                <div class="z-nrk-left"><img :src="'https://ymedicine.jiujingwulian.com/public'+nrxq.fimage" /></div>
                <div class="z-nrk-right">
                  <div class="z-n-r-title">{{nrxq.name}}</div>
                  <div class="z-n-r-nr1">技术单位：<span>{{nrxq.ly6}}</span></div>
                  <div class="z-n-r-nr1">技术领域：<span>{{nrxq.ly0}}</span></div>
                  <div class="z-n-r-nr1">成熟阶段：<span>{{nrxq.ly1}}</span></div>
                  <div class="z-n-r-nr1">技术来源：<span>{{nrxq.ly2}}</span></div>
                </div>
                <div class="but" @click="gxq()" v-show="ps==0">我感兴趣</div>
                <div class="but bys" v-show="ps==1">我感兴趣</div>


              </div>
              <div class="z-nrk-con" v-html="nrxq.content"></div>
            </div>




          </div>
        </div>


      </div>
    </div>


    <el-dialog class="ddb zd" :width="w50" align-center v-model="dialogTableVisible2" :show-close="false">
      <div class="top">
        <div class="">请填写您的信息</div>
        <div class="gb "><img @click="dialogTableVisible2=false" src="../../assets/my/guanbi.png" /></div>
      </div>
      <div class="kuai">

        <div class="hang">
          <div class="lxr1">
            <div class="bt">姓名<span class="red">*</span>：</div>
            <el-input class="shuru" v-model="name" placeholder="请输入" />
          </div>
        </div>
        <div class="hang">
          <div class="lxr1">
            <div class="bt">手机号<span class="red">*</span>：</div>
            <el-input class="shuru" v-model="phone" placeholder="请输入" />
          </div>
        </div>
        <div class="hang">
          <div class="lxr1">
            <div class="bt"><span class="red"></span>类型：</div>
            <el-radio-group v-model="type" class="ml-4">
              <el-radio label="0" size="large">个人</el-radio>
              <el-radio label="1" size="large">单位</el-radio>
            </el-radio-group>
          </div>
        </div>
      </div>
      <div class="kuai biao">

        <div class="hang">
          <div class="lxr1">
            <div class="bt">公司/单位名称<span class="red">*</span>：</div>
            <el-input class="shuru" v-model="gsname" placeholder="请输入" />
          </div>
        </div>

      </div>


      <div class="dban">

        <el-button class="anniu bs1" type="primary" @click="open">
          <div>确认提交</div>
        </el-button>

      </div>

    </el-dialog>





    <foot></foot>
    <left></left>
  </div>
</template>

<script>
  import {
    ref,
    onMounted
  } from 'vue';
  import {
    getHome
  } from 'network/home.js';
  import {
    useRouter
  } from 'vue-router';

  import {
    getfinvestment,
    getfinvestmentin,
    getfinvestmentxq,
    getinsertftechnologyorder
  } from 'network/cooperation.js';

  import {
    ElMessage,
    ElMessageBox
  } from 'element-plus';
  import {
    Action
  } from 'element-plus';



  import Head from "components/content/headder/Head.vue";
  import Foot from "components/content/footer/Foot.vue";
  import Left from "components/content/left/Left.vue";
  import Bread from "components/content/breadcrumb/Breadcrumb.vue";
  import Banner from "components/content/banner/Banner.vue";

  import Journalism from 'components/content/journalism/Journalism.vue'
  import Notice from "components/content/notice/Notice.vue";
  import List from "components/content/list/List.vue";

  export default {
    name: 'New',
    data() {
      return {
        w50:'50%',
        grxx:{},
        ps:0,
        name: '',
        phone: '',
        type: 1,
        gsname: '',
        id:'',
        dialogTableVisible2: false,
        nrxq:{},
        nrlb:[],
        v: 0,
        i: 1,
        bn: 'public/img/banner.387b0333.png',
        all: [{
            name: '您当前的位置:首页',
            url: '/'
          },

          {
            name: '合作交流',
            url: '/Cooperation'
          },
          {
            name: '详情',
            url: '/New'
          },
        ]
      }
    },
    setup() {
      const top = 9;
      const router = useRouter();
      const databa = ref([]);
      const ab = 'sb';
      console.log(ab);
      onMounted(() => {

        getHome().then(res => {
          console.log(res.data.data);
          databa.value = res.data;
          console.log(databa);

        }).catch(err => {

        })

      })
      //跳转方法
      const goTo = (path, query) => {
        router.push({
          path: path,
          query: query || {}
        })
      }
      const type = ref('1');
      return {
        type,
        databa,
        ab,
        goTo,
        top
      }
    },
    mounted() {
      // var url = decodeURI(window.location.search); //?id="123456"&name="www";
      // // var url = window.location.href;
      // console.log(url);
      // var object = {};
      // if (url.indexOf("?") != -1) //url中存在问号，也就说有参数。
      // {
      //   var str = url.substr(1); //得到?后面的字符串
      //   var strs = str.split("&"); //将得到的参数分隔成数组[id="123456",name="www"];
      //   for (var i = 0; i < strs.length; i++) {
      //     object[strs[i].split("=")[0]] = strs[i].split("=")[1]; //得到{id:'123456',name:'www'}
      //   }
      // }
      // console.log(object);
      // var id = object['id'];
      var id =this.GetUrlParam('id');
      this.id=id;
      console.log(id);
      this.getdata(id);

    },
    methods: {

      GetUrlParam(paraName) {
      var url = window.location.toString();
      var arrObj = url.split("?");
      if (arrObj.length > 1) {
        var arrPara = arrObj[1].split("&");
        var arr;
        for (var i = 0; i < arrPara.length; i++) {
          arr = arrPara[i].split("=");
          if (arr != null && arr[0] == paraName) {
            return arr[1];
          }
        }
        return "";
      } else {
        return "";
      }
    },
    getdata(ids){

      var that = this;
      var data = {
        id:ids,
        fusers_id:this.grxx.id,
      };
      getfinvestmentxq(data).then(res => {

        console.log('zx');
        console.log(res);

        if(res.data.data.finvestmentinone.usersmy){
          that.ps=1;
        }else{
          that.ps=0;
        }


        that.nrxq = res.data.data.finvestmentinone;
        that.nrlb =res.data.data.finvestmentin;


      }).catch(err => {})
    },

      qh(it) {
        console.log(it);
        this.v = it;
      },
   isMobile() {
       　　let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
       　　return flag;
       },
      gxq() {
        if (this.isMobile()) {
              // alert("移动端");
              //移动端跳转路由
              this.w50='90%';
            } else {
              // alert("pc端");
        　　　　//Pc端跳转路由
              this.w50='50%';
            }
        console.log(this.grxx);
        if (!this.grxx.id) {
          this.$router.push('/login')
        } else {
          this.dialogTableVisible2 = true;
        }
      },

      open() {
        if (!this.name) {
          ElMessage({
            type: 'info',
            message: '请输入姓名',
          })
          return false;
        }
        if (!this.phone) {
          ElMessage({
            type: 'info',
            message: '请输入手机号',
          })
          return false;
        }
        const reg = /^[1][1,2,3,4,5,6,7,8,9][0-9]{9}$/;
        if ((!reg.test(this.phone)) && this.phone != '') {
          ElMessage({
            type: 'info',
            message: '请输入正确的手机号',
          })
          return false;
        }
        if (!this.gsname) {
          ElMessage({
            type: 'info',
            message: '请输入公司/单位名称',
          })
          return false;
        }






        ElMessageBox.confirm(
            '是否确认提交?',
            '提示', {
              confirmButtonText: '确认',
              cancelButtonText: '取消',
              type: '提示',
            }
          )
          .then(() => {
            ElMessage({
              type: 'success',
              message: '提交成功',
            })
            this.jkzl();
          })
          .catch(() => {
            ElMessage({
              type: 'info',
              message: '取消成功',
            })
          })
      },

      jkzl() {

        var that = this;
        var data = {
          name: this.name,
          phone: this.phone,
          type: this.type,
          gsname: this.gsname,
          fusers_id: this.grxx.id,
          finvestmentin_id: this.nrxq.id,
        };
        console.log(data);
        getinsertftechnologyorder(data).then(res => {

          console.log('zx');
          console.log(res);
          that.ps=1;
          that.dialogTableVisible2=false;


        }).catch(err => {})

        // name:'',
        // phone:'',
        // type:'',
        // gsname:'',

      },


      grxxff1(grxxs) {
        this.grxx = grxxs;
        console.log(grxxs);
        console.log('zhende');
        this.getdata(this.id);

      },

    },
    components: {
      Head,
      Foot,
      Left,
      Bread,
      Notice,
      Journalism,
      List,
      Banner
    },
  }
</script>
<style lang="scss" scoped="scoped">
  .a-cen {
    width: 100vw;
    background: #F5F6FA;
    padding: 1rem 0;

    .center {
      width: var(--allWidth);
      margin: 0 auto;

      .zo-cen {
        width: var(--allWidth);
        margin: 0 auto;
        display: flex;



        .zoc-right {
          width: 100%;
          padding: 1.875rem;
          background: #fff;
          // margin:0 1.875rem  1.875rem  1.875rem;
          position: relative;
          min-height: 50rem;

          //列表
          .z-bt {
            border-bottom: 0.0625rem solid #DBDBDB;
            padding-bottom: 1.875rem;
            font-weight: bold;
            font-size: 1.2rem;
            img{
              width: 1.075rem;
              margin-right: 0.625rem;
            }
          }
          .z-nrk-con{
            padding: 0.625rem;
          }
          .z-nrk {
            padding: 1rem;
            border-bottom: 0.0625rem solid #DBDBDB;
            display: flex;
            position: relative;
           .z-nrk-left{
             margin-right: 2rem;
            img{
              width: 30.875rem;
              border-radius: 0.75rem;
            }
           }
           .z-nrk-right{
            .z-n-r-title{
              font-size: 1.225rem;
              padding-bottom: 1.25rem;
              // border-bottom: 0.35rem solid #2E9BD6;
              display: inline-block;
              margin-bottom: 0.625rem;
            }
            .gby{
              margin-bottom: 3.875rem;
            }
            .z-n-r-nr1{
              font-size: 0.9125rem;
              margin-top: 1.25rem;
              span{
                color: #888888;
              }
            }
           }

          }


          //列表结束


        }
      }


    }
  }
  .but{
    position: absolute;
    bottom: 0.625rem;
    padding: 1rem 4rem;
    background: #2E9BD6;
    color: #fff;
    right: 2rem;
    bottom: 1.58rem;
    cursor: pointer;
  }

  .bys{
    background: #9da2a5!important;
  }
  .center {}


  .ddb {
    position: relative;
    z-index: 9999999999999999999;

    .top {
      font-weight: bold;
      position: absolute;
      top: 0;
      left: 0;
      background: #2E9BD6;
      color: #FFFFFF;
      width: 100%;
      padding: 1.075rem;
      font-size: 1.2rem;
      text-align: center;


      .gb {
        cursor: pointer;
        position: absolute;
        top: 50%;
        right: 1.25rem;
        transform: translate(0, -50%);
      }
    }

    .biao {
      padding-top: 1rem;
    }

    .kuai {
      border-bottom: 0.1625rem solid #F8F8F8;


      .biaoti {
        margin: 0 1.175rem;
        margin-top: 0.625rem;
        margin-bottom: 1.075rem;
        display: flex;
        align-items: center;

        .bmfy {
          font-weight: bold;
          font-size: 0.9rem;
        }

        .bmje {
          font-size: 1.2rem;
          color: #DB2626;
        }

      }

      .k-biaoti {
        padding: 0 4.875rem 0 4.875rem;
        font-weight: bold;
        position: relative;
        margin-top: 1.25rem;

        .gh {
          position: absolute;
          top: 50%;
          right: 4.825rem;
          transform: translate(0%, -50%);

          .ans {
            background: #2E9BD6;
            padding: 0.5rem 1.5rem;
          }
        }

      }

      .k-ones {
        padding: 1.25rem 4.875rem 0 4.875rem;
        display: flex;

        .kone {
          width: 33.333%;
          text-align: center;
          background: #F8F8F8;
          padding: 0.75rem 0;
          font-size: 0.8rem;
        }

        .kone1 {
          background: #F8F8F8;
          color: #B8B8B8;
          padding: 1.075rem 1.3rem;
          margin-right: 0.925rem;
        }

        .kone2 {
          padding: 1.075rem 1.3rem;
          border: 0.0625rem solid #DBDBDB;
          margin-right: 0.925rem;
        }

        .bs {
          border: 0.0625rem solid #2E9BD6;
          color: #2E9BD6;
        }
      }


    }

    .lxr,
    .lxr1 {
      width: 50%;
      display: flex;
      align-items: center;
      // display: inline-block;
      margin-right: 0.625rem;

      .bt {
        width: 8.5rem;
        text-align: right;
        font-weight: bold;

        .red {
          color: red;
        }
      }

      .shuru {
        width: 60%;
      }
    }

    .lxr1 {
      width: 100%;

      .shuru {
        width: 80.5%;
      }
    }

    .hang {
      display: flex;
      margin: 0 1.175rem;
      margin-top: 0.625rem;
      margin-bottom: 1.075rem;

      .h-title {
        font-weight: bold;
        color: #000000;
        border-left: 0.225rem solid #2E9BD6;
        padding-left: 0.625rem;
      }
    }

    .dban1 {
      text-align: center;
    }

    .dban {
      text-align: center;
      margin-top: 2.175rem;
      position: relative;

      .anniu {
        width: 30%;
        text-align: center;
        padding: 1.85rem 0 1.85rem 0;
        font-size: 1rem;
        position: relative;
        background: #2E9BD6;

        .je {
          font-weight: bold;
          position: absolute;
          top: 1rem;
          left: 50%;
          transform: translate(-50%, 0);
        }
      }

      .bs {
        background: #AFC81D;
        border: 0.0625rem solid #AFC81D;
        margin-right: 2.275rem;

        img {
          width: 1rem;
          margin-right: 0.625rem;
        }
      }

      .bs1 {
        background: #2E9BD6;
      }

      .je {
        display: block !important;
        font-size: 1rem;

      }
    }

  }

  @media screen and (max-width: 1300px) {
    .zo-cen{
      width: 100%!important;
      .zoc-right{
        padding: 0!important;
        .z-nrk{
          flex-wrap: wrap;
          .z-nrk-left{
            margin: 0!important;
            img{
              width: 100%!important;
            }
          }
          .z-nrk-right{
            width: 100%!important;
            .z-n-r-title{
              margin-bottom: 1.2rem!important;
              padding-bottom: 0!important;
              font-size: 1.1rem!important;
              margin-top: 0.2rem!important;
            }
            .z-n-r-nr1{
              margin: 0!important;
              margin-bottom: 0.61rem!important;
            }
          }
          .but{
            position: relative!important;
            padding: 0.8rem 3rem!important;
            right: 0!important;
            bottom: 0!important;
            font-size: 1rem!important;
            margin-top: 0.625rem!important;
          }

        }
      }
    }


    .ddb{
      .hang{
        margin: 0.625rem 0rem;
        .bt{
          width: 35%;
          padding-left: 0.3125rem;
        }
      }
    }
    .el-radio-group{
      width: 75%;
    }
    .el-dialog__body{
      width: 100%!important;
      padding: 1.75rem 0!important;
      margin: 0 auto!important;
    }
    .dban{
      margin-top: 1.175rem!important;
    }
    .anniu{
      font-size: 0.95rem!important;
      padding: 1.3rem 6rem!important;
    }

    .ddb{
      .top{
        font-size: 1rem;
        padding: 0.8rem;
      }
    }

  }





</style>
