<template>

  <div class="all">

    <div class="a-cen">
      <div class="center">
        <div class="head">
          <!-- <img class="oneimg" @click="goTo('/')" src="~assets/login/logo1.png" /> -->

          <img class="oneimg" @click="goTo1('/')" :src="
                  'https://ymedicine.jiujingwulian.com/public' +
                  shezhi.logotop
                " />
          <img class="twoimg" @click="goTo('/')" src="~assets/login/logo2.png" />
          <img class="threeimg" src="~assets/login/erji.png">
          <div class="rx">服务热线:<span class="dh">{{shezhi.phone}}</span></div>

        </div>
      </div>
    </div>
    <div class="ccen">
      <img class="sbb" src="~assets/login/bjt.png" />
      <div class="zhongjian">
        <div class="z-left">
          <img src="~assets/login/banner.png" />
        </div>
        <div class="z-right">
          <div class="z-r-title" v-show="zt==0">欢迎登录CBIITA生物医药产业链创新转化联合体！</div>
          <div class="zhengti" v-show="zt==0">
            <div class="lh">
              <div class="zha" @click="qh(0)" :class="v==0?'bs':''">账号密码登录</div>
              <div class="zha" @click="qh(1)" :class="v==1?'bs':''">手机验证码登录</div>
            </div>
            <div class="yihang" v-show="v==0">
              <span class="ts">账号</span>
              <input placeholder="请输入账号!" v-model="account" />
            </div>
            <div class="yihang" v-show="v==0">
              <span class="ts">密码</span>
              <input type="password" placeholder="请输入密码!" v-model="password" />
            </div>

            <div class="yihang" v-show="v==1">
              <span class="ts">手机号</span>
              <input placeholder="请输入手机号!" v-model="phone" />
            </div>
            <div class="yihang" v-show="v==1">
              <span class="ts">验证码</span>
              <input type="password" placeholder="请输入验证码!" v-model="dlyzm" />
              <el-button class="yzm" v-show="yzm1show" @click="qddsq(1)" type="primary">获取验证码</el-button>
              <el-button class="yzm" v-show="!yzm1show" type="primary">{{yzm1}}秒后重新获取</el-button>
            </div>

            <div class="lianghang">
              <!-- <input type="checkbox" /><span>七天免登录</span> -->
              <el-checkbox v-model="checked1" label="七天免登录" size="large" />
              <div class="wjmm" @click="wjmm()">忘记密码</div>
            </div>
            <div class="dl" @click="denglu()">登录</div>
            <div class="qtdl sbbaa">
              <div>其他登录方式</div><span class="bs1">|</span>
              <div @click="saoma()"><img src="~assets/my/wx.png" /><span>微信扫描登录</span></div>

            </div>
            <div class="qtdl db">
              <div class="q-one">没有账号？</div><span class="bs1">|</span>
              <div @click="mfzc()">免费注册</div><span class="bs1">|</span>
              <div class="dwss" @mouseover="kefu=true" @mouseout="kefu=false">
                <span>客服咨询</span>
                <img class="kefu" v-show="kefu" :src="'https://ymedicine.jiujingwulian.com/public'+shezhi.kimage" />
              </div>
            </div>

          </div>

          <!-- //忘记密码 -->
          <div class="z-r-title" v-show="zt==1">忘记密码！</div>
          <div class="zhengti" v-show="zt==1">
            <div class="fh" @click="fhdl()">
              <img src="../../assets/home/fh.png" />
              返回登录
            </div>
            <div class="yihang">
              <span>手机号</span>
              <input placeholder="请输入手机号!" v-model="phone1" />
            </div>
            <div class="yihang">
              <span>验证码</span>
              <input placeholder="请输入验证码!" v-model="yzm" />
              <!-- <el-button class="yzm" type="primary">获取验证码</el-button> -->

              <el-button class="yzm" v-show="yzm2show" @click="qddsq(2)" type="primary">获取验证码</el-button>
              <el-button class="yzm" v-show="!yzm2show" type="primary">{{yzm2}}秒后重新获取</el-button>


            </div>
            <div class="yihang">
              <span>新密码</span>
              <input type="password" placeholder="请输入新密码!" v-model="newpsw" />
            </div>
            <div class="yihang">
              <span>确认密码</span>
              <input type="password" placeholder="请输入确认密码!" v-model="newpsw1" />
            </div>
            <div class="yihang ann">
              <el-button class="wd" type="primary" @click="xgmima">保存</el-button>
            </div>


          </div>

          <!-- //免费注册 -->

          <div class="z-r-title" v-show="zt==2">欢迎注册医药联合体！</div>
          <div class="zhengti" v-show="zt==2">
           <!--   <div class="fh" @click="fhdl()">
                <img src="../../assets/home/fh.png" />
                返回登录
              </div> -->
             <!-- <div class="yihang wu">
                <span>昵称</span>
                <input placeholder="请输入昵称!" v-model="inickname" />
              </div> -->
            <div class="yihang ">
              <span>手机号</span>
              <input placeholder="请输入手机号!" v-model="iphone" />
            </div>
            <div class="yihang ">
              <span>验证码</span>
              <input placeholder="请输入验证码!" v-model="iyzm" />
              <!-- <el-button class="yzm pp" type="primary">获取验证码</el-button> -->
              <el-button class="yzm " v-show="yzm3show" @click="qddsq(3)" type="primary">获取验证码</el-button>
              <el-button class="yzm " v-show="!yzm3show" type="primary">{{yzm3}}秒后重新获取</el-button>
            </div>
           <!-- <div class="yihang wu">
              <span>账号</span>
              <input placeholder="请输入              <span>账号</span>!" v-model="iaccount" />
            </div>
            <div class="yihang wu">
              <span>密码</span>
              <input type="password" placeholder="请输入密码!" v-model="ipassword" />
            </div> -->
           <!-- <div class="yihang wu ann">
              <elxz-button class="wd" type="primary" @click="tjzh()">保存</el-button>
            </div> -->

            <div class="lianghang">
              <!-- <el-checkbox type="checkbox" v-model="checked2"   size="large"   label="我已阅读并同意《服务协议》及《隐私条款》" /> -->
              <el-checkbox type="checkbox" v-model="checked2"   size="large" label="我已阅读并同意"  />
              <span @click="xieyi">《服务协议》</span>及<span @click="yingsi">《隐私条款》</span>
            </div>
            <div class="dl" @click="tjzh()">注册</div>
            <div class="qtdl sbbaa">
             <div>其他登录方式</div><span class="bs1">|</span>
             <div @click="saoma()"><img src="~assets/my/wx.png" /><span>微信扫描登录</span></div>

              </div>
            <div class="qtdl db">
              <div class="q-one">已有账号？</div><span class="bs1">|</span>
              <div @click="fhdl()">去登陆</div><span class="bs1">|</span>
            </div>



          </div>



          <div class="z-r-title" v-show="zt==3">欢迎登录医药联合体！</div>

          <div id="weixin" class="qrcode" v-show="ls==0" >
              <!-- 使用微信扫一扫登录 -->
          </div>
          <div class="zhengti" v-show="zt==3">

             <div class="qtdl db">
               <div class="q-one">已有账号？</div><span class="bs1">|</span>
               <div @click="fhdl()">去登陆</div><span class="bs1">|</span>
             </div>
          </div>




        </div>
      </div>
    </div>
    <!-- 微信扫描登录 -->
    <el-dialog class="zd" :show-close="false" align-center v-model="dialogTableVisible" title="">
      <template #header="{ close, titleId, titleClass }">
        <div class="qd-top">
         <div class="xian"></div>
          <div class="qd-bt">使用微信扫一扫登录</div>
          <div class="xian"></div>
         <!-- <div id="weixin" class="qrcode" v-show="!isShowForm">

          </div> -->

        </div>
      </template>


      <!-- <div class="ewm1">
        <img src="../../assets/my/ewm.png" />
        <div class="e-bt">请微信扫码进入移动端完成签到操作</div>
      </div> -->
    </el-dialog>

    <!-- 协议 -->
    <el-dialog
       v-model="xy"
       title="服务协议"
       width="80%"
       align-center>
       <div class="ewm">
        <div v-html="shezhi.xycontent" ></div>
       </div>
     </el-dialog>


    <!-- 微信扫描登录 -->
    <el-dialog
       v-model="ys"
       title="隐私协议"
       width="80%"
       align-center>
       <div class="ewm">
        <div v-html="shezhi.yscontent" ></div>
       </div>
     </el-dialog>
<!--
    <el-dialog class="zd" :show-close="false" align-center v-model="ys" title="123123">
      <template #header="{ close, titleId, titleClass }">
        <div class="qd-top">
         <div class="xian"></div>
          <div class="qd-bt">隐私条款</div>
          <div class="xian"></div>
          <div v-html="shezhi.yscontent" ></div>
        </div>
      </template>
    </el-dialog> -->


<!-- 《服务协议》</span>及<span @click="yingsi">《隐私条款》 -->


    <foot></foot>
    <left></left>
  </div>
</template>

<script>
  import {
    ref,
    getCurrentInstance,
    onMounted
  } from 'vue';


  import {
    xgpassword,
    getphoneUploadPas,
    getinUsers
  } from 'network/my.js';
  import {
    getHome,
    getbanner
  } from 'network/home.js';
  import {
    useRouter
  } from 'vue-router';
  import {
    getlogin
  } from 'network/login.js';

  import {
    ElMessage,
    ElMessageBox
  } from 'element-plus';
  import {
    Action
  } from 'element-plus';


  import {
    send,
    check
  } from 'network/send.js';

  import {
    setCookie,
    getCookie,
    clearCookie
  } from "components/common/common.js";
  import {
    getfshezhi
  } from 'network/home.js';



  import Head from "components/content/headder/Head.vue";
  import Foot from "components/content/footer/Foot.vue";
  import Left from "components/content/left/Left.vue";
  import Bread from "components/content/breadcrumb/Breadcrumb.vue";
  import Banner from "components/content/banner/Banner.vue";




  export default {
    name: 'New',
    data() {
      return {
        ls:0,
        xy:false,
        ys:false,

        kefu:false,
        shezhi:{},

        isShowForm:false,


        yzm1show: true,
        yzm1: 60,

        yzm2show: true,
        yzm2: 60,

        yzm3show: true,
        yzm3: 60,




        phone: '',
        dlyzm: '',
        dialogTableVisible: false,
        checked1: false,
        checked2: false,

        phone1: '',
        yzm: '',
        newpsw: '',
        newpsw1: '',
        v: 0,
        lx: 0,
        zt: 0,
        bn: 'public/img/banner.387b0333.png',
        account: '',
        password: '',
        all: [{
            name: '您当前的位置:首页',
            url: '/'
          },

          {
            name: '资讯',
            url: '/New'
          },
        ],
        inickname: '',
        iphone: '',
        iyzm: '',
        yhq:'',//优惠券
        iaccount: '',
        ipassword: '',

      }
    },
    setup() {
      const top = 2;
      const router = useRouter();
      const databa = ref([]);
      const ab = 'sb';
      console.log(ab);
      onMounted(() => {

        getHome().then(res => {
          console.log(res.data.data);
          databa.value = res.data;
          console.log(databa);

        }).catch(err => {

        })

      })
      //跳转方法
      const goTo = (path, query) => {
        router.push({
          path: path,
          query: query || {}
        })
      }

      return {
        databa,
        ab,
        goTo,
        top
      }
    },
    mounted() {
      // setCookie(2);
      // var url = decodeURI(window.location.search); //?id="123456"&name="www";
      // var object = {};
      // if (url.indexOf("?") != -1) //url中存在问号，也就说有参数。
      // {
      //   var str = url.substr(1); //得到?后面的字符串
      //   var strs = str.split("&"); //将得到的参数分隔成数组[id="123456",name="www"];
      //   for (var i = 0; i < strs.length; i++) {
      //     object[strs[i].split("=")[0]] = strs[i].split("=")[1]; //得到{id:'123456',name:'www'}
      //   }
      // }
      // console.log(object);
      var zt = this.GetUrlParam('zt');
      // var zt = object['zt'];
      if (zt) {
        this.zt = zt;
      }
      
      // this.yhq = object['yhq'];
      this.yhq =  this.GetUrlParam('yhq');

      var cs = {
        i: 0
      };
      var that = this;
      getbanner(cs).then(res => {
        that.banner = res.data;

      }).catch(err => {

      })
      this.yanzheng('18118499668', '5641');

      // getCookie
      window.addEventListener("keydown", this.KeyDown, true);

      	this.getfl();

        // let _this = this;
        // let obj = new WxLogin({
        //    self_redirect: false,
        //    id: 'weixin',
        //    appid: "wxdf42d3604b518081",
        //    scope: 'snsapi_login',
        //    redirect_uri: 'http://cbiita.com/', // 这里跳的是扫码成功后,账户操作的地址
        //  })
        //  this.isShowForm=false;

        //  console.log(obj);


    },

    methods: {
      GetUrlParam(paraName) {
      var url = window.location.toString();
      var arrObj = url.split("?");
      if (arrObj.length > 1) {
        var arrPara = arrObj[1].split("&");
        var arr;
        for (var i = 0; i < arrPara.length; i++) {
          arr = arrPara[i].split("=");
          if (arr != null && arr[0] == paraName) {
            return arr[1];
          }
        }
        return "";
      } else {
        return "";
      }
    },
      getfl(){
        var that = this;
        var data = {

        };
        getfshezhi(data).then(res => {
          // console.log('zd');
          // console.log(res);
          that.shezhi=res.data.data;
        }).catch(err => {})
      },

      KeyDown(e){
        console.log(e.key,e.keyCode)
        //用过这个方法打印出键盘的key和keyCode
        //然后根据条件进行相应的操作即可

        if(e.key==='Enter'|| e.keyCode===13){
          console.log(this.zt);

          // return false;
          if(this.zt==0){
            this.denglu();
               console.log('[[bb]]');
            return false;
          }else if(this.zt==1){
            this.xgmima();
               console.log('[[cc]]');
            return false;
          }else if(this.zt==2){
            this.tjzh();
               console.log('[[ww]]');
            return false;
          }

        }
      },

      qddsq(type) {
        this.gbdsq();
        if (type == 1) {

          if (!this.phone) {
            ElMessage({
              type: 'info',
              message: '请输入手机号',
            })
            return false;
          }
          const reg = /^[1][1,2,3,4,5,6,7,8,9][0-9]{9}$/;
          if ((!reg.test(this.phone)) && this.phone != '') {
            ElMessage({
              type: 'info',
              message: '请输入正确的手机号',
            })
            return false;
          }


          this.yzm1show = false;
          this.fasong(this.phone);
        }
        if (type == 2) {

          if (!this.phone1) {
            ElMessage({
              type: 'info',
              message: '请输入手机号',
            })
            return false;
          }
          const reg = /^[1][1,2,3,4,5,6,7,8,9][0-9]{9}$/;
          if ((!reg.test(this.phone1)) && this.phone1 != '') {
            ElMessage({
              type: 'info',
              message: '请输入正确的手机号',
            })
            return false;
          }




          this.yzm2show = false;
          this.fasong(this.phone1);
        }
        if (type == 3) {

          if (!this.iphone) {
            ElMessage({
              type: 'info',
              message: '请输入手机号',
            })
            return false;
          }
          const reg = /^[1][1,2,3,4,5,6,7,8,9][0-9]{9}$/;
          if ((!reg.test(this.iphone)) && this.iphone != '') {
            ElMessage({
              type: 'info',
              message: '请输入正确的手机号',
            })
            return false;
          }


          this.yzm3show = false;
          this.fasong(this.iphone);
        }
        this.timeInter = setInterval(() => {
          if (type == 1) {
            this.kqsj1();
          }
          if (type == 2) {
            this.kqsj2();
          }
          if (type == 3) {
            this.kqsj3();
          }
        }, 1000)


      },
      fasong(phone) {

        var data = {
          mobile: phone,
        };
        send(data).then(res => {
          console.log(res);
        }).catch(err => {})

      },
      yanzheng(phone, captcha) {
        var data = {
          mobile: phone,
          captcha: captcha,
        };
        check(data).then(res => {
          console.log(res);
          if (res.msg == '验证码不正确') {
            console.log('cwcwcw');
          } else {
            console.log('zqzq');
          }
        }).catch(err => {})
      },



      kqsj1() {
        this.yzm1 = this.yzm1 - 1;
        if (this.yzm1 <= 0) {
          this.gbdsq();

        }
      },
      kqsj2() {
        this.yzm2 = this.yzm2 - 1;
        if (this.yzm2 <= 0) {
          this.gbdsq();
        }
      },
      kqsj3() {
        this.yzm3 = this.yzm3 - 1;
        if (this.yzm3 <= 0) {
          this.gbdsq();
        }
      },
      gbdsq() {
        clearInterval(this.timeInter); //关闭
        this.yzm1show = true;
        this.yzm1 = 60;
        this.yzm2show = true;
        this.yzm2 = 60;
        this.yzm3show = true;
        this.yzm3 = 60;

      },

      tjzh() {



        if (!this.checked2) {
          ElMessage({
            type: 'info',
            message: '请勾选《服务协议》及《隐私条款》！',
          })
          return false;
        }

        if (!this.inickname) {
          ElMessage({
            type: 'info',
            message: '请输入昵称',
          })
          return false;
        }
        if (!this.iphone) {
          ElMessage({
            type: 'info',
            message: '请输入手机号',
          })
          return false;
        }
        const reg = /^[1][1,2,3,4,5,6,7,8,9][0-9]{9}$/;
        if ((!reg.test(this.iphone)) && this.iphone != '') {
          ElMessage({
            type: 'info',
            message: '请输入正确的手机号',
          })
          return false;
        }
        if (!this.iyzm) {
          ElMessage({
            type: 'info',
            message: '请输入验证码',
          })
          return false;
        }
        if (!this.iaccount) {
          ElMessage({
            type: 'info',
            message: '请输入账号',
          })
          return false;
        }
        if (!this.ipassword) {
          ElMessage({
            type: 'info',
            message: '请输入密码',
          })
          return false;
        }

        ElMessageBox.confirm(
            '是否确认提交?',
            '提示', {
              confirmButtonText: '确认',
              cancelButtonText: '取消',
              type: '提示',
            }
          )
          .then(() => {
            // ElMessage({
            //   type: 'success',
            //   message: '提交成功',
            // })

            var data1s = {
              mobile: this.phone1,
              captcha: this.yzm,
            };



            check(data1s).then(res1 => {
              console.log(res1);
              if (res1.data.msg == '验证码不正确') {
                ElMessage({
                  type: 'info',
                  message: '验证码不正确',
                })
                return false;
              } else {


                this.iusers();

              }

            }).catch(err => {})

          })
          .catch(() => {
            ElMessage({
              type: 'info',
              message: '取消成功',
            })
          })



      },
      iusers() {
        var data = {
          inickname: this.inickname,
          iphone: this.iphone,
          iyzm: this.iyzm,
          iaccount: this.iaccount,
          ipassword: this.ipassword,
        };
        getinUsers(data).then(res => {
          console.log(res);
          if (res.data.res == 0) {
            // alert('用户名不存在！');
            ElMessage({
              type: 'info',
              message: '手机号已存在,或验证码错误!',
            })

            return false;
          } else if (res.data.res == -1) {
            ElMessage({
              type: 'info',
              message: '账号或者密码已存在!',
            })

            return false;
          } else {
            ElMessage({
              type: 'success',
              message: '登录成功',
            })
            this.zt = 0;
          }
        }).catch(err => {})

      },
      wjmm() {
        this.zt = 1;
      },
      mfzc() {
        this.zt = 2;
      },
      xieyi(){
        this.xy = true;
      },
      yingsi(){
        this.ys = true;
      },
      saoma() {
         this.zt = 3;
         this.ls=0;
         console.log('11');
         // // 微信登录第一步：申请微信登录二维码
         let _this = this;
         let obj = new WxLogin({
            href:'https://ymedicine.jiujingwulian.com/public/css/cs.css',
            self_redirect: false,
            id: 'weixin',
            appid: "wxdf42d3604b518081",
            scope: 'snsapi_login',
            redirect_uri: 'http://cbiita.com/', // 这里跳的是扫码成功后,账户操作的地址
          })
          // this.isShowForm=false;
          console.log(obj);


      },
      xgmima() {
        // this.uppsw();
        // return false;
        if (!this.phone1) {
          ElMessage({
            type: 'info',
            message: '请输入手机号',
          })
          return false;
        }
        if (!this.yzm) {
          ElMessage({
            type: 'info',
            message: '请输入验证码',
          })
          return false;
        }
        const reg = /^[1][1,2,3,4,5,6,7,8,9][0-9]{9}$/;
        if ((!reg.test(this.phone1)) && this.phone1 != '') {
          ElMessage({
            type: 'info',
            message: '请输入正确的手机号',
          })
          return false;
        }
        // if (!this.gsname) {
        //   ElMessage({
        //     type: 'info',
        //     message: '请输入公司/单位名称',
        //   })
        //   return false;
        // }

        if (this.newpsw != this.newpsw1) {
          ElMessage({
            type: 'info',
            message: '两次手机号输入不一致!',
          })
          return false;
        }


        ElMessageBox.confirm(
            '是否确认提交?',
            '提示', {
              confirmButtonText: '确认',
              cancelButtonText: '取消',
              type: '提示',
            }
          )
          .then(() => {
            // ElMessage({
            //   type: 'success',
            //   message: '提交成功',
            // })

            var data1s = {
              mobile: this.phone1,
              captcha: this.yzm,
            };



            check(data1s).then(res1 => {
              console.log(res1);
              if (res1.data.msg == '验证码不正确') {
                ElMessage({
                  type: 'info',
                  message: '验证码不正确',
                })
                return false;
              } else {


                this.uppsw();
              }

            }).catch(err => {})
          })
          .catch(() => {
            ElMessage({
              type: 'info',
              message: '取消成功',
            })
          })



      },
      uppsw() {

        var data = {
          phone: this.phone1,
          yzm: this.yzm,
          newpsw: this.newpsw
        };
        getphoneUploadPas(data).then(res => {
          console.log(res);
          if (!res.data.res) {
            // alert('用户名不存在！');
            ElMessage({
              type: 'info',
              message: '用户不存在!',
            })

            return false;
          } else {
            ElMessage({
              type: 'success',
              message: '修改成功',
            })
            this.zt = 0;
          }
        }).catch(err => {})


      },
      fhdl() {
        this.zt = 0;
        this.ls=1;
      },
      qh(ps) {
        this.v = ps;
      },
      denglu() {



        var that = this;
        var lx = this.lx;
        console.log(lx);
        var v = this.v;

        if (v == 0) {
          if (!this.account) {
            // alert('请输入账号！');
            ElMessage({
              type: 'info',
              message: '1请输入账号 !',
            })

            return false;

          }
          if (!this.password) {
            // alert('请输入密码！');
            ElMessage({
              type: 'info',
              message: '请输入密码 !',
            })
            return false;
          }
          var data = {
            account: this.account,
            password: this.password,
            type: lx
          };

          const router = useRouter();

          getlogin(data).then(res => {
            console.log(res);
            if (!res.data.data) {
              // alert('用户名或密码错误！');
              ElMessage({
                type: 'info',
                message: '用户名或密码错误',
              })
              return false;
            } else {
              console.log('登录成功');
              sessionStorage.setItem("uid", res.data.data.id);

              console.log(res.data.data.id);
              if(that.checked1==true){
                setCookie(res.data.data.id,7);
              }else{
                setCookie(res.data.data.id);
              }


              if(this.yhq>0){
                this.$router.push('/myCoupon?id='+this.yhq);
              }else{                
                this.$router.push('/')
              }
              

              // router.push({
              //   path:'/',
              //   query:{}
              // })
              // console.log('登录成功1s');
            }
          }).catch(err => {})





        } else {
          if (!this.phone) {
            // alert('请输入账号！');
            ElMessage({
              type: 'info',
              message: '请输入手机号',
            })
            return false;
          }
          if (!this.dlyzm) {
            // alert('请输入密码！');
            ElMessage({
              type: 'info',
              message: '请输入验证码',
            })
            return false;
          }
          var data = {
            phone: this.phone,
            dlyzm: this.dlyzm,
            type: lx
          };


          var data1s = {
            mobile: this.phone,
            captcha: this.dlyzm,
          };

          const router = useRouter();

          check(data1s).then(res1 => {
            console.log(res1);
            if (res1.data.msg == '验证码不正确') {
              ElMessage({
                type: 'info',
                message: '验证码不正确',
              })
              return false;
            } else {

              getlogin(data).then(res => {
                console.log(res);
                if (!res.data.data) {
                  // alert('用户名或密码错误！');
                  ElMessage({
                    type: 'info',
                    message: '手机号或者验证码错误',
                  })
                  return false;
                } else {
                  console.log('登录成功');
                  sessionStorage.setItem("uid", res.data.data.id);

                  if(that.checked1==true){
                    setCookie(res.data.data.id,7);
                  }else{
                    setCookie(res.data.data.id);
                  }

                  this.$router.push('/')

                  // router.push({
                  //   path:'/',
                  //   query:{}
                  // })
                  // console.log('登录成功1s');
                }
              }).catch(err => {})


            }
          }).catch(err => {})

        }
      }

    },
    components: {
      Head,
      Foot,
      Left,
      Bread,
      Banner
    },
  }
</script>
<style lang="scss" scoped="scoped">
  .head {
    // width: ;
    display: flex;
    align-items: center;

    .oneimg {
      width: 20.25rem;
      cursor: pointer;
    }

    .twoimg {
      width: 10.25rem;
      margin-left: 1.875rem;
      cursor: pointer;
    }

    .threeimg {
      width: 1.6rem;
      margin-right: 0.625rem;
      margin-left: 28rem;
    }

    .rx {
      span {
        font-weight: bold;
        margin-left: 0.625rem;
      }
    }
  }

  .a-cen {
    width: 100vw;
    background: #fff;
    padding: 1rem 0;

    .center {
      width: var(--allWidth);
      margin: 0 auto;
    }
  }

  .ccen {
    width: 100vw;
    position: relative;

    .zhongjian {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: flex;


      .z-left {
        img {
          width: 45rem;
          height:100%;
        }
      }

      .z-right {
        width: 28rem;
        padding: 1.625rem;
        background: #fff;
        box-shadow: 0px 4px 40px rgb(216, 219, 233);

        position: relative;

        .bs1 {
          color: #D9D9D9;
        }

        .db {
          position: absolute;
          bottom: 1.025rem;
          left: 50%;
          transform: translate(-50%, 0);

          color: #318FC6;
          font-size: 0.8125rem;

          .q-one {
            color: #808080;
          }
        }

        .z-r-title {
          margin-top: 1.875rem;
          margin-left: 1.39375rem;
          font-size: 1.8rem;
          letter-spacing: 0.3rem;
          color: #318FC6;
          font-weight: bold;
        }

        .zhengti {
          .fh {
            cursor: pointer;
            margin-top: 1.875rem;
            margin-left: 1rem;

            img {
              width: 1.075rem;
            }
          }

          .lh {
            display: flex;
            justify-content: space-evenly;
            margin: 0 0.625rem;
            margin-top: 2.875rem;
            text-align: center;

            .zha {
              width: 34%;
              padding-bottom: 0.925rem;
              font-size: 1.125rem;
              cursor: pointer;
            }

            .bs {
              font-weight: bold;
              border-bottom: 0.2625rem solid #318FC6;
            }
          }

          .wu {
            input {
              padding: 0.3125rem !important;
            }
          }

          .yihang {
            width: 94%;
            margin: 0 auto;
            margin-top: 1.875rem;
            position: relative;

            span {
              font-size: 0.8rem;
              // margin-right: 0.925rem;
              width: 18%;
              display: inline-block;
              text-align: right;
              margin-right: 4%;

            }

            .yzm {
              position: absolute;
              padding: 1.3125rem 0.75rem;
              top: 0;
              right: 4%;
            }

            .pp {
              position: absolute;
              padding: 0 0.75rem;
              top: 0;
              right: 4%;
            }

            .ts {
              font-size: 1.1rem;
              width: 17%;
            }

            input {
              padding: 0.8125rem;
              width: 75%;
              border: 0.0625rem solid #DDDDDD;
            }



          }

          .ann {
            text-align: center;

            .wd {
              width: 50%;
              margin: 0 auto;
              padding: 1.325rem;
              height: auto!important;
              background: #318fc6!important;
            }
          }

          .lianghang {
            width: 78%;
            margin: 0 auto;
            margin-top: 1.975rem;
            font-size: 0.875rem;

            input {
              vertical-align: middle;
              margin-right: 0.3125rem;
            }

            span {
              vertical-align: middle;
            }

            position: relative;

            .wjmm {
              position: absolute;
              color: #999999;
              right: 0;
              top: 50%;
              transform: translate(-30%, -50%);
              cursor: pointer;
            }
          }

          .dl {
            width: 78%;
            margin: 0 auto;
            margin-top: 1.975rem;
            text-align: center;
            color: #fff;
            font-size: 1.125rem;
            background: #318FC6;
            padding: 1.375rem 0;
            letter-spacing: 0.3125rem;
            border-radius: 0.125rem;
            cursor: pointer;
          }

          .qtdl {
            justify-content: space-evenly;
            width: 69%;
            margin: 0 auto;
            margin-top: 0.625rem;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            font-size: 0.78rem;

            div {
              cursor: pointer;
            }

            img {
              width: 1.075rem;
              margin-right: 0.22rem;
            }
          }

        }
      }
    }

    img {
      width: 100%;
    }
  }

  .center {}

  @media screen and (max-width: 1300px) {
    .head {
      display: flex;
      align-items: center;
      width: 95%;
      margin: 0 auto;
      flex-wrap: wrap;

      .oneimg {
        width: 64%;
      }

      .twoimg {
        width: 30%;
        margin-left: 3%;
      }

      .threeimg {
        margin-left: 1%;
        margin-top: 0.575rem;
        width: 6%;
      }

      .rx {
        margin-top: 0.575rem;
        font-size: 1rem;
      }
    }

    .center {
      width: 100%;
    }

    .ccen {

      // display: none;
      .sbb {
        display: none;
      }

      .zhongjian {
        position: relative;
        left: 0;
        top: 0;
        transform: translate(0%, 0%);

        display: flex;
        width: 95%;
        margin: 0 auto;
        flex-wrap: wrap;
        margin-top: 4px;

        .z-left {
          width: 100%;
          height:100%;
          img {
            width: 100%;
          }
        }

        .z-right {
          width: 100%;

          .z-r-title {
            margin: 0;
            margin-top: 0.6rem;
            font-size: 1.4rem;
            text-align: center;
          }

          .lh {
            margin-top: 1rem !important;

            .zha {
              width: 40% !important;
              font-size: 1rem !important;
            }
          }

          .yihang {
            margin-top: 1.2rem !important;

            .ts {
              font-size: 0.9rem !important;
            }
          }

          .lianghang {
            margin-top: 0.4rem !important;
            font-size: 0.6rem !important;
          }

          .el-checkbox__label {
            font-size: 0.6rem !important;
          }

        }

      }
    }

    .dl {
      margin-top: 0.475rem !important;
      padding: 0.675rem 0 !important;
      font-size: 1rem !important;
    }

    .sbbaa {
      margin-bottom: 2.5625rem !important;
    }

    .yzm {
      font-size: 0.6rem !important;
    }

    .ann {
      margin-bottom: 1.25rem !important;
    }

    .fh {
      margin-top: 0.9rem !important;
    }

  }
  .dwss{
    position: relative;
    .kefu{
       width: 12rem!important;
       position: absolute;
       top: -13%;
       left: 34%;
       transform: translate(-50%, -100%);
    }
  }
  .lianghang{
    display: flex;
    align-items: center;
    span{
      cursor: pointer;
      color:#318FC6;
    }
  }
  .normalPanel>.title{
    display: none;
  }
  .loginPanel .normalPanel{
    display: none;
  }
  .qrcode{
    text-align: center;
    margin-top: 1.025rem;
  }

</style>
