<template>
  <div class="r-bot">
    <div>
      {{pdshows}}
      <el-dialog
         v-model="pdshow1"
         title="支付确认"
         width="80%"
         align-center>
         <div class="ewm">
           <img class="tp" src="~assets/meeting/wechat.png" />
           <img class="eee" :src="ewms" />
           <div class="e-price">￥{{je}}</div>
           <div class="dbs">
             <img class="saosao" src="~assets/meeting/scan.png" />
             <div class="e-span">请使用微信扫一扫扫描二维码支付</div>
           </div>
         </div>
       </el-dialog>
    </div>



  </div>

</template>

<script>
  import {
    useRouter
  } from 'vue-router';

  export default {
    name: 'Pay',
   data() {
      return {
       pdshow1:true,
      }
    },
    props:['ewms','pdshows','je'],
    setup() {
      //跳转方法
      const router = useRouter();
      const goTo = (path, id) => {

        var query = {
          id: id
        }
        console.log(query);
        router.push({
          path: path,
          query: query || {}
        })
      }

      return {
        goTo
      }

    },
     mounted() {
       this.pdshow1=pdshows;

     },
    methods:{


    }
  }
</script>

<style lang="scss" scoped="scoped">

  .ewm{
    text-align: center;
    .tp{
      display: block;
      margin: 0 auto;
      margin-bottom: 1.625rem;
      width: 5.875rem;
    }
    .eee{
        min-width: 33%;
    }
    .e-price{
      color: red;
      font-size: 1.6rem;
      margin-top: 0.625rem;
    }
    .dbs{
      background: #00c800;
      margin-top: 0.875rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      padding: 0.75rem 0;
      .saosao{
        width: 1.6875rem;
        margin-right: 0.625rem;
      }
      .e-span{
          max-width: 60%;
          text-align: left;
      }
    }

  }


</style>
