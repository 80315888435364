<template>
  <div class="all">
    <Head :top="top"  :qhs='qh' :grxxff="grxxff"></Head>


    <Bread :all="all"></Bread>
      <!--  <div class="z-o">
          <div class="zo-top">
      </div>
    </div> -->
    <div class="zong">
      <div class="zo-cen">
        <div class="zoc-left">
          <div class="zocl-top ">关于联合体</div>
          <div class="zocl-cen" @click="qh(0)" :class="v==0?'bj':''">联合体简介</div>
          <div class="zocl-cen" @click="qh(1)" :class="v==1?'bj':''">领导简介</div>
          <div class="zocl-cen" @click="qh(2)" :class="v==2?'bj':''">组织架构</div>
          <!-- bs==1?'bs':'' -->

          <div class="zocl-foot" @click="bsa1(0)" :class="[v==2?'xs':'',bs==0?'bsx':'']">专业委员会</div>
          <div class="zocl-foot" @click="bsa1(1)" :class="[v==2?'xs':'',bs==1?'bsx':'']">工作委员会</div>
        </div>
        <div class="zoc-right" v-if="v==0">
          <div v-html="listin.gycontent"></div>

        </div>
        <div class="zoc-right ld" v-if="v==1">
           <div v-html="listin.ldcontent"></div>
        </div>

        <div class="zoc-right ld yca" v-if="v==2 && bs==2">
           <div v-html="listin.sjcontent"></div>
        </div>


        <div class="zoc-right ld aaaamb" v-if="v==2 && bs==2">
          <div class="top-bt">
            <div class="tbt">联合体架构组织图</div>
            <div class="tnr">Alliance Organization Chart</div>
          </div>
          <div class="hx">
            <div class="h-nr">
              <div class="h-t">资讯机构</div>
              <div class="h-b dj"  @click="bsa(one)">{{one.name}}</div>
            </div>
            <div class="xian"></div>
            <div class="h-nr">
              <div class="h-t">理事长</div>
              <div class="h-b dj"  @click="bsa(two)">{{two.name}}</div>
            </div>
            <div class="xian"></div>
            <div class="h-nr">
              <div class="h-t">监督机构</div>
              <div class="h-b dj"  @click="bsa(three)">{{three.name}}</div>
            </div>
          </div>

          <div class="jxi" style="overflow: hidden;">
            <div class="xian1 bs"></div>
            <div class="mishuchu bs">秘书处</div>
            <div class="xian1 bs"></div>
            <div class="sxian"></div>
            <div class="xian1"></div>
            <div class="mishuchu dj">秘书处</div>
            <div class="xian1"></div>
          </div>
          <div class="jb mobi">
          <!--  <div class="xian1" style="width: 4%;"></div>
            <div class="xian1" style="width: 0%;"></div> -->
            <div class="xian1" style="width: 20%;"></div>
            <div class="xian1 bs" style="width: 20%;"></div>
          </div>
          <div class="jb jbm">
            <!-- <div class="xian1zj bs"></div> -->
            <div class="shu"></div>
         <!--  <div class="xian1 bs" style="    width: 1rem;"></div>
            <div class="xian1 bs" style="    width: 2%;"></div> -->
            <div class="xian1 bs" style="width: 20%;"></div>
            <div class="xian1 bs" style="width: 20%;"></div>
            <!-- <div class="xian1zj "></div> -->
            <div class="shu bs"></div>
          </div>
          <div class="jb mini">
            <div class="cws bs" style="width: 2rem;"> </div>
            <div class="xian1 bs"  style="width: 17%;"></div>
            <div class="cws">常务理事</div>
            <div class="xian1 bs mobi"></div>
            <div class="xian1 bs mobi"></div>
            <div class="cws bs  mobi"> </div>
            <div class="xian1 bs"  style="width: 17%;"></div>
            <div class="cws bxs" style="width: 2rem;">
              <div class="dw" >
                会员单位
              </div>
            </div>
          </div>
          <div class="jb mini ">

            <div class="shu"></div>
            <!--  <div class="xian1 bs" style="    width: 1rem;"></div>
            <div class="xian1 bs" style="    width: 2%;"></div> -->
            <div class="xian1 bs mobi" style="width: 20%;"></div>
            <div class="xian1 bs mobi" style="width: 20%;"></div>
            <!-- <div class="xian1zj "></div> -->
            <div class="shu bs mobi"></div>


          <!--  <div class="shu bv"></div>
            <div class="xian1zj bs" style="    width: 4.5%;"></div>
            <div class="shu bv"></div> -->

          </div>
          <div class="jb mini kd">
            <div class="xian1 " style="width: 20%;"></div>
            <div class="xian1 " style="width: 20%;"></div>
            <div class="xian1 bs mobi" style="width: 20%;"></div>
            <div class="xian1 bs mobi" style="width: 20%;"></div>
          </div>

          <div class="jb mini kd" >

            <div class="shu"></div>
            <div class="xian1 bs" style="width: 20%;"></div>
            <div class="shu"></div>
            <div class="xian1 bs" style="width: 20%;"></div>


            <div class="xian1 mobi" style="    width: 10.3%;height: 6.00rem;"></div>
            <div class="shu"></div>
            <div class="xian1 bs mobi" style="width: 9%;"></div>
            <div class="xian1 bs mobi" style="width: 20%;"></div>
            <div class="shu bs mobi"></div>

          </div>

          <div class="jb dbsn  mobi tdi" style="    display: flex;
            justify-content: flex-start;
            margin-left: 1.5rem;">
                    <div class="fhz bb dj" style="    margin-right: 4rem;">副会长单位</div>
                    <div class="fhz zj dj" style="    margin-right: 4rem;">副理事长单位</div>
                    <div class="fhz ba dj" style="    margin-right: 4rem;width:24rem;" >专业委员会</div>
                    <div class="fhz  dj bs mobi"> </div>
                  </div>

          <div class="jb dbsn tdi mobi" style="    display: flex;
            justify-content: flex-start;
            margin-left: 1.5rem;
            }">
            <div class="fhz1 bb zd " style="    margin-right: 4rem;">
              <block v-for="(item,index) in four"  @click="bsa(item)">
                <div class="thlxxian"></div>
                <div class="thl">{{item.name}}</div>
              </block>
              <!-- <div class="thlxxian"></div>
              <div class="thl">thl</div>
              <div class="thlxxian"></div>
              <div class="thl">thl</div>
              <div class="thlxxian"></div>
              <div class="thl">thl</div> -->
            </div>
            <div class="fhz1 zj zd" style="    margin-right: 4rem;">
              <block v-for="(item,index) in five"  @click="bsa(item)">
                <div class="thlxxian"></div>
                <div class="thl">{{item.name}}</div>
              </block>
             <!-- <div class="thlxxian"></div>
              <div class="thl">thl</div>
              <div class="thlxxian"></div>
              <div class="thl">thl</div>
              <div class="thlxxian"></div>
              <div class="thl">thl</div>
              <div class="thlxxian"></div>
              <div class="thl">thl</div> -->

            </div>
            <div class="fhz1 zd " style="margin-right: 1.6%;width: 24rem;    ">
              <block v-for="(item,index) in six"  >
                <div class="thlxxian"></div>
                <div class="thl1 mosb" style="display: flex;justify-content: space-evenly;">

                  <div v-for="(li,index) in item.list" @click="bsain(li)" style="width:20%;" class="t-ab">{{li.name}}</div>

                </div>
              </block>
              <!-- <div class="thlxxian"></div>
              <div class="thl">thl</div>
              <div class="thlxxian"></div>
              <div class="thl">thl</div>
              <div class="thlxxian"></div>
              <div class="thl">thl</div>
              <div class="thlxxian"></div>
              <div class="thl">thl</div> -->
            </div>
            <div class="fhz1 zd jd mobi" >
              <div class="jhl" v-for="(item,index) in enty"  @click="bsa(item)">{{item.name}}</div>
          <!--    <div class="jhl">jhl</div>
              <div class="jhl">jhl</div>
              <div class="jhl">jhl</div>
              <div class="jhl">jhl</div> -->
            </div>

            <div class="fhz1 zd mobi" style="display:none">
              <block v-for="(item,index) in seven"  @click="bsa(item)">
                <div class="thlxxian"></div>
                <div class="thl">{{item.name}}</div>
              </block>
             <!-- <div class="thlxxian"></div>
              <div class="thl">thl</div>
              <div class="thlxxian"></div>
              <div class="thl">thl</div>
              <div class="thlxxian"></div>
              <div class="thl">thl</div>
              <div class="thlxxian"></div>
              <div class="thl">thl</div> -->
            </div>
          </div>

          <div class="jb jbmz dbsn ygdh" style="display: none;">
            <div class="nr">
              <!--             <div class="thlxxian"></div> -->
              <div class="thl">会员代表大会</div>

            </div>
            <div class="xians"></div>
          </div>
          <div class="shude"></div>



        </div>
        <!-- six -->
        <!-- seven -->
        <div class="zoc-right" v-if="v==2 && bs==0">
          <div class="z-bt" @click="fh1()"><img src="~assets/about/fh.png" />专业委员会列表</div>
          <div class="z-nrk">
            <div class="z-n-one" v-for="item in six1" @click="bsain(item)">
              <div class="zno-left">
                <img :src="'https://ymedicine.jiujingwulian.com/public'+item.fimage" />
              </div>
              <div class="zno-right">
                <div class="znor-title">{{item.name}}</div>
                <div class="znor-content">
                  {{item.jianjie}}
                </div>
              </div>
            </div>

            <!-- div class="z-n-one">
              <div class="zno-left">
                <img src="~assets/about/fmt.png" />
              </div>
              <div class="zno-right">
                <div class="znor-title">公司名称</div>
                <div class="znor-content">
                  公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介介公司简介
                </div>
              </div>
            </div>

            <div class="z-n-one">
              <div class="zno-left">
                <img src="~assets/about/fmt.png" />
              </div>
              <div class="zno-right">
                <div class="znor-title">公司名称</div>
                <div class="znor-content">
                  公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介介公司简介
                </div>
              </div>
            </div>


            <div class="z-n-one">
              <div class="zno-left">
                <img src="~assets/about/fmt.png" />
              </div>
              <div class="zno-right">
                <div class="znor-title">公司名称</div>
                <div class="znor-content">
                  公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介介公司简介
                </div>
              </div>
            </div>

            <div class="z-n-one">
              <div class="zno-left">
                <img src="~assets/about/fmt.png" />
              </div>
              <div class="zno-right">
                <div class="znor-title">公司名称</div>
                <div class="znor-content">
                  公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介介公司简介
                </div>
              </div>
            </div> -->

          </div>
        </div>

        <div class="zoc-right" v-if="v==2 && bs==1">
          <div class="z-bt" @click="fh1()"><img src="~assets/about/fh.png" />工作委员会</div>
          <div class="z-nrk">
            <div class="z-n-one" v-for="item in seven" @click="bsa(item)">
              <div class="zno-left">
                <img :src="'https://ymedicine.jiujingwulian.com/public'+item.fimage" />
              </div>
              <div class="zno-right">
                <div class="znor-title">{{item.name}}</div>
                <div class="znor-content">
                  {{item.jianjie}}
                </div>
              </div>
            </div>

            <!-- <div class="z-n-one">
              <div class="zno-left">
                <img src="~assets/about/fmt.png" />
              </div>
              <div class="zno-right">
                <div class="znor-title">公司名称</div>
                <div class="znor-content">
                  公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介介公司简介
                </div>
              </div>
            </div>

            <div class="z-n-one">
              <div class="zno-left">
                <img src="~assets/about/fmt.png" />
              </div>
              <div class="zno-right">
                <div class="znor-title">公司名称</div>
                <div class="znor-content">
                  公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介介公司简介
                </div>
              </div>
            </div>


            <div class="z-n-one">
              <div class="zno-left">
                <img src="~assets/about/fmt.png" />
              </div>
              <div class="zno-right">
                <div class="znor-title">公司名称</div>
                <div class="znor-content">
                  公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介介公司简介
                </div>
              </div>
            </div>

            <div class="z-n-one">
              <div class="zno-left">
                <img src="~assets/about/fmt.png" />
              </div>
              <div class="zno-right">
                <div class="znor-title">公司名称</div>
                <div class="znor-content">
                  公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介介公司简介
                </div>
              </div>
            </div> -->

          </div>
        </div>

        <div class="zoc-right" v-if="v==2 && bs==3">
          <div class="z-bt" @click="fanhui()"><img  src="~assets/about/fh.png" />{{name}}</div>

          <div class="z-nrk">
            <div class="biaoti">{{bbsa.name}}</div>
            <div class="jianjie" v-html="bbsa.content"></div>
            <!-- 以大力提升细胞治疗药物技术及产品为目标，以推动细胞与基因产业实现高质量发展为宗旨，构建和培育促进该领域发展的“政、产、学、研、用资”产业生态圈，协调政府对技术行业的监管，促进行业自律健康发展，注重行业技术标准制定，鼓励、协同细胞与基因领域共性关键技术创新，为行业人员提供技术创新及服务平台，实现资源共享和互利共赢。 -->
            <div class="grjj">
              <img class="bj bja" src="../../assets/about/bj1.png" />
              <div class="nr">
                <img class="tx" :src="'https://ymedicine.jiujingwulian.com/public'+bbsa.headimage" />
                <div class="right">
                  <div class="r-title">{{bbsa.lname}}</div>
                  <div class="r-jj">{{bbsa.lzhiwei}}</div>
                  <div class="nr-nr" v-html="ljcontent" ></div>
                  <!-- ·门罗生物联合创始人/董事长、SAPA中国候任主席<p/>
                   ·美国药学家学会会士(AAPS Fellow)，美中医药开发协会 (SAPA) 董事会董事/前总会主席 -->
                </div>

              </div>

            </div>

            <div class="xm">
              <div class="xm-title"><img src="../../assets/my/jt.png" />个人简介</div>
              <div class="xm-nr" v-html="bbsa.grcontent" ></div>
              <!-- 戴卫国博士于美国约翰霍普金斯大学毕业后拥有二十多年美国工业界(美国强生、安进》生物大分子药物及细胞治疗产品研发及产品上市经验。熟悉药物创新研究、工艺开发、生产、临床及注册申报的产业链，尤其在创新成果转化及产业代积累了丰富的实战经验。在专业期刊发表研究学术论文56篇。2012年当选为美国强生制药集团公司首届62名杨森院士之一.2013年当选为美国药学家学会会土 (AAPS Fellow) ，美国药学家学会工业药学与生物药剂学部(PPB) 主席 (2014-2015) 及美国AAPS FellOw评审委员会主席。目前是美中医药开发协会 (APA) 董事会董事，前总会主席 (2015-2016) 。荣获多项声誉奖项，包括2011年度强生集团总公司PhilipbHofmann杰出研究科学家奖。 -->

            </div>

            <div class="fuzhuren">
              <div class="fuzhuren-title">
                <img src="../../assets/about/jiantou.png" />
                <div class="f-t-bt">副主任委员</div>
              </div>
              <div class="f-bottom">

                <div class="f-b-one" v-for="item in bbsa.faboutin">

                  <img class="txs" :src="'https://ymedicine.jiujingwulian.com/public'+item.headimage" />
                  <div class="right">
                    <div class="r-title">{{item.name}}</div>
                    <div class="nr-nr" v-html="item.jscontent"></div>
                    <!-- ·门罗生物联合创始人/董事长<p/>
                    ·美国药学家学会会士 -->
                  </div>

                </div>
          <!--
                <div class="f-b-one">
                  <img class="txs" src="../../assets/about/tx.png" />
                  <div class="right">
                    <div class="r-title">戴卫国</div>
                    <div class="nr-nr">
                      ·门罗生物联合创始人/董事长<p/>
                      ·美国药学家学会会士
                    </div>
                  </div>

                </div>

                <div class="f-b-one">
                  <img class="txs" src="../../assets/about/tx.png" />
                  <div class="right">
                    <div class="r-title">戴卫国</div>
                    <div class="nr-nr">
                      ·门罗生物联合创始人/董事长<p/>
                      ·美国药学家学会会士
                    </div>
                  </div>

                </div> -->



              </div>
            </div>

            <div class="xbj" v-show="bbsa.gzcontent"></div>
            <div class="nr-gzjh" v-show="bbsa.gzcontent">
              <div class="nr-g-tou">
                <img src="../../assets/about/zuo.png"/>
                <span>工作计划</span>
                <img src="../../assets/about/you.png"/>
              </div>
              <div class="xq" v-html="bbsa.gzcontent" ></div>
              <!-- 打造“技术交流”和“成果转化”2个核心工作平台。举办各种类型的论坛、研讨会和展览会，打造精品论坛。依托平台自身“政、产、学、研、用、资”一体的优势，和临床P合作，聚焦细胞治疗药物领域技术成果临床转化，并且加强与投资基金合作，为有关单位提供技术评估、融资等关键朋务。 -->
            </div>
          </div>
        </div>


        <div class="zoc-right" v-if="v==2 && bs==4">
          <div class="z-bt" @click="fanhui()"><img  src="~assets/about/fh.png" />{{name}}</div>

          <div class="z-nrk">
            <div class="nr-nr" v-html="content" ></div>
          </div>
        </div>
      </div>
    </div>


    <foot></foot>
    <left></left>
  </div>
</template>

<script>
  import {
    ref,
    onMounted
  } from 'vue';
  import {
    getHome
  } from 'network/home.js';

  import {
    getfaboutshezhi,
    getfabout
  } from 'network/about.js';


  import {
    useRouter
  } from 'vue-router';


  import Head from "components/content/headder/Head.vue";
  import Foot from "components/content/footer/Foot.vue";
  import Left from "components/content/left/Left.vue";

  import Bread from "components/content/breadcrumb/Breadcrumb.vue";

  // import Aboutin from './ChildComps/Aboutin.vue';

  export default {
    name: 'HomeView',
    data() {
      return {
        bbsa:[],
        one:{},
        two:{},
        three:{},
        four:[],
        five:[],
        six:[],
        seven:[],
        enty:[],
        listin:{},
        // zq:0,
        // bs: 4,
        // v: 0,

        zq:2,
        bs: 2,
        v: 2,

        // //不要
        // bs: 3,
        // v: 2,

        name:'',
        content:'',
        ljcontent:'',

        // bs: 2,
        // v: 0,
        all: [{
            name: '您当前的位置:首页',
            url: '/'
          },
          {
            name: '关于联合体',
            url: '/about?i=1'
          },
          {
            name: '联合体简介',
            url: '/about?i=1'
          },
        ]
      }
    },
    // mounted() {
    //   console.log('sbc');
    // },
    setup() {
      const top = 2;
      const router = useRouter();
      const databa = ref([]);
      const ab = 'sb';
      console.log(ab);
      onMounted(() => {

        getHome().then(res => {
          console.log(res.data.data);
          databa.value = res.data;
          console.log(databa);

        }).catch(err => {

        })

      })
      //跳转方法
      const goTo = (path, query) => {
        router.push({
          path: path,
          query: query || {}
        })
      }

      return {
        databa,
        ab,
        goTo,
        top
      }
    },
     mounted() {
      //  var url = decodeURI(window.location.search); //?id="123456"&name="www";
      //  // var url = window.location.href;
      //  console.log(url);
      //  var object = {};
      //  if (url.indexOf("?") != -1) //url中存在问号，也就说有参数。
      //  {
      //    var str = url.substr(1); //得到?后面的字符串
      //    var strs = str.split("&"); //将得到的参数分隔成数组[id="123456",name="www"];
      //    for (var i = 0; i < strs.length; i++) {
      //      object[strs[i].split("=")[0]] = strs[i].split("=")[1]; //得到{id:'123456',name:'www'}
      //    }
      //  }
      //  console.log(object);
      //  var fl = object['fl'];
       var fl=this.GetUrlParam('fl');
       if(fl){
         this.v=fl;
         if(fl==2){
           this.zq=2;
            this.bs = 2;
         }
       }else{
         this.v=0;


       }



       // this.v=2;//不要
       // this.bs=3;//不要

       this.listdata();
       this.faboutdata();
       window.scrollTo( 0, 0 );
     },
    methods: {
      GetUrlParam(paraName) {
      var url = window.location.toString();
      var arrObj = url.split("?");
      if (arrObj.length > 1) {
        var arrPara = arrObj[1].split("&");
        var arr;
        for (var i = 0; i < arrPara.length; i++) {
          arr = arrPara[i].split("=");
          if (arr != null && arr[0] == paraName) {
            return arr[1];
          }
        }
        return "";
      } else {
        return "";
      }
    },
      grxxff(grxxs){
        this.grxx=grxxs;
        console.log(grxxs);
        console.log('zhende');
      },

      listdata(){
        var that = this;
        var data = {

        };
        getfaboutshezhi(data).then(res => {
          console.log(res);
          console.log('ymy');
          that.listin=res.data.data;
          console.log(that.listin);
        }).catch(err => {})
      },
      faboutdata(){
        var that = this;
        var data = {

        };
        getfabout(data).then(res => {
          console.log('fw');
          console.log(res);

          // console.log('1');
          // console.log(one);
          // console.log('2');
          that.one=res.data.data.one;
          that.two=res.data.data.two;
          that.three=res.data.data.three;

          that.four=res.data.data.four;
          that.five=res.data.data.five;
          that.six=res.data.data.six;
          that.six1=res.data.data.six1;

          that.bbsa=that.six1[0];


          that.seven=res.data.data.seven;
          that.enty=res.data.data.enty;

          // that.listin=res.data.data;
        }).catch(err => {})

      },

      fh1(){
        this.bs = 2;
        this.v = 2;
        this.zq=2;
      },
      qh(it) {
        console.log(it);
        this.bs = 2;
        this.v = it;
        this.zq=2;

      },
      bsa1(bbs){
        this.bs=bbs;
        this.zq=bbs;


      },
      fanhui(){
        this.bs=this.zq;
      },
      bsain(bbs){
        console.log(bbs);
        this.bs = 3;
        this.ljcontent=bbs.ljcontent;
        console.log(bbs.ljcontent);
        this.content=bbs.content;
        this.content=bbs.content;

        this.name=bbs.name;
        this.bbsa=bbs;
         window.scrollTo( 0, 100 );

      },
      bsa(bbs) {
        console.log(bbs);
        this.bs = 4;
        this.ljcontent=bbs.ljcontent;
        console.log(bbs.ljcontent);
        this.content=bbs.content;
        this.content=bbs.content;

        this.name=bbs.name;
        this.bbsa=bbs;
         window.scrollTo( 0, 100 );
      }
    },
    components: {
      Head,
      Foot,
      Left,
      Bread
      // Aboutin
    },
  }
</script>
<style lang="scss" scoped="scoped">


  .zong {
    width: 100vw;
    background: #F5F6FA;

    .zo-cen {
      width: var(--allWidth);
      margin: 0 auto;
      display: flex;

      .zoc-left {
        width: 14%;
        border-left: 0.125rem dotted #D7D7D7;


        .zocl-top {
          font-size: 1rem;
          font-weight: bold;
          padding: 1.625rem;
        }

        .zocl-cen {
          padding: 1.35rem;
          font-size: 0.9125rem;
          cursor: pointer;
        }

        .zocl-foot {
          display: none;
          width: 100%;
          padding: 0.925rem 1.3rem;
          background: #fff;
          font-size: 0.8125rem;
          border-bottom: 0.0625rem dotted #EEEEEE;
          cursor: pointer;
        }

        .xs {
          display: block;
        }

        .bsx {
          color: #2E9BD6;
        }

        .bj {
          background: url(assets/about/fw.png) no-repeat;
          background-size: 100% 100%;
          color: #fff;
        }

      }

      .zoc-right {
        width: 86%;
        padding: 1.875rem;
        background: #fff;
        margin: 1.875rem;
        position: relative;
        min-height: 50rem;

        //列表
        .z-bt {
          border-bottom: 0.0625rem solid #DBDBDB;
          padding-bottom: 1.875rem;
          font-weight: bold;
          font-size: 1.2rem;
          img{
            width: 1.075rem;
            margin-right: 0.625rem;
          }
        }

        .z-nrk {
          padding: 1rem;

          .z-n-one {
            padding-bottom: 1rem;
            border-bottom: 0.0625rem solid #DBDBDB;
            display: flex;
            align-items: center;
            margin-bottom: 1rem;
            cursor: pointer;
            .zno-left {
              margin-right: 1.875rem;

              img {
                width: 8.875rem;
              }
            }

            .zno-right {
              .znor-title {
                font-size: 1.075rem;
                font-weight: bold;
                margin-bottom: 1.075rem;
              }

              .znor-content {
                font-size: 0.8rem;
                color: #888888;
              }
            }
          }

        }

        //列表结束
        .shude {
          position: absolute;
          top: 17.875rem;
          right: 4.875rem;
          height: 8.65rem;
          border-left: 0.065rem dotted #D7D7D7;
          margin: 0 auto;
          width: 0.0625rem;
        }

        .top-bt {
          border-bottom: 0.0625rem solid #DBDBDB;
          padding-bottom: 1.875rem;
          text-align: center;

          .tbt {
            font-size: 1.875rem;
            font-weight: bold;
          }

          .tnr {
            color: #9A9A9A;
            font-size: 0.8125rem;
            margin-top: .875rem;
          }
        }

        .hx {
          display: flex;
          align-items: center;
          justify-content: center;

          .h-nr {
            text-align: center;
            margin-top: 1.875rem;
            width: 16%;
            .h-t {
              font-size: 1.1375rem;
              font-weight: bold;
              margin-bottom: 0.925rem;

            }

            .h-b {
              padding: 0.8rem 1.25rem;
              border: 0.0625rem solid #AFC81D;
              background: #F1F9E9;

            }
          }

          .xian {
            width: 17%;
            height: 0.0625rem;
            border-bottom: 0.065rem dotted #BABABA;
            margin-top: 4rem;
          }

        }

        .jxi {
          display: flex;
          align-items: center;
          justify-content: center;

          .sxian {
            height: 6.25rem;
            width: 0.0625rem;
            border-left: 0.065rem dotted #BABABA;
            // margin-top: 4rem;
          }

          .xian1 {
            width: 17%;
            height: 6.25rem;
            border-bottom: 0.065rem dotted #BABABA;
            margin-top: -6rem;
          }

          .mishuchu {
            padding: 0.625rem 1.875rem;
            background: #F0F8FD;
            border: 0.125rem solid #2E9BD6;
            overflow: hidden;
            position: relative;
          }

          .bs {
            padding: 0.625rem 1.875rem;
            background: rgba(0, 0, 0, 0);
            border: 0.125rem solid rgba(0, 0, 0, 0);
            ;
            color: #FFFFFF;
          }

        }



        .jb {
          .cws {
            width: 9rem;
            text-align: center;
            background: #F1F9E9;
            padding: 0.75rem 0;
            border: 0.125rem solid #A8BF29;
          }
        }

        .dbsn {
          margin-left: 7.5rem;

          .fhz {
            background: #F0F8FD;
            border: 0.0625rem solid #2E9BD6;
            text-align: center;
            padding: 0.8125rem 0;
            width: 9rem;
            margin-right: 0.825rem;
          }

          .fhz1 {
            width: 9rem;
            margin-right: 0.825rem;
            padding: 0.8125rem 0;
            text-align: center;



            .thl1 {
              // margin-bottom: 0.625rem;
              padding: 0.5125rem 0.8rem;
              border: 0.0625rem solid #B3B3B3;
              font-size: 0.8125rem;


            }

            .thl {
              // margin-bottom: 0.625rem;
              padding: 0.5125rem 0.8rem;
              border: 0.0625rem solid #B3B3B3;
              font-size: 0.8125rem;


            }

            .thlxxian {
              height: 1.25rem;
              border-left: 0.065rem dotted #D7D7D7;
              margin: 0 auto;
              width: 0.0625rem;

            }
          }

          .jd {
            border: 0.125rem dotted #D7D7D7;
            width: 5rem;
            padding: 0.8125rem 0;
            font-size: 0.8125rem;

            .jhl {
              padding: 0.5125rem 0;
            }
          }

          // .zj{
          //   margin-right: 4.3rem;
          // }
        }

        .ygdh {
          margin-top: 1.875rem;

          .nr {
            font-size: 1.125rem;
            text-align: center;
            width: 9rem;
            border: 0.125rem solid #D7D7D7;
            padding: 1rem;

          }

          .xians {
            height: 0.0625rem;
            width: 70%;
            border-bottom: 0.065rem dotted #BABABA;
            width: 78.4%;
          }
        }

      }
    }
  }

  .zd {
    padding: 0 !important;
  }

  .bs {
    background: rgba(0, 0, 0, 0) !important;
    border: 0.125rem solid rgba(0, 0, 0, 0) !important;
    color: rgba(0, 0, 0, 0) !important;
  }

  .jb {
    display: flex;
    align-items: flex-start;
    // align-items: center;
    justify-content: center;
  }

  .jbm {}

  .xian1 {
    width: 13%;
    height: 6.25rem;
    border-bottom: 0.065rem dotted #BABABA;
    margin-top: -6rem;
  }

  .xian1ban {
    width: 6%;
    height: 6.25rem;
    border-bottom: 0.065rem dotted #BABABA;
    margin-top: -6rem;
  }

  .shu {
    height: 3.25rem;
    width: 0.0625rem;
    border-left: 0.065rem dotted #BABABA;
  }

  .jbmz {
    justify-content: left;
  }

  .bv {
    margin-left: 22.075rem;
  }

  .on {
    margin-right: 19.53%;
  }

  .jx {
    // margin: 0 10rem;
    // margin-right: 11.3rem;
    // margin-right: 13.63rem;
    // margin-left: 12.5rem;
    margin-right: 19.53%;
  }

  .th {}

  .zb {
    margin: 0;
  }

  .yb {
    margin: 0;
  }

  .bb {
    margin-right: 6rem;
  }

  .shsha {
    margin-left: 12rem;
  }

  .szaa {
    margin-left: 12rem;

    .aa1 {
      width: 12%;

    }

    .aa2 {
      width: 27.5%;
    }
  }

  .bsa {
    width: 27% !important;
  }

  .xian1zj {
    width: 10%;
    border-bottom: 0.065rem dotted #BABABA;
    height: 6.25rem;
    margin-top: -9.5rem;
  }

  .ba {
    border: 0.065rem solid #FFFFFF !important;
    background: linear-gradient(to top, #EF8C09, #FFCE96) !important;
    color: #fff;
    box-shadow: 0.1875rem 3px 5px rgba(0, 0, 0, 0.3);
  }

  .bsb {
    border: 0.065rem solid #FFFFFF !important;
    background: linear-gradient(to top, #1C81BD, #81D0FF) !important;
    color: #fff;
    box-shadow: 0.1875rem 3px 5px rgba(0, 0, 0, 0.3);
  }

  .dj {
    cursor: pointer;
  }
  .thl,.z-bt{
    cursor: pointer;
  }



  @media screen and (max-width: 1300px) {

    .yca{
      display: block!important;
    }
    .aaaamb{
      display: none!important;
    }



    .mosb{
          flex-wrap: wrap;
          flex-direction: column;
          padding: 0.5125rem 0.1rem!important;
          .t-ab{
            width: 100%!important;
            margin-bottom: 0.625rem;
          }
    }
    .thl{
      padding: 0.5125rem 0.1rem!important;
    }
    .shude{
      height: 10rem!important;
    }
    .tdi{
      .dj,.zd{
        margin-right: 0.625rem!important;
      }
      .zd{

      }
    }
    .kd{
      .xian1{
        width: 40%!important;
      }
    }
    .mobi{
      display: none;
    }
    .mini{
     .mobi{
       display: none;
     }
    }
    .zong{
      .zo-cen{
        .zoc-right{
          .hx{
            .h-nr{
              width: 88%!important;
              .h-b{
                padding: 0.8rem 0rem!important;
              }
            }
          }
          .jxi{
            .bs{
              padding: 0!important;
            }
            .mishuchu{
              width: 34% !important;
              padding: 0.2rem 0.25rem !important;
              font-size: 0.8rem!important;
            }
          }

          .jbm{
            .bs{
              display: none;
            }
          }
        }
      }
    }

    .bja{
          height: 13rem!important;
              display: none;
    }
    .z-nrk {
      .grjj{
        .nr{

              width: 90%!important;
              position: relative!important;
              align-items: center!important;
              display: flex!important;
              flex-wrap: wrap!important;
              text-align: center!important;
              transform: translate(0, 0)!important;
              .tx{
                    width: 12rem!important;
                    margin: 0.625rem auto!important;
              }
          }
          .right{
                color: #323232!important;
                .r-title{
                      margin-right: 0.825rem!important;
                }
                .r-jj{
                  color: rgb(72 72 72 / 67%)!important;
                }
                .nr-nr{
                      color: rgb(68 68 68 / 67%)!important;
                      margin-top: 0.175rem!important;
                      font-size: 0.8rem!important;
                      line-height: 1.5rem!important;
                      text-align: left!important;
                }
          }


        }

    }
    .zno-left{
      margin-right: 0.875rem!important;
    }
    .zocl-cen{
      margin-bottom: 0.625rem;
    }
    .z-bt{
      padding-bottom: 0.875rem!important;
      font-size: 1rem!important;
      padding-top: 0.375rem!important;
    }
    .nr-gzjh{

    }
    .nr-gzjh{
        width: 100%!important;
    }
    .z-nrk{
       padding: 0!important;
      .fuzhuren{
        padding: 0!important;
        .f-bottom{
          margin-top: 0.7rem!important;
        }
      }
    }
    .f-b-one{
      width: 100%!important;
    }
    .z-nrk>.grjj>.nr>.right{
      margin-left: 0.625rem!important;
    }
    .z-nrk>.grjj>.nr>.right>.nr-nr{

    }
    .zo-cen{
      width: 100%!important;
      flex-wrap: wrap;
      .zoc-left{
        width: 95%!important;
        margin: 0 auto;
        .zocl-cen{
          display: inline-block;
          width: 30%;
          text-align: center;
          background: #b3b4b5;
          color: #fff;
          border-radius: 1.9375rem;
          padding: 0.7rem!important;
          margin-right: 5%;
        }
        .zocl-cen:nth-child(4n){
          margin-right: 0%;
        }
      }
    }
    .zoc-right{
      width: 95%!important;
      padding: 0.6rem 0.6rem!important;
      background: #fff;
      margin: 0 auto!important;
      position: relative;
      min-height: 50rem;
      margin-top: 18px;
      margin-top: 0.625rem;
      .top-bt{

      }
      .hx{
        .h-nr{
          .h-t{
                font-size: 1rem!important;
          }
          .h-b{
            font-size: 0.6rem!important;
          }
        }

      }
      .jxi{

        .mishuchu {
            width: 20%!Important;
            font-size: 1rem;
            padding: 0 0.875rem!important;
            text-align: center;
            margin: 0;
        }

        .xian1 {
          width: 19%!Important;
          font-size: 0;
          padding: 0;
          margin: 0;
        }
        .bs{
          // width: 10%!important;
        }
        .sxian{
          width: 2%;
        }


      }


    }
    .xian1zj{
      width: 0%!important;
    }
    .jbmz{
      .bv{
        margin-left: 12.075rem;
      }
      .xian1zj {
        width: 6%!important;
      }

    }
    .szaa,.shsha{
      margin-left: 5rem!important;
    }

    .dbsn{
      margin-left: 0rem!important;
      .fhz{
        width: 7rem!important;
        margin-right: 0.825rem;
        font-size: 0.6rem!important;
      }
      .fhz1 {
        width: 7rem!important;
        font-size: 0.6rem!important;
      }
      .jd{
        margin-top: 24px;
        width: 3rem!important;

      }
    }
    .shude {
      top: 16.175rem!important;
      right: 0.675rem!important;
      height: 10.65rem!important;
    }
    .ygdh{
      .nr{
            font-size: 0.6rem!important;
      }
      .xians{
            height: 1.3625rem!important;
      }
    }
    .zoc-left{
      margin-bottom: 0.625rem!important;
    }
  }
  .zocl-cen:hover{
    color: #2E9BD6;
  }
  .zocl-foot:hover{
    color: #2E9BD6;
  }
  .thl:hover{
    color: #2E9BD6;
  }

  .t-ab:hover{
    color: #2E9BD6;
  }
  .t-ab{
    cursor: pointer;
  }



  .z-nrk{
   .biaoti{
     text-align: center;
     font-weight: bold;
     font-size: 1.4rem;
     margin-top: 0.725rem;
   }
   .jianjie{
      margin-top: 1.875rem;
      line-height: 1.4rem;
   }

   .grjj{
     margin-top: 0.5rem;
     position: relative;

     .bj{
       width: 100%;
     }
     .nr{
       width: 90%;

       position: absolute;
       top: 50%;
       left: 5%;
       transform: translate(0,-50%);
           align-items: center;
       .tx{
         width: 6rem;
       }
       display: flex;
       .right{
          margin-left: 1.625rem;
          color: #fff;
          font-size: 1.2rem;
          .r-jj{
            font-size: 0.8rem;
            display: inline-block;
            color:rgba(255,255,255,0.67);
          }
          .r-title{
            display: inline-block;
            margin-right: 0.825rem;
          }
          .nr-nr{
            color:rgba(255,255,255,0.67);
            margin-top: 1.175rem;
            font-size: 0.8rem;
            line-height: 1.5rem;
          }
       }


     }

   }

   .xm{
     width: 100%;
     background: #F9F9F9;
     color: #888888;
     padding: 1.25rem 5%;
     border-radius: 0 0 0.625rem 0.625rem;
     .xm-title{
        font-size: 0.9rem;
        color: #3A3A3A;
        display: flex;
        align-items: center;
        // justify-content: center;
        img{
          width: 0.351rem;
          margin-right: 0.25rem;
          margin-top: -0.125rem;
        }
     }
     .xm-nr{

       font-size: 0.9rem;
       line-height: 1.4rem;
       width: 97%;
       margin: 0 auto;
       margin-top: 1.275rem;
     }
   }

   .fuzhuren{
     margin-top: 0.625rem;
     border: 0.0625rem solid #EDEDED;
     padding:0 0 1.875rem  0;
     position: relative;
     .fuzhuren-title{
       position: relative;
       width: 8.25rem;
       img{
         width: 8.25rem;
       }
       .f-t-bt{
          position:absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          font-size: 0.85rem;
          color: #fff;
            width: 100%;
            text-align: center;
       }
     }
     .f-bottom{

       width: 99%;
       margin: 0 auto;
       margin-top: 1.25rem;
       display: flex;
       align-items: center;
       justify-content: space-between;
       flex-wrap: wrap;
       .f-b-one{
         background: #F9F9F9;
         padding: 0.75rem;
         margin-bottom: 1.075rem;
         min-height: 11vh;


         .txs{
           width: 26%;
         }
         width: 33%;
         display: flex;
         align-items: flex-start;
        .right{
          margin-left: 0.625rem;
          .r-title{
            color: #4A4A4A;
            font-size: 0.9rem;
          }
          .nr-nr{
            // font-size: 0.8rem;
            // color: #888888;
            // margin-top: 0.825rem;
            // line-height: 1.575rem;
             font-size: 0.2rem;
             color: #888888;
             margin-top: 0.25rem;
             line-height: 1rem;
             transform: scale(0.9);
             width: 117%;
             margin-left: -6%;
          }

        }
       }
     }
   }


    .xbj{
      background: #F3FBFF;
      width: 100%;
      height: 8rem;
    }

    .nr-gzjh{
      background: #2C9DD6;
      width: 65%;
      margin: 0 auto;
      padding:0rem  0   1.875rem  0;
      position: relative;
      top: -4rem;
      .nr-g-tou{
         // padding-bottom: 1.5rem;
         height: 4rem;
         border-bottom: 0.0625rem solid #fff;
         color: #fff;
         text-align: center;
         display: flex;
         justify-content: center;
         align-items: center;
         img{
           width: 5.5rem;
         }
         span{
           margin: 0 0.625rem;
         }
      }
      .xq{
        font-size: 0.75rem;
        color:rgba(255,255,255,0.67);
        width: 90%;
        margin: 0 auto;
        margin-top: 1.275rem;
        line-height: 1.6rem;
      }
    }
  }

  .cws{
    position: relative;
  }
  .dw{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #000;
    padding: 16px 10px;
    overflow: hidden;
    z-index: 986;
    background: #fff;
  }
  .bxs{
    background: #fff!important;
    border: 0!important;
  }

  .yca{
    display: none;
  }
</style>
