<template>
  <div class="all">
    <Head :top="top" :grxxff="grxxff" :qhs="qh"></Head>
    <Banner :bn='bn'></Banner>
    <Bread :all="all"></Bread>
    <div class="a-cen">
      <div class="center">

        <div class="zong">
          <div class="zo-cen">
            <div class="zoc-left">
              <div class="zocl-cen" @click="qh(0)" :class="v==0?'bj':''">专家库</div>
              <div class="zocl-cen" @click="qh(1)" :class="v==1?'bj':''">机构库</div>
            </div>


            <div class="zoc-right1" v-show="v==0 && xq==1" >
              <div class="z-bt" @click="fhzj()"><img src="~assets/about/fh.png" />{{xqnr.name}}</div>
              <div class="z-nrk">
                <div class="z-nrk-left"><img :src="'https://ymedicine.jiujingwulian.com/public'+xqnr.fimage" /></div>
                <div class="z-nrk-right">
                  <div class="z-n-r-title">{{xqnr.name}}</div>
                  <div class="z-n-r-nr1">所属单位：<span>{{xqnr.danwei}}</span></div>
                  <!-- <div class="z-n-r-nr1">所属领域：<span>{{xqnr.ly}}</span></div> -->
                  <div class="z-n-r-nr1">个人简介：<span>{{xqnr.jianjie}}</span></div>
                </div>
              </div>
              <div class="z-nrk-con" v-html="xqnr.content"></div>
            </div>

            <div class="zoc-right2" v-show="v==1 && xq==2">
              <div class="z-bt"  @click="fhjg()"><img src="~assets/about/fh.png" />{{jgnr.name}}</div>
              <div class="z-nrk">
                <div class="z-nrk-left"><img :src="'https://ymedicine.jiujingwulian.com/public'+jgnr.fimage" /></div>
                <div class="z-nrk-right">
                  <div class="z-n-r-title">{{jgnr.name}}</div>
                  <div class="z-n-r-nr1">机构简介：<span>{{jgnr.jianjie}}</span></div>
                </div>
              </div>
              <div class="z-nrk-con" v-html="jgnr.content"></div>
            </div>


            <div class="c-ku" v-show="v==0 && xq==0">
              <div class="c-ku-top" v-show="false">

               <div class="c-ku-bt">专业领域：</div>               
                <block @click="qhly(item.id)" style="display: flex;    align-items: center;" v-for="(item,index) in fzhuanjialy">
                  <div class="c-ku-one " :class="lyid==item.id?'ds':''">{{item.name}}</div>
                  <div class="shu">|</div>
                </block>
                <!--
                <div class="c-ku-one">领域一</div>
                <div class="shu">|</div>
                <div class="c-ku-one">领域一</div>
                <div class="shu">|</div>
                <div class="c-ku-one">领域一</div>
                <div class="shu">|</div>
                <div class="c-ku-one">领域一</div> -->
              </div>


              <div class="c-ku-top1">

                <div class="top1-one" v-for="(item,index) in fzhuanjia" @click="zjxq(item)">
                  <div class="one-left">
                    <div class="jiujiu"></div><img :src="'https://ymedicine.jiujingwulian.com/public'+item.fimage"  />
                  </div>
                  <div class="one-right">
                    <div class="o-title">{{item.name}}</div>
                    <div class="o-count twohang">专家简介：{{item.jianjie}}
                    </div>
                    <div class="o-jieshao">所属单位：{{item.danwei}}</div>
                  </div>
                </div>

                <page v-show="xq==0" :items='items' :litems='litems' :total="total" :per_page="per_page" :current_page="current_page" :last_page="last_page" :listcount='listcount' ></page>

                <!--
                <div class="top1-one">
                  <div class="one-left">
                    <div class="jiujiu"></div><img src="~assets/expert/zjtx.png" />
                  </div>
                  <div class="one-right">
                    <div class="o-title">成成成</div>
                    <div class="o-count">专家简介：专家简介专家简介专家简介专家简介专家简介专家简介专家简介家简介
                    </div>
                    <div class="o-jieshao">所属单位：xxx研究中心</div>
                  </div>
                </div>
                <div class="top1-one">
                  <div class="one-left">
                    <div class="jiujiu"></div><img src="~assets/expert/zjtx.png" />
                  </div>
                  <div class="one-right">
                    <div class="o-title">成成成</div>
                    <div class="o-count">专家简介：专家简介专家简介专家简介专家简介专家简介专家简介专家简介家简介
                    </div>
                    <div class="o-jieshao">所属单位：xxx研究中心</div>
                  </div>
                </div>
                <div class="top1-one">
                  <div class="one-left">
                    <div class="jiujiu"></div><img src="~assets/expert/zjtx.png" />
                  </div>
                  <div class="one-right">
                    <div class="o-title">成成成</div>
                    <div class="o-count">专家简介：专家简介专家简介专家简介专家简介专家简介专家简介专家简介家简介
                    </div>
                    <div class="o-jieshao">所属单位：xxx研究中心</div>
                  </div>
                </div>
                <div class="top1-one">
                  <div class="one-left">
                    <div class="jiujiu"></div><img src="~assets/expert/zjtx.png" />
                  </div>
                  <div class="one-right">
                    <div class="o-title">成成成</div>
                    <div class="o-count">专家简介：专家简介专家简介专家简介专家简介专家简介专家简介专家简介家简介
                    </div>
                    <div class="o-jieshao">所属单位：xxx研究中心</div>
                  </div>
                </div> -->



              </div>
            </div>

            <div class="c-ku" v-show="v==1 && xq==0">
              <List :i='i' :list='fjigou' :qiehuan="qiehuan" :tz="tz"></List>
              <page v-show="xq==0" :items='items' :litems='litems' :total="total" :per_page="per_page" :current_page="current_page" :last_page="last_page" :listcount='listcount' ></page>
            </div>


            <div class="c-right" v-show="xq==0">
              <div class="r-ban">
                <img src="../../assets/new/ban.png" />
              </div>
              <Journalism ></Journalism>
              <Notice :pd="pd"></Notice>
            </div>


          </div>

        </div>


      </div>
    </div>

    <foot></foot>
    <left></left>
  </div>
</template>

<script>
  import {
    ref,
    onMounted
  } from 'vue';
  import {
    getHome,
    getbanner
  } from 'network/home.js';

  import {
    getfzhuanjia,
    getfzhuanjialy,
    getfjigou
  } from 'network/expert.js';

  import {
    useRouter
  } from 'vue-router';


  import Head from "components/content/headder/Head.vue";
  import Foot from "components/content/footer/Foot.vue";
  import Left from "components/content/left/Left.vue";
  import Bread from "components/content/breadcrumb/Breadcrumb.vue";
  import Banner from "components/content/banner/Banner.vue";

  import Journalism from 'components/content/journalism/Journalism.vue'
  import Notice from "components/content/notice/Notice.vue";
  import List from "components/content/list/List.vue";
  import Page from "components/content/page/Page.vue";

  export default {
    name: 'New',
    data() {
      return {
        items:[
        ],
        litems:[
        ],
        page:0,
        total:'',
        per_page:'',
        current_page:'',
        last_page:'',



        pd:1,
        xq:0,
        v: 0,
        xqnr:{},
        jgnr:{},
        lyid:0,
        fjigou:[],
        fzhuanjia:[],
        fzhuanjialy:[],
        tz:"/ExpertDeJg",

        i: 1,
        bn: '',

        all: [{
            name: '您当前的位置:首页',
            url: '/'
          },

          {
            name: '专家库',
            url: '/New'
          },
        ]
      }
    },
    setup() {
      const top = 6;
      const router = useRouter();
      const databa = ref([]);
      const ab = 'sb';
      console.log(ab);
      onMounted(() => {

        getHome().then(res => {
          console.log(res.data.data);
          databa.value = res.data;
          console.log(databa);

        }).catch(err => {

        })

      })
      //跳转方法
      const goTo = (path, query) => {
        router.push({
          path: path,
          query: query || {}
        })
      }

      return {
        databa,
        ab,
        goTo,
        top
      }
    },
    mounted() {

      // var url = decodeURI(window.location.search); //?id="123456"&name="www";
      // // var url = window.location.href;
      // console.log(url);
      // var object = {};
      // if (url.indexOf("?") != -1) //url中存在问号，也就说有参数。
      // {
      //   var str = url.substr(1); //得到?后面的字符串
      //   var strs = str.split("&"); //将得到的参数分隔成数组[id="123456",name="www"];
      //   for (var i = 0; i < strs.length; i++) {
      //     object[strs[i].split("=")[0]] = strs[i].split("=")[1]; //得到{id:'123456',name:'www'}
      //   }
      // }
      // console.log(object);
      var pd =  this.GetUrlParam('pd');
      var fl =this.GetUrlParam('fl');
      // var pd = object['pd'];
      // var fl = object['fl'];
      console.log(pd);
      if(pd){
        this.v=pd;
      }
      if(fl){
        this.v=fl;
      }

      this.banner();
      this.getfzhuanjialy1();
      this.getfjigou1();
      window.scrollTo( 0, 0 );
    },

    methods: {
      GetUrlParam(paraName) {
      var url = window.location.toString();
      var arrObj = url.split("?");
      if (arrObj.length > 1) {
        var arrPara = arrObj[1].split("&");
        var arr;
        for (var i = 0; i < arrPara.length; i++) {
          arr = arrPara[i].split("=");
          if (arr != null && arr[0] == paraName) {
            return arr[1];
          }
        }
        return "";
      } else {
        return "";
      }
    },
      grxxff(grxxs){
        this.grxx=grxxs;
        console.log(grxxs);
        console.log('zhende');
      },
      fhzj(){
        this.xq=0;
        this.v=0;
      },
      fhjg(){
        this.xq=0;
        this.v=1;
      },

      zjxq(item){
        console.log(item);
        this.xqnr=item;
        this.xq=1;
      },
      qiehuan(item){
        this.jgnr=item;
        this.xq=2;
      },
      getfjigou1(){
        var that = this;
        var data = {
          i:3,
          page:this.page,
          sl:6
        };
        getfjigou(data).then(res => {
          // that.fjigou=res.data.data;
          console.log(res);
          console.log('j;');
          that.fjigou=res.data.data.table.data;


          that.total=res.data.data.table.total;
          that.per_page=res.data.data.table.per_page;
          that.current_page=res.data.data.table.current_page;
          that.last_page=res.data.data.table.last_page;
          that.items=res.data.data.items;
          that.litems=res.data.data.litems;




        }).catch(err => {})
      },
      banner(){

        var that = this;
        var data = {
          i:3,

        };
        getbanner(data).then(res => {
          that.bn='https://ymedicine.jiujingwulian.com/public'+res.data[0].bimage;
        }).catch(err => {})
      },

      qhly(lyid){
        // this.page=0;
        this.lyid=lyid;
        this.getfzhuanjia1(lyid);
      },
      getfzhuanjialy1() {
        var that = this;
        var data = {
          i:3,
        };
        getfzhuanjialy(data).then(res => {
          console.log(res);
          console.log('ss');
          that.fzhuanjialy=res.data.data;






          that.lyid=that.fzhuanjialy[0].id;

          console.log(that.fzhuanjialy);

          that.getfzhuanjia1(that.lyid);

        }).catch(err => {})

      },
      listcount(page){
        this.page=page;
        this.banner();

        if(this.v==0){
          this.getfzhuanjialy1();
        }else{
          this.getfjigou1();
        }



      },
      getfzhuanjia1(fnewfl_id) {
        var that = this;
        var data = {
          id:fnewfl_id,
          page:that.page,
        };
        getfzhuanjia(data).then(res => {
          that.fzhuanjia=res.data.data.list.data;
          that.total=res.data.data.list.total;
          that.per_page=res.data.data.list.per_page;
          that.current_page=res.data.data.list.current_page;
          that.last_page=res.data.data.list.last_page;
          that.items=res.data.data.items;
          that.litems=res.data.data.litems;
          that.page=res.data.data.page;
        }).catch(err => {})

      },

      qh(it) {
        this.page=0;
        console.log(it);
        this.v = it;
        this.xq=0;
        if(it==0){
          this.getfzhuanjialy1();
        }else{
          this.getfjigou1();
        }
      },
    },
    components: {
      Head,
      Foot,
      Left,
      Bread,
      Notice,
      Journalism,
      List,
      Page,
      Banner
    },
  }
</script>
<style lang="scss" scoped="scoped">
  .a-cen {
    width: 100vw;
    background: #F5F6FA;
    padding: 1rem 0;

    .center {
      width: var(--allWidth);
      margin: 0 auto;

      .zo-cen {
        width: var(--allWidth);
        margin: 0 auto;
        display: flex;

        .zoc-left {
          width: 14%;
          border-left: 0.125rem dotted #D7D7D7;


          .zocl-top {
            font-size: 1rem;
            font-weight: bold;
            padding: 1.625rem;
          }

          .zocl-cen {
            padding: 1.35rem;
            font-size: 0.9125rem;
            cursor: pointer;
          }

          .zocl-foot {
            display: none;
            width: 100%;
            padding: 0.925rem 1.3rem;
            background: #fff;
            font-size: 0.8125rem;
            border-bottom: 0.0625rem dotted #EEEEEE;
            cursor: pointer;
          }

          .xs {
            display: block;
          }

          .bsx {
            color: #2E9BD6;
          }

          .bj {
            background: url(assets/about/fw.png) no-repeat;
            background-size: 100% 100%;
            color: #fff;
          }

        }

        .c-ku {
          width: 57%;
          min-height: 10vh;
          // background: #fff;
          margin-right: 2%;

          .c-ku-top {
            background: #fff;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            div {
              padding: 1.125rem 0.4rem 1.125rem 0.425rem;
              cursor: pointer;
            }

            .c-ku-bt {
              padding-left: 1.625rem;
              font-weight: bold;
            }

            .ds {
              color: #158BD0;
              border-bottom: 0.225rem solid #158BD0;
            }
          }

          .c-ku-top1 {
            background: #fff;
            margin-top: 0rem;
            padding: 0.8125rem;
            padding-top: 0;
            display: flex;
              flex-wrap: wrap;

            .top1-one:nth-child(2n) {
              margin-right: 0!important;
            }

            .top1-one {
              width: 49%;
              margin-right: 2%;
              display: flex;
              background: #F4F4F4;
              margin-bottom: 1rem;
              cursor: pointer;
              img {
                width: 7.975rem;
                margin-right: 0.8625rem;
                position: relative;
                z-index: 9;
                height: 100%;
              }

              .one-left {
                position: relative;

                .jiujiu {
                  position: absolute;
                  bottom: 50%;
                  right: 0%;
                  background: #D2D2D2;
                  width: 20px;
                  height: 20px;
                  transform: translate(-50%, 50%) rotate(45deg);

                }
              }

              .one-right {
                padding: 1rem 1rem 1rem 0.2rem;

                .o-title {
                  font-size: 1.0625rem;
                  font-weight: bold;

                }

                .o-count {
                  color: #818181;
                  font-size: 0.8rem;
                  margin: 1.3rem 0;
                  line-height: 1.025rem;
                }
                .o-jieshao{
                  font-size: 0.8rem;
                }
              }
            }


            .top1-one:hover{
               background: #2E9BD6;
                .o-title{
                  color: #fff;
                }
                .o-count{
                  color: #fff;
                }
                .o-jieshao{
                    color: #fff;
                }
            }

          }
        }

        .c-right {
          width: 28%;

          .r-ban {
            width: 100%;

            img {
              width: 100%;
            }
          }

        }
      }


    }
  }

  .center {}

  .zoc-right1 {
    width: 86%;
    padding: 1.875rem;
    background: #fff;
    // margin:0 1.875rem  1.875rem  1.875rem;
    position: relative;
    min-height: 50rem;

    //列表
    .z-bt {
      cursor: pointer;
      border-bottom: 0.0625rem solid #DBDBDB;
      padding-bottom: 1.875rem;
      font-weight: bold;
      font-size: 1.2rem;
      img{
        width: 1.075rem;
        margin-right: 0.625rem;
      }
    }
    .z-nrk-con{
      padding: 0.625rem;
    }
    .z-nrk {
      padding: 1rem;
      border-bottom: 0.0625rem solid #DBDBDB;
      display: flex;
     .z-nrk-left{
       margin-right: 0.9rem;
      img{
        width: 7.875rem;
      }
     }
     .z-nrk-right{
      width: 90%;
      .z-n-r-title{

        font-size: 1.225rem;
        padding-bottom: 1.25rem;
        border-bottom: 0.35rem solid #2E9BD6;
        display: inline-block;
        margin-bottom: 0.625rem;
      }
      .z-n-r-nr1{
            width: 45%;
            line-height: 1.4rem;

        font-size: 0.9125rem;
        margin-top: 0.625rem;
        span{
          color: #888888;
        }
      }
     }

    }


    //列表结束


  }


  .zoc-right2 {
    width: 86%;
    padding: 1.875rem;
    background: #fff;
    // margin:0 1.875rem  1.875rem  1.875rem;
    position: relative;
    min-height: 50rem;

    //列表
    .z-bt {
      cursor: pointer;
      border-bottom: 0.0625rem solid #DBDBDB;
      padding-bottom: 1.875rem;
      font-weight: bold;
      font-size: 1.2rem;
      img{
        width: 1.075rem;
        margin-right: 0.625rem;
      }
    }
    .z-nrk-con{
      padding: 0.625rem;
    }
    .z-nrk {
      padding: 1rem;
      border-bottom: 0.0625rem solid #DBDBDB;
      display: flex;
      align-items: center;
     .z-nrk-left{
       margin-right: 0.9rem;
      img{
        width: 7.875rem;
      }
     }
     .z-nrk-right{
      .z-n-r-title{
        font-size: 1.225rem;
        padding-bottom: 1.25rem;
        // border-bottom: 0.35rem solid #2E9BD6;
        display: inline-block;
        margin-bottom: 0.625rem;
        font-weight: bold;
      }
      .z-n-r-nr1{
        font-size: 0.9125rem;
        margin-top: 0.625rem;
        span{
          color: #888888;
        }
      }
     }

    }


    //列表结束


  }


  @media screen and (max-width: 1300px) {

    .but{
      padding: 0.325rem!important;
      width: 7rem!important;
      text-align: center;
      margin: 0 1.625rem 0.25rem 0!important;
    }

    .zo-cen{
      width: 100%!important;
      flex-wrap: wrap;
      text-align: center;

      .zoc-left{
        width: 98%!important;
        display: flex!important;
        margin: 0 auto;
        margin-bottom: 16px;
        justify-content: space-around;

       .zocl-cen{
         width: 45%;
         background: #b3b4b5;
         color: #fff;
         padding: 0.65rem!important;
         border-radius: 3.125rem;
       }
      }
    }

    .c-ku{
      width: 95%!important;
      margin: 0 auto!important;
    }
    .c-right{
      width: 95%!important;
      margin: 0 auto;
      margin-top: 1.875rem!important;
    }
    .c-ku-top{
      font-size: 0.9rem;
    }
    .c-ku-bt{
      padding-left: 0.625rem!important;
    }
    .c-ku-one {
      padding: 0.625rem 0.4rem 0.625rem 0.425rem!important;
    }
    .top1-one{
      width: 100%!important;
      margin-right: 0!important;
    }
    .o-title,.o-jieshao,.o-count,.r-top-cot,.znor-title{
      text-align: left!important;
    }



    .zoc-right1,.zoc-right2{
      width: 95%!important;
      margin: 0 auto;
      padding: 0.8rem;
      text-align: left;

    }
    .zoc-right2{
      .z-nrk{
        align-items: flex-start!important;
        .z-n-r-title{
              font-size: 1rem!important;
              padding-bottom: 0.25rem!important;
        }
      }
    }
    .z-nrk{
          padding: 1rem 0!important;
    }
    .z-n-r-nr1{
      width: 100%!important;
    }
    .z-bt{
      font-size: 1rem!important;
      padding-bottom: 0.85rem!important;
    }

  }


</style>
