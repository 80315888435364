<template>
  <div class="all">
    <Head :top="top" :grxxff="grxxff" :qhs="qhliltfe" ></Head>

    <div class="i-center">
      <div class="center">




        <div class="lmt-bot">
          <div class="l-left">
            <List :list='fmeetingnew' :tz="tz" :i='i'></List>

            <page :items='items'
            :litems='litems'
            :total="total"
            :per_page="per_page"
            :current_page="current_page"
            :last_page="last_page"
            :listcount='listcount'></page>


          </div>

        </div>


      </div>




    </div>



    <!-- List -->


    <foot></foot>
    <left></left>

  </div>



</template>

<script>
  import {
    ref,
    onMounted
  } from 'vue';
  import {
    getHome,
    getfmeeting
  } from 'network/home.js';
  import {
    getfmeetingshezhi,
    getfmeetingtj,
    getfmeetingnew
  } from 'network/neeting.js';
  import {
    useRouter
  } from 'vue-router';


  import Head from "components/content/headder/Head.vue";
  import Foot from "components/content/footer/Foot.vue";
  import Left from "components/content/left/Left.vue";
  import List from "components/content/list/List.vue";
  import List1 from "./ChildComps/List.vue";
  import Title from "components/content/title/Title.vue";
  import Page from "components/content/page/Page.vue";

  export default {
    name: 'MeetingNewList',
    data() {
      return {
        items:[
        ],
        litems:[
        ],
        page:0,
        total:'',
        per_page:'',
        current_page:'',
        last_page:'',
        cs:0,

        timeInter:'',
        nums:0,
        num:'left:-0px;',
        tz1:'new',
        tz: '/meetingNewDe',
        feqh: 0,
        fel: [],
        fmeetingnew: [],
        fmeetingnew1: [],
        ftqt: [],
        fmeetingtj: [],
        fmeetingtjlb: [],

        fmeetingshezhi: [],
        i: 1,
        title0: '参展商访谈',
        title1: '会议快讯',
        list: [],
        bn: 'public/img/banner.387b0333.png',
        all: [{
            name: '您当前的位置:首页',
            url: '/'
          },

          {
            name: '资讯',
            url: '/New'
          },
        ]
      }
    },

    setup() {
      const top = 5;
      const router = useRouter();
      const databa = ref([]);
      const ab = 'sb';
      console.log(ab);
      onMounted(() => {

        getHome().then(res => {
          console.log(res.data.data);
          databa.value = res.data;
          console.log(databa);

        }).catch(err => {

        })

      })
      //跳转方法
      const goTo = (path, id) => {

        var query = {
          id: id
        }
        console.log(query);
        router.push({
          path: path,
          query: query || {}
        })
      }

      return {
        databa,
        ab,
        goTo,
        top
      }
    },
    mounted() {
      // var url = decodeURI(window.location.search); //?id="123456"&name="www";
      // // var url = window.location.href;
      // console.log(url);
      // var object = {};
      // if (url.indexOf("?") != -1) //url中存在问号，也就说有参数。
      // {
      //   var str = url.substr(1); //得到?后面的字符串
      //   var strs = str.split("&"); //将得到的参数分隔成数组[id="123456",name="www"];
      //   for (var i = 0; i < strs.length; i++) {
      //     object[strs[i].split("=")[0]] = strs[i].split("=")[1]; //得到{id:'123456',name:'www'}
      //   }
      // }
      // console.log(object);
      // var fl = object['fl'];
      // var cs = object['id'];

      var cs = this.GetUrlParam('id');
      var fl = this.GetUrlParam('fl');
      if(fl){
        this.feqh=fl;
      }
      this.cs=cs;


      this.Banner();
      // this.faboutdata();
      this.qhlistfe(this.feqh);
      this.getfmeetingtj();
      this.getfmeetingtj1();
      this.getfmeetingnewaa();


    // let timer = setTimeout(() => {
    //   //需要定时执行的代码
    //   console.debug("Hello World");
    //   this.yd();
    // }, 100)
      window.scrollTo( 0, 0 );


      // num:'left:-0px;',
    },

    beforeDestroy() {
        // if(this.timeInter) { //如果定时器还在运行，直接关闭，不用判断

        // }
    },

    methods: {
      GetUrlParam(paraName) {
      var url = window.location.toString();
      var arrObj = url.split("?");
      if (arrObj.length > 1) {
        var arrPara = arrObj[1].split("&");
        var arr;
        for (var i = 0; i < arrPara.length; i++) {
          arr = arrPara[i].split("=");
          if (arr != null && arr[0] == paraName) {
            return arr[1];
          }
        }
        return "";
      } else {
        return "";
      }
    },
      grxxff(grxxs){
        this.grxx=grxxs;
        console.log(grxxs);
        console.log('zhende');
      },
      mouseover(){
        //关闭

      },
      mouseout(){
      },
      yd(){
        this.nums=this.nums+1;
        // console.log(this.nums);
        var shuju= this.fmeetingtjlb.length*50;
        if(this.nums==shuju){
          this.nums=0;
        }

        this.num='left:-'+this.nums+'%;';
        // console.log(this.num);
      },
      listcount(page){
        this.page=page;
        this.getfmeetingnewaa();
      },


      getfmeetingnewaa() {

        var cs = {
          page:this.page,
          li:this.cs
        };
        var that = this;
        getfmeetingnew(cs).then(res => {
          that.fmeetingnew = res.data.data.getfmeetingnew.table.data;

          that.news = res.data.data.getfmeetingnew.table.data;
          that.total=res.data.data.getfmeetingnew.table.total;
          that.per_page=res.data.data.getfmeetingnew.table.per_page;
          that.current_page=res.data.data.getfmeetingnew.table.current_page;
          that.last_page=res.data.data.getfmeetingnew.table.last_page;
          that.items=res.data.data.getfmeetingnew.items;
          that.litems=res.data.data.getfmeetingnew.litems;



          that.fmeetingnew1 = res.data.data.getfmeetingnew1;


        }).catch(err => {

        })
      },
      getfmeetingtj() {
        var cs = {};
        var that = this;
        getfmeetingtj(cs).then(res => {
          that.fmeetingtj = res.data.data;

        }).catch(err => {

        })
      },
      getfmeetingtj1() {
        var cs = {
          limit: 8
        };
        var that = this;
        getfmeetingtj(cs).then(res => {
          that.fmeetingtjlb = res.data.data;

        }).catch(err => {

        })
      },
      qhliltfe(tt) {
        this.feqh = tt;
        console.log('2');
        this.qhlistfe(tt);
      },

      qhlistfe(fnewfl_id) {

        var that = this;
        var data = {
          type: 0,
          fl: fnewfl_id
        };
        console.log(data);
        getfmeeting(data).then(res => {

          console.log(res);
          that.fel = res.data.list;
          // that.fel1=res.data.list[1];
          // if (fnewfl_id) {
          //   that.feqh = fnewfl_id;
          // } else {
          //   that.feqh = res.data.list[0].id;
          // }


          that.ftqt = res.data.data;
          console.log(that.ftqt)
          // var newtwo={};
          // var newqt=[];
          // var j=0;
          // var i=0;
          // for(i=0;i<ddd.length;i++){

          //   // console.log(ddd[i]);
          //   console.log(i);

          //   if(i<=0){
          //     newtwo=ddd[i];
          //   }else{
          //     newqt[j]=ddd[i];
          //     j++;
          //   }
          // }


          // that.fttwo=newtwo;
          // console.log('会议');
          // console.log(that.fttwo);
          // that.ftqt=newqt;

        }).catch(err => {

        })

      },



      Banner() {
        var cs = {};
        var that = this;
        getfmeetingshezhi(cs).then(res => {
          that.fmeetingshezhi = res.data.data;

        }).catch(err => {

        })

      }
    },
    components: {
      Head,
      Foot,
      Left,
      List,
      List1,
      Page,
      Title
    },
  }
</script>



<style lang="scss" scoped="scoped">
  .lbt {
    .el-carousel__arrow {
      display: none !important;
    }
  }

  .el-carousel__arrow--left {
    display: none !important;

  }

  .el-carousel__arrow--right {
    display: none !important;
  }

  div::-webkit-scrollbar {
    width: 0;
  }

  .i-center {
    background: #F5F6FA;
    padding-bottom: 1.75rem;

    .center {
      width: var(--allWidth);
      margin: 0 auto;

      .c-top {

        background: #fff;
        margin-top: 1.875rem;
        display: flex;
        margin-bottom: 1.625rem;

        .c-left {
          min-height: 27.4375rem;
          width: 74%;

          .zj {
            cursor: pointer;
            width: 100%;
            position: relative;

            img {
              width: 100%;
            }

            .dibu {
              background: rgba(0, 0, 0, 0.63);
              color: #fff;
              position: absolute;
              bottom: 1px;
              padding: 14px;
              width: 100%;
              font-size: 1rem;
            }
          }

          .el-carousel {
            height: 27.4375rem;
            position: relative;

            .el-carousel__container {
              height: 27.4375rem;
            }

            .el-carousel__indicators {
              width: 100%;
              position: absolute;
              right: 0.75rem;
              bottom: 0.5rem;
              transform: translate(-50%, 0px);
            }
          }
        }

        .c-right {
          // width: 10%;
          width: 26.1%;
          background: #F6FBFE;
          min-height: 10vh;
          padding: 0 0.875rem 0 0.875rem;

          div {
            margin-bottom: 0.625rem;

            img {
              width: 18.75rem;
              cursor: pointer;
              height: 9.9rem;
            }
          }
        }

      }



      .l-top {
        background: #fff;

        .l-list {
          display: flex;
          align-items: flex-start;
          background: #fff;
          position: relative;
          margin-bottom: 1.625rem;

          .l-list-one {
            padding: 1.225rem 1.225rem;
            font-size: 1.05rem;
            cursor: pointer;
            position: relative;

            .l-list-one-img {
              position: absolute;
              bottom: 0;
              left: 50%;
              background: #2e9bd6;
              width: 20px;
              height: 20px;
              transform: translate(-50%, 50%) rotate(45deg);
            }
          }

          .l-list-one-bgcolor {
            background: #2E9BD6;
            color: #fff;
          }
        }

        .l-right {
          position: absolute;
          right: 0.625rem;
          top: 50%;
          transform: translate(0, -50%);
          font-size: 0.8125rem;
          display: flex;
          align-items: center;
          color: #4A4A4A;
          cursor: pointer;

          img {
            width: 0.96rem;
            margin-left: 0.3125rem;
          }
        }




        .l-top-list {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          align-items: flex-start;
          padding: 1.125rem;

          .l-top-one {
            margin-right: 2.66%;
            width: 23%;
            margin-bottom: 1.25rem;
            border: 0.0625rem solid #DADADA;

            img {
              width: 100%;
            }

            .one-title {
              margin: 0.625rem 0.9rem;
              font-size: 1.0625rem;
              font-weight: bold;
            }

            .one-nrjh {
              font-size: 0.8625rem;
              color: #949494;
              margin: 1.325rem 0.9rem;

              .one-nr {
                margin-top: 0.425rem;
              }
            }

          }

          .l-top-one:nth-child(4n) {
            margin-right: 0;
          }


        }

      }

      .lmt-bot {
        margin: 1.625rem 0;
        display: flex;

        .l-left {
          width: 100%;
          background: #fff;
          padding: 1.125rem 1.125rem 2rem 1.125rem;
          margin-right: 1%;
          margin-top: 1.875rem;
        }

        .c-top {
          margin: 0;
        }

        .l-right {
          width: 30%;
          background: #fff;
          padding: 1.125rem 1.125rem 2rem 1.125rem;
          cursor: pointer;

          .right-bt {
            width: 100%;
            margin-top: 1.45rem;

            img {
              width: 100%;
            }
          }

          .me-r-nr {
            width: 100%;
            font-size: 0.75rem;

            .me-r-right {
              margin-top: 0.825rem;
              cursor: pointer;
              display: flex;
              align-items: center;

              img {
                width: 0.6125rem;
                margin-right: 0.3125rem;
              }

              margin-bottom: 0.3125rem;

              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;

            }

            .me-r-right:hover {
              color: #2E9BD6;
            }
          }
        }
      }

    }
  }

  .on:hover {
    color: #318FC6;
    cursor: pointer;
  }

  .lbt {
    margin-top: 0.61rem;

    template {
      display: block;
    }

    .el-carousel {
      height: 4rem !important;
    }

    .h3 {
      // color: #475669;
      // opacity: 0.75;
      // line-height: 4rem;
      margin: 0;
      background: #FFFFFF;
      // text-align: center;
      display: flex;
      align-items: center;
      padding: 1.1rem 3.6875rem;

      img {
        margin-right: 0.625rem;
      }

      font-size: 0.875rem;

      .hs {
        .hsb {
          background: #FF3333;
          color: #fff;
          padding: 0.4rem 0.625rem;
          margin-right: 0.625rem;
          font-size: 1rem;
        }

      }

      .zw {
        margin-right: 0.625rem;
      }

      .sj {

        color: #A7A7A7;
      }

      .dian {
        margin: 0 1.625rem;
      }
    }


    .el-carousel__item .h3 {
      // color: #475669;
      // opacity: 0.75;
      // line-height: 4rem;
      margin: 0;
      background: #FFFFFF;
      // text-align: center;
      display: flex;
      align-items: center;
      padding: 1.1rem 3.6875rem;

      img {
        margin-right: 0.625rem;
      }

      font-size: 0.875rem;

      .hs {
        .hsb {
          background: #FF3333;
          color: #fff;
          padding: 0.4rem 0.625rem;
          margin-right: 0.625rem;
          font-size: 1rem;
        }

      }

      .zw {
        margin-right: 0.625rem;
      }

      .sj {

        color: #A7A7A7;
      }

      .dian {
        margin: 0 1.625rem;
      }
    }

    .el-carousel__item:nth-child(2n) {
      background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n + 1) {
      background-color: #d3dce6;
    }
  }
  .alls{
    width: 77%;
    overflow: hidden;
    white-space: nowrap;
    .zi{
      position: relative;
    }
  }
</style>
