<template>
  
  <div class="content"  v-show="items.length>1">
    <!-- v-show="total>10" -->
    <div class="tol">共<span>{{total}}</span>条</div>
    <div class="top" v-show="top" @click="top1()">上一页</div>
    <div class="list">
      <div class="li " @click="index(li)" :class="current_page==li?'bs':''" v-for="li in items">{{li}}</div>
      <div class="li" v-show="center">....</div>
      <div class="li" @click="index(li)" :class="current_page==li?'bs':''" v-for="li in litems">{{li}}</div>

    </div>
    <div class="bottom" v-show="bottom" @click="bottom1()">下一页</div>
    <div class="tol" style="margin-top: 0.625rem;">
      跳至
      <input class="shuru" v-model="paindex" type="number"/>
      页
      <div class="qd" @click="tab">确定</div>
    </div>

    <!-- {{top}}
    {{bottom}} -->
  </div>
</template>

<script>
  import {
    useRouter
  } from 'vue-router';

  import {
    ElMessage,
    ElMessageBox
  } from 'element-plus';
  import {
    Action
  } from 'element-plus';

  export default {
    data() {
      return {
        paindex:'',
        top:false,
        center:false,
        bottom:false,
      }
    },
    name: 'Page',
    props: ['total','per_page','current_page','last_page','items','litems','listcount'],
    mounted() {

      if(this.last_page==1){
        this.top=false;
      }else{
        this.top=true;
      }
      if(this.current_page>=4 && this.litems.length>0){
        this.center=true;
      }
      if(this.current_page==this.last_page){
        this.bottom=true;
      }else{
        this.bottom=false;

      }
    },
    setup() {
      //跳转方法
      const router = useRouter();
      const goTo = (path, query) => {
        console.log(query);
        query = {
          name: 'nn'
        }
        console.log(query);
        router.push({
          path: path,
          query: query || {}
        })
      }

      return {
        goTo
      }

    },
    methods: {
      bianse(current_page){
        // if(this.last_page==1){
        //   this.top=false;
        // }else{
        //   this.top=true;
        // }
        // if(current_page>=4 && this.litems.length>0){
        //   this.center=true;
        // }
        // if(current_page==this.last_page){
        //   this.bottom=false;
        // }else{
        //   this.bottom=true;
        // }
      },


      index(li){
        if(li>=4 && this.litems.length>0){
          this.center=true;
        }else{
          this.center=false;
        }
       this.bianse(li);
       this.listcount(li);

      },
      tab(){
        var li=this.paindex;
        this.bianse(li);
        if(!li){
          ElMessage({
            type: 'info',
            message: '请输入跳转页数',
          })


          return false;

        }
        if(li<0){
          ElMessage({
            type: 'info',
            message: '请输入跳转页数不能小于0',
          })
          return false;
        }
        if(li>this.last_page){
          ElMessage({
            type: 'info',
            message: '页数不能大于最大页数'+this.last_page+'页',
          })
          return false;
        }

        // ElMessage({
        //   type: 'info',
        //   message: '请输入跳转页数',
        // })
        if(li>=4 && this.litems.length>0){
           this.center=true;
         }else{
           this.center=false;
         }
        this.listcount(li);
      },

      top1(){
        var li=this.current_page;
        li--;
        if(li<=0){
          ElMessage({
            type: 'info',
            message: '第一页',
          })
          return false;
        }
        this.listcount(li);
      },
      bottom1(){
        var li=this.current_page;
        li++;
        if(li>this.last_page){
          ElMessage({
            type: 'info',
            message: '最后一页',
          })
          return false;
        }
        this.listcount(li);
      },



    },

  }
</script>

<style lang="scss" scoped="scoped">
  .content{
    text-align: center;
    padding: 1rem 0;
    font-size: 0.8rem;
    .tol,.top,.list,.li,.bottom,.qd{
      display: inline-block;
      margin: 0 0.625rem;
    }
    .tol{
      color: #505050;
      span{
        color: #2E9BD6;
        padding: 0 0.2rem;
      }
    }
    .top,.li,.bottom{
      padding: 0.8rem 1rem;
      background: #F6F6F6;
      color: #949494;
      margin: 0 0.2rem;
      cursor: pointer;
    }
    .list{
    }
    .shuru{
      padding: 0.4rem 0;
      width: 3.75rem;
      margin: 0 0.3rem;
      text-align: center;
      border: 0.0625rem solid #D0D0D0;
    }
    .qd{
      border: 0.0625rem solid #D0D0D0;
      padding: 0.625rem ;
      cursor: pointer;
    }
  }
  .bs{
    background: #2E9BD6!important;
    color: #fff!important;
  }

</style>
