<template>
  <div class="all">
    <Head :top="top" :grxxff="grxxff"></Head>
    <Banner :bn="'https://ymedicine.jiujingwulian.com/public'+fmeeting.bimage"></Banner>
    <Bread :all="all"></Bread>
    <div class="a-cen">
      <div class="center">
        <div class="mde-bt">
          <div @click="qh(0)" class="mde-bt1" :class="i==0?'td':''">活动介绍</div>
          <div class="mde-bt1">|</div>
          <div @click="qh(1)" class="mde-bt1" :class="i==1?'td':''">线上直播</div>
          <div class="mde-bt1" v-show="types==0">|</div>
          <div @click="qh(2)" v-show="types==0" class="mde-bt1" :class="i==2?'td':''">会议进展</div>

          <div class="biaoti">
            <div class="bmfy">报名费用：</div>
            <div class="bmje">¥ {{fmeeting.price}}</div>
          </div>


        </div>

        <div class="mde-xq" :class="i==0?'xs':''" v-html="fmeeting.hdcontent">

        </div>







        <div class="mde-xq " :class="i==1?'zj':''">
          <div class="zhibo" v-for="item in fmeetingzb">
           <!-- <video :src="item.zurl" style="object-fit:cover;"
              :poster="'https://ymedicine.jiujingwulian.com/public'+item.fimage" controls="controls"
              width="100%"></video> -->
              <img @click="tianzhuan(item.zurl)" :src="'https://ymedicine.jiujingwulian.com/public'+item.fimage" style="object-fit:cover;width=100%;height: 14.625rem;" />
            <!--  -->
            <!--  -->
            <div class="hc">{{item.name}}</div>
          </div>

          <!-- <div class="zhibo">
            <video src="detailData.video_url" style="object-fit:cover;" poster="~assets/meeting/banne1.png" controls="controls" width="100%"></video>
          </div>
          <div class="zhibo">
            <video src="detailData.video_url" style="object-fit:cover;" poster="~assets/meeting/banne1.png" controls="controls" width="100%"></video>
          </div>
          <div class="zhibo">
            <video src="detailData.video_url" style="object-fit:cover;" poster="~assets/meeting/banne1.png" controls="controls" width="100%"></video>
          </div> -->

        </div>
        <div class="mde-xq" :class="i==2?'xs':''" v-show="types==0">
          <div class="xx">
            <div class="x-one " @click="qhfen(index)" v-for="(item,index) in fmeetingfen" :class="flid==index?'bjs':''">
              {{item.name}}</div>
            <!-- <div class="x-one">分论坛二</div>
            <div class="x-one">分论坛三</div> -->
          </div>
          <div class="x-nrsb">
            <div v-html="hdcontent"></div>
            <!-- asdasdasdasdasdasdasdasdasd -->
          </div>
          <div class="xxan">
            <div class="xxan-left" @click="fenlt()">前往分论坛专区</div>
            <div class="xxan-left ru" v-show="pdshow==false"  @click="canzhan()">立即报名</div>
          </div>
        </div>


      </div>
        <!-- 支付二维码 -->
       <el-dialog
          v-model="payewm"
          title="支付确认"
          :width="w50"
          align-center
           @close='closeDialog'
          >
          <div class="ewm">
            <img class="tp" src="../../assets/meeting/wechat.png" />
            <img class="eee" :src="ewms" />
            <div class="e-price">￥{{prices}}</div>
            <div class="dbs">
              <img class="saosao" src="../../assets/meeting/scan.png" />
              <div class="e-span">请使用微信扫一扫扫描二维码支付</div>
            </div>
          </div>
        </el-dialog>




      <!-- {{pdshow}} -->
      <el-dialog class="ddb zd" :width="w50" align-center  v-model="dialogTableVisible2" :show-close="false">
        <div class="top">
          <div class="">请填写报名信息</div>
          <!-- <div class="gb right"><img @click="dialogTableVisible2=false" src="../../assets/my/guanbi.png" /></div> -->
        </div>
        <div class="kuai">
          <div class="hang">
            <div class="lxr">
              <div class="bt"><span class="red">*</span>姓名：</div>
              <el-input class="shuru" v-model="name1" placeholder="请输入" />
            </div>
            <div class="lxr">
              <div class="bt"><span class="red">*</span>公司/单位名称：</div>
              <el-input class="shuru" v-model="gsname1" placeholder="请输入" />
            </div>
          </div>
          <div class="hang">
            <div class="lxr">
              <div class="bt"><span class="red">*</span>手机号：</div>
              <el-input class="shuru" v-model="phone1" placeholder="请输入" />
            </div>
            <div class="lxr">
              <div class="bt"><span class="red"></span>邮 箱：</div>
              <el-input class="shuru" v-model="email1" placeholder="请输入" />
            </div>
          </div>

          <div class="hang">
            <div class="lxr1">
              <div class="bt"><span class="red">*</span>其他说明：</div>
              <el-input class="shuru" v-model="qt1" placeholder="请输入" />
            </div>
          </div>
        </div>
        <div class="kuai biao">
          <div class="biaoti">
            <div class="bmfy">活动报名费用：</div>
            <div class="bmje">¥ {{fmeeting.price}}</div>
          </div>
          <div class="biaoti">
            <div class="bmfy">是否开票：</div>
            <div class="bmje">
              <el-radio-group v-model="radio1" class="ml-4" >
                <el-radio label="0" size="large">不开票</el-radio>
                <el-radio label="1" size="large">开票</el-radio>
              </el-radio-group>
            </div>

          </div>
        </div>
        <div class="kuai biao">
          <div class="k-biaoti" v-show="radio1==1">
            <div>开票信息：</div>
            <div class="gh right" @click="fapiao()">
                 <el-button class="ans" type="primary">更换</el-button>
              </div>
          </div>
          <div class="k-ones" v-show="radio1==1">
           <div class="kone">{{mrfmeetingfp.zname}}</div>
            <div class="kone">{{mrfmeetingfp.rise}}</div>
            <div class="kone">{{mrfmeetingfp.duty}}</div>
          </div>

          <div class="k-biaoti">优惠券信息：</div>
          <div class="k-ones">

            <div class="kone1" v-show="mrfmeetingyhq.length==0">暂无优惠券</div>
            <div class="kone2" @click="yhq(item.id)" :class="item.id==yid?'bs':''" v-for="item in mrfmeetingyhq" >{{item.name}}</div>
            <!-- bs -->

          </div>



        </div>
        <div class="dban">

          <el-button class="anniu bs1" type="primary" @click="pay">
            <div class="je">￥{{fmeeting.price}}</div>
            <div>确认报名</div>
          </el-button>

        </div>
        <div class="dban1">
          <el-radio-group v-model="radio1a">
            <el-radio :label="3" >我已阅读并同意<span @click="xieyi()" style="color:#2E9BD6;">《参会协议》</span></el-radio>
            <!-- <el-checkbox size="large">我已阅读并同意<span @click="xieyi()" style="color:#2E9BD6;">《参会协议》</span></el-checkbox> -->
          </el-radio-group>
        </div>
      </el-dialog>

      <el-dialog class="huiyixieyi" :width="w50" align-center v-model="dialogTableVisible3" :show-close="false">
        <div class="top">
          <div class="">参会协议<div class="gb right"><img @click="dialogTableVisible3=false"
                src="../../assets/meeting/guanbi.png" /></div>
          </div>
        </div>
        <div class="hy-nr">
          asdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasd
          asdasdasd
          asdasdasd
          asdasdasd

        </div>
        <div class="hy-bot">
          <el-button class="anniu bs1" @click="dialogTableVisible3=false" type="primary" >
            <div>我已阅读并同意</div>
          </el-button>
        </div>

      </el-dialog>

      <el-dialog class="kaipiao" :width="w50"  align-center v-model="dialogTableVisible4" :show-close="false">
        <div class="top">
          <div class="">选择开票信息<div class="gb right"><img @click="dialogTableVisible4=false"
                src="../../assets/meeting/guanbi.png" /></div>
          </div>
        </div>
        <div class="kai-cen">
          <div class="k-c-one" v-for="item in mrfmeetingfplist">
            <div class="k-top">{{item.zname}}</div>
            <div class="k-cen">
              <div class="k-left">{{item.rise}}</div>
              <div class="k-left">{{item.duty}}</div>

              <div class="tubiao">
                <img @click="fmeetingfpfind(item.id)" src="../../assets/meeting/xiugai.png" />
                <el-radio-group v-model="radio3">
                  <el-radio :label="item.id" size="large">&nbsp;</el-radio>
                </el-radio-group>
              </div>

            </div>

          </div>


          <!-- <div class="k-c-one">
            <div class="k-top">1、增值税专用发票抬头</div>
            <div class="k-cen">
              <div class="k-left">xxxx科技有限公司</div>
              <div class="k-left">91442737232377823</div>

              <div class="tubiao">
                <img src="../../assets/meeting/xiugai.png" />
                <el-radio-group v-model="radio3">
                  <el-radio size="large"> </el-radio>
                </el-radio-group>
              </div>

            </div>

          </div>



          <div class="k-c-one">
            <div class="k-top"></div>
            <div class="k-cen">
              <div class="k-left"</div>
              <div class="k-left"></div>

              <div class="tubiao">
                <img src="../../assets/meeting/xiugai.png" />
                <el-radio-group v-model="radio3">
                  <el-radio size="large"> </el-radio>
                </el-radio-group>
              </div>

            </div>

          </div> -->

          <div class="k-anniu">
            <el-button class="new" @click="xinjian()">新建</el-button>
            <el-button class="choice" @click="xiugaimoren()" type="primary">确认选择</el-button>
          </div>

        </div>



      </el-dialog>

      <el-dialog class="taitou" :width="w50" align-center v-model="dialogTableVisible5" :show-close="false">
        <div class="top">
          <div class="">
            <div class="gb left">
              <img @click="dialogTableVisible5=false"
                  src="../../assets/about/fh.png" />
            </div>
            添加发票
            <div class="gb right">
              <img @click="dialogTableVisible5=false"
                  src="../../assets/meeting/guanbi.png" />
            </div>
          </div>
        </div>

        <div class="tai-all">
          <div class="t-a-one">
            <div class="t-a-title">发票类型：</div>
            <div class="t-a-radio">
              <el-radio-group v-model="lx" class="ml-4">
                <el-radio label="0" size="large">普通发票</el-radio>
                <el-radio label="1" size="large">增值税专用发票</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="t-a-one" v-show="lx==0">
            <div class="t-a-title">抬头类型：</div>
            <div class="t-a-radio" >
              <el-radio-group v-model="type" class="ml-4">
                <el-radio label="0" size="large">个人</el-radio>
                <el-radio label="1" size="large">单位</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="t-a-one">
            <div class="t-a-title">发票抬头：</div>
            <div class="t-a-radio">
                <el-input v-model="rise" placeholder="请输入" clearable />
            </div>
          </div>

          <!-- 普通发票 单位 开始 -->
          <div class="t-a-one"  v-show="type==1 || lx==1">
            <div class="t-a-title">单位税号：</div>
            <div class="t-a-radio">
                <el-input v-model="duty" placeholder="请输入" clearable />
            </div>
          </div>
          <!-- 普通发票 单位 结束 -->

          <!-- 增值税发票 开始 -->

          <div class="t-a-one" v-show="lx==1" >
            <div class="t-a-title">注册地址：</div>
            <div class="t-a-radio">
                <el-input v-model="address" placeholder="请输入" clearable />
            </div>
          </div>
          <div class="t-a-one" >
            <div class="t-a-title">注册电话：</div>
            <div class="t-a-radio">
                <el-input v-model="phone" placeholder="请输入" clearable />
            </div>
          </div>
          <div class="t-a-one" >
            <div class="t-a-title">开户银行：</div>
            <div class="t-a-radio">
                <el-input v-model="bank" placeholder="请输入" clearable />
            </div>
          </div>
          <div class="t-a-one" >
            <div class="t-a-title">银行账号：</div>
            <div class="t-a-radio">
                <el-input v-model="bankcode" placeholder="请输入" clearable />
            </div>
          </div>
          <!-- 增值税发票 开始 -->

          <div class="t-a-one">
            <div class="t-a-title">邮箱：</div>
            <div class="t-a-radio">
                <el-input v-model="email" placeholder="请输入" clearable />
            </div>
          </div>
          <div class="t-a-one">
            <div class="t-a-title">收件人：</div>
            <div class="t-a-radio">
                <el-input v-model="uname" placeholder="请输入" clearable />
            </div>
          </div>

          <div class="t-a-one">
            <div class="t-a-title">联系电话：</div>
            <div class="t-a-radio">
                <el-input v-model="uphone" placeholder="请输入" clearable />
            </div>
          </div>
          <div class="t-a-one">
            <div class="t-a-title">收件地址：</div>
            <div class="t-a-radio">
                <el-input v-model="uaddress" placeholder="请输入" clearable />
            </div>
          </div>

          <div class="t-a-anniu">
            <el-button class="choice" type="primary" @click="infmeetingfp()">提交</el-button>
          </div>
        </div>
      </el-dialog>



      <el-dialog

         :title="title"

         align-center
         width="25rem"
          v-model="xy"

         >
         <!-- :width="w50" -->
         <div class="ewm1">
           <div class="tp1">
             <img :src="'https://ymedicine.jiujingwulian.com/public'+fmeeting.ewmimage" />
           </div>
          <div  class="dibu1">
            <ul>
              <li><div>打开微信，点击底部的“发现”</div></li>
              <li><div>使用“扫一扫”即可选择将网页分享至朋友圈。</div></li>
            </ul>


          </div>
         </div>
       </el-dialog>





    </div>
    <foot></foot>
    <left :pdshow="pdshow" :fff="fenxiangs"  :can="canzhan"></left>
  </div>


</template>

<script>
  import {
    ref,
    onMounted
  } from 'vue';
  import {
    getHome
  } from 'network/home.js';
  import {
    getmeetingDe,


    getfmeetingfp,
    getfmeetingfpselect,
    getfmeetingfpfind,
    getinfmeetingfp,
    getupfmeetingfpstatus,
    getfmeetingyhq,
    getpay

  } from 'network/neeting.js';

  import {
    ElMessage,
    ElMessageBox
  } from 'element-plus';
  import {
    Action
  } from 'element-plus';

  import {
    useRouter
  } from 'vue-router';


  import Head from "components/content/headder/Head.vue";
  import Foot from "components/content/footer/Foot.vue";
  import Left from "./ChildComps/Left.vue";
  import Bread from "components/content/breadcrumb/Breadcrumb.vue";
  import Banner from "components/content/banner/Banner.vue";

  import wx from "weixin-js-sdk";

  export default {
    name: 'MeetingDe',
    data() {
      return {
        w50:'60%',
        title:'分享至微信朋友圈',
        xy:false,

        prices:'',
        ewms:'',
        payewm:false,
        id:'',
        zsfpid:'',
        yid:'',
        grxx:{},
        mrfmeetingfp:{
          zname:'',
          rise:'',
          duty:'',
        },
        mrfmeetingyhq:{},
        mrfmeetingfplist:{},

        input:'',
        radio1a:0,
        radio1: 0,
        radio2: 1,
        radio3: 1,

        radio4: 1,
        radio5: 1,
        dialogTableVisible2: false,
        dialogTableVisible3: false,

        dialogTableVisible4: false,
        dialogTableVisible5: false,

        fmeeting: {},
        fmeetingzb: [],
        fmeetingfen: [],
        flid: 0,
        hdcontent: "",
        i: 0,
        bn: 'public/img/banner.387b0333.png',
        types: 0,
        all: [{
            name: '您当前的位置:首页',
            url: '/'
          },

          {
            name: '会议论坛',
            url: '/meeting'
          },
          {
            name: '',

          },
        ],
        name:'',
        gsname:'',
        phone:'',
        email:'',
        qt:'',


        lx:0,
        type:0,
        rise:'',
        duty:'',
        address:'',
        phone:'',
        bank:'',
        bankcode:'',
        email:'',
        uname:'',
        uphone:'',
        uaddress:'',
        fpid:'',

      name1:'',
      gsname1:'',
      phone1:'',
      email1:'',
      qt1:'',
      zsfpid:'',

      pdshow:false,//==true 说明已经报名了



      }
    },
    setup() {
      const top = 5;
      const router = useRouter();
      const databa = ref([]);
      const ab = 'sb';
      console.log(ab);
      onMounted(() => {

        getHome().then(res => {
          console.log(res.data.data);
          databa.value = res.data;
          console.log(databa);

        }).catch(err => {

        })

      })
      //跳转方法
      const goTo = (path, query) => {
        router.push({
          path: path,
          query: query || {}
        })
      }
      var radio1 = ref('0');
      var lx = ref('0');
      var type = ref('0');
      return {
        lx,
        type,
        radio1,
        databa,
        ab,
        goTo,
        top
      }
    },
    mounted() {


      // var url = decodeURI(window.location.search); //?id="123456"&name="www";
      // // var url = window.location.href;
      // console.log(url);
      // var object = {};
      // if (url.indexOf("?") != -1) //url中存在问号，也就说有参数。
      // {
      //   var str = url.substr(1); //得到?后面的字符串
      //   var strs = str.split("&"); //将得到的参数分隔成数组[id="123456",name="www"];
      //   for (var i = 0; i < strs.length; i++) {
      //     object[strs[i].split("=")[0]] = strs[i].split("=")[1]; //得到{id:'123456',name:'www'}
      //   }
      // }
      // console.log(object);
      
      // var id = object['id'];
      // var lx = object['lx'];
      var id = this.GetUrlParam('id');
      var lx = this.GetUrlParam('lx');
      if (lx == 1) {
        this.types = 1;
      }else{
        this.types = 0;
      }
      this.id=id;
      console.log(id);
      this.qhlist(id, '0');


    },

    methods: {
      GetUrlParam(paraName) {
      var url = window.location.toString();
      var arrObj = url.split("?");
      if (arrObj.length > 1) {
        var arrPara = arrObj[1].split("&");
        var arr;
        for (var i = 0; i < arrPara.length; i++) {
          arr = arrPara[i].split("=");
          if (arr != null && arr[0] == paraName) {
            return arr[1];
          }
        }
        return "";
      } else {
        return "";
      }
    },

      getShareInfo() {
        //获取url链接（如果有#需要这么获取）
        var url = encodeURIComponent(window.location.href.split("#")[0]);
      //获取url链接（如果没有#需要这么获取）
       // var url = encodeURIComponent(window.location.href);
        getSing(url).then(res => {
          wx.config({
            debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
            appId: res.data.appId, // 必填，公众号的唯一标识
            timestamp: parseInt(res.data.timestamp), // 必填，生成签名的时间戳
            nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
            signature: res.data.signature, // 必填，签名
            jsApiList: [
              "updateAppMessageShareData",
              "updateTimelineShareData"
            ] // 必填，需要使用的 JS 接口列表
          });
          wx.ready(() => {
            var shareData = {
              title: "每日新闻",
              desc: "2022年12月20日21:47:55每日新闻",
              link: window.location.href,
              imgUrl: "https://blogobs.88688.team/blog/l-logo-y.jpg"
            };
            //自定义“分享给朋友”及“分享到QQ”按钮的分享内容
            wx.updateAppMessageShareData(shareData);
            //自定义“分享到朋友圈”及“分享到 QQ 空间”按钮的分享内容（1.4.0）
            wx.updateTimelineShareData(shareData);
          });
          //错误了会走 这里
          wx.error(function (res) {
            console.log("微信分享错误信息", res);
          });
        });
      },
      fenxiang(){
        this.getShareInfo()
      },
      tianzhuan(url){
           window.open(url);
      },
      yhq(id){
        this.yid=id;
      },
      //查询默认发票
      meetingDe(){
        var that = this;
        var data = {
          uid: this.grxx.id,
        };
        getfmeetingfp(data).then(res => {
          if(res.data.data.fmeetingfp.length!=0){
            that.mrfmeetingfp=res.data.data.fmeetingfp;
          }else{
            that.mrfmeetingfp={
              zname:'',
              rise:'',
              duty:'',
            };
          }
          console.log(that.mrfmeetingfp);
          console.log('表格 我出来咯')

          that.zsfpid=res.data.data.fmeetingfp.id;
        }).catch(err => {})
      },
      //查询优惠券
      fmeetingyhq(){
        var that = this;
        var data = {
          uid: this.grxx.id,
          lx:this.types,
          fid:this.id
        };
        getfmeetingyhq(data).then(res => {
          that.mrfmeetingyhq=res.data.res.fmeetingyhq;

        }).catch(err => {})

      },
      //查询所有的发票
      fmeetingyhqselect(){
        var that = this;
        var data = {
          uid: this.grxx.id,
        };
        getfmeetingfpselect(data).then(res => {
          that.mrfmeetingfplist=res.data.data.fmeetingfp;
          that.radio3=res.data.data.xid;
        }).catch(err => {})

      },
      xiugaimoren(){
        var that = this;
        var data = {
          uid: this.grxx.id,
          id: this.radio3,
        };
        getupfmeetingfpstatus(data).then(res => {
          that.dialogTableVisible4=false;
          that.meetingDe();
        }).catch(err => {})


      },


      //添加修改发票
      infmeetingfp(){
        if(!this.rise){
          ElMessage({
            type: 'info',
            message: '发票抬头不能为空！',
          })
          return false;
        }
        if(this.type==1 || this.lx==1){
          if(!this.duty){
            ElMessage({
              type: 'info',
              message: '单位税号不能为空！',
            })
            return false;
          }

        }
        if(this.lx==1){


            if(!this.address){
              ElMessage({
                type: 'info',
                message: '注册地址不能为空！',
              })
              return false;
            }
            if(!this.phone){
              ElMessage({
                type: 'info',
                message: '注册电话不能为空！',
              })
              return false;
            }
            const reg = /^[1][1,2,3,4,5,6,7,8,9][0-9]{9}$/;
            if ((!reg.test(this.phone)) && this.phone != '') {
              ElMessage({
                type: 'info',
                message: '请输入正确的注册电话',
              })
              return false;
            }

            if(!this.bank){
              ElMessage({
                type: 'info',
                message: '开户银行不能为空！',
              })
              return false;
            }
            if(!this.bankcode){
              ElMessage({
                type: 'info',
                message: '银行账号不能为空！',
              })
              return false;
            }
        }
        if(!this.email){
          ElMessage({
            type: 'info',
            message: '邮箱不能为空！',
          })
          return false;
        }

        const reg1 =/^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
        if ((!reg1.test(this.email)) && this.email != '') {
          ElMessage({
            type: 'info',
            message: '请输入正确的邮箱',
          })
          return false;
        }


        if(!this.uname){
          ElMessage({
            type: 'info',
            message: '收件人不能为空！',
          })
          return false;
        }
        if(!this.uphone){
          ElMessage({
            type: 'info',
            message: '联系电话不能为空！',
          })
          return false;
        }
        const reg = /^[1][1,2,3,4,5,6,7,8,9][0-9]{9}$/;
        if ((!reg.test(this.uphone)) && this.uphone != '') {
          ElMessage({
            type: 'info',
            message: '请输入正确的联系电话',
          })
          return false;
        }

        if(!this.uaddress){
          ElMessage({
            type: 'info',
            message: '收件地址不能为空！',
          })
          return false;
        }

        // 不能为空
        var that = this;
        var data = {
          lx:this.lx,
          type:this.type,
          rise:this.rise,
          duty:this.duty,
          address:this.address,
          phone:this.phone,
          bank:this.bank,
          bankcode:this.bankcode,
          email:this.email,
          uname:this.uname,
          uphone:this.uphone,
          uaddress:this.uaddress,
          id:this.fpid,

          fusers_id: this.grxx.id,
        };
        getinfmeetingfp(data).then(res => {
          console.log(res);
          if(that.fpid){
            ElMessage({
              type: 'success',
              message: '修改成功',
            })
          }else{
            ElMessage({
              type: 'success',
              message: '新增成功',
            })
          }
          that.fmeetingyhqselect();
          that.meetingDe();
          that.dialogTableVisible5=false;
        //详情
        }).catch(err => {})

      },
      //单个发票内容
      fmeetingfpfind(id){
        console.log(id);
        console.log('1');

        var that = this;
        var data = {
          id: id,
        };

        getfmeetingfpfind(data).then(res => {
          that.dialogTableVisible5=true;
          console.log(data);

          // that.fmeetingfpfind=res.data.data.fmeetingfp;
          that.lx=res.data.data.fmeetingfp.lx;
          that.type=res.data.data.fmeetingfp.type;
          that.rise=res.data.data.fmeetingfp.rise;
          that.duty=res.data.data.fmeetingfp.duty;
          that.address=res.data.data.fmeetingfp.address;
          that.phone=res.data.data.fmeetingfp.phone;
          that.bank=res.data.data.fmeetingfp.bank;
          that.bankcode=res.data.data.fmeetingfp.bankcode;
          that.email=res.data.data.fmeetingfp.email;
          that.uname=res.data.data.fmeetingfp.uname;
          that.uphone=res.data.data.fmeetingfp.uphone;
          that.uaddress=res.data.data.fmeetingfp.uaddress;
          that.fpid=res.data.data.fmeetingfp.id;


        }).catch(err => {})

      },
      qk(){
        var that=this;
        that.lx='';
        that.type='';
        that.rise='';
        that.duty='';
        that.address='';
        that.phone='';
        that.bank='';
        that.bankcode='';
        that.email='';
        that.uname='';
        that.uphone='';
        that.uaddress='';
        that.fpid='';
      },
      grxxff(grxxs){
        this.grxx=grxxs;
        console.log(grxxs);
        console.log('zhende');

        //查询默认发票
        this.meetingDe();
        this.fmeetingyhq();
        this.fmeetingyhqselect();
        this.qhlist(this.id, '0');
      },
      isMobile() {
       　　let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
       　　return flag;
       },
      canzhan(){

        if (this.isMobile()) {
              // alert("移动端");
              //移动端跳转路由
              this.w50='90%';
            } else {
              // alert("pc端");
        　　　　//Pc端跳转路由
              this.w50='60%';
            }

        // if(this.pdshow==false){
        //   this.payewm=true;
        //   return false;
        // }
        console.log(this.grxx);
        console.log('bb');
        console.log(this.grxx.id);
        if(!this.grxx.id){
          ElMessageBox.confirm(
              '您未登录是否登录?',
              '提示', {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                type: '提示',
              }
            )
            .then(() => {

               this.$router.push('/login')
            })
            .catch(() => {
              ElMessage({
                type: 'info',
                message: '取消成功',
              })
            })

            return false;
        }
        console.log('11');
        this.meetingDe();
        this.fmeetingyhq();
        this.fmeetingyhqselect();

        this.dialogTableVisible2=true;

      },

      //新增支付
      pay(){
        if(this.radio1a==0){
          ElMessage({
            type: 'info',
            message: '请勾选参会协议!',
          })
          return false;
        }
        if(!this.name1){
          ElMessage({
            type: 'info',
            message: '请输入姓名!',
          })
          return false;
        }
        if(!this.gsname1){
          ElMessage({
            type: 'info',
            message: '请输入公司单位!',
          })
          return false;
        }
        if(!this.phone1){
          ElMessage({
            type: 'info',
            message: '请输入手机号!',
          })
          return false;
        }
        const reg = /^[1][1,2,3,4,5,6,7,8,9][0-9]{9}$/;
        if ((!reg.test(this.phone1)) && this.phone1 != '') {
          ElMessage({
            type: 'info',
            message: '请输入正确的手机号',
          })
          return false;
        }

        if(!this.qt1){
          ElMessage({
            type: 'info',
            message: '请输入其他说明!',
          })
          return false;
        }

          var type=0;
          if (this.isMobile()) {
            // this.Mobile=true;
            type=1;
            //去支付
          }
          // alert(type);
          // return ;
        var that = this;
        var data = {
          typea:type,
          fmetting_id:this.fmeeting.id,
          name:this.name1,
          gsname:this.gsname1,
          phone:this.phone1,
          email:this.email1,
          qt:this.qt1,
          fmeetingyhq_id:this.yid,
          fstatus:this.radio1,
          fmeetingfp_id:this.zsfpid,
          type:this.types, //0=会议 1=论坛
          uid: this.grxx.id,
        };
        getpay(data).then(res => {
          console.log(res);
          if(type==0){
            ElMessage({
              type: 'success',
              message: '新增成功',
            })
            that.payewm=true;
            that.pdshow=true;
            that.dialogTableVisible4=false;
            that.dialogTableVisible2=false;
            that.prices=res.data.price;
            that.ewms='https://ymedicine.jiujingwulian.com/public/'+res.data.zfimage;
          }else{
            window.location.href=res.data.h5_url;
          }

          // that.meetingDe();
        }).catch(err => {})

      },
      closeDialog(){
          this.pdshow=false;
      },



      isMobile() {
      　　let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      　　return flag;
      },

      fapiao(){
        this.fmeetingyhqselect();
        this.dialogTableVisible4=true;
      },
      xieyi(){
        this.dialogTableVisible3=true;
      },
      xinjian(){
        this.qk();
        this.dialogTableVisible5=true;
      },
      fenlt() {
        var id = this.fmeetingfen[this.flid].id;
        this.qhlist(id, '1');
        this.types = 1;
        this.i = 0;
      },
      qhfen(index) {
        this.flid = index;
        this.hdcontent = this.fmeetingfen[index].hdcontent;

      },

      qhlist(ids, type) {

        var that = this;
        var data = {
          id: ids,
          type: this.types,
          uid: this.grxx.id,
        };
        getmeetingDe(data).then(res => {
          that.fmeeting = res.data.data.fmeeting;
          if(that.grxx.id>0){
            if(res.data.pd==true){
              that.pdshow=res.data.pd;
              if(res.data.list.status==0){
                // that.payewm=true;
                that.prices=res.data.list.price;
                that.ewms='https://ymedicine.jiujingwulian.com/public/'+res.data.list.zfimage;
              }

              console.log('我除来咯')
              console.log(res.data);
            }else{
              console.log('我获取咯')
            }
          }



          console.log('jz');
          console.log(res);


          var all = that.all;
          all[2]['name'] = that.fmeeting.name;
          that.all = all;


          that.fmeetingzb = res.data.data.fmeetingzb;
          console.log(that.fmeetingzb);
          that.fmeetingfen = res.data.data.fmeetingfen;
          that.hdcontent = that.fmeetingfen[0].hdcontent;






          console.log('<!--')
          console.log(that.fmeetingzb)
        }).catch(err => {})


      },
      qh(is) {
        this.i = is;
      },
      fenxiangs(lx){
        console.log('1');
        this.xy=!this.xy;
        console.log(this.xy);
      },
    },
    components: {
      Head,
      Foot,
      Left,
      Bread,
      Banner
    },
  }
</script>
<style lang="scss" scoped="scoped">
  body {
    background: #f5f6fa;
  }

  .a-cen {
    width: 100vw;
    background: #f5f6fa;
    padding: 0.625rem 0;

    .center {
      width: var(--allWidth);
      margin: 0 auto;
    }
  }

  .center {
    position: relative;
    .biaoti{
         position: absolute;
         top: 50%;
         right: 1rem;
         transform: translate(0px, -50%);
         display: flex;
         align-items: center;
         .bmje {
           font-size: 1.2rem;
           color: #DB2626;
         }
    }
    .mde-bt {
      background: #fff;
      display: flex;
      justify-content: center;
      border-bottom: 0.0625rem solid #E4E4E4;
      position: relative;
      .mde-bt1 {
        margin: 1.925rem 1.875rem;
        padding-bottom: 1.925rem;
        margin-bottom: 0;
        font-size: 1.125rem;
        font-weight: bold;
        cursor: pointer;
      }

      .td {
        border-bottom: 0.2025rem solid #2E9BD6;
        color: #2E9BD6;
      }
    }

    .mde-xq {
      background: #fff;
      padding: 1.875rem;
      min-height: 30vh;
      // margin-top: 1.875rem;
      margin-bottom: 0.625rem;
      display: none;

      .xx {
        display: flex;

        .x-one {
          padding: 0.95rem;
          font-size: 0.8125rem;
          margin-right: 0.625rem;
          cursor: pointer;
        }

        .bjs {
          background: #2E9BD6;
          color: #fff;
        }
      }

      .x-nrsb {
        padding: 1.875rem 0;
      }

      .xxan {
        display: flex;
        justify-content: space-around;
        padding: 0 18.75rem;

        .xxan-left {
          width: 37%;
          padding: 1.25rem 0;
          text-align: center;
          color: #fff;
          border-radius: 0.3125rem;
          background-color: #AFC81D;
          cursor: pointer;
        }

        .ru {
          background-color: #2E9BD6;
        }


      }
    }

    .xs {
      display: block;
    }

    .zj {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;

      .zhibo {
        margin-bottom: 0.825rem;
        margin-right: 5%;
        width: 30%;
        height: 14.6rem;
        cursor: pointer;

        video {
          width: 100%;
          height: 100%;
        }

        img {
          width: 100%;
        }

        position: relative;
      }

      .hc {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 0.825rem;
        color: #fff;
        background: rgba(0, 0, 0, 0.8);
      }

      .zhibo:nth-child(3n) {
        margin-right: 0 !important;
      }

    }

  }

  .ddb {
    position: relative;
    z-index: 9999999999999999999;

    .top {
      font-weight: bold;
      position: absolute;
      top: 0;
      left: 0;
      background: #2E9BD6;
      color: #FFFFFF;
      width: 100%;
      padding: 1.075rem;
      font-size: 1.2rem;
      text-align: center;
    }

    .biao {
      padding-bottom: 1rem;
    }

    .kuai {
      border-bottom: 0.1625rem solid #F8F8F8;


      .biaoti {
        padding: 1.25rem 4.875rem 0 4.875rem;

        display: flex;
        align-items: center;

        .bmfy {
          font-weight: bold;
          font-size: 0.9rem;
        }

        .bmje {
          font-size: 1.2rem;
          color: #DB2626;
        }

      }

      .k-biaoti {
        padding: 0 4.875rem 0 4.875rem;
        font-weight: bold;
        position: relative;
        margin-top: 1.25rem;
        .gh{
          position: absolute;
          top: 50%;
          right: 4.825rem;
          transform: translate(0%,-50%);
          .ans{
            background: #2E9BD6;
            padding: 0.5rem 1.5rem;
          }
        }

      }

      .k-ones {
        padding: 1.25rem 4.875rem 0 4.875rem;
        display: flex;

        .kone {
          width: 33.333%;
          text-align: center;
          background: #F8F8F8;
          padding: 0.75rem 0;
          font-size: 0.8rem;
        }

        .kone1 {
          background: #F8F8F8;
          color: #B8B8B8;
          padding: 1.075rem 1.3rem;
          margin-right: 0.925rem;
        }

        .kone2 {
          padding: 1.075rem 1.3rem;
          border: 0.0625rem solid #DBDBDB;
          margin-right: 0.925rem;
        }

        .bs {
          border: 0.0625rem solid #2E9BD6;
          color: #2E9BD6;
        }
      }


    }

    .lxr,
    .lxr1 {
      width: 50%;
      display: flex;
      align-items: center;
      // display: inline-block;
      margin-right: 0.625rem;

      .bt {
        width: 8.5rem;
        text-align: right;

        .red {
          color: red;
        }
      }

      .shuru {
        width: 60%;
      }
    }

    .lxr1 {
      width: 100%;

      .shuru {
        width: 80.5%;
      }
    }

    .hang {
      display: flex;
      margin: 0 1.175rem;
      margin-top: 0.625rem;
      margin-bottom: 1.075rem;

      .h-title {
        font-weight: bold;
        color: #000000;
        border-left: 0.225rem solid #2E9BD6;
        padding-left: 0.625rem;
      }
    }

    .dban1 {
      text-align: center;
    }

    .dban {
      text-align: center;
      margin-top: 2.175rem;
      position: relative;

      .anniu {
        width: 30%;
        text-align: center;
        padding: 3.5rem 0 1.85rem 0;
        font-size: 1rem;
        position: relative;
        background: #2E9BD6;

        .je {
          font-weight: bold;
          position: absolute;
          top: 1rem;
          left: 50%;
          transform: translate(-50%, 0);
        }
      }

      .bs {
        background: #AFC81D;
        border: 0.0625rem solid #AFC81D;
        margin-right: 2.275rem;

        img {
          width: 1rem;
          margin-right: 0.625rem;
        }
      }

      .bs1 {
        background: #2E9BD6;
      }

      .je {
        display: block ;
        font-size: 1rem;

      }
    }

  }

  .huiyixieyi {
    position: relative;
    z-index: 9999999999999999999;

    .top {
      font-weight: bold;
      position: absolute;
      top: 0;
      left: 0;
      background: #fff;
      color: #000;
      width: 100%;
      padding: 1.075rem;
      font-size: 1.2rem;
      text-align: center;
      border-bottom: 0.0625rem solid #E6E6E6;

      img {
        width: 1.5rem;
      }
    }

    .hy-nr {
      padding: 1.875rem;
    }

    .hy-bot {
      text-align: center;

      .anniu {
        width: 20%;
        margin: 0 auto;
        text-align: center;
        padding: 1.5rem 0 1.5rem 0;
        font-size: 1rem;
        background: #2E9BD6;
      }
    }

  }

  .kaipiao,
  .taitou {
    position: relative;
    z-index: 9999999999999999999;

    .top {
      position: absolute;
      font-weight: bold;
      top: 0;
      left: 0;
      background: #fff;
      color: #000;
      width: 100%;
      padding: 1.075rem;
      font-size: 1.2rem;
      text-align: center;
      border-bottom: 0.0625rem solid #E6E6E6;

      img {
        width: 1.5rem;
      }
    }

    .kai-cen {
      padding: 1.275rem;

      .k-top {
        font-size: 1rem;
        font-weight: bold;
        margin-bottom: 1rem;
      }

      .k-c-one {
        margin-bottom: 1.85rem;

        .k-cen {
          background: #F9F9F9;
          padding: 0.875rem 0;

          .k-left {
            display: inline-block;
            width: 35%;
            padding-left: 0.875rem;
          }

          position: relative;

          .tubiao {
            position: absolute;
            top: 50%;
            right: 0.875rem;
            transform: translate(0, -50%);

            img {
              width: 0.825rem;
              margin-right: 1.25rem;
              cursor: pointer;
            }
          }


        }

      }

      .k-anniu {
        text-align: center;

        .new {
          border: 0.125rem solid #2E9BD6;
          color: #2E9BD6;
          width: 20%;
          padding: 1.25rem 0;
        }

        .choice {
          width: 20%;
          padding: 1.25rem 0;
          border: 0.125rem solid #2E9BD6;
          background: #2E9BD6;
          color: #fff;
        }
      }
    }


    .tai-all{
      margin-top: 1.875rem;
      .t-a-one{
        width: 60%;
        margin: 0 auto;
        margin-bottom: 1.875rem;
        display: flex;
        align-items: center;
        .t-a-title{
          font-weight: bold;
          margin-right: 2.875rem;
          width: 20%;
          text-align: right;
        }
        .t-a-radio{
          width: 60%;

        }
      }
      .t-a-anniu{
        text-align: center;
        .choice{
          width: 50%;
          padding: 1.5rem 0;
          background: #2E9BD6;
          font-size: 1rem;
        }
      }
    }
  }

  @media screen and (max-width: 1300px) {
    .biaoti{

       position: relative!important;
       top: 50%!important;
       right: 0!important;
       transform: translateY(-0%)!important;
       display: flex!important;
       // align-items: flex-start!important;
       margin-top: -0.8rem;
    }
    .el-radio{
      margin: 0 auto!important;
    }
    .zhibo{
      width: 100%!important;
      margin-right: 0!important;
    }
    .xxan{
      padding: 0!important;
      .xxan-left{
        width: 43%!important;
        padding: 0.85rem 0!important;
        font-size: 0.9rem!important;
      }
    }
    .mde-bt1{
     margin: 0.325rem 0.875rem !important;
     padding-bottom: 0.325rem !important;
     font-size: 1rem !important;
     margin-top: 1.4rem!important;
    }
    .mde-xq{
      padding: 4%!important;
      width: 98%;
      margin: 0 auto;

    }



    .ddb{
      .hang{
        margin: 0.625rem 0rem;
        .bt{
          width: 35%;
          padding-left: 0.3125rem;
        }
      }
    }
    .el-radio-group{
      width: 75%;
    }
    .el-dialog__body{
      width: 100%!important;
      padding: 1.75rem 0!important;
      margin: 0 auto!important;
    }
    .dban{
      margin-top: 1.175rem!important;
    }
    .anniu{
      font-size: 0.95rem!important;
      padding: 1.3rem 6rem!important;
    }

    .ddb{
      .top{
        font-size: 1rem;
        padding: 0.8rem;
      }
    }
    .hang{
      flex-wrap: wrap;
    }
    .lxr{
      width: 100%!important;
      margin-bottom: 0.625rem!important;
      .bt{
        width: 30%!important;
      }

    }
   .shuru{
     width: 100%!important;
     margin-left: 0.625rem!important;
   }
   .biaoti{
     padding: 1.25rem 0.875rem 0 0.875rem!important;
   }
   .k-biaoti{
     margin-top: 0!important;

      padding: 1.2rem!important;
      .gh {
        right: 1rem!important;
      }
   }
   .kone2{
     padding: 0.875rem 0.5rem!important;
     font-size: 0.875rem!important;
   }
   .k-ones{
     padding: 0!important;

   }
   .je{
     display: none!important;
   }
   .kon{
     line-height: 1.2rem;
   }

   .kai-cen{
     padding: 0!important;
     margin-top: 1rem!important;
   }
    .k-left{
        width: 28%!important;
    }
    .tubiao{
      display: flex;
      align-items: center;
    }

    .kaipiao .top, .taitou .top{
      font-size: 1rem!important;
      padding: 0.8rem!important;
    }

    .ddb{
      .top{
        font-size: 1rem;
        padding: 0.8rem;
      }
    }
    .new{
      width: 40%!important;
      font-size: 0.75rem!important;
      padding: 1.1rem 0!important;
    }
    .choice{
      width: 40%!important;
      font-size: 0.75rem!important;
      padding: 1.1rem 0!important;
    }
    .tai-all{

      margin-top: 0.875rem!important;
      .t-a-one{
            margin-bottom: 0.875rem!important;
            width: 100%!important;
            .t-a-title{
                  margin-right: 0.2rem!important;
                  width: 29%!important;
            }
      }
    }


  }

  .ewm{
    text-align: center;
    .tp{
      display: block;
      margin: 0 auto;
      margin-bottom: 1.625rem;
      width: 5.875rem;
    }
    .eee{
        min-width: 33%;
    }
    .e-price{
      color: red;
      font-size: 1.6rem;
      margin-top: 0.625rem;
    }
    .dbs{
      background: #00c800;
      margin-top: 0.875rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      padding: 0.75rem 0;
      .saosao{
        width: 1.6875rem;
        margin-right: 0.625rem;
      }
      .e-span{
          max-width: 60%;
          text-align: left;
      }
    }

  }


  .ewm1{
    width: 90%;
    margin: 0 auto;

  }
  .tp1{
    text-align: center;
    width: 60%;
    margin: 0 auto;
    margin-bottom: 1.875rem;
    img{
      width: 100%;
    }
  }
  .dibu1{
    div{
      margin-bottom: 0.625rem;
      // text-emphasis: circle #f00;
    }
  }



</style>
