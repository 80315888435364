import { request } from "./request.js";


export function getfinvestment(params){
    console.log(params);
    return request(
      {
          url:'/public/index/index/fbusiness',
          method:'POST',
          params:params,
      }
    )
}


export function getfinvestmentin(params){
    console.log(params);
    return request(
      {
          url:'/public/index/index/fbusinessin',
          method:'POST',
          params:params,
      }
    )
}


export function getfinvestmentxq(params){
    console.log(params);
    return request(
      {
          url:'/public/index/index/fbusinessinxq',
          method:'POST',
          params:params,
      }
    )
}



export function getfbusinessorder(params){
    console.log(params);
    return request(
      {
          url:'/public/index/index/insertfbusinessorder',
          method:'POST',
          params:params,
      }
    )
}
