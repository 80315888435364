<template>
  <div class="all">
    <Head :top="top" :grxxff="grxxff" :qhs="qhs" :sousu="sousu" :nr="nr"></Head>

    <Banner :bn="bn"></Banner>
    <Bread :all="all"></Bread>
    <!-- {{sousuo}} -->

    <div class="a-cen">
      <div class="center">
        <div class="c-left">
          <div class="l-top">
            <div class="l-list">
              <!--  <div class="l-list-one ">行业头条
                <div class="l-list-one-img"></div>
              </div> -->
              <div
                @click="qhs(item.id)"
                class="l-list-one"
                v-for="item in newfl"
                :class="ph == item.id ? 'l-list-one-bgcolor' : ''"
              >
                {{ item.name }}
                <div class="l-list-one-img" v-show="ph == item.id"></div>
              </div>
              <!-- <div class="l-list-one">行业头条</div>
              <div class="l-list-one">行业头条</div> -->
            </div>
          </div>
          <div class="l-bot">
            <div
              class="z-n-one"
              v-for="item in news"
              @click="goTo('/newin', item.id)"
            >
              <div class="zno-left">
                <img
                  :src="
                    'https://ymedicine.jiujingwulian.com/public' + item.fimage
                  "
                />
              </div>
              <div class="zno-right">
                <div class="znor-title onehang">{{ item.name }}</div>
                <div class="znor-content twohang">
                  {{ item.jianjie }}
                </div>
                <div class="rq">{{ item.time }}</div>
              </div>
            </div>

            <page
              :items="items"
              :litems="litems"
              :total="total"
              :per_page="per_page"
              :current_page="current_page"
              :last_page="last_page"
              :listcount="listcount"
            ></page>
            <!--<div class="z-n-one">
              <div class="zno-left">
                <img src="~assets/about/fmt.png" />
              </div>
              <div class="zno-right">
                <div class="znor-title">公司名称</div>
                <div class="znor-content">
                  新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新新闻内容新闻新闻内容新闻闻
                  内容新闻内容新闻内容新闻内容...
                </div>
                <div class="rq">2022-02-02</div>
              </div>
            </div>


            <div class="z-n-one">
              <div class="zno-left">
                <img src="~assets/about/fmt.png" />
              </div>
              <div class="zno-right">
                <div class="znor-title">公司名称</div>
                <div class="znor-content">
                  新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新新闻内容新闻新闻内容新闻闻
                  内容新闻内容新闻内容新闻内容...
                </div>
                <div class="rq">2022-02-02</div>
              </div>
            </div> -->
          </div>
        </div>

        <div class="c-right">
          <div class="r-ban">
            <img src="~assets/new/ban.png" />
          </div>

          <!-- <div class="r-top">
            <div class="r-top-title">最新公告</div>
            <div class="r-top-list">
              <div class="r-top-time"><img src="~assets/new/sq.png" /><span>2022-09-08</span></div>
              <div class="r-top-cot">内容内容内容内容内容</div>
            </div>
            <div class="r-top-list">
              <div class="r-top-time"><img src="~assets/new/sq.png" /><span>2022-09-08</span></div>
              <div class="r-top-cot">内容内容内容内容内容</div>
            </div>

            <div class="r-top-list">
              <div class="r-top-time"><img src="~assets/new/sq.png" /><span>2022-09-08</span></div>
              <div class="r-top-cot">内容内容内容内容内容</div>
            </div>

            <div class="r-top-list">
              <div class="r-top-time"><img src="~assets/new/sq.png" /><span>2022-09-08</span></div>
              <div class="r-top-cot">内容内容内容内容内容</div>
            </div>
          </div> -->
          <Journalism></Journalism>

          <Notice :pd="pd"></Notice>
        </div>
      </div>
    </div>
    <foot></foot>
    <left></left>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { getHome, getbanner, getnew } from "network/home.js";
import { useRouter } from "vue-router";

import Head from "components/content/headder/Head.vue";
import Foot from "components/content/footer/Foot.vue";
import Left from "components/content/left/Left.vue";
import Bread from "components/content/breadcrumb/Breadcrumb.vue";

import Banner from "components/content/banner/Banner.vue";
import Journalism from "components/content/journalism/Journalism.vue";
import Notice from "components/content/notice/Notice.vue";
import Page from "components/content/page/Page.vue";

export default {
  name: "New",
  data() {
    return {
      items: [],
      litems: [],
      page: 0,
      total: "",
      per_page: "",
      current_page: "",
      last_page: "",

      pd: 1,
      ph: 0,
      bn: "",
      sousuo: "",
      sousu: "",
      news: [],
      newfl: [],
      all: [
        {
          name: "您当前的位置:首页",
          url: "/",
        },

        {
          name: "资讯",
          url: "/New",
        },
      ],
    };
  },
  setup() {
    const top = 3;
    const router = useRouter();
    const databa = ref([]);
    const ab = "sb";
    console.log(ab);
    onMounted(() => {
      getHome()
        .then((res) => {
          console.log(res.data.data);
          databa.value = res.data;
          console.log(databa);
        })
        .catch((err) => {});
    });
    //跳转方法
    const goTo = (path, cs) => {
      var query = {
        id: cs,
      };

      router.push({
        path: path,
        query: query,
      });
    };

    return {
      databa,
      ab,
      goTo,
      top,
    };
  },
  mounted() {
    // var url = decodeURI(window.location.search); //?id="123456"&name="www";
    // // var url = window.location.href;
    // console.log(url);
    // console.log(222222222);
    // var object = {};
    // if (url.indexOf("?") != -1) //url中存在问号，也就说有参数。
    // {
    //   var str = url.substr(1); //得到?后面的字符串
    //   var strs = str.split("&"); //将得到的参数分隔成数组[id="123456",name="www"];
    //   for (var i = 0; i < strs.length; i++) {
    //     object[strs[i].split("=")[0]] = strs[i].split("=")[1]; //得到{id:'123456',name:'www'}
    //   }
    // }
    // console.log(object);
    // var id = object['id'];
    // this.sousuo = object['sousuo'];
    // this.sousu = object['sousuo'];

    var id = this.GetUrlParam("id");
    this.sousuo = this.GetUrlParam("sousuo");
    this.sousu = this.GetUrlParam("sousuo");

    if (id) {
      this.getfl(id);
    } else {
      this.getfl();
    }

    this.banner();

    window.scrollTo(0, 0);
  },
  methods: {
    GetUrlParam(paraName) {
      var url = window.location.toString();
      var arrObj = url.split("?");
      if (arrObj.length > 1) {
        var arrPara = arrObj[1].split("&");
        var arr;
        for (var i = 0; i < arrPara.length; i++) {
          arr = arrPara[i].split("=");
          if (arr != null && arr[0] == paraName) {
            return arr[1];
          }
        }
        return "";
      } else {
        return "";
      }
    },
    grxxff(grxxs) {
      this.grxx = grxxs;
      console.log(grxxs);
      console.log("zhende");
    },
    nr(sousuo) {
      console.log(sousuo);
      this.sousuo = sousuo;
      this.sousu = sousuo;

      this.getfl();
    },
    banner() {
      var that = this;
      var data = {
        i: 1,
      };
      getbanner(data)
        .then((res) => {
          that.bn =
            "https://ymedicine.jiujingwulian.com/public" + res.data[0].bimage;
        })
        .catch((err) => {});
    },
    listcount(page) {
      this.page = page;
      this.banner();
      this.getfl(this.ph);
    },

    getfl(fnewfl_id) {
      var that = this;
      var data = {
        type: 0,
        sousuo: this.sousuo,
        fnewfl_id: fnewfl_id,
        page: this.page,
        limit: 100,
      };
      getnew(data)
        .then((res) => {
          that.newfl = res.data.list;
          if (fnewfl_id) {
            that.ph = fnewfl_id;
          } else {
            that.ph = that.newfl[0].id;
          }

          that.news = res.data.data.table.data;

          that.total = res.data.data.table.total;
          that.per_page = res.data.data.table.per_page;
          that.current_page = res.data.data.table.current_page;
          that.last_page = res.data.data.table.last_page;

          that.items = res.data.data.items;
          that.litems = res.data.data.litems;
        })
        .catch((err) => {});
    },
    // ggnews:'',
    // tj:'',
    qhs(tid) {
      this.page = 0;
      this.getfl(tid);
      // window.scrollTo( 100, 100 );
    },
    tjyd() {
      var that = this;
      var data = {
        type: 3,
      };
      getnew(data)
        .then((res) => {
          that.tjnews = res.data.data;
        })
        .catch((err) => {});
    },
    zxgg() {},
  },

  components: {
    Head,
    Foot,
    Left,
    Bread,
    Notice,
    Journalism,
    Page,
    Banner,
  },
};
</script>
<style lang="scss" scoped="scoped">
.a-cen {
  width: 100vw;
  background: #f5f6fa;

  .center {
    width: var(--allWidth);
    margin: 0 auto;
  }
}

.bn {
  width: 100%;

  img {
    width: 100%;
  }
}

.center {
  padding: 1.25rem 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .c-left {
    width: 70%;

    .l-top {
      background: #fff;

      .l-list {
        display: flex;
        align-items: flex-start;
        background: #fff;
        .l-list-one:hover {
          color: #2e9bd6;
          background: #d7e9fd;
        }
        .l-list-one {
          padding: 1.225rem 1.225rem;
          font-size: 1.05rem;
          cursor: pointer;
          position: relative;

          .l-list-one-img {
            position: absolute;
            bottom: 0;
            left: 50%;
            background: #2e9bd6;
            width: 20px;
            height: 20px;
            transform: translate(-50%, 50%) rotate(45deg);
          }
        }

        .l-list-one-bgcolor {
          background: #2e9bd6 !important;
          color: #fff !important;
        }
      }
    }
  }

  .c-right {
    width: 28%;

    .r-ban {
      width: 100%;

      img {
        width: 100%;
      }
    }

    .r-bot {
      background: #fff;
      min-height: 6.25rem;
      margin-top: 0.625rem;
      padding: 1.225rem;

      .r-top-title {
        font-size: 1.175rem;
        font-weight: bold;
      }
    }

    .r-top {
      background: #fff;
      min-height: 6.25rem;
      margin-top: 0.625rem;
      padding: 1.225rem;

      .r-top-title {
        font-size: 1.175rem;
        font-weight: bold;
      }

      .r-top-list {
        margin-top: 1.225rem;

        .r-top-time {
          font-size: 0.875rem;
          color: #a7a7a7;
          display: flex;
          align-items: center;

          img {
            width: 0.8075rem;
            margin-right: 0.625rem;
          }
        }

        .r-top-cot {
          margin-left: 1.625rem;
          margin-top: 0.325rem;
          font-size: 0.875rem;
        }
      }

      .r-bot {
      }
    }
  }
}

.l-bot {
  margin-top: 1.875rem;

  .z-n-one:hover {
    background: linear-gradient(
      rgba(98, 186, 238, 0.1568627450980392) 0%,
      rgba(196, 196, 196, 0) 100%
    );

    .znor-title {
      color: #2e9bd6;
    }
  }

  .z-n-one {
    padding: 0.825rem;
    padding-bottom: 1rem;
    border-bottom: 0.0625rem solid #dbdbdb;
    display: flex;
    // align-items: center;
    align-items: flex-start;
    // margin-bottom: 1rem;
    background: #fff;
    cursor: pointer;

    position: relative;

    .zno-left {
      margin-right: 1.875rem;

      img {
        width: 9.875rem;
        border-radius: 0.25rem;
      }
    }

    .zno-right {
      .znor-title {
        font-size: 1.075rem;
        font-weight: bold;
        margin-bottom: 0.675rem;
      }

      .znor-content {
        font-size: 0.7rem;
        color: #888888;
        line-height: 1rem;
      }

      .rq {
        font-size: 0.7rem;
        color: #888888;
        margin-top: 0.625rem;
        position: absolute;
        bottom: 17%;
        // left: 52%;
      }
    }
  }

  .z-n-one1 {
    padding: 0;
    padding-bottom: 0.8rem;
    align-items: normal;

    .zno-left {
      margin-right: 0.675rem;

      img {
        width: 7rem;
        border-radius: 0.75rem;
      }
    }

    // .zon-left{
    //   img{
    //     width: 10%!important;
    //   }
    // }
  }

  .zno-right {
    padding-top: 0.25rem;
  }
}

@media screen and (max-width: 1300px) {
  .c-left {
    width: 95% !important;
    margin: 0 auto;
  }

  .l-list-one {
    font-size: 0.6rem !important;
  }

  .znor-title {
    font-size: 0.9rem !important;
  }

  .c-right {
    width: 95% !important;
    margin: 0 auto !important;

    margin-top: 0.625rem !important;
  }

  .zno-left {
    margin-right: 0.875rem !important;
  }

  .znor-title {
    line-height: 1.2rem !important;
  }
}
</style>
