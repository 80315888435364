<template>
  <div class="all">
    <Head :top="top" :grxxff="grxxff"></Head>
    <div class="center">
      <div class="c-all">
        <MyLeft :topleft="topleft"></MyLeft>

        <div class="c-right">
          <div class="c-top" @click="fanhui()"><img src="~assets/about/fh.png" /><span>个人资料</span></div>


          <div class="from" v-show="zt=='0' || zt=='3'">

            <div class="one">
              <span class="xingming"><span class="xing">*</span>真实姓名:</span>
              <div class="kd">
                <el-input v-model="name" size="large" placeholder="请输入" clearable />
              </div>
            </div>
            <div class="one">
              <span class="xingming"><span class="xing">*</span>性别:</span>
              <div class="kd">
                <el-radio-group v-model="gender" class="ml-4">
                  <el-radio label="1" size="large">男</el-radio>
                  <el-radio label="2" size="large">女</el-radio>
                </el-radio-group>
             </div>
            </div>
            <div class="one">
              <span class="xingming"><span class="xing">*</span>请选择:</span>
              <div class="kd">
                <el-cascader :options='options' :placeholder="zw" style="width: 100%;" class="ml-4"  v-model="seLectedoptions1" @change="addressChange" ></el-cascader>
              <!-- <el-select v-model="value" class="m-2" placeholder="选择省" size="large">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
              <el-select v-model="value" class="m-2" placeholder="选择市" size="large">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
              <el-select v-model="value" class="m-2" placeholder="选择区/县" size="large">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
              </el-select> -->
              </div>
            </div>

            <div class="one">
              <span class="xingming"><span class="xing">*</span>邮箱:</span>
              <div class="kd">
                <el-input v-model="email" size="large" placeholder="请输入" clearable />
              </div>
            </div>

            <div class="one">
              <span class="xingming"><span class="xing"></span>Q Q:</span>
              <div class="kd">
                <el-input v-model="qq" size="large" placeholder="请输入" clearable />
              </div>
            </div>
            <div class="one">
              <span class="xingming"><span class="xing">*</span>手机号:</span>
              <div class="kd">
                <el-input v-model="phone" size="large" placeholder="请输入" clearable />
              </div>
            </div>


            <div class="one">
              <span class="xingming"><span class="xing"></span>固定电话:</span>
              <div class="kd">
                <el-input v-model="gphone" size="large" placeholder="请输入" clearable />
              </div>
            </div>
            <div class="one">
              <span class="xingming"><span class="xing">*</span>职位:</span>
              <div class="kd">
                <el-input v-model="zhiwei" size="large" placeholder="请输入" clearable />
              </div>
            </div>

            <div class="one">
              <span class="xingming"><span class="xing"></span>个人简介:</span>
              <div class="kd">
                <el-input
                    v-model="jianjie"
                    :autosize="{ minRows: 2, maxRows: 4 }"
                    type="textarea"
                    placeholder="请输入"
                  />
              </div>
            </div>
            <div class="one" >
              <span class="xingming"><span class="xing"></span></span>
              <div class="kd">
                <el-button type="primary"  @click="open()"  class="baocun">修改</el-button>
              </div>
            </div>


          </div>


          <!-- shzt 0= 待审核,1=已驳回 -->
          <div class="shenhe" v-show="zt=='1' && shzt==0">
            <div class="sh-img">
              <img src="~assets/my/cg.png"  />
            </div>
            <div class="sh-title">恭喜您，您的资料已经提交成功</div>
            <div class="sh-jianjie">请等待审核！</div>
            <div><el-button type="primary" class="baocun">立即支付</el-button></div>
          </div>

          <div class="shenhe" v-show="zt=='1' && shzt==1">
            <div class="sh-img">
              <img src="~assets/my/cw.png"  />
            </div>
            <div class="sh-title">抱歉，您的申请已被驳回</div>
            <div class="sh-jianjie">请修改后重新提交！</div>
            <div><el-button type="primary" class="baocun">修改申请表</el-button></div>
          </div>

          <div class="huifei" v-show="zt=='2'">
            <div class="h-dengji">
              <div class="h-d-title">申请等级:</div>
              <div class="h-d-con">理事</div>
            </div>
            <div class="h-dengji">
              <div class="h-d-title">应缴会费:</div>
              <div class="h-d-con1">￥10000.00</div>
            </div>
            <el-button type="primary" class="baocun td">立即支付</el-button>
          </div>


        </div>

      </div>
    </div>


    <foot></foot>
    <left></left>
  </div>
</template>

<script>
  import {
    ref,
    onMounted
  } from 'vue';
  import {
    getHome
  } from 'network/home.js';
  import {
    useRouter
  } from 'vue-router';

  import {
    getxgxx,
  } from 'network/my.js';

  import {
    ElMessage,
    ElMessageBox
  } from 'element-plus';
  import {
    Action
  } from 'element-plus';



  import Head from "components/content/headder/Head.vue";
  import Foot from "components/content/footer/Foot.vue";
  import Left from "components/content/left/Left.vue";

  import MyLeft from "views/my/ChildComps/MyLeft.vue";

  import {regionData,CodeToText} from 'element-china-area-data';


  export default {
    name: 'My',
    data() {
      return {
        options:regionData,//不带全部的一级联动//
        // seLectedoptions1:"请选择",//或者写字符串，不影响数组格式
        seLectedoptions1:[
          '110009','110109','110101'
          ], //这里给一个默认省X

        zt:3,
        shzt:1,//shzt 0= 待审核,1=已驳回
        topleft:7,
        radio1: 1,
        textarea2:'',
        // options:[
        //   {
        //     value:'城市',
        //     lable:'0'
        //   },
        // ]
        // ,
        name: '',
        grxx: [],

        zw:'请选择',
        name: '',
        gender:0,
        address:'',
        email:'',
        qq:'',
        phone:'',
        gphone:'',
        zhiwei:'',
        jianjie:'',



      }
    },
    setup() {
      const top = 0;
      const router = useRouter();
      const databa = ref([]);
      const ab = 'sb';
      console.log(ab);
      onMounted(() => {

        getHome().then(res => {
          console.log(res.data.data);
          databa.value = res.data;
          console.log(databa);

        }).catch(err => {

        })

      })
      //跳转方法
      const goTo = (path, query) => {
        router.push({
          path: path,
          query: query || {}
        })
      }

      return {
        databa,
        ab,
        goTo,
        top
      }
    },
    methods: {

      addressChange (arr) {
      // console.log(this.selectedoptions) // proxy [0: '120000', 1: '20100, 2: '120101]//
      console.log(arr) // Proxy f0: 120000，1: 120100，2: 1201011 和上句#
      var addressText=CodeToText[arr[0]]+CodeToText[arr[1]]+CodeToText[arr[2]];
      console.log(addressText);
      this.address=addressText;
      // var addressText=CodeToText[arr@]]+CodeToText[arr[1]]+CodeToTextarr[2]];
      // console.log(addressText) // addressText为el-cascader的值北京市 市X 朝阳X
      },

      grxxff(grxxs) {
       this.grxx = grxxs;
       // 状态:0=未提交,1=待审核,2=已审核,3=已驳回,4=已支付
       if(this.grxx.stauts==1){
         this.zt=this.grxx.stauts;
         this.shzt=0;
       }else if(this.grxx.stauts==2){
         this.zt=this.grxx.stauts;
       }else if(this.grxx.stauts==3){
         this.zt=1;
         this.shzt=1;
       }else if(this.grxx.stauts==4){
         this.zt=3;
       }
       this.price=this.grxx.price;

       this.name=this.grxx.name;
       this.gender=this.grxx.gender;
       // this.name=this.grxx.name;
       // this.seLectedoptions1=
       this.email=this.grxx.email;
       this.qq=this.grxx.qq;
       this.phone=this.grxx.phone;
       this.gphone=this.grxx.gphone;
       this.zhiwei=this.grxx.zhiwei;
       this.jianjie=this.grxx.jianjie;
       this.zw=this.grxx.address;
       this.address=this.grxx.address;

       console.log(grxxs);
       console.log('zhende');
      },
      mounted() {

        window.scrollTo( 0, 0 );
      },

      open() {
        console.log(this.grxx);
        if (!this.grxx.id) {
          this.$router.push('/login')
        } else {
          this.dialogTableVisible2 = true;
        }


        if (!this.name) {
          ElMessage({
            type: 'info',
            message: '请输入姓名',
          })
          return false;
        }
        if (!this.gender) {
          ElMessage({
            type: 'info',
            message: '请选择性别',
          })
          return false;
        }
        if (!this.address) {
          ElMessage({
            type: 'info',
            message: '请选择地址',
          })
          return false;
        }
        if (!this.email) {
          ElMessage({
            type: 'info',
            message: '请输入邮箱',
          })
          return false;
        }


        const reg1 =/^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
        if ((!reg1.test(this.email)) && this.email != '') {
          ElMessage({
            type: 'info',
            message: '请输入正确的邮箱',
          })
          return false;
        }

        if (!this.phone) {
          ElMessage({
            type: 'info',
            message: '请输入手机号',
          })
          return false;
        }
        const reg = /^[1][1,2,3,4,5,6,7,8,9][0-9]{9}$/;
        if ((!reg.test(this.phone)) && this.phone != '') {
          ElMessage({
            type: 'info',
            message: '请输入正确的手机号',
          })
          return false;
        }
        if (!this.zhiwei) {
          ElMessage({
            type: 'info',
            message: '请输入职位',
          })
          return false;
        }






        ElMessageBox.confirm(
            '是否确认提交?',
            '提示', {
              confirmButtonText: '确认',
              cancelButtonText: '取消',
              type: '提示',
            }
          )
          .then(() => {
            ElMessage({
              type: 'success',
              message: '修改成功',
            })
            this.tijiao();
          })
          .catch(() => {
            ElMessage({
              type: 'info',
              message: '取消成功',
            })
          })
      },

      tijiao(){
        console.log(this.grxx.id);
        console.log('jll');

        var that = this;
        var data = {
          name:that.name,
          gender:that.gender,
          address:that.address,
          email:that.email,
          qq:that.qq,
          phone:that.phone,
          gphone:that.gphone,
          zhiwei:that.zhiwei,
          jianjie:that.jianjie,
          uid:this.grxx.id,
          status:1,
        };
        getxgxx(data).then(res => {

          console.log('zx');
          console.log(res);
          // that.zt=1;


        }).catch(err => {})

      },

      fanhui(){
        window.history.go(-1);
      }
    },
    components: {
      Head,
      Foot,
      Left,
      MyLeft
    },
  }
</script>
<style lang="scss" scoped="scoped">
  .all {
    background: #f5f6fa;
  }

  .center {
    .c-all {
      width: var(--allWidth);
      margin: 0 auto;
      margin-top: 1.275rem;
      display: flex;

      .c-right {
        margin-left: 1.275rem;
        background: #fff;
        width: 90%;
        margin-bottom: 3.125rem;

        .c-top {
          font-weight: bold;
          padding: 1.275rem;
          border-bottom: 0.0625rem solid #E8E8E8;
          cursor: pointer;
          img {
            vertical-align: middle;
            width: 1.0875rem;
            margin-right: 0.625rem;
          }

          span {
            vertical-align: middle;
          }
        }

        .c-r-cen {
          padding: 0 1.875rem;
          display: flex;
          justify-content: space-between;

          .c-r-r-left {
            width: 40%;
            padding: 2.575rem 0;
            text-align: center;

            .xm {
              margin-top: 0.875rem;
            }

            .c-c-all {
              margin-top: 1.875rem;
              display: flex;
              justify-content: center;

              .c-rrone {
                padding: 0.3125rem 0.825rem;
                font-size: 0.8125rem;
                color: #747474;
                border: 0.0625rem solid #747474;
                display: inline-block;
                border-radius: 1.875rem;
                display: flex;

                img {
                  width: 0.75rem;
                  margin-right: 0.5rem;
                }

                margin-right: 10px;
                cursor: pointer;
              }

              .op {
                background: #2E9BD6;
                border: 0.0625rem solid #2E9BD6;
                color: #fff;
              }

              .oo {
                background: #AFC81D;
                border: 0.0625rem solid #AFC81D;
                color: #fff;
              }

            }


            .c-c-bo {
              margin-top: 3.0rem;
              display: flex;
              align-items: center;
              justify-content: space-around;


              .c-c-c-on {
                border: 0.0625rem solid #E5E5E5;
                padding: 2.2rem;

                span {
                  display: inline-block;
                  margin-bottom: 0.625rem;
                  font-size: 1.6rem;
                  color: #FF9135;
                }

                div {
                  font-size: 0.8rem;
                }

                .er {
                  color: #808080;
                }

                .san {
                  color: #2E9BD6;
                }
              }
            }
          }


          .c-r-r-Right {
            width: 58%;

            .el-carousel {
              height: 24.075rem;
              position: relative;

              div {
                height: 24.075rem !important;
              }
            }

            .el-carousel__container {
              // height: 24.075rem!important;
            }

            .el-carousel__indicators {
              width: auto;
              position: absolute;
              right: -6.75rem;
              bottom: 0.5rem;
            }


            .zj {
              width: 100%;

              img {
                width: 100%;
                height: 100%;
              }
            }


          }

          .c-r-top {
            background: #F7FCFF;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            padding: 1.875rem 0;
            margin-top: 0.825rem;

            .jiantou {
              margin: 0 0.325rem;
              margin-top: -1.25rem;
            }

            .c-r-pp {
              text-align: center;
              font-size: 0.8125rem;
              color: #686868;
              font-weight: bold;

              div {
                margin-top: 0.625rem;
              }
            }

            .zd {
              color: #2E9BD6;
            }
          }
        }


        .gy {
          padding: 0 2.875rem;
          display: flex;
          justify-content: space-between;

          .g-left {
            width: 40%;

            .g-l-top {
              background: #2E9BD6;
              padding: 0.825rem;
              color: #fff;
            }

            .g-l-le {
              border: 0.0625rem solid #E8E8E8;

              .g-l-o {
                padding: 0.725rem;
                font-size: 0.65rem;
                display: flex;
                align-items: center;

                img {
                  margin-right: 0.325rem;
                  width: 0.425rem;
                }
              }
            }
          }

          .g-right {
            width: 58%;

            .g-l-top {
              background: #AFC81D;
              padding: 0.825rem;
              color: #fff;
            }

            .g-l-le {
              border: 0.0625rem solid #E8E8E8;
              display: flex;
              justify-content: center;
              align-items: center;

              .shu {
                background: #E5E5E5;
                width: 0.0625rem;
                height: 3.625rem;
              }

              .g-l-lleft {
                text-align: center;
                width: 50%;
                padding: 1.08rem 0;

                img {
                  width: 8.75rem;
                  margin-bottom: 1.475rem;
                }

                .xm {
                  display: flex;
                  justify-content: center;

                  // align-items: center;
                  img {
                    width: 1.275rem;
                    margin-right: 0.3125rem;
                  }
                }

                .xms {
                  width: 97%;
                  text-align: center;
                  margin: 0 auto;
                  padding: 0.625rem 0;
                  background: #F9FAF1;
                  font-size: 0.8125rem;
                  letter-spacing: 0.1875rem;
                  margin-top: 0.8rem;
                }

                .sx {
                  background: #F4FBFF;
                }
              }
            }

          }
        }
      }

    }

  }

  .from {
    width: 80%;
    margin: 0 auto;
    margin-top: 1.875rem;
    padding-bottom: 1.875rem;
    position: relative;
    .bj{
      background: rgba(0,0,0,0);
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 9999999;
    }
    .tg{
      position: absolute;
      z-index: 99999999;
      bottom: 6.875rem;
      right: 8.875rem;
      img{
        width: 8rem;
      }
    }
    .one {
      display: flex;
      align-items: center;
      margin-bottom: 1.075rem;

      .xing {
        color: red;
      }

      .xingming {
        margin-right: 0.625rem;
        width: 6.5rem;
        text-align: right;
        display: inline-block;
      }
      .m-2{
        margin-right: 1rem;
      }
      .kd{
        width: 80%;
      }
    }

  }
  .baocun{
    padding:1.2rem 4.325rem;
    background: #2E9BD6;
  }
  .shenhe{
    text-align: center;
    margin-top: 1.875rem;
    .sh-img{
      margin-bottom: 1.25rem;
      img{
        width: 3.8rem;
      }
    }
    .sh-title{
      margin-bottom: 1.25rem;
    }
    .sh-jianjie{
      color: #7D7D7D;
      font-size: 0.9375rem;
      margin-bottom: 1.25rem;
    }
  }

  .huifei{
    width: 60%;
    margin: 0 auto;
    margin-top: 1.875rem;
    text-align: center;
    .h-dengji{
      border-top: 0.0625rem solid #E8E8E8;
      border-bottom: 0.0625rem solid #E8E8E8;
      padding: 1.875rem;
      div{
        display: inline-block;
      }
      font-size: 1.125rem;
      .h-d-title{
        margin-right: 0.625rem;
      }
      .h-d-con{
        // font-size: 1.2rem;
        font-weight: bold;
        width: 3.875rem;
      }
      .h-d-con1{
        font-weight: bold;
        width: 3.875rem;
        color: #F63434;
      }
    }
    .td{
      margin-top: 1.875rem;
      padding:1.6rem 5.325rem;
      font-weight: bold;
      font-size: 1rem;
    }
  }

   @media screen and (max-width: 1300px) {
      .c-all{
        width: 95%!important;
        margin: 0 auto;
        flex-wrap: wrap;
      }
      .c-right{
        width: 100%!important;
        margin: 0 auto!important;
      }

      .lefts{
        width: 100%;
      }

      .c-right{
       .center{
            margin: 0rem !important;
            padding: 0.75rem!important;
       }
      }

      .c-bt{
        display: flex;
      }
      .c-lt{
        padding: 0.65rem 0!important;
        width: 36%;
        text-align: center;
        margin-bottom: 0.625rem;
      }

      .from{
            width: 95%!important;
      }
      .ddb{
        .top{
          font-size: 1rem;
          padding: 0.8rem;
        }
      }
      

   }

</style>
