<template>
  <div class="all">
    <Head :top="top" :grxxff="grxxff"></Head>
    <div class="center">
      <div class="c-all">
        <MyLeft :topleft="topleft"></MyLeft>

        <div class="c-right">
          <div class="c-top" @click="fanhui()"><img src="~assets/about/fh.png" /><span>点击核销</span></div>
          <div class="saoyisao" @click="handleScan()">
            <img src="~assets/about/saomiao.png" />

          </div>
          <div class="ddb">

            <div class="ddgy dbx">
              <div class="d-bt">订单概要</div>
              <div class="ddgy-one">
                <div class="d-o-bt">订单编号：{{list.name}}</div>
                <div class="d-o-bt">订单类型：{{list.lx}}</div>
              </div>
              <div class="ddgy-one">
                <div class="d-o-bt">创建时间：{{list.time}}</div>
                <div class="d-o-bt">支付时间：{{list.ztime}}</div>
              </div>
              <div class="ddgy-one">
                <div class="d-o-bt">支付状态：{{list.status}}</div>
                <div class="d-o-bt">支付方式：微信</div>
              </div>

            </div>

            <div class="ddgy">
              <div class="d-bt">订单概要</div>
              <div class="table">
                <div class="tr">
                  <div class="td">会议名称</div>
                  <div class="td">起止时间</div>
                  <div class="td">所在城市</div>
                  <div class="td">当前状态</div>
                </div>

                <div class="tr1">
                  <div class="td">{{list.name}}</div>
                  <div class="td">{{list.stime}}-{{list.dtime}}</div>
                  <div class="td">{{list.cityname}}</div>
                  <div class="td">{{list.zt}}</div>
                </div>
              </div>
            </div>

            <div class="ddgy">
              <div class="d-bt">报名信息</div>
              <div class="ddgy-one">
                <div class="d-o-bt">姓名：{{list.uname}}</div>
                <div class="d-o-bt">公司/单位名称：{{list.gsname}}</div>
              </div>
              <div class="ddgy-one">
                <div class="d-o-bt">手机号：{{list.phone}}</div>
                <div class="d-o-bt">邮箱：{{list.email}}</div>
              </div>
              <div class="ddgy-one">
                <div class="d-o-bt">其他说明：{{list.qt}}</div>
                <div class="d-o-bt">是否开票：{{list.kp}}</div>
              </div>
              <div class="ddgy-one" v-show="kp='是'">
                <div class="d-o-bt">发票抬头：{{list.taitou}}</div>
                <div class="d-o-bt">税号: {{list.sh}}</div>
              </div>
            </div>

            <div class="dban">
              <el-button class="anniu bs" type="primary" @click="qiandao(list)"><img
                  src="../../assets/my/bis.png" />立即核销
              </el-button>
              <el-button class="anniu bs1" type="primary" @click="jkzl(list)">返回扫码</el-button>
            </div>

          </div>



        </div>

      </div>
    </div>



    <foot></foot>
    <left></left>
  </div>
</template>

<script>
  import {
    ref,
    onMounted
  } from 'vue';
  import {
    getHome
  } from 'network/home.js';

  import {
    getSignature,
    getfmeeting
  } from 'network/mywriteoff.js';
  import {
    useRouter
  } from 'vue-router';


  import Head from "components/content/headder/Head.vue";
  import Foot from "components/content/footer/Foot.vue";
  import Left from "components/content/left/Left.vue";

  import MyLeft from "views/my/ChildComps/MyLeft.vue";
  // import qrcode1 from "components/content/qrcodereader/QrcodeReader.vue";

  export default {
    name: 'My',
    data() {
      return {
        list:{},
        kp: 0,
        zt: 0,
        shzt: 1, //shzt 0= 待审核,1=已驳回
        topleft: 0, //左边
        radio1: 1,
        textarea2: '',
        options: [{
          value: '城市',
          lable: '0'
        }, ],
        name: '',
        grxx: [],
      }
    },
    setup() {
      const top = 9;
      const router = useRouter();
      const databa = ref([]);
      const ab = 'sb';
      console.log(ab);
      onMounted(() => {

        getHome().then(res => {
          console.log(res.data.data);
          databa.value = res.data;
          console.log(databa);

        }).catch(err => {

        })

      })
      //跳转方法
      const goTo = (path, query) => {
        router.push({
          path: path,
          query: query || {}
        })
      }

      return {
        databa,
        ab,
        goTo,
        top
      }
    },
    created() {
      this.dingdan();
      this.wxconfigInit();
    },

    methods: {
      dingdan(){
        var that=this;
        var data = {
          id:3
        };
        // return false;
        getfmeeting(data).then(res => {
          that.list=res.data;
          console.log(that.list);
        }).catch(err => {})

      },
      wxconfigInit() {
        const urlValue = (window.location.href).split('#')[0] // 动态获取当前页的#之前的url地址，这个是有https:// 或者http://的
        console.log("uuuuuuu " + urlValue);
        let params = {
          url: urlValue
        };
        getSignature(params).then(res => {
            console.log("sign : " + JSON.stringify(res));
            if (res != null) {
              this.sign.appId = res.appid;
              this.sign.timestamp = res.timestamp;
              this.sign.nonceStr = res.nonceStr;
              this.sign.signature = res.signature;
              console.log(this.sign);
              // 调用后端接口返回，配置需要的结果
              wx.config({
                debug: true, // 开启调试模式,
                appId: res.appid, // 必填，企业号的唯一标识，此处填写企业号corpid
                timestamp: res.timestamp, // 必填，生成签名的时间戳
                nonceStr: res.nonceStr, // 必填，生成签名的随机串
                signature: res.signature, // 必填，签名，见附录1
                jsApiList: ['checkJsApi', 'scanQRCode'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
              })
            }
          })
          .catch(err => {
            console.log(err.msg)
          })


      },
      //扫码
      handleScan() {
        //获取服务器签名
        let _this = this;
        console.log('1');
        wx.error(function(res) {
          alert("出错了：" + JSON.stringify(res)); //这个地方的好处就是wx.config配置错误，会弹出窗口哪里错误，然后根据微信文档查询即可。
        });
        wx.ready(function() {
          wx.checkJsApi({ //这个配置检查也是显示正确
            jsApiList: ['scanQRCode'], //检查是否有扫描有二维码权限
            success: function(res) {
              if (res.checkResult.scanQRCode === true) {
                wx.scanQRCode({
                  needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                  // scanType : [ "qrCode"], // 可以指定扫二维码还是一维码，默认二者都有
                  success: function(res) {
                    alert(JSON.stringify(res) + 'success')
                    // let result
                    // if (res.resultStr.indexOf(',') != -1) {
                    //   result = res.resultStr.split(',')[1]; // 当needResult 为 1 时，扫码返回的结果
                    // } else {
                    //   result = res.resultStr
                    // }
                    // _this.formData.contractNum = result
                  },
                  fail: function(error) {
                    alert('fail' + res)
                  },
                  error: function(error) {
                    alert('error', error)
                  }
                });
              } else {
                alert('抱歉,当前客户端版本不支持扫一扫')
              }
            },
            fail: function(res) { // 检测getNetworkType该功能失败时处理
              alert('fail' + res)
            }
          });

        })
      },


      // // 打开相机

      // clickCode() {

      //   // camera:: 'rear'--前摄像头，'front'后摄像头，'off'关闭摄像头，会获取最后一帧显示，'auto'开始获取摄像头

      //   this.qrcode = true

      //   this.camera = 'rear'

      // },

      // // 扫码结果回调

      // onDecode(result) {

      //   console.log(result)

      //   this.turnCameraOff()

      // },

      // // 相机反转

      // switchCamera() {

      //   switch (this.camera) {

      //     case 'front':

      //       this.camera = 'rear'

      //       break

      //     case 'rear':

      //       this.camera = 'front'

      //       break

      //     default:

      //       this.$toast('错误')

      //   }

      // },

      // // 关闭相机？？？？？？

      // turnCameraOff() {

      //   // this.camera = 'off'

      //   this.qrcode = !this.qrcode

      //   if (this.camera === 'off') {

      //     this.camera = 'front'

      //   } else {

      //     this.camera = 'off'

      //   }

      // },

      // // 打开手电筒

      // ClickFlash() {

      //   switch (this.torchActive) {

      //     case true:

      //       this.torchActive = false

      //       break

      //     case false:

      //       this.torchActive = true

      //       break

      //     default:

      //       this.$toast('错误')

      //   }

      // },





      // // 检查是否调用摄像头

      // async onInit(promise) {

      //   try {

      //     await promise

      //   } catch (error) {

      //     if (error.name === 'StreamApiNotSupportedError') {

      //     } else if (error.name === 'NotAllowedError') {

      //       this.errorMessage = 'Hey! I need access to your camera'

      //     } else if (error.name === 'NotFoundError') {

      //       this.errorMessage = 'Do you even have a camera on your device?'

      //     } else if (error.name === 'NotSupportedError') {

      //       this.errorMessage =

      //         'Seems like this page is served in non-secure context (HTTPS, localhost or file://)'

      //     } else if (error.name === 'NotReadableError') {

      //       this.errorMessage = "Couldn't access your camera. Is it already in use?"

      //     } else if (error.name === 'OverconstrainedError') {

      //       this.errorMessage =

      //         "Constraints don't match any installed camera. Did you asked for the front camera although there is none?"

      //     } else {

      //       this.errorMessage = 'UNKNOWN ERROR: ' + error.message

      //     }

      //   }

      // },



      getnums1() {
        console.log('1');
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: 'wxb980e45d0a4be6ad', // 必填，公众号的唯一标识
          timestamp: '{$timestamp}', // 必填，生成签名的时间戳
          nonceStr: '{$nonceStr}', // 必填，生成签名的随机串
          signature: '{$signature}', // 必填，签名
          jsApiList: ["scanQRCode", "checkJsApi"] // 必填，需要使用的JS接口列表
        });
        // alert(location.href.split('#')[0]);
        var jqid = '';
        var imei = '';

        wx.ready(function() {
          wx.scanQRCode({
            needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
            scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
            success: function(res) {
              console.log(res);
              let ewmid = res.resultStr.split('id=').pop();
              imei = ewmid;

              if (imei.length > 20) {
                imei = imei.substr(5, 15);
              }

              $('#jg1').html(imei);
              // $('#ewmid').html(res.resultStr.split('/').pop());
            }

          });

        });

      },



      grxxff(grxxs) {
        this.grxx = grxxs;
        console.log(grxxs);
        console.log('zhende');
      },
      fanhui() {
        window.history.go(-1);
      }
    },
    components: {
      Head,
      Foot,
      Left,
      // qrcode1,
      MyLeft
    },
  }
</script>
<style lang="scss" scoped="scoped">
  .all {
    background: #f5f6fa;
  }

  .center {
    .c-all {
      width: var(--allWidth);
      margin: 0 auto;
      margin-top: 1.275rem;
      display: flex;

      .c-right {
        margin-left: 1.275rem;
        background: #fff;
        width: 90%;
        margin-bottom: 3.125rem;

        .c-top {
          font-weight: bold;
          padding: 1.275rem;
          border-bottom: 0.0625rem solid #E8E8E8;
          cursor: pointer;

          img {
            vertical-align: middle;
            width: 1.0875rem;
            margin-right: 0.625rem;
          }

          span {
            vertical-align: middle;
          }
        }

      }
    }

  }

  @media screen and (max-width: 1300px) {
    .c-all {
      width: 95% !important;
      margin: 0 auto;
      flex-wrap: wrap;
    }

    .c-right {
      width: 100% !important;
      margin: 0 auto !important;
    }

    .lefts {
      width: 100%;
    }

    .c-right {
      .center {
        margin: 0rem !important;
        padding: 0.75rem !important;
      }
    }

    .c-bt {
      display: flex;
    }

    .c-lt {
      padding: 0.65rem 0 !important;
      width: 36%;
      text-align: center;
      margin-bottom: 0.625rem;
    }



  }



  .ddb {
    position: relative;
    z-index: 9999999999999999999;

    .top {
      position: absolute;
      top: 0;
      left: 0;
      background: #2E9BD6;
      color: #FFFFFF;
      width: 100%;
      padding: 1.075rem;
      font-size: 1.2rem;
    }

    .dbx {
      border-bottom: 0.0625rem solid #E6E6E6;
    }

    .ddgy {
      margin-top: 1.075rem;

      .d-bt {
        font-weight: bold;
        font-size: 1rem;
        margin-bottom: 1.025rem;
        color: #000000;
      }

      .ddgy-one {
        margin-bottom: 0.75rem;

        .d-o-bt {
          width: 50%;
          display: inline-block;
          color: #383838;
        }
      }

      .table {
        border: 0.0625rem solid #D5D5D5;

        .tr {
          text-align: center;
          display: flex;
          background: #F8F8F8;
          padding: 0.675rem;

          .td {
            width: 40%;
            padding: 0 20px;
          }
        }

        .tr1 {
          text-align: center;
          display: flex;
          padding: 0.675rem;

          .td {
            width: 40%;
            padding: 0 20px;
          }
        }
      }
    }
  }

  .dban {
    text-align: center;
    margin-top: 2.175rem;

    .anniu {
      width: 30%;
      text-align: center;
      padding: 1.4rem 0;
      font-size: 1rem;
    }

    .bs {
      background: #AFC81D;
      border: 0.0625rem solid #AFC81D;
      margin-right: 2.275rem;

      img {
        width: 1rem;
        margin-right: 0.625rem;
      }
    }

    .bs1 {
      background: #2E9BD6;
    }
  }

  .gb {
    cursor: pointer;
  }

  .zd {
    z-index: 9999999999999999999999999999999999 !important;
  }

  @media screen and (max-width: 1300px) {
    .c-all {
      width: 95% !important;
      margin: 0 auto;
      flex-wrap: wrap;
    }

    .c-right {
      width: 100% !important;
      margin: 0 auto !important;
    }

    .lefts {
      width: 100%;

    }

    .c-right {
      .center {
        margin: 0rem !important;
        padding: 0.75rem !important;
      }
    }

    .c-bt {
      display: flex;
    }

    .c-lt {
      padding: 0.65rem 0 !important;
      width: 36%;
      text-align: center;
      margin-bottom: 0.625rem;
    }

    .o-nr {
      flex-wrap: wrap;

      .tbimg {
        width: 100% !important;
        margin: 0;
        margin-top: 0.625rem;
      }
    }

    .o-l-title {
      margin-bottom: 0.75rem !important;
      margin-top: 0.75rem !important;
      font-size: 1rem !important;
    }

    .dws {
      position: relative !important;
      bottom: 0 !important;
      right: 0 !important;
      display: flex;
      font-size: 0.75rem;
      margin-top: 0.75rem;

      .el-button {
        font-size: 0.75rem;
      }
    }

    .o-l-bt {
      flex-wrap: wrap;
    }

    .ones {
      margin-bottom: 0.625rem;
    }

    .shu {
      margin-bottom: 0.725rem !important;
    }

    .el-button+.el-button {
      margin-left: 0.25rem;
    }

    .zd {
      width: 80% !important;
    }

    .qd-bt {
      font-size: 1rem;
    }

    .ewm {
      .lefts {
        width: 47% !important;

        img {
          margin-bottom: 0.5rem;
        }
      }

    }

    .db {
      margin-top: 1rem;
    }

    .d-o-bt {
      width: 100% !important;
      margin-bottom: 0.625rem;
    }

    .ddgy-one {
      margin-bottom: 0 !important;
    }

    .td {
      width: 40% !important;
      padding: 0 0.25rem !important;
      font-size: 0.75rem !important;
    }

    .anniu {
      width: 45% !important;
      font-size: 1rem !important;
    }

    .bs {
      margin-right: 3% !important;
    }

    .dban {
      margin-top: 1rem;
    }

    .ddb {
      padding: 0.825rem;

      .top {
        font-size: 1rem;
        padding: 0.8rem;
      }
    }

    .saoyisao {
      text-align: center;
      margin-top: 1.875rem;

      img {
        width: 60%;
      }
    }
  }

  .qrcode {

    .box {

      width: 100%;

    }

  }
</style>
