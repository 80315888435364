import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementPlus from 'element-plus';
// import 'element-plus/lib/theme-chalk/index.css';
import 'element-plus/theme-chalk/index.css'


// import {ref,onMounted} from 'vue';
import {useRouter} from 'vue-router';
import BaiduMap from 'vue-baidu-map-3x';

// import 'lib-flexible';

import {
    ElMessage,
    ElMessageBox
  } from 'element-plus';
  import {
    Action
  } from 'element-plus';



const app = createApp(App)

import {
    setCookie,
    getCookie,
    clearCookie
  } from "components/common/common.js";


// const app = createApp(App);
app.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: '2rRRzsZa0yBl4ng9Qouw57Bt2IoMULtY',
});

// import util from '@/Common/Js/util.js'
// app.config.globalProperties.$useRouter = useRouter;
// app.config.globalProperties.$ref = ref;
// app.config.globalProperties.$onMounted = onMounted;
const router1=useRouter();
const promptMy = function(path,query) {
      console.log('1');
     router1.push({
       path:path,
       query:query || {}
     })
}

app.config.globalProperties.$promptMy = promptMy;






// app
app.use(useRouter)
createApp(App).use(ElementPlus).use(store).use(router).mount('#app')
