<template>
  <div class="all">
    <Head :top="top" :grxxff="grxxff"></Head>
    <div class="center">
      <div class="c-all">
        <MyLeft :topleft="topleft"></MyLeft>

        <div class="c-right">
          <div class="c-top" @click="fanhui()"><img src="~assets/about/fh.png" /><span>技术成果库</span></div>


          <div class="center">
            <div class="c-bt">
              <div class="c-lt" @click="qh(0)" :class="zt==0?'zs':''">待审核</div>
              <div class="c-lt" @click="qh(1)" :class="zt==1?'zs':''">待洽谈</div>
              <div class="c-lt" @click="qh(2)" :class="zt==2?'zs':''">已完成</div>
            </div>
            <div class="dibu">
              <div class="one" v-for="item in ftechnologyorder">
                <div class="o-title">记录编号：{{item.ddh}}</div>
                <div class="o-nr">
                  <img class="tbimg" :src="'https://ymedicine.jiujingwulian.com/public'+item.fimage" />
                  <div class="o-le">
                    <div class="o-l-title onehang">{{item.fname}}</div>
                    <div class="o-l-bt">
                      <div class="ones">
                        <!-- <img src="../../assets/home/dws.png" /> -->
                        <div class="ones-one">技术单位：{{item.danwei}}</div>
                      </div>
                      <div class="shu">|</div>
                      <div class="ones">
                        <div class="ones-fy">技术领域：{{item.jishu}}</div>
                      </div>
                      <div class="shu">|</div>
                      <div class="ones">
                        <img src="../../assets/my/sjs.png" />
                        <div class="ones-nrs">成熟阶段：{{item.chengshu}}</div>
                      </div>

                    </div>
                  </div>
                </div>
                <div class="zt">{{item.zt}}</div>
                <div class="dws">
                  <div class="down">
                  </div>
                  <!-- <el-button class="bj2" @click="qiandao()">未签到 <img src="../../assets/my/qb.png" /></el-button> -->
                  <el-button type="primary" @click="xiangqing(item)" class="bj1">我的预留信息</el-button>
                  <!-- <el-button class="bj2" @click="xiangqing()">查看详情 >></el-button> -->
                </div>




              </div>

              <null :list='ftechnologyorder' ></null>
              <page :items='items'
              :litems='litems'
              :total="total"
              :per_page="per_page"
              :current_page="current_page"
              :last_page="last_page"
              :listcount='listcount'></page>





            </div>

            <el-dialog class="zd" :width="w50"  :show-close="false" align-center v-model="dialogTableVisible" title="">
              <template #header="{ close, titleId, titleClass }">
                <div class="qd-top">
                  <div class="xian"></div>
                  <div class="qd-bt">入场签到</div>
                  <div class="xian"></div>
                </div>
              </template>
              <div class="ewm1">
                <img src="../../assets/my/ewm.png" />
                <div class="e-bt">请微信扫码进入移动端完成签到操作</div>
              </div>
            </el-dialog>

            <el-dialog class="zd" :width="w50"  :show-close="false" align-center v-model="dialogTableVisible1" title="">
              <template #header="{ close, titleId, titleClass }">
                <div class="qd-top">
                  <div class="xian"></div>
                  <div class="qd-bt">上传健康信息</div>
                  <div class="xian"></div>
                </div>
              </template>
              <div class="ewm">
                <div>
                  <el-upload class="avatar-uploader"
                    action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15" :show-file-list="false"
                    :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                    <img src="../../assets/my/jia.png" class="avatar" />
                  </el-upload>
                  <div class="e-bt">健康码</div>
                </div>

                <div>
                  <el-upload class="avatar-uploader"
                    action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15" :show-file-list="false"
                    :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                    <img src="../../assets/my/jia.png" class="avatar" />
                  </el-upload>
                  <div class="e-bt">健康码</div>
                </div>

              </div>
              <div class="db">
                <el-button type="primary" class="baocun">保存</el-button>
              </div>
            </el-dialog>

              <el-dialog class="ddb zd"  :width="w50"  v-model="dialogTableVisible2" :show-close="false">
                <div class="top">
                  <div class="">预留信息<div class="gb right"><img @click="dialogTableVisible2=false" src="../../assets/my/guanbi.png" /></div></div>
                </div>
                <div class="ddgy dbx">
                  <div class="d-bt">订单概要</div>
                  <div class="ddgy-one">
                    <div class="d-o-bt">订单编号：{{list.ddh}}</div>

                  </div>
                  <div class="ddgy-one">
                    <div class="d-o-bt">订单状态：{{list.zt}}</div>
                    <div class="d-o-bt">创建时间：{{list.time}}</div>
                  </div>
                <!--  <div class="ddgy-one">
                    <div class="d-o-bt">支付状态：已支付</div>
                    <div class="d-o-bt">支付方式：微信</div>
                  </div>
 -->
                </div>

               <!-- <div class="ddgy">
                  <div class="d-bt">订单概要</div>
                  <div class="table">
                    <div class="tr">
                      <div class="td">会议名称</div>
                      <div class="td">起止时间</div>
                      <div class="td">所在城市</div>
                      <div class="td">当前状态</div>
                    </div>

                    <div class="tr1">
                      <div class="td">江苏医药xx会议论坛会议论坛标题</div>
                      <div class="td">2022.09.12 12:32:12<br/>22022.09.12 12:32:12</div>
                      <div class="td">南京市</div>
                      <div class="td">进行中</div>
                    </div>
                  </div>
                </div> -->

                <div class="ddgy">
                  <div class="d-bt">报名信息</div>
                  <div class="ddgy-one">
                    <div class="d-o-bt">姓名：{{list.name}}</div>
                    <div class="d-o-bt">手机号：{{list.phone}}</div>
                  </div>
                  <div class="ddgy-one">
                    <div class="d-o-bt">类型：{{list.type}}</div>
                  </div>
                 <div class="ddgy-one">
                    <div class="d-o-bt">公司名称：{{list.gsname}}</div>
                     <!-- <div class="d-o-bt">单位名称：{{list.danwei}}</div> -->
                  </div>
                <!--<div class="ddgy-one">
                    <div class="d-o-bt">发票抬头：xxxx科技有限公司</div>
                    <div class="d-o-bt">税号: 23213123882992</div>
                  </div> -->
                </div>

                <!--<div class="dban">
                   <el-button class="anniu bs" type="primary" @click="qiandao" ><img src="../../assets/my/bis.png" />立即签到</el-button>
                   <el-button class="anniu bs1" type="primary" @click="jkzl">+上传健康信息</el-button>
                </div>
                -->

              </el-dialog>




          </div>





        </div>

      </div>
    </div>


    <foot></foot>
    <left></left>
  </div>
</template>

<script>
  import {
    ref,
    onMounted
  } from 'vue';
  import {
    getHome
  } from 'network/home.js';
  import {
    useRouter
  } from 'vue-router';
  import {
    getftechnologyorder,
    getmessage
  } from 'network/my.js';


  import Head from "components/content/headder/Head.vue";
  import Foot from "components/content/footer/Foot.vue";
  import Left from "components/content/left/Left.vue";

  import MyLeft from "views/my/ChildComps/MyLeft.vue";
  import Page from "components/content/page/Page.vue";
  import Null from "components/content/errornull/null.vue";



  export default {
    name: 'My',
    data() {
      return {
        w50:'50%',
        items:[
        ],
        litems:[
        ],
        page:0,
        total:'',
        per_page:'',
        current_page:'',
        last_page:'',



        list:{},
        dialogTableVisible: false,
        dialogTableVisible1: false,
        dialogTableVisible2: false,

        zt: 0,
        shzt: 1, //shzt 0= 待审核,1=已驳回
        topleft: 4, //左边
        radio1: 1,
        textarea2: '',
        options: [{
          value: '城市',
          lable: '0'
        }, ],
        name: '',
        grxx: [],
        ftechnologyorder:{},
      }
    },
    setup() {
      const top = 0;
      const router = useRouter();
      const databa = ref([]);
      const ab = 'sb';
      console.log(ab);
      onMounted(() => {

        getHome().then(res => {
          console.log(res.data.data);
          databa.value = res.data;
          console.log(databa);

        }).catch(err => {

        })

      })
      //跳转方法
      const goTo = (path, query) => {
        router.push({
          path: path,
          query: query || {}
        })
      }

      return {
        databa,
        ab,
        goTo,
        top
      }
    },
    mounted() {
      this.getdata(this.zt);
    },
    methods: {
      listcount(page){
        this.page=page;
        this.getdata(this.zt);
      },

      getdata(ids){
        var that = this;
        var data = {
          type: ids,
          uid: this.grxx.id
        };
        var that = this;
        var data = {
          type: ids,
          uid: this.grxx.id,
          page:this.page,
          sl:5
        };
        getftechnologyorder(data).then(res => {
          console.log('zx');
          // that.ftechnologyorder = res.data.ftechnologyorder;

          that.ftechnologyorder = res.data.ftechnologyorder.table.data;
          that.total=res.data.ftechnologyorder.table.total;
          that.per_page=res.data.ftechnologyorder.table.per_page;
          that.current_page=res.data.ftechnologyorder.table.current_page;
          that.last_page=res.data.ftechnologyorder.table.last_page;
          that.items=res.data.ftechnologyorder.items;
          that.litems=res.data.ftechnologyorder.litems;


          // console.log(that.fmeetingbm);

        }).catch(err => {})
      },

      qh(zt){
        this.zt=zt;
        this.page=0;
        this.getdata(this.zt);
      },
      grxxff(grxxs) {
        this.grxx = grxxs;
        console.log(grxxs);
        console.log('zhende');
        this.getdata(this.zt);
      },
      fanhui() {
        window.history.go(-1);
      },
      qiandao() {
        this.dialogTableVisible = true;
      },
      jkzl(){
        this.dialogTableVisible1 = true;
      },
      isMobile() {
     　　let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
     　　return flag;
      },
      xiangqing(item){
        if (this.isMobile()) {
          // alert("移动端");
          //移动端跳转路由
          this.w50='90%';
        } else {
          // alert("pc端");
    　　　　//Pc端跳转路由
          this.w50='50%';
        }



        this.list=item;
        this.dialogTableVisible2 = true;

        var that=this;
        var data = {
          fusers_id: this.grxx.id,
          type:'2',
          fid:item.id
        };
        getmessage(data).then(res => {
          console.log('zx');
          console.log(res);
          // that
        }).catch(err => {})


      }

    },
    components: {
      Head,
      Foot,
      Left,
      Page,
      Null,
      MyLeft
    },
  }
</script>
<style lang="scss" scoped="scoped">
  .all {
    background: #f5f6fa;
  }

  .center {
    .c-all {
      width: var(--allWidth);
      margin: 0 auto;
      margin-top: 1.275rem;
      display: flex;

      .c-right {
        margin-left: 1.275rem;
        background: #fff;
        width: 90%;
        margin-bottom: 3.125rem;

        .c-top {
          font-weight: bold;
          padding: 1.275rem;
          border-bottom: 0.0625rem solid #E8E8E8;
          cursor: pointer;

          img {
            vertical-align: middle;
            width: 1.0875rem;
            margin-right: 0.625rem;
          }

          span {
            vertical-align: middle;
          }
        }

        .center {

          margin: 1.875rem;

          .c-bt {
            .c-lt {
              font-size: 1rem;
              padding: .675rem 2.5rem;
              border: 0.0625rem solid #E9E9E9;
              display: inline-block;
              cursor: pointer;
            }

            .zs {
              background: #2E9BD6;
              color: #fff;
            }
          }

          .dibu {
            padding: 0.8 25rem;
            border: 0.0625rem solid #E3E3E3;

            .one {
              position: relative;
              border-bottom: 0.0625rem solid #E3E3E3;

              .zt {
                color: #F31F1F;
                position: absolute;
                top: 1.325rem;
                right: 1.325rem;
                font-size: 1rem;
                font-weight: bold;
              }

              .dws {
                color: #F31F1F;
                position: absolute;
                bottom: 1.325rem;
                right: 0.925rem;
                font-size: 1rem;
                font-weight: bold;

                .down {
                  margin-bottom: 0.625rem;
                }

                .bj1 {
                  background: #2E9BD6;
                }

                .bj2 {
                  // background: #fff;
                  // color: #000000;
                  border: 0.0625rem solid #B3B3B3;
                }

                .yqd {
                  border: 0.0625rem solid #2E9BD6;
                  color: #2E9BD6;
                }
              }

              padding: 1.075rem;

              .o-title {
                margin-bottom: 0.925rem;
              }

              .o-nr {
                display: flex;

                img {
                  width: 5.275rem;
                  margin-right: 0.8125rem;
                }

                .o-le {
                  .o-l-title {
                    font-weight: bold;
                    margin-bottom: 1.075rem;
                    margin-top: 0.2rem;
                  }

                  .o-l-bt {
                    display: flex;
                    align-items: center;
                    color: #4E4E4E;
                    font-size: 0.3375rem;

                    .ones {
                      display: flex;
                      align-items: center;

                      img {
                        width: 0.875rem;
                        margin-right: 0.3125rem;
                      }

                      .ones-fy {
                        color: #FF2323;
                      }

                      .ones-nrs {
                        color: #2E9BD6;
                      }
                    }

                    .shu {
                      margin: 0 0.275rem;
                      color: #C0C0C0;
                    }
                  }
                }
              }
            }
          }
        }

      }
    }

  }

  .qd-top {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.3rem;
    font-weight: bold;

    .qd-bt {
      margin: 0 1.25rem;
    }

    .xian {
      width: 40%;
      height: 0.125rem;
      background: #D2D2D2;
    }


  }

  .ewm {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    img {
      width: 10.875rem;
      margin-bottom: 1.875rem;
    }

    .e-bt {
      font-size: 1.125rem;
      color: #1B1B1B;
    }
  }
  .ewm1 {
    text-align: center;
    // display: flex;
    // align-items: center;
    // justify-content: space-evenly;
    img {
      width: 10.875rem;
      margin-bottom: 1.875rem;
    }

    .e-bt {
      font-size: 1.125rem;
      color: #1B1B1B;
    }
  }

  .db{
    text-align: center;
    margin-top: 2.875rem;
  }
  .baocun{
    margin: 0 auto;
    padding: 1.375rem 6rem;
    font-size: 1.1875rem;
    background: #2E9BD6;
  }

  .my-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #2E9BD6;
    color: #fff;
  }
  .ddb{
    position: relative;
    z-index: 9999999999999999999;
    .top{
      position: absolute;
      top: 0;
      left: 0;
      background: #2E9BD6;
      color: #FFFFFF;
      width: 100%;
      padding: 1.075rem;
      font-size: 1.2rem;
    }
    .dbx{
      border-bottom: 0.0625rem solid #E6E6E6;
    }
    .ddgy{
      margin-top: 1.075rem;
      .d-bt{
        font-weight: bold;
        font-size: 1rem;
        margin-bottom: 1.025rem;
        color: #000000;
      }
      .ddgy-one{
        margin-bottom: 0.75rem;
        .d-o-bt{
          width: 50%;
          display: inline-block;
          color: #383838;
        }
      }

      .table{
        border: 0.0625rem solid #D5D5D5;
        .tr{
          text-align: center;
          display: flex;
          background: #F8F8F8;
          padding: 0.675rem;
          .td{
            width: 40%;
            padding:0 20px;
          }
        }
        .tr1{
          text-align: center;
          display: flex;
          padding: 0.675rem;
          .td{
            width: 40%;
            padding:0 20px;
          }
        }
      }
    }
  }
  .dban{
    text-align: center;
    margin-top: 2.175rem;
    .anniu{
      width: 30%;
      text-align: center;
      padding: 1.4rem 0;
      font-size: 1rem;
    }
    .bs{
      background: #AFC81D;
      border: 0.0625rem solid #AFC81D;
      margin-right: 2.275rem;
      img{
        width: 1rem;
        margin-right: 0.625rem;
      }
    }
    .bs1{
      background: #2E9BD6;
    }
  }
  .gb{
    cursor: pointer;
  }
  .zd{
    z-index: 9999999999999999999999999999999999!important;
  }

  @media screen and (max-width: 1300px) {
     .c-all{
       width: 95%!important;
       margin: 0 auto;
       flex-wrap: wrap;
     }
     .c-right{
       width: 100%!important;
       margin: 0 auto!important;
     }

     .lefts{
       width: 100%;
     }

     .c-right{
      .center{
           margin: 0rem !important;
           padding: 0.75rem!important;
      }
     }

     .c-bt{
       display: flex;
     }
     .c-lt{
       padding: 0.65rem 0!important;
       width: 36%;
       text-align: center;
       margin-bottom: 0.625rem;
     }


     .o-nr{
       flex-wrap: wrap;
       .tbimg{
         width: 100%!important;
         margin: 0;
         margin-top: 0.625rem;
       }
     }
     .o-l-title{
       margin-bottom: 0.75rem!important;
       margin-top: 0.75rem!important;
       font-size: 1rem!important;
     }
     .dws{
       position: relative!important;
       bottom: 0!important;
       right: 0!important;
       display: flex;
       font-size: 0.75rem;
       margin-top: 0.75rem;
       .el-button{
         font-size: 0.75rem;
       }
     }
     .o-l-bt{
       flex-wrap: wrap;
     }
     .ones{
       margin-bottom: 0.625rem;
     }
     .shu{
       margin-bottom: 0.725rem!important;
     }
     .el-button+.el-button {
         margin-left: 0.25rem;
     }
     .zd{
       width: 80%!important;
     }
     .qd-bt{
       font-size: 1rem;
     }
     .ewm{
      .lefts{
        width: 47%!important;
        img{
          margin-bottom: 0.5rem;
        }
      }

     }
     .db{
        margin-top: 1rem;
      }

      .d-o-bt{
        width: 100%!important;
        margin-bottom: 0.625rem;
      }
      .ddgy-one{
        margin-bottom: 0!important;
      }
      .td{
            width: 40%!important;
            padding: 0 0.25rem!important;
            font-size: 0.75rem!important;
      }
      .anniu {
        width: 45%!important;
        font-size: 1rem!important;
      }
      .bs{
        margin-right: 3%!important;
      }
      .dban{
        margin-top: 1rem;
      }






  }

</style>
