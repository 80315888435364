<template>
	<div class="r-bot">

		<div class="r-top-title">推荐阅读</div>
		<div class="l-bot">

			<div class="z-n-one z-n-one1" v-for="item in news" v-show="pd=='1'"  @click="goTo('/newin',item.id)" >
				<div class="zno-left">
					<img :src="'https://ymedicine.jiujingwulian.com/public'+item.fimage" />
				</div>
				<div class="zno-right zno-right1">
					<div class="znor-title onehang">{{item.name}}</div>
					<div class="rq">{{item.time}}</div>
				</div>
			</div>

			<div class="z-n-one z-n-one1" v-show="pd=='2'" v-for="item in news"  @click="tz('/newin',item.id)" >
				<!--  @-->
				<!--  -->
				<div class="zno-left">
					<img :src="'https://ymedicine.jiujingwulian.com/public'+item.fimage" />
				</div>
				<div class="zno-right zno-right1">
					<div class="znor-title onehang">{{item.name}}</div>
					<div class="rq">{{item.time}}</div>
				</div>
			</div>

      <div class="z-n-one z-n-one1" v-show="pd=='3'" v-for="item in li"  @click="qhnr(item.id)" >
      	<!--  @-->
      	<!--  -->
      	<div class="zno-left">
      		<img :src="'https://ymedicine.jiujingwulian.com/public'+item.fimage" />
      	</div>
      	<div class="zno-right zno-right1">
      		<div class="znor-title1 onehang">{{item.name}}</div>
          <div class="rq onehang">{{item.jianjie}}</div>
      		<div class="rq">{{item.time}}</div>
      	</div>
      </div>


			<!-- <div class="z-n-one z-n-one1">
        <div class="zno-left">
          <img src="~assets/about/fmt.png" />
        </div>
        <div class="zno-right zno-right1">
          <div class="znor-title">内容内容内容内容内容内容内容内容内容内容</div>
          <div class="rq">2022-02-02</div>
        </div>
      </div>
      <div class="z-n-one z-n-one1">
        <div class="zno-left">
          <img src="~assets/about/fmt.png" />
        </div>
        <div class="zno-right zno-right1">
          <div class="znor-title">内容内容内容内容内容内容内容内容内容内容</div>
          <div class="rq">2022-02-02</div>
        </div>
      </div>

      <div class="z-n-one z-n-one1">
        <div class="zno-left">
          <img src="~assets/about/fmt.png" />
        </div>
        <div class="zno-right zno-right1">
          <div class="znor-title">内容内容内容内容内容内容内容内容内容内容</div>
          <div class="rq">2022-02-02</div>
        </div>
      </div> -->


		</div>


	</div>

</template>

<script>
	import {
		useRouter
	} from 'vue-router';

	import {
		getHome,
		getnew
	} from 'network/home.js';
	const router = useRouter();
	export default {
		name: 'Notice',
		// props: [],
		props: ['getChildInfo','pd','li'],
		data() {
			return {

				news: []
			}
		},
		setup() {
			//跳转方法
			const router = useRouter();
			//跳转方法
			var that = this;
			const goTo = (path, cs) => {
				var query = {
					id: cs
				}
				router.push({
					path: path,
					query: query
				})
				// that.getChildInfo(tid);
			}

			return {
				goTo
			}

		},
		mounted() {
			this.getfl();
		},
		methods: {

			tz(path,tid) {

				// const router = useRouter();
				// var query = {
				// 	id: tid
				// }
				// console.log(query);
				// console.log(path);
				// router.push({
				// 	path: path,
				// // 	query: query
				// })||[]
				this.getChildInfo(tid);
			},
			getfl(fnewfl_id) {
				var that = this;
				var data = {
					type: 3,
				};
				getnew(data).then(res => {
					console.log('最新公告');
					console.log(res);
					that.news = res.data.data.table.data;
				}).catch(err => {})

			},


		},

	}
</script>

<style lang="scss" scoped="scoped">
	.r-bot {
		background: #fff;
		min-height: 6.25rem;
		margin-top: 0.625rem;
		padding: 1.225rem;

		.r-top-title {
			font-size: 1.175rem;
			font-weight: bold;
		}

		.l-bot {
			margin-top: 1.875rem;

			.z-n-one:hover {
				background: linear-gradient(rgba(98, 186, 238, 0.1568627450980392) 0%, rgba(196, 196, 196, 0) 100%);

				.znor-title {
					color: #2E9BD6;

				}
			}

			.z-n-one {
				padding: 0.825rem;
				padding-bottom: 1rem;
				border-bottom: 0.0625rem solid #DBDBDB;
				display: flex;
				align-items: center;
				// margin-bottom: 1rem;
				background: #fff;
				cursor: pointer;

				.zno-left {
					margin-right: 1.875rem;

					img {
						width: 9.875rem;
            border-radius: 0.25rem;
					}
				}

				.zno-right {
          .znor-title1{
            font-size: 1.075rem;
            font-weight: bold;
            margin-bottom: 0.675rem;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
					.znor-title {
						font-size: 0.85rem;
						font-weight: bold;
						margin-bottom: 0.675rem;
						text-overflow: -o-ellipsis-lastline;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
					}

					.znor-content {
						font-size: 0.7rem;
						color: #888888;
						line-height: 1rem;
					}

					.rq {
						font-size: 0.7rem;
						color: #888888;
						margin-top: 0.625rem;
					}
				}
			}



			.z-n-one1 {
				padding: 0;
				padding-bottom: 0.8rem;
        padding-top: 0.8rem;
				align-items: normal;

				.zno-left {
					margin-right: 0.675rem;

					img {
						width: 7rem;
					}
				}

				// .zon-left{
				//   img{
				//     width: 10%!important;
				//   }
				// }
			}

			.zno-right {
				padding-top: 0.25rem;
			}
		}

	}

  @media screen and (max-width: 1300px) {
    .znor-title{
      font-size: 0.85rem!important;
      line-height: 1.3rem!important;
    }
    .znor-title{
      text-align: left!important;
    }
    .rq{
      text-align: left!important;
    }
  }
</style>
