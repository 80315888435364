<template>
<div>
  <div style="width:800px;margin: auto;display: flex;flex-direction: column;">
  请选择上传图片:
  <input type="file" id="back" ref="backfile" @change="handleFile" /><br/>
  <div id="imglist" style="width:100%;">
    <div v-for="(item,i) in selFiles" :key="i" style="float:left;margin-left: 20px;margin-top: 20px;height:150px;position:relative;">
      <img :src="item.fileimg"  style="height:150px;"/>
    </div>
  </div>
    <div>
  <input type="button" value="上传" @click="upload" />
    </div>
  </div>
</div>
</template>

<script>
import {ref} from "vue";
import axios from 'axios';

export default {
  name: "ImgUpload",
  setup() {
    //选中的图片文件，保存在数组中
    const selFiles = ref([]);
    //选中图片后的处理
    const handleFile = () => {
      let filePaths = window.event.target.files;
       console.log(filePaths);
      //清空原有缩略图
      if (filePaths.length === 0) {
        //未选择，则返回
        return
      } else {
        //清空数组中原有图片
        selFiles.value.length = 0;
      }
      //把新选中的图片加入数组
      for( var i=0;i<filePaths.length; i++ ){
        let file = filePaths[i];
        console.log(file);
        let one = {
          fileimg:URL.createObjectURL(file),  //预览用
          file:file,
        }
        selFiles.value.push(one);
      }
    }

    //上传
    const upload = () => {
      //判断是否选中文件
      if (selFiles.value.length == 0) {
        alert("请在上传前先选中文件!");
        return;
      }
      var file = selFiles.value[0].file;
      if (typeof(file) === "undefined") {
        alert("请在上传前先选中文件!");
        return;
      }
      // 创建一个表单数据
      var data = new FormData();

      //遍历文件并上传
      for( var i=0;i<selFiles.value.length; i++ ){
        let fileOne = selFiles.value[i].file;
        console.log(fileOne);
        console.log("上传:"+fileOne.name)
        data.append("file",fileOne);
      }

      data.append("userName","laoliu");

      let url = 'https://ymedicine.jiujingwulian.com/public/index/index/upload';
      //axios上传文件
      axios({
        method:"post",
        url:url,
        data:data,
        headers:{'Content-Type': 'multipart/form-data'},
      }).then((res) => {
            console.log(res.data);
            // if (res.data.code == 0) {
            //   let data = res.data.data;
            //   console.log(data);
            //   let userName = data.userName;
            //   let imageUrl = data.imageurl;
            //   alert("success:提交的用户名:"+userName+";image:"+imageUrl);
            // } else {
            //   alert("error:"+res.data.msg);
            // }
          }
      ).catch(err=>{
        console.log(err);
        alert('网络错误:'+err.message);
      });
    }

     return {
       selFiles,
       handleFile,
       upload,
     }
  }
}
</script>

<style scoped>

</style>
