<template>
  <div class="all">
    <Head :top="top" :grxxff="grxxff"></Head>
    <div class="center">
      <div class="c-all">
        <!-- <MyLeft :topleft="topleft"></MyLeft> -->

        <div class="c-right">
          <!-- <div class="c-top" @click="fanhui()"><img src="~assets/about/fh.png" /><span>优惠券领取</span></div> -->

          <div class="center-all">
            <div class="c-all">
              <div class="c-all-top">
                <div class="c-a-left">
                  <div class="c-a-l-ti">￥<span>{{datall.price}}</span></div>
                  <div class="c-a-l-pr">无使用门槛</div>
                </div>
                <div class="c-a-right">
                  <div class="c-a-r">{{datall.name}}</div>
                  <div class="c-a-ti">有效期至：{{datall.ytime}}</div>
                </div>
              </div>
              <div class="c-all-bottom">
                <div class="title">使用说明：</div>
                <div v-html="datall.content" >

                </div>

              </div>
            </div>


            <div class="shengyu">
              剩余：{{datall.synumber}}张
            </div>

            <div class="ylq" v-show="datall.yh">
              已领取
            </div>

            <div class="ylq" v-show="!datall.yh && datall.synumber==0">
              已被领取完
            </div>
            <div class="ylq" v-show="!datall.yh && datall.synumber!=0 && datall.panduan=='-2'">
              已过期
            </div>
            <div class="lq" @click="lq()" v-show="!datall.yh && datall.synumber!=0 && datall.panduan=='1' && datall.synumber>0">
              立即领取
            </div>







          </div>




        </div>

      </div>
    </div>


    <foot></foot>
    <left></left>
  </div>
</template>

<script>
  import {
    ref,
    onMounted
  } from 'vue';
  import {
    getHome
  } from 'network/home.js';

  import {
    fmeetingyhqshezhi,
    fmeetingyhqshezhilq
  } from 'network/mycoupon.js';
  import {
    useRouter
  } from 'vue-router';

  import {
    ElMessage,
    ElMessageBox
  } from 'element-plus';
  import {
    Action
  } from 'element-plus';

  import Head from "components/content/headder/Head.vue";
  import Foot from "components/content/footer/Foot.vue";
  import Left from "components/content/left/Left.vue";

  import MyLeft from "views/my/ChildComps/MyLeft.vue";


  export default {
    name: 'My',
    data() {
      return {
        datall:{},
        id:0,
        zt:0,
        shzt:1,//shzt 0= 待审核,1=已驳回
        topleft:0,//左边
        radio1: 1,
        textarea2:'',
        options:[
          {
            value:'城市',
            lable:'0'
          },
        ]
        ,
        name: '',
        grxx: [],
      }
    },
    setup() {
      const top = 10;
      const router = useRouter();
      const databa = ref([]);
      const ab = 'sb';
      console.log(ab);
      onMounted(() => {

        getHome().then(res => {
          console.log(res.data.data);
          databa.value = res.data;
          console.log(databa);

        }).catch(err => {

        })

      })
      //跳转方法
      const goTo = (path, query) => {
        router.push({
          path: path,
          query: query || {}
        })
      }

      return {
        databa,
        ab,
        goTo,
        top
      }
    },
    mounted() {
      // var url = decodeURI(window.location.search); //?id="123456"&name="www";
      // // var url = window.location.href;
      // console.log(url);
      // var object = {};
      // if (url.indexOf("?") != -1) //url中存在问号，也就说有参数。
      // {
      //   var str = url.substr(1); //得到?后面的字符串
      //   var strs = str.split("&"); //将得到的参数分隔成数组[id="123456",name="www"];
      //   for (var i = 0; i < strs.length; i++) {
      //     object[strs[i].split("=")[0]] = strs[i].split("=")[1]; //得到{id:'123456',name:'www'}
      //   }
      // }
      // console.log(object);
      // this.id = object['id'];
      this.id=this.GetUrlParam('id');
      // console.log(id);
      // this.getdata(this.id);
    },
    methods: {
      GetUrlParam(paraName) {
      var url = window.location.toString();
      var arrObj = url.split("?");
      if (arrObj.length > 1) {
        var arrPara = arrObj[1].split("&");
        var arr;
        for (var i = 0; i < arrPara.length; i++) {
          arr = arrPara[i].split("=");
          if (arr != null && arr[0] == paraName) {
            return arr[1];
          }
        }
        return "";
      } else {
        return "";
      }
    },
        getdata(ids) {
        console.log(this.grxx);
        console.log('wd');
         if(!this.grxx.name){
           ElMessageBox.confirm(
               '您未登录是否登录?',
               '提示', {
                 confirmButtonText: '确认',
                 cancelButtonText: '取消',
                 type: '提示',
               }
             )
             .then(() => {

                this.$router.push('/login?yhq='+this.id);
             })
             .catch(() => {
               ElMessage({
                 type: 'info',
                 message: '取消成功',
               })
             })

             // return false;
         }


        var that = this;
        var data = {
          id: ids,
          fid:this.grxx.id,
        };
        fmeetingyhqshezhi(data).then(res => {
          console.log(res);
          this.datall=res.data;
        }).catch(err => {})
      },
      lq(){


        if(!this.grxx.name){
           ElMessageBox.confirm(
               '您未登录是否登录?',
               '提示', {
                 confirmButtonText: '确认',
                 cancelButtonText: '取消',
                 type: '提示',
               }
             )
             .then(() => {

                this.$router.push('/login?yhq='+this.id);
             })
             .catch(() => {
               ElMessage({
                 type: 'info',
                 message: '取消成功',
               })
             })

             return false;
         }


          var that = this;
          var data = {
            id: this.datall.id,
            fid:this.grxx.id,
          };


        ElMessageBox.confirm(
            '是否领取优惠券?',
            '提示', {
              confirmButtonText: '确认',
              cancelButtonText: '取消',
              type: '提示',
            }
          )
          .then(() => {

              fmeetingyhqshezhilq(data).then(res => {
                console.log(res);
                // this.datall=res.data;
                if(res.data=='-1'){
                  ElMessage({
                    type: 'success',
                    message: '领取成功',
                  })
                }else{
                  ElMessage({
                    type: 'info',
                    message: '已被领取完！',
                  })
                }

                that.getdata(this.datall.id);
              }).catch(err => {})

          })
          .catch((res) => {
            console.log(res);
            ElMessage({
              type: 'info',
              message: '取消成功',
            })
          })

      },
      grxxff(grxxs) {
        this.grxx = grxxs;
        console.log(grxxs);
        console.log('zhende');
        this.getdata(this.id);
      },
      fanhui(){
        window.history.go(-1);
      }
    },
    components: {
      Head,
      Foot,
      Left,
      MyLeft
    },
  }
</script>
<style lang="scss" scoped="scoped">
  .all {
    background: #f5f6fa;
  }

  .center {
    .c-all {
      width: var(--allWidth);
      margin: 0 auto;
      margin-top: 1.275rem;
      display: flex;

      .c-right {
        margin-left: 1.275rem;
        background: #fff;
        width: 90%;
        margin-bottom: 3.125rem;

        .c-top {
          font-weight: bold;
          padding: 1.275rem;
          border-bottom: 0.0625rem solid #E8E8E8;
          cursor: pointer;
          img {
            vertical-align: middle;
            width: 1.0875rem;
            margin-right: 0.625rem;
          }

          span {
            vertical-align: middle;
          }
        }

      }
    }

  }
  .center-all{
    display: none;
  }
 @media screen and (max-width: 1300px) {
   .center-all{
     background: linear-gradient(180.00deg, rgba(186,234,255,1) 21.96%,rgba(246,110,39,0) 61%);
     display: block;
     min-height: 20vh;
     padding-top: 0.625rem;
     .c-all{
        width: 90%;
        margin: 0 auto;
        background: #fff;
        // min-height: 10vh;
        padding-top: 0.625rem;
        border-radius: 0.625rem;
        margin-bottom: 1.875rem;
        box-shadow: 0rem 0.375rem 0.9375rem rgba(206, 206, 206, 0.8);
        padding-bottom: 0.825rem;
       .c-all-top{
         width: 94%;
         margin: 0 auto;
         background: linear-gradient(90.00deg, rgba(246,110,39,1) 50%,rgba(255,153.31,68.17,0.7151764705882353) );
         // min-height: 10vh;
         border-radius: 0.625rem;
         display: flex;
         align-items: center;
         padding: 0.675rem 0;
         .c-a-left{
           color: #FFFFFF;
           width: 30%;
           text-align: center;
           .c-a-l-ti{
             font-size: 0.8125rem;
             margin-bottom: 0.625rem;
             span{
               font-size: 1.325rem;
               font-weight: bold;
             }
           }
         }
         .c-a-right{
           width: 65%;
           background: #fff;
           padding: 1.35rem;
           border-radius: 0.625rem;
           .c-a-r{
             margin-bottom: 0.625rem;
             font-size: 1.075rem;
             font-weight: bold;
           }
           .c-a-ti{
             color: #8F8F8F;
           }
         }
       }
     }
     .c-all-bottom{
       width: 90%;
       margin: 0 auto;
       margin-top: 0.825rem;
       .title{
         margin-bottom: 0.625rem;
         font-weight: bold;
       }
     }

     .shengyu{
       width: 95%;
       margin: 0 auto;
       text-align: center;
       padding: 0.825rem 0;
       background: #F5F4F4;
       border-radius: 1.875rem;
       margin-bottom: 1.25rem;
     }
     .lq,.ylq{
       background: #F98D35;
       color: #fff;
       padding: 1.075rem 0;
       border-radius: 1.875rem;
       width: 60%;
       margin: 0 auto;
       text-align: center;
       margin-bottom: 0.625rem;
       cursor: pointer;
     }
     .ylq{
       color: #6B6B6B;
       background: #fff;
       border: 0.09375rem solid rgb(178, 178, 178);
     }
   }



    .c-all{
      width: 95%!important;
      margin: 0 auto;
      flex-wrap: wrap;
    }
    .c-right{
      width: 100%!important;
      margin: 0 auto!important;
    }

    .lefts{
      width: 100%;
    }

    .c-right{
     .center{
          margin: 0rem !important;
          padding: 0.75rem!important;
     }
    }

    .c-bt{
      display: flex;
    }
    .c-lt{
      padding: 0.65rem 0!important;
      width: 36%;
      text-align: center;
      margin-bottom: 0.625rem;
    }



 }


</style>

<!-- MyCoupon -->
