<template>
  <div class="all">
    <Head :top="top" :grxxff="grxxff1"></Head>
    <!-- <Banner :bn='bn'></Banner> -->
    <Banner1 :p='p' :bn='bn' :list="nrxq"></Banner1>

    <Bread :all="all"></Bread>

    <div class="a-cen">
      <div class="center">
        <div class="fde-left">
          <div class="one">

             <div class="z-top-title">需求详情</div>
             <div class="z-top">
               <div class="xuxian"></div>
               <img src="~assets/download/yuan.png" />
               <div class="xuxian"></div>
             </div>
              <div class="z-content" v-html="nrxq.bjcontent">

              </div>

          </div>

          <div class="one">

             <div class="z-top-title">图片资料</div>
             <div class="z-top">
               <div class="xuxian"></div>
               <img src="~assets/download/yuan.png" />
               <div class="xuxian"></div>
             </div>
              <div class="z-content" >
                <img class="cimg" :src="'https://ymedicine.jiujingwulian.com/public'+item" v-for="item in nrxq.gkcontent" />

              </div>

          </div>

<!--
          <div class="one">

             <div class="z-top-title">项目核心优势</div>
             <div class="z-top">
               <div class="xuxian"></div>
               <img src="~assets/download/yuan.png" />
               <div class="xuxian"></div>
             </div>
              <div class="z-content" v-html="nrxq.kxcontent">

              </div>

          </div>
 -->

        </div>

        <div class="fde-right">
         <div class="fde-r" v-show="ps==0">
           <img :src="'https://ymedicine.jiujingwulian.com/public'+users.headimage" />
           <div class="fde-r-title">{{users.name}}</div>
           <div class="fde-r-xq" @click="gxq()"><img src="~assets/flnancing/ax.png" />我感兴趣</div>
         </div>
         <div class="fde-r" v-show="ps==1">
           <img :src="'https://ymedicine.jiujingwulian.com/public'+users.headimage" />
           <div class="fde-r-title">{{users.name}}</div>
           <div class="fde-r-xq bys" ><img src="~assets/flnancing/ax.png" />我感兴趣</div>
         </div>

          <div class="fde-t">相关项目推荐</div>

          <div class="fde-b" @click="getdata(item.id)" v-for="item in nrlb">
            <div class="fde-b-bt" >{{item.name}}</div>
            <div class="bt">{{item.ly0.name}}</div>
            <div class="bt">{{item.ly1.name}}</div>
          </div>
          <!-- <div class="fde-b">
            <div class="fde-b-bt">项目名称项目名称项目名称项目名称项目名项目名称项目名称项目名称项目名称项目名称项目</div>
            <div class="bt">医药</div>
            <div class="bt">医药</div>
            <div class="bt">医药</div>
            <div class="bt">医药</div>
          </div>
          <div class="fde-b">
            <div class="fde-b-bt">项目名称项目名称项目名称项目名称项目名项目名称项目名称项目名称项目名称项目名称项目</div>
            <div class="bt">医药</div>
            <div class="bt">医药</div>
            <div class="bt">医药</div>
            <div class="bt">医药</div>
          </div>
          <div class="fde-b">
            <div class="fde-b-bt">项目名称项目名称项目名称项目名称项目名项目名称项目名称项目名称项目名称项目名称项目</div>
            <div class="bt">医药</div>
            <div class="bt">医药</div>
            <div class="bt">医药</div>
            <div class="bt">医药</div>
          </div> -->
        </div>


      </div>
    </div>

  <el-dialog class="ddb zd" width="80%"  align-center v-model="dialogTableVisible2" :show-close="false">
    <div class="top">
      <div class="">请填写您的信息</div>
      <div class="gb "><img @click="dialogTableVisible2=false" src="../../assets/my/guanbi.png" /></div>
    </div>
    <div class="kuai">

      <div class="hang">
        <div class="lxr1">
          <div class="bt">姓名<span class="red">*</span>：</div>
          <el-input class="shuru" v-model="name" placeholder="请输入" />
        </div>
      </div>
      <div class="hang">
        <div class="lxr1">
          <div class="bt">手机号<span class="red">*</span>：</div>
          <el-input class="shuru" v-model="phone" placeholder="请输入" />
        </div>
      </div>
      <div class="hang">
        <div class="lxr1">
          <div class="bt"><span class="red"></span>类型：</div>
          <el-radio-group v-model="type" class="ml-4">
            <el-radio label="0" size="large">个人</el-radio>
            <el-radio label="1" size="large">单位</el-radio>
          </el-radio-group>
        </div>
      </div>
    </div>
    <div class="kuai biao">

      <div class="hang">
        <div class="lxr1">
          <div class="bt">公司/单位名称<span class="red">*</span>：</div>
          <el-input class="shuru" v-model="gsname" placeholder="请输入" />
        </div>
      </div>

    </div>


    <div class="dban">

      <el-button class="anniu bs1" type="primary" @click="open">
        <div>确认提交</div>
      </el-button>

    </div>

  </el-dialog>




    <foot></foot>
    <left></left>
  </div>
</template>

<script>

import {ref,onMounted} from 'vue';
import {getHome} from 'network/home.js';
import {getfinvestmentxq,getfbusinessorder} from 'network/merchant.js';
import {useRouter} from 'vue-router';

  import {
    ElMessage,
    ElMessageBox
  } from 'element-plus';
  import {
    Action
  } from 'element-plus';


import Head from "components/content/headder/Head.vue";
import Foot from "components/content/footer/Foot.vue";
import Left from "components/content/left/Left.vue";
import Bread from "components/content/breadcrumb/Breadcrumb.vue";
import Banner1 from "../financing/ChildComps/Banner.vue";


export default {
  name: 'New',
  data() {
    return {
      users:{},
      name: '',
      phone: '',
      type: 1,
      gsname: '',
      id:'',
      grxx:{},

      ps:0,
      dialogTableVisible2: false,
      p:1,
      nrxq:{},

      nrlb:[],
      bn:'public/img/banner.387b0333.png',
      all: [
        {
            name: '您当前的位置:首页',
            url: '/'
          },

          {
            name: '需求商机',
            url: '/merchant'
          },
          {
            name: '项目名称',
            url: '/'
          },
        ]
    }
  },
  setup(){
    const top=9;
    const router=useRouter();
    const databa=ref([]);
    const ab='sb';
    console.log(ab);
    onMounted(()=>{

      getHome().then(res=>{
        console.log(res.data.data);
        databa.value=res.data;
        console.log(databa);

      }).catch(err=>{

      })

    })
    //跳转方法
   const goTo=(path,id)=>{
     // console.log(query);
     var query={id:id}
     console.log(query);
     router.push({
       path:path,
       query:query || {}
     })
   }
   const type = ref('1');
   return { databa,ab,goTo,top,type }
  },
  mounted() {


    // var url = decodeURI(window.location.search); //?id="123456"&name="www";
    // var url = window.location.href;
    
    // var object = {};
    // if (url.indexOf("?") != -1) //url中存在问号，也就说有参数。
    // {
    //   var str = url.substr(1); //得到?后面的字符串
    //   var strs = str.split("&"); //将得到的参数分隔成数组[id="123456",name="www"];
    //   for (var i = 0; i < strs.length; i++) {
    //     object[strs[i].split("=")[0]] = strs[i].split("=")[1]; //得到{id:'123456',name:'www'}
    //   }
    // }
    // console.log(object);
    // var id = object['id'];
    // this.id = id;
    var tempid=this.GetUrlParam("id");
    this.id =  tempid;
 
    this.getdata(tempid);

  },
  methods: {
    GetUrlParam(paraName) {
      var url = window.location.toString();
      var arrObj = url.split("?");
      if (arrObj.length > 1) {
        var arrPara = arrObj[1].split("&");
        var arr;
        for (var i = 0; i < arrPara.length; i++) {
          arr = arrPara[i].split("=");
          if (arr != null && arr[0] == paraName) {
            return arr[1];
          }
        }
        return "";
      } else {
        return "";
      }
    },
    open() {
      console.log(this.grxx);
      if (!this.grxx.id) {
        this.$router.push('/login')
      } else {
        this.dialogTableVisible2 = true;
      }


      if (!this.name) {
        ElMessage({
          type: 'info',
          message: '请输入姓名',
        })
        return false;
      }
      if (!this.phone) {
        ElMessage({
          type: 'info',
          message: '请输入手机号',
        })
        return false;
      }
      const reg = /^[1][1,2,3,4,5,6,7,8,9][0-9]{9}$/;
      if ((!reg.test(this.phone)) && this.phone != '') {
        ElMessage({
          type: 'info',
          message: '请输入正确的手机号',
        })
        return false;
      }
      if (!this.gsname) {
        ElMessage({
          type: 'info',
          message: '请输入公司/单位名称',
        })
        return false;
      }






      ElMessageBox.confirm(
          '是否确认提交?',
          '提示', {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: '提示',
          }
        )
        .then(() => {
          ElMessage({
            type: 'success',
            message: '提交成功',
          })
          this.jkzl();
        })
        .catch(() => {
          ElMessage({
            type: 'info',
            message: '取消成功',
          })
        })
    },
    grxxff1(grxxs) {
      this.grxx = grxxs;
      console.log(grxxs);
      console.log('zhende');
      this.getdata(this.id);

    },
    jkzl() {

      var that = this;
      var data = {
        name: this.name,
        phone: this.phone,
        type: this.type,
        gsname: this.gsname,
        fusers_id: this.grxx.id,
        finvestmentin_id: this.nrxq.id,
      };
      getfbusinessorder(data).then(res => {

        console.log('zx');
        console.log(res);
        that.ps=1;
        that.dialogTableVisible2=false;

        //

        // that.nrxq = res.data.data.finvestmentinone;
        // that.users=res.data.data.finvestmentinone.users;
        // that.nrlb = res.data.data.finvestmentin;
        that.bn = 'https://ymedicine.jiujingwulian.com/public' + res.data.data.finvestmentinone.bimage;



      }).catch(err => {})

      // name:'',
      // phone:'',
      // type:'',
      // gsname:'',

    },




    gxq(){

      console.log(this.grxx);
      if (!this.grxx.id) {
        this.$router.push('/login')
      } else {
        this.dialogTableVisible2 = true;
      }

      // this/*  */.dialogTableVisible2=true;
    },
    getdata(ids){
      window.scrollTo( 0, 0 );
      console.log(this.grxx.id);
      console.log('jll');

      var that = this;
      var data = {
        id:ids,
        fusers_id:this.grxx.id
      };
      console.log(data);
      console.log(1111111);
      getfinvestmentxq(data).then(res => {

        console.log('zx');
        console.log(res);
        that.users = res.data.data.finvestmentinone.users;
        console.log(res.data.data.finvestmentinone.usersmy);
        if(res.data.data.finvestmentinone.usersmy){
          that.ps=1;
        }else{
          that.ps=0;
        }

        that.nrxq = res.data.data.finvestmentinone;
        that.nrlb =res.data.data.finvestmentin;



      }).catch(err => {})
    }
  },
  components:{
    Head,
    Foot,
    Left,
    Bread,
    Banner1
  },
}
</script>
<style lang="scss" scoped="scoped">
  // nth-child
  .a-cen{
    width: 100vw;
    background: #F5F6FA;
    padding: 1rem 0;
    .center{
      width: var(--allWidth);
      margin: 0 auto;
    }
  }
  .center{
    display: flex;
    // align-items: center;
    .fde-right{
      // background: #fff;
      width: 28%;
      margin-left: 2%;
      .fde-r{
        background: #fff;
        padding: 1.875rem;
        text-align: center;
      }
      .fde-t{
        margin: 1.3rem 0;
        font-size: 1.225rem;
        font-weight: bold;
      }
      .fde-b{
        background: #fff;
        padding: 0.925rem;
        cursor: pointer;
        .fde-b-bt{
          font-size: 0.875rem;
          line-height: 1.2rem;
        }
        .bt{
          font-size: 0.775rem;
          color: #2E9BD6;
          border: 0.0625rem solid rgb(46, 155, 214);
          box-shadow: 0rem 0.25rem 0.25rem rgba(222, 222, 222, 0.25);;
          border-radius: 0.1875rem;
          padding: 0.2125rem 0.625rem;
          display: inline-block;
          margin-right: 0.625rem;
          margin-top: 0.6375rem;
        }
        margin-bottom: 0.625rem;
      }
      .fde-r-title{
        font-size: 1.2rem;
        margin-top: 0.625rem;
      }
      .fde-r-xq{
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        img{
          margin-right: 0.3125rem;
        }
        margin-top: 1.875rem;
        padding: 0.875rem 0rem;
        background: #2E9BD6;
        color: #fff;
        border-radius: 0.625rem;
        font-size: 0.875rem;
      }
    }
    .fde-left{

      width: 70%;

      .one{
        .z-content{
          padding: 1.875rem;
          padding-top: 0.6rem;
          word-break: break-all;
          .cimg{
            width: 100%!important;
          }
        }
        img{
          max-width: 100%!important;
        }

        padding:0.6rem 0.6rem;

        margin-bottom: 1.875rem;
        background: #FFFFFF;
        .z-top-title{
          margin-top: 1.25rem;
          font-size: 1.4rem;
          font-weight: bold;
          text-align: center;
          margin-bottom: 1.875rem;
        }
        .z-top{
          display: flex;
          align-items: center;
          img{
            width: 1.125rem;
          }
          .xuxian{
              border-top: 0.125rem solid #E0E0E0;
              width: 60%;
          }
          margin-bottom: 1.875rem;
        }
      }
   }
  }


  .ddb {
    position: relative;
    z-index: 9999999999999999999;

    .top {
      font-weight: bold;
      position: absolute;
      top: 0;
      left: 0;
      background: #2E9BD6;
      color: #FFFFFF;
      width: 100%;
      padding: 1.075rem;
      font-size: 1.2rem;
      text-align: center;
      .gb{
        cursor: pointer;
        position: absolute;
        top: 50%;
        right:1.25rem;
        transform: translate(0,-50%);
      }
    }

    .biao {
      padding-top: 1rem;
    }

    .kuai {
      border-bottom: 0.1625rem solid #F8F8F8;


      .biaoti {
        margin: 0 1.175rem;
        margin-top: 0.625rem;
        margin-bottom: 1.075rem;
        display: flex;
        align-items: center;

        .bmfy {
          font-weight: bold;
          font-size: 0.9rem;
        }

        .bmje {
          font-size: 1.2rem;
          color: #DB2626;
        }

      }

      .k-biaoti {
        padding: 0 4.875rem 0 4.875rem;
        font-weight: bold;
        position: relative;
        margin-top: 1.25rem;

        .gh {
          position: absolute;
          top: 50%;
          right: 4.825rem;
          transform: translate(0%, -50%);

          .ans {
            background: #2E9BD6;
            padding: 0.5rem 1.5rem;
          }
        }

      }

      .k-ones {
        padding: 1.25rem 4.875rem 0 4.875rem;
        display: flex;

        .kone {
          width: 33.333%;
          text-align: center;
          background: #F8F8F8;
          padding: 0.75rem 0;
          font-size: 0.8rem;
        }

        .kone1 {
          background: #F8F8F8;
          color: #B8B8B8;
          padding: 1.075rem 1.3rem;
          margin-right: 0.925rem;
        }

        .kone2 {
          padding: 1.075rem 1.3rem;
          border: 0.0625rem solid #DBDBDB;
          margin-right: 0.925rem;
        }

        .bs {
          border: 0.0625rem solid #2E9BD6;
          color: #2E9BD6;
        }
      }


    }

    .lxr,
    .lxr1 {
      width: 50%;
      display: flex;
      align-items: center;
      // display: inline-block;
      margin-right: 0.625rem;

      .bt {
        width: 8.5rem;
        text-align: right;
        font-weight: bold;

        .red {
          color: red;
        }
      }

      .shuru {
        width: 60%;
      }
    }

    .lxr1 {
      width: 100%;

      .shuru {
        width: 80.5%;
      }
    }

    .hang {
      display: flex;
      margin: 0 1.175rem;
      margin-top: 0.625rem;
      margin-bottom: 1.075rem;

      .h-title {
        font-weight: bold;
        color: #000000;
        border-left: 0.225rem solid #2E9BD6;
        padding-left: 0.625rem;
      }
    }

    .dban1 {
      text-align: center;
    }

    .dban {
      text-align: center;
      margin-top: 2.175rem;
      position: relative;

      .anniu {
        width: 30%;
        text-align: center;
        padding: 1.85rem 0 1.85rem 0;
        font-size: 1rem;
        position: relative;
        background: #2E9BD6;

        .je {
          font-weight: bold;
          position: absolute;
          top: 1rem;
          left: 50%;
          transform: translate(-50%, 0);
        }
      }

      .bs {
        background: #AFC81D;
        border: 0.0625rem solid #AFC81D;
        margin-right: 2.275rem;

        img {
          width: 1rem;
          margin-right: 0.625rem;
        }
      }

      .bs1 {
        background: #2E9BD6;
      }

      .je {
        display: block !important;
        font-size: 1rem;

      }
    }

  }



  .bys{
    background: #9da2a5!important;
  }

  @media screen and (max-width: 1300px) {
   .fde-left {
     width: 95% !important;
     margin: 0 auto !important;

     .one {
       margin-bottom: 0.875rem !important;

       .z-top-title {
         margin: 0.5rem 0 !important;
         font-size: 1.1rem !important;
       }
     }

   }
   .fde-right{
     width: 95% !important;
     margin: 0 auto !important;
   }
   .fde-t{
     font-size: 1rem;
   }
   .ddb{
     .hang{
       margin: 0.625rem 0rem;
       .bt{
         width: 35%;
         padding-left: 0.3125rem;
       }
     }
   }
   .el-radio-group{
     width: 75%;
   }
   .el-dialog__body{
     width: 100%!important;
     padding: 1.75rem 0!important;
     margin: 0 auto!important;
   }
   .dban{
     margin-top: 1.175rem!important;
   }
   .anniu{
     font-size: 0.95rem!important;
     padding: 1.3rem 6rem!important;
   }
   .ddb{
     .top{
       font-size: 1rem;
       padding: 0.8rem;
     }
   }

  }

</style>
