<template>

  <div class="z-o">
    <div class="zo-top">
      <div class="top-control">
        <img src="~assets/home/dws.png" />
        <el-breadcrumb :separator-icon="ArrowRight">
          <el-breadcrumb-item v-for="item in all" :to="{ path: item.url }">{{item.name}}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
  </div>
</template>




<script>
  import {
    ArrowRight
  } from '@element-plus/icons-vue'
  import {
    useRouter
  } from 'vue-router';

  export default {
    name: 'Bread',
    props: ["all"],
    // data:{
    //   all:[
    //     {
    //       name:'首页',
    //       url:'/'
    //     },
    //     {
    //       name:'关于联合体',
    //       url:'/'
    //     },
    //   ]
    // },
    setup() {
      //跳转方法
      const router = useRouter();
      const goTo = (path, query) => {
        console.log(query);
        query = {
          name: 'nn'
        }
        console.log(query);
        router.push({
          path: path,
          query: query || {}
        })
      }

      return {
        goTo
      }

    }
  }
</script>

<style lang="scss" scoped="scoped">
  .z-o {
    width: var(--allWidth);
    margin: 0 auto;

    .zo-top {
      padding: 1.075rem 0;
    }
  }
  .top-control {
    display: flex;
    // justify-content: center;
    align-items: center;

    img {
      width: 0.85rem;
      margin-right: 0.325rem;
    }
  }
   @media screen and (max-width: 1300px) {
     .z-o{
       width: 100%;
       padding: 0 1rem!important;
     }
     .el-breadcrumb{
       line-height: 1.8;
     }
   }
</style>
