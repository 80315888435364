import { request } from "./request.js";

export function getSignature(params){
    return request(
      {
          url:'/public/index/index/signature',
          method:'POST',
          params:params
      }
    )
}
export function getfmeeting(params){
    return request(
      {
          url:'/public/index/index/fmeetingone',
          method:'POST',
          params:params
      }
    )
}
