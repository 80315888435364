<template>
  <div class="all">
    <Head :top="top" :grxxff="grxxff"></Head>
    <Banner :bn='bn'></Banner>
    <Bread :all="all"></Bread>
    <div class="a-cen">
      <div class="center">

        <div class="zong">
          <div class="zo-cen">
            <div class="zoc-left">
              <div class="zocl-cen" @click="qh(0)" :class="v==0?'bj':''">专家库</div>
              <div class="zocl-cen" @click="qh(1)" :class="v==1?'bj':''">机构库</div>
              <!--  <div class="zocl-cen" @click="qh(2)" :class="v==2?'bj':''">组织架构</div>
              <div class="zocl-foot" @click="bsa(0)" :class="[v==2?'xs':'',bs==0?'bsx':'']">专业委员会</div>
              <div class="zocl-foot" @click="bsa(1)" :class="[v==2?'xs':'',bs==1?'bsx':'']">工作委员会</div> -->
            </div>


            <div class="zoc-right" >
              <div class="z-bt"><img src="~assets/about/fh.png" />公司名称</div>
              <div class="z-nrk">
                <div class="z-nrk-left"><img src="~assets/expert/tx.png" /></div>
                <div class="z-nrk-right">
                  <div class="z-n-r-title">陆涛</div>
                  <div class="z-n-r-nr1">个人简介：<span>简介内容简介内容简介内容简介内容简介内容简介内容简介内容简介内容</span></div>
                </div>
              </div>
              <div class="z-nrk-con">简介内容简介内容简介内容简介内容简介内容简介内容简介内容简介内容简介内容简介内容简介内容简介内容简介内容简介内容简介内容简介内容简介内容简介内容简介内容简介内容简介内容简介内容简介内容简介内容</div>
            </div>




          </div>
        </div>


      </div>
    </div>
    <foot></foot>
    <left></left>
  </div>
</template>

<script>
  import {
    ref,
    onMounted
  } from 'vue';
  import {
    getHome
  } from 'network/home.js';
  import {
    useRouter
  } from 'vue-router';


  import Head from "components/content/headder/Head.vue";
  import Foot from "components/content/footer/Foot.vue";
  import Left from "components/content/left/Left.vue";
  import Bread from "components/content/breadcrumb/Breadcrumb.vue";
  import Banner from "components/content/banner/Banner.vue";

  import Journalism from 'components/content/journalism/Journalism.vue'
  import Notice from "components/content/notice/Notice.vue";
  import List from "components/content/list/List.vue";

  export default {
    name: 'New',
    data() {
      return {
        v: 1,
        i: 1,
        bn: 'public/img/banner.387b0333.png',
        all: [{
            name: '您当前的位置:首页',
            url: '/'
          },

          {
            name: '专家库',
            url: '/New'
          },
        ]
      }
    },
    setup() {
      const top = 6;
      const router = useRouter();
      const databa = ref([]);
      const ab = 'sb';
      console.log(ab);
      onMounted(() => {

        getHome().then(res => {
          console.log(res.data.data);
          databa.value = res.data;
          console.log(databa);

        }).catch(err => {

        })

      })
      //跳转方法
      const goTo = (path, query) => {
        router.push({
          path: path,
          query: query || {}
        })
      }

      return {
        databa,
        ab,
        goTo,
        top
      }
    },
    methods: {
      grxxff(grxxs){
        this.grxx=grxxs;
        console.log(grxxs);
        console.log('zhende');
      },
      qh(it) {
        console.log(it);
        this.v = it;
      },
    },
    components: {
      Head,
      Foot,
      Left,
      Bread,
      Notice,
      Journalism,
      List,
      Banner
    },
  }
</script>
<style lang="scss" scoped="scoped">
  .a-cen {
    width: 100vw;
    background: #F5F6FA;
    padding: 1rem 0;

    .center {
      width: var(--allWidth);
      margin: 0 auto;

      .zo-cen {
        width: var(--allWidth);
        margin: 0 auto;
        display: flex;

        .zoc-left {
          width: 14%;
          border-left: 0.125rem dotted #D7D7D7;


          .zocl-top {
            font-size: 1rem;
            font-weight: bold;
            padding: 1.625rem;
          }

          .zocl-cen {
            padding: 1.35rem;
            font-size: 0.9125rem;
            cursor: pointer;
          }

          .zocl-foot {
            display: none;
            width: 100%;
            padding: 0.925rem 1.3rem;
            background: #fff;
            font-size: 0.8125rem;
            border-bottom: 0.0625rem dotted #EEEEEE;
            cursor: pointer;
          }

          .xs {
            display: block;
          }

          .bsx {
            color: #2E9BD6;
          }

          .bj {
            background: url(assets/about/fw.png) no-repeat;
            background-size: 100% 100%;
            color: #fff;
          }

        }

        .c-ku {
          width: 57%;
          min-height: 10vh;
          background: #fff;
          margin-right: 2%;




        }

        .zoc-right {
          width: 86%;
          padding: 1.875rem;
          background: #fff;
          // margin:0 1.875rem  1.875rem  1.875rem;
          position: relative;
          min-height: 50rem;

          //列表
          .z-bt {
            border-bottom: 0.0625rem solid #DBDBDB;
            padding-bottom: 1.875rem;
            font-weight: bold;
            font-size: 1.2rem;
            img{
              width: 1.075rem;
              margin-right: 0.625rem;
            }
          }
          .z-nrk-con{
            padding: 0.625rem;
          }
          .z-nrk {
            padding: 1rem;
            border-bottom: 0.0625rem solid #DBDBDB;
            display: flex;
            align-items: center;
           .z-nrk-left{
             margin-right: 0.9rem;
            img{
              width: 7.875rem;
            }
           }
           .z-nrk-right{
            .z-n-r-title{
              font-size: 1.225rem;
              padding-bottom: 1.25rem;
              // border-bottom: 0.35rem solid #2E9BD6;
              display: inline-block;
              margin-bottom: 0.625rem;
              font-weight: bold;
            }
            .z-n-r-nr1{
              font-size: 0.9125rem;
              margin-top: 0.625rem;
              span{
                color: #888888;
              }
            }
           }

          }


          //列表结束


        }
      }


    }
  }

  .center {}
</style>
