<template>
  <div class="bn">
    <img class="qshi" :src="bn" />
    <div class="bt" v-show="p!=1">
      <div  class="xmmc">{{list.name}}</div>
      <div class="xmimg">
        <img :src="'https://ymedicine.jiujingwulian.com/public'+list.fimage" />
      </div>
    </div>
    <div class="dws" v-show="p!=1">
      <div class="dws-one">
        <img src="~assets/flnancing/dw.png" />
        <div class="dws-rig">
          <div class="dws-bt">{{list.ly1}}</div>
          <div class="dws-nr">所在地区</div>
        </div>
      </div>
      <div class="shu"></div>
      <div class="dws-one">
        <img src="~assets/flnancing/zz.png" />
        <div class="dws-rig">
          <div class="dws-bt">{{list.ly2}}</div>
          <div class="dws-nr">所处阶段 </div>
        </div>
      </div>
      <div class="shu"></div>
      <div class="dws-one">
        <img src="~assets/flnancing/yy.png" />
        <div class="dws-rig">
          <div class="dws-bt">{{list.ly0}}</div>
          <div class="dws-nr">所属领域</div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  import {
    useRouter
  } from 'vue-router';

  export default {
    name: 'Banner',
    props: ['bn', 'list', 'p'],
    setup() {
      //跳转方法
      const router = useRouter();
      const goTo = (path, query) => {
        console.log(query);
        query = {
          name: 'nn'
        }
        console.log(query);
        router.push({
          path: path,
          query: query || {}
        })
      }

      return {
        goTo
      }

    },
  }
</script>

<style lang="scss" scoped="scoped">
  .bn {
    width: 100%;

    img {
      width: 100%;
    }

    position: relative;

    .dws {
      position: absolute;
      /* left: 50%; */
      bottom: 0;
      transform: translate(0%, 0%);
      width: 100%;
      background: rgba(0, 79, 157, 0.64);
      padding: 1.25rem 1.875rem;
      z-index: 99;
      display: flex;
      justify-content: center;
      align-items: center;

      .shu {
        margin: 0 10.875rem;
        background: #fff;
        width: 0.0625rem;
        height: 3.625rem;
      }

      .dws-one {
        display: flex;
        align-items: center;
        color: #fff;

        .dws-rig {
          .dws-bt {
            font-size: 1.3rem;
          }

          .dws-nr {
            font-size: 1rem;
            ;
          }
        }

        img {
          width: 1.875rem;
          margin-right: 0.625rem;
        }
      }

    }

    .bt {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;

      .xmmc {
        font-size: 1.5625rem;
        font-weight: bold;
        margin-right: 0.825rem;
        color: #fff;
      }

      .xmimg {
        padding: 0.625rem;
        background: #FFFFFF;

        img {
          width: 20.875rem;
        }
      }
    }
  }

  @media screen and (max-width: 1300px) {
    .bt {
      width: 100% !important;
      position: relative !important;
      top: 0 !important;
      left: 0 !important;
      transform: translate(0, 0) !important;
      display: inline-block !important;

      .xmmc {
        color: #fff !important;
        font-size: 1.32rem !important;
        text-align: center !important;
        padding: 0.625rem !important;
        font-weight: 500 !important;
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        background: rgba(0, 79, 157, 0.64);
        width: 100%;

      }

      .xmimg {
        padding: 0 !important;
        width: 100% !important;

        img {
          width: 100% !important;
        }
      }
    }


    .dws {
      padding: 0.25rem 0.5rem !important;
      justify-content: space-around !important;
      align-items: center;

      .dws-one {
        img {
          width: 1.2rem !important;
        }

        .dws-bt,
        .dws-nr {
          font-size: 0.75rem !important;
        }

      }

      .shu {
        margin: 0 !important;
        height: 3rem !important;
      }

    }
    .qshi{
      display: none;
    }

  }
</style>
