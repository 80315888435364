<template>
  <div class="all">
    <Head :top="top" :grxxff="grxxff"></Head>
    <Bread :all="all"></Bread>
    <div class="a-cen">
      <div class="center">
        <div class="mde-bt" v-show="fl==0">
          <block  v-for="(item,index) in fel">
            <div @click="qhliltfe(item.id)" style="display: inline-block;" class="mde-bt1" :class="item.id==feqh?'td':''">{{item.name}}</div>
            <!-- <div class="mde-bt1" style="display: inline-block;">|</div> -->
            <!--  -->
          </block>
        <!-- <div @click="qh(1)" class="mde-bt1" :class="i==1?'td':''">线上直播</div>
          <div class="mde-bt1">|</div>
          <div @click="qh(2)" class="mde-bt1" :class="i==2?'td':''">会议进展</div> -->
        </div>

        <List1 :list="ftqt"></List1>
        <page :items='items'
        :litems='litems'
        :total="total"
        :per_page="per_page"
        :current_page="current_page"
        :last_page="last_page"
        :listcount='listcount'></page>



      </div>
    </div>
    <foot></foot>
    <left></left>
  </div>
</template>

<script>

  import {ref,onMounted} from 'vue';
  import {getHome,getfmeeting} from 'network/home.js';
  import {useRouter} from 'vue-router';


  import Head from "components/content/headder/Head.vue";
  import Foot from "components/content/footer/Foot.vue";
  import Left from "components/content/left/Left.vue";
  import Bread from "components/content/breadcrumb/Breadcrumb.vue";
  import Banner from "components/content/banner/Banner.vue";
  import List1 from "./ChildComps/List.vue";
  import Page from "components/content/page/Page.vue";


export default {
  name: 'MeetingList',
  data() {
    return {
      items:[
      ],
      litems:[
      ],
      page:0,
      total:'',
      per_page:'',
      current_page:'',
      last_page:'',


      i:0,
      bn:'public/img/banner.387b0333.png',
      all: [
        {
            name: '您当前的位置:首页',
            url: '/'
          },

          {
            name: '会议论坛',
            url: '/meeting'
          },
          {
            name: '会议列表',
            url: '/meetingList'
          },
        ],
        fl:0,
        fel:[],
        feqh:0,
        ftqt:[]
    }
  },
  setup(){
    const top=5;
    const router=useRouter();
    const databa=ref([]);
    const ab='sb';
    console.log(ab);
    onMounted(()=>{

      getHome().then(res=>{
        console.log(res.data.data);
        databa.value=res.data;
        console.log(databa);

      }).catch(err=>{

      })

    })
    //跳转方法
    const goTo=(path,query)=>{
      router.push({
        path:path,
        query:query || {}
      })
    }

    return { databa,ab,goTo,top }
  },
  mounted() {

    // var url = decodeURI(window.location.search); //?id="123456"&name="www";
    // // var url = window.location.href;
    // console.log(url);
    // var object = {};
    // if (url.indexOf("?") != -1) //url中存在问号，也就说有参数。
    // {
    //   var str = url.substr(1); //得到?后面的字符串
    //   var strs = str.split("&"); //将得到的参数分隔成数组[id="123456",name="www"];
    //   for (var i = 0; i < strs.length; i++) {
    //     object[strs[i].split("=")[0]] = strs[i].split("=")[1]; //得到{id:'123456',name:'www'}
    //   }
    // }
    console.log(object);
    // var id = object['id'];
    var id= this.GetUrlParam('id');
    this.fl=id;
    this.qhlistfe();



  },
  methods: {
    GetUrlParam(paraName) {
      var url = window.location.toString();
      var arrObj = url.split("?");
      if (arrObj.length > 1) {
        var arrPara = arrObj[1].split("&");
        var arr;
        for (var i = 0; i < arrPara.length; i++) {
          arr = arrPara[i].split("=");
          if (arr != null && arr[0] == paraName) {
            return arr[1];
          }
        }
        return "";
      } else {
        return "";
      }
    },
    grxxff(grxxs){
      this.grxx=grxxs;
      console.log(grxxs);
      console.log('zhende');
    },
    // qh(is){
    //   this.i=is;
    // }
    qhliltfe(tt){
      console.log('2');
      this.page=1;
      this.qhlistfe(tt);
    },

    listcount(page){
      this.page=page;
      this.qhlistfe(this.feqh);
    },

    qhlistfe(fnewfl_id){

      var that=this;
      var data={type:0,fl:this.fl,fmeetingfl_id:fnewfl_id,page:this.page,sl:12};
      console.log(data);
      getfmeeting(data).then(res => {

        console.log(res);
        that.fel=res.data.list;
        // that.fel1=res.data.list[1];
        if(fnewfl_id){
          that.feqh=fnewfl_id;
        }else{
          that.feqh=res.data.list[0].id;
        }


        that.ftqt = res.data.data.table.data;

        that.total=res.data.data.table.total;
        that.per_page=res.data.data.table.per_page;
        that.current_page=res.data.data.table.current_page;
        that.last_page=res.data.data.table.last_page;
        that.items=res.data.data.items;
        that.litems=res.data.data.litems;


        console.log(that.ftqt)
        // var newtwo={};
        // var newqt=[];
        // var j=0;
        // var i=0;
        // for(i=0;i<ddd.length;i++){

        //   // console.log(ddd[i]);
        //   console.log(i);

        //   if(i<=0){
        //     newtwo=ddd[i];
        //   }else{
        //     newqt[j]=ddd[i];
        //     j++;
        //   }
        // }


        // that.fttwo=newtwo;
        // console.log('会议');
        // console.log(that.fttwo);
        // that.ftqt=newqt;

      }).catch(err => {

      })

    },



  },
  components:{
    Head,
    Foot,
    Left,
    Bread,
    List1,
    Page,
    Banner
  },
}
</script>
<style lang="scss" scoped="scoped">
  body{
    background: #f5f6fa;
  }
  .a-cen{
    width: 100vw;
    background: #f5f6fa;
    padding: 0.625rem 0;
    .center{
      width: var(--allWidth);
      margin: 0 auto;
    }
  }
  .center{
    .mde-bt{
      background: #fff;
      display: flex;
      justify-content: center;
      border-bottom: 0.0625rem solid #E4E4E4;
      .mde-bt1{
          margin: 1.925rem 1.875rem;
          padding-bottom: 1.925rem;
          margin-bottom: 0;
          font-size: 1.125rem;
          font-weight: bold;
          cursor: pointer;
          // display: none;
      }
      .td{
        border-bottom: 0.2025rem solid #2E9BD6;
        color: #2E9BD6;
      }
    }
    .mde-xq{
      background: #fff;
      padding: 1.875rem;
      min-height: 30vh;
      // margin-top: 1.875rem;
      margin-bottom: 0.625rem;
      display: none;

      .xx{
        display: flex;
        .x-one{
          padding: 0.95rem;
          font-size: 0.8125rem;
          margin-right: 0.625rem;
        }
        .bjs{
          background: #2E9BD6;
          color: #fff;
        }
      }
      .x-nrsb{
         padding: 1.875rem 0;
      }
      .xxan{
          display: flex;
          justify-content: space-around;
          padding: 0 18.75rem;
         .xxan-left{
           width: 37%;
           padding: 1.25rem 0;
           text-align: center;
           color: #fff;
           border-radius: 0.3125rem;
           background-color: #AFC81D;
           cursor: pointer;
         }
         .ru{
           background-color: #2E9BD6;
         }


      }
    }
    .xs{
      display: block;
    }
    .zj{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
      .zhibo{
        margin-bottom: 0.825rem;
        margin-right: 5%;
        width: 30%;

        img{
          width: 100%;
        }
        position: relative;
      }
      .hc{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 0.825rem;
        color: #fff;
        background:rgba(0,0,0,0.8);
      }
      .zhibo:nth-child(3n){
          margin-right: 0!important;
      }

    }

  }

  @media screen and (max-width: 1300px) {
    .mde-bt1{
      display: inline-block;
      margin: 1rem!important;
      font-size: 1rem!important;
      padding-bottom: 0.1rem!important;
    }
  }

</style>
