import { request } from "./request.js";


export function getfinvestment(params){
    console.log(params);
    return request(
      {
          url:'/public/index/index/ftechnologyfl',
          method:'POST',
          params:params,
      }
    )
}


export function getfinvestmentin(params){
    console.log(params);
    return request(
      {
          url:'/public/index/index/ftechnology',
          method:'POST',
          params:params,
      }
    )
}


export function getfinvestmentxq(params){
    console.log(params);
    return request(
      {
          url:'/public/index/index/ftechnologyxq',
          method:'POST',
          params:params,
      }
    )
}


export function getinsertftechnologyorder(params){
    console.log(params);
    return request(
      {
          url:'/public/index/index/insertftechnologyorder',
          method:'POST',
          params:params,
      }
    )
}


