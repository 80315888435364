<template>
  <div class="all">
    <div class="h-top">
      <div class="t-top">
        <div class="t-center">
          <div class="t-span">Hi~欢迎访问CBIITA生物医药产业链创新转化联合体！</div>
          <!-- <div class="right">
            <span>登录</span>|
            <span>免费注册</span>|
            <span>找回密码</span>|
          </div> -->
        </div>
      </div>
    </div>
    <Banner :bn='bn'></Banner>
    <div class="a-cen">
      <div class="center">
        <div class="cc-top">
          <div class="cc-top-one">
            <div class="ttp">{{fyb.qy}}</div>
            <div>参与企业</div>
          </div>
          <div class="shu"></div>
          <div class="cc-top-one">
            <div class="ttp">{{fyb.tp}}</div>
            <div>累计票数</div>
          </div>
          <div class="shu"></div>
          <div class="cc-top-one">
            <div class="ttp">{{fyb.fw}}</div>
            <div>累计访问</div>
          </div>
          <div class="shu"></div>
          <dib class="bman">报名参选</dib>
        </div>
        <div class="cc-top1">
          <div @click="qh(0)" class="cc-top1-one " :class="is==0?'hx':''">活动介绍</div>
          <div class="shu"></div>
          <div @click="qh(1)" class="cc-top1-one" :class="is==1?'hx':''">参选企业</div>
          <div class="shu"></div>
          <div @click="qh(2)" class="cc-top1-one" :class="is==2?'hx':''">专家介绍</div>
          <div class="shu"></div>
          <div @click="qh(3)" class="cc-top1-one" :class="is==3?'hx':''">评分规则</div>
          <div class="shu"></div>
          <div @click="qh(4)" class="cc-top1-one" :class="is==4?'hx':''">评价体系</div>
          <div class="shu"></div>
          <div @click="qh(5)" class="cc-top1-one" :class="is==5?'hx':''">相关文章</div>
          <div class="shu"></div>
          <div @click="qh(6)" class="cc-top1-one" :class="is==6?'hx':''">专家访谈</div>
          <div class="shu"></div>
          <div @click="qh(7)" class="cc-top1-one" :class="is==7?'hx':''">颁奖晚会</div>
          <div class="shu"></div>
          <div @click="qh(8)" class="cc-top1-one" :class="is==8?'hx':''">往届风云榜</div>
        </div>
      </div>
    </div>
    <div class="bot " v-show="is==0">
      <div class="zj" v-html="fyb.nrcontent">

      </div>
    </div>
    <div class="bot" v-show="is==1">
      <div class="cen-t sb">
        <div class="sousuo">
          <img src="../../assets/cloud/sousuo.png"/>
          <input placeholder="根据企业名称或编号进行搜索" />
          <div class="annniu">搜索</div>
        </div>
        <div class="one-list">
          <div class="one"  v-for="item in fyb.qylist">
            <img :src="'https://ymedicine.jiujingwulian.com/public'+item.fimage" />
            <div class="llp">
              <div class="bt">{{item.name}}</div>
              <div class="piao">
                <div class="piao-title">{{item.piao}}票</div>
                <div class="piao-anniu" v-show="fyb.status==0">未开始</div>
                <div class="piao-anniu tp" v-show="fyb.status==1" @click="tp(item.id)">投票</div>
                <div class="piao-anniu js" v-show="fyb.status==2">已结束</div>
              </div>
            </div>
            <div class="bh">
              编号{{item.id}}
            </div>
          </div>
     <!--    <div class="one">
           <img src="~assets/cooperation/left.png" />
           <div class="llp">
             <div class="bt">企业名称企业名称企业名称企业名称</div>
             <div class="piao">
               <div class="piao-title">1000票</div>
               <div class="piao-anniu js">未开始</div>
             </div>
           </div>
           <div class="bh">
             编号001
           </div>
         </div>

         <div class="one">
           <img src="~assets/cooperation/left.png" />
           <div class="llp">
             <div class="bt">企业名称企业名称企业名称企业名称</div>
             <div class="piao">
               <div class="piao-title">1000票</div>
               <div class="piao-anniu tp">未开始</div>
             </div>
           </div>
           <div class="bh">
             编号001
           </div>
         </div> -->


        </div>
      </div>
    </div>
    <div class="bot" v-show="is==2">
      <div class="zj" v-html="fyb.zjcontent">

      </div>
    </div>
    <div class="bot" v-show="is==3">
      <div class="zj" v-html="fyb.pfcontent">

      </div>
    </div>
    <div class="bot" v-show="is==4">
      <div class="zj"  v-html="fyb.pftxcontent">
      </div>
    </div>
    <div class="bot" v-show="is==5">
      <CloudNewIn :ids="ids" :newall="news" :pds="pd" :is="ishow" :list="lb"  ></CloudNewIn>

      <div class="zj" v-show="ishow==0">
        <div class="zj-one" v-for="item in fyb.ffybnew" @click="tzs(item.id)">
          <div class="zj-left">
            <img :src="'https://ymedicine.jiujingwulian.com/public'+item.fimage" />
          </div>
          <div class="zj-right">
            <div class="zj-right-title onehang">{{item.name}}</div>
            <div class="zj-right-nr twohang">{{item.jianjie}}</div>
            <div class="zj-right-sj">{{item.time}}</div>
          </div>
        </div>
        <!--
        <div class="zj-one">
          <div class="zj-left">
            <img src="~assets/cloud/xw.png" />
          </div>
          <div class="zj-right">
            <div class="zj-right-title">新闻标题新闻标题新闻标题新闻标题新闻标题新闻标</div>
            <div class="zj-right-nr">题新闻标题新闻标题新闻标题新闻标新闻标题新闻标题新闻标题新闻标题新闻标题新闻标新闻标题新闻标题新闻标题新闻标题新闻标题新闻标新闻标题新闻标题新闻标题新闻标题新闻标题新闻标新闻标题新闻标题新闻标题新闻标题新闻标题新闻标新闻标题新闻标题新闻标题新闻标题新闻标题新闻标新闻标题新闻标题新闻标题新闻标题新闻标题新闻标新闻标题新闻标题新闻标题新闻标题新闻标题新闻标闻标题新闻标</div>
            <div class="zj-right-sj">2022-09-08</div>
          </div>
        </div>


        <div class="zj-one">
          <div class="zj-left">
            <img src="~assets/cloud/xw.png" />
          </div>
          <div class="zj-right">
            <div class="zj-right-title">新闻标题新闻标题新闻标题新闻标题新闻标题新闻标</div>
            <div class="zj-right-nr">题新闻标题新闻标题新闻标题新闻标新闻标题新闻标题新闻标题新闻标题新闻标题新闻标新闻标题新闻标题新闻标题新闻标题新闻标题新闻标新闻标题新闻标题新闻标题新闻标题新闻标题新闻标新闻标题新闻标题新闻标题新闻标题新闻标题新闻标新闻标题新闻标题新闻标题新闻标题新闻标题新闻标新闻标题新闻标题新闻标题新闻标题新闻标题新闻标新闻标题新闻标题新闻标题新闻标题新闻标题新闻标闻标题新闻标</div>
            <div class="zj-right-sj">2022-09-08</div>
          </div>
        </div> -->


      </div>
    </div>
    <div class="bot" v-show="is==6">
      <CloudNewIn :ids="ids" :newall="news" :pds="pd" :is="ishow"  :list="lb" ></CloudNewIn>
      <div class="bjsp" v-show="ishow==0">访谈文章</div>
      <div class="zj zjsj" v-show="ishow==0">
        <div class="zj-one sj" v-for="item in fyb.ffybnew1"  @click="tzs(item.id)">
          <div class="zj-left">
            <img :src="'https://ymedicine.jiujingwulian.com/public'+item.fimage" />
          </div>
          <div class="zj-right">
            <div class="zj-right-title onehang">{{item.name}}</div>
            <div class="zj-right-nr twohang">{{item.jianjie}}</div>

          </div>
        </div>

    <!--  <div class="zj-one sj">
        <div class="zj-left">
          <img src="~assets/cloud/xw.png" />
        </div>
        <div class="zj-right">
          <div class="zj-right-title">新闻标题新闻标题新闻标题新闻标题新闻标题新闻标</div>
          <div class="zj-right-nr">新闻标题新闻标题新闻标题新闻标题新闻标题新闻标新闻标题新闻标题新闻标题新闻标题新闻标题新闻标</div>

        </div>
      </div>


      <div class="zj-one sj">
        <div class="zj-left">
          <img src="~assets/cloud/xw.png" />
        </div>
        <div class="zj-right">
          <div class="zj-right-title">新闻标题新闻标题新闻标题新闻标题新闻标题新闻标</div>
          <div class="zj-right-nr">新闻标题新闻标题新闻标题新闻标题新闻标题新闻标新闻标题新闻标题新闻标题新闻标题新闻标题新闻标</div>

        </div>
      </div> -->


    </div>



      <div class="bjsp">颁奖视频</div>
      <div class="cen-t">
        <div class="bot-one" v-for="item in fyb.ffybsp"  @click="bfsp(item.curl,item.fimage,item.name)">
          <img :src="'https://ymedicine.jiujingwulian.com/public'+item.fimage" />
          <!-- <img src="~assets/cloud/lb.png" /> -->
          <!-- <video :src="item.curl"  style="object-fit:cover;height: auto;width: 100%;"  :poster="'https://ymedicine.jiujingwulian.com/public'+item.fimage" controls="controls" width="100%" ></video> -->
          <div class="">{{item.name}}</div>
        </div>

      <!--  <div class="bot-one">
          <img src="~assets/cloud/lb.png" />
          <div class="">2021年度xxxx风云榜</div>
        </div>


        <div class="bot-one">
          <img src="~assets/cloud/lb.png" />
          <div class="">2021年度xxxx风云榜</div>
        </div>


        <div class="bot-one">
          <img src="~assets/cloud/lb.png" />
          <div class="">2021年度xxxx风云榜</div>
        </div>


        <div class="bot-one">
          <img src="~assets/cloud/lb.png" />
          <div class="">2021年度xxxx风云榜</div>
        </div> -->

      </div>

    </div>
    <div class="bot" v-show="is==7">
      <div class="zj" style="margin-bottom: 1.875rem;" v-html="fyb.whcontent">
      </div>
      <div class="bjsp">颁奖视频</div>
      <div class="cen-t">

        <div class="bot-one" v-for="item in fyb.ffybsp1" @click="bfsp(item.curl,item.fimage,item.name)">
          <img :src="'https://ymedicine.jiujingwulian.com/public'+item.fimage" />
          <!-- <video :src="item.curl"  style="object-fit:cover;height: auto;width: 100%;"  :poster="'https://ymedicine.jiujingwulian.com/public'+item.fimage" controls="controls" width="100%" ></video> -->
          <div class="">{{item.name}}</div>
        </div>

        <!-- <div class="bot-one">
          <img src="~assets/cloud/lb.png" />
          <div class="">2021年度xxxx风云榜</div>
        </div>

        <div class="bot-one">
          <img src="~assets/cloud/lb.png" />
          <div class="">2021年度xxxx风云榜</div>
        </div>


        <div class="bot-one">
          <img src="~assets/cloud/lb.png" />
          <div class="">2021年度xxxx风云榜</div>
        </div>


        <div class="bot-one">
          <img src="~assets/cloud/lb.png" />
          <div class="">2021年度xxxx风云榜</div>
        </div>


        <div class="bot-one">
          <img src="~assets/cloud/lb.png" />
          <div class="">2021年度xxxx风云榜</div>
        </div> -->

      </div>

    </div>
    <div class="bot bjs" v-show="is==8">

      <div class="cen-t">
        <div class="bot-one" v-for="item in fyb.wjfyb" @click="qhs(item.id)">
          <img :src="'https://ymedicine.jiujingwulian.com/public'+item.fimage" />
          <div class="">{{item.name}}</div>
        </div>
<!--
        <div class="bot-one">
          <img src="~assets/cloud/lb.png" />
          <div class="">2021年度xxxx风云榜</div>
        </div>


        <div class="bot-one">
          <img src="~assets/cloud/lb.png" />
          <div class="">2021年度xxxx风云榜</div>
        </div>


        <div class="bot-one">
          <img src="~assets/cloud/lb.png" />
          <div class="">2021年度xxxx风云榜</div>
        </div>


        <div class="bot-one">
          <img src="~assets/cloud/lb.png" />
          <div class="">2021年度xxxx风云榜</div>
        </div>
 -->
      </div>
    </div>



<!--    <el-button text @click="centerDialogVisible = true">
      Click to open the Dialog
    </el-button> -->

    <el-dialog v-model="centerDialogVisible" :title="title" width="90%" center>
      <span>
       <video :src="curl"  style="object-fit:cover;height: auto;width: 100%;"  :poster="'https://ymedicine.jiujingwulian.com/public'+fimage" controls="controls" width="100%" ></video>
      </span>
      <template #footer>
        <span class="dialog-footer">
          <!-- <el-button @click="centerDialogVisible = false">Cancel</el-button> -->
          <!-- <el-button type="primary" @click="centerDialogVisible = false">
            关闭
          </el-button> -->
        </span>
      </template>
    </el-dialog>


    <foot></foot>
    <left></left>
  </div>
</template>

<script>
  import {
    ref,
    onMounted
  } from 'vue';
  import {
    getHome,
    getffybnew
  } from 'network/home.js';
  import {
    getffyb
  } from 'network/cloud.js';

  import {
    useRouter
  } from 'vue-router';


  import Head from "components/content/headder/Head.vue";
  import Foot from "components/content/footer/Foot.vue";
  import Left from "components/content/left/Left.vue";
  import Bread from "components/content/breadcrumb/Breadcrumb.vue";
  import Banner from "components/content/banner/Banner.vue";

  import CloudNewIn from "./CloudNewIn.vue";


  export default {
    name: 'New',
    data() {
      return {
        lb:[],
        news:{},
        centerDialogVisible:false,
        curl:'',
        fimage:'',
        title:'',
        ishow:0,

        fyb:{},
        is:5,
        bn: 'https://ymedicine.jiujingwulian.com/public/images/bns.png',
        all: [{
            name: '您当前的位置:首页',
            url: '/'
          },

          {
            name: '资讯',
            url: '/New'
          },
        ]
      }
    },
    setup() {
      const top = 2;
      const router = useRouter();
      const databa = ref([]);
      const ab = 'sb';
      console.log(ab);
      onMounted(() => {

        getHome().then(res => {
          console.log(res.data.data);
          databa.value = res.data;
          console.log(databa);

        }).catch(err => {

        })

      })
      //跳转方法
      const goTo = (path, query) => {
        router.push({
          path: path,
          query: query || {}
        })
      }

      return {
        databa,
        ab,
        goTo,
        top
      }
    },

    mounted() {



      this.getffyb1('');
    },



    methods: {

      bfsp(curl,fimage,title){
        this.centerDialogVisible=true;
        this.curl=curl;
        this.fimage=fimage;
        this.title=title;
      },
      tp(){
        vue.showModal()

      },
      getffyb1(id){
        var that = this;
        var data = {
          id:id,
        };
        getffyb(data).then(res => {

          that.fyb=res.data.data;
          that.bn='https://ymedicine.jiujingwulian.com/public'+res.data.data.bimage;
        }).catch(err => {})
      },
        qh(ii){
          this.is=ii
          this.ishow=0;
        },
        qhs(itemid){
          this.getffyb1(itemid);
          this.is=0;
        },
        pd(num){
          this.ishow=num;

        },
        tzs(ids){

          // console.log(ids);
          // console.log(this.ishow);

          var that = this;
          var data = {
            id:ids,
          };
          getffybnew(data).then(res => {
            that.news = res.data.data.nr;
            that.lb = res.data.data.lb;
             that.ishow=1;
            console.log(that.lb);
            // var all=that.all;
            // all[2]['name']=that.news.name;
            // that.all=all;
            // console.log();
          }).catch(err => {})


        }

    },
    components: {
      Head,
      Foot,
      Left,
      Bread,
      CloudNewIn,
      Banner
    },
  }
</script>
<style lang="scss" scoped="scoped">
  // nth-child

  .all{
    background: #f5f6fa;
  }
  .a-cen {
    width: 100vw;
    background: #fff;
    padding: 1rem 0;

    .center {
      width: var(--allWidth);
      margin: 0 auto;
    }
  }

  .center {
    position: relative;
    top: -4.625rem;

    .cc-top1 {

      background: #FFFFFF;
      display: flex;
      align-items: center;
      padding: 3.25rem 0;
      justify-content: space-evenly;

      .cc-top1-one {
        cursor: pointer;
        padding: 0.625rem;
      }

      .shu {
        width: 0.0625rem;
        height: 3vh;
        background: #C2C2C2;
        margin: 0 0.9375rem;
      }

      .hx {
        color: #2D95D8;
        border-bottom: 0.225rem solid #2D95D8;
      }

    }

    .cc-top {

      padding: 2.25rem;
      // position: relative;
      // top: -4.625rem;
      background: rgb(255, 255, 255);
      box-shadow: 0px 0.25rem 1.875rem rgba(145, 145, 145, 0.25);
      ;
      display: flex;
      justify-content: space-around;

      .cc-top-one {
        text-align: center;
        font-size: 1rem;

        .ttp {
          font-weight: bold;
          font-size: 1.6rem;
          margin-bottom: 1.625rem;
        }
      }

      .shu {
        width: 0.0625rem;
        height: 4rem;
        background: #C2C2C2;
      }

      .bman {
        cursor: pointer;
        margin-top: 0.625rem;
        padding: 1.375rem 4rem;
        background: #2D95D8;
        color: #fff;
        box-shadow: 0rem 0.25rem 1.25rem rgba(145, 145, 145, 0.25);
        ;
      }
    }
  }


  .bot {
    position: relative;
    top: -7.025rem;
    width: 100vw;
    padding: 1rem 0;
    background: #F5F6FA;
    min-height: 30vh;

    .zj {
      width: var(--allWidth);
      margin: 0 auto;
      background: #fff;
      padding: 1.875rem;
      .zj-one{
        padding: 1.875rem 0;
        border-bottom: 0.0625rem solid #E2E2E2;
        cursor: pointer;

        .zj-left{
          width: 20%;
          display: inline-block;
          vertical-align: top;
          img{
            width: 100%;
          }
        }
        .zj-right{
          width: 77%;
          margin-left: 3%;
          display: inline-block;
          vertical-align: top;
          .zj-right-title{
            font-size: 1.2rem;
            font-weight: bold;
            margin-bottom: 0.625rem;
          }
          .zj-right-nr{
            font-size: 0.9rem;
            color: #888888;
            margin-top: 0.925rem;
            margin-bottom: 1.875rem;
          }
          .zj-right-sj{
            font-size: 0.9rem;
            color: #888888;
            margin-top: 1.825rem;
          }
        }

      }

      .zj-one:hover{
        color:#2E9BD6;
      }
    }
  }

  .cen-t{
    width: var(--allWidth);
    margin: 0 auto;
    display: flex;
        flex-wrap: wrap;
    .bot-one{
      width: 23%;
      margin-right: 2.667%;
      margin-bottom: 1.25rem;
      background: #fff;
      text-align: center;
      img{
        width: 100%;
      }
      div{
       padding: 1rem;
      }
      cursor:pointer;
    }
    .bot-one:nth-child(4n){
      margin-right: 0%!important;
    }
    .bot-one:hover{
      color: #2E9BD6;
    }
  }
  .h-top {
    width: 100vw;
    // min-height: 4rem;
    // background: url(assets/home/top.png) no-repeat;
    background-size: 100% 100%;

    .t-top {
      background: rgba(255, 255, 255, 0.4);
      padding: 0.625rem 0.3125rem;

      .t-center {
        width: var(--allWidth);
        margin: 0 auto;
        font-size: 0.875rem;

        .t-span {
          color: rgb(113, 113, 113);
          display: inline-block;
        }

        .right {
          span {
            margin-right: 0.625rem;
          }
        }
      }
    }

    .t-cen {
      width: var(--allWidth);
      margin: 0 auto;
      padding: 1.875rem 0;
      display: flex;
      align-items: center;

      .t-img1 {
        width: 20vw;
        margin-right: 2vw;
      }

      .t-img2 {
        width: 10vw;
      }

      position: relative;

      .sousuo {
        position: absolute;
        top: 50%;
        right: 1.125rem;
        transform: translate(0, -50%);
        width: 15vw;
        display: flex;

        input {
          width: 70%;
          padding: 0.75rem 0.625rem;
          border-radius: 1.875rem;
          border: 0.125rem solid #AFC81D;
          border-right: 0;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          font-size: 0.8125rem;
          outline: 0;
        }

        .sanniu {
          width: 30%;
          background: #AFC81D;
          font-size: 0.8em;
          padding: 0.75rem 0.3125rem;
          border: 0.125rem solid #AFC81D;
          border-top-right-radius: 1.875rem;
          border-bottom-right-radius: 1.875rem;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          text-align: center;

          img {
            margin-right: 0.1875rem;
          }
        }

      }
    }
  }
  .bjsp{
    width: var(--allWidth);
    margin: 0 auto;
    font-size: 1.175rem;
    margin-bottom: 1.25rem;
    padding-left: 0.625rem;
    border-left: 0.2625rem solid #2E9BD6;
    font-weight: bold;
  }
  .zjsj{
    display: flex;
    flex-wrap: wrap;
    background: #f5f6fa!important;
    padding: 0!important;

    .sj{
      width: 49%;
      margin-right: 2%;
      background: #fff!important;
      padding: 0.925rem!important;
      margin-bottom: 0.625rem;
      border: 0!important;
    }
    .sj>.zj-left{
      width: 33%!important;
    }
    .sj>.zj-right{
      width: 64%!important;

    }
    .sj:nth-child(2n){
      margin-right: 0%;
    }
    .sj:hover{
      // color:#2E9BD6;
      .zj-right-nr{
        color:#2E9BD6!important;
      }
    }
  }

  .sb{
    position: relative;
    .one-list{
      margin-top: 4.875rem;
      display: flex;
      flex-wrap: wrap;
      .one{
        width: 49%;
        margin-right: 2%;
        background: #fff;
        margin-bottom: 0.825rem;
        padding: 1.25rem;
        border-radius: 0.3125rem;
        display: flex;
        img{
          width: 35%;
        }
        position: relative;
        .bh{
          position: absolute;
          top: 0;
          left: 0;
          box-shadow: 0px 0.25rem 0.375rem rgba(223, 223, 223, 0.25);;
          border-radius: 0.3125rem 0px 0.3125rem 0px;
          background: #FF773B;
          color: #fff;
          padding: 0.625rem 0.8rem;
          font-size: 0.875rem;
        }
        .llp{
          width: 65%;
          margin-left: 0.625rem;
          .bt{
            font-size: 1.2rem;;
            // font-weight: bold;
          }
          .piao{
            margin-top: 4.875rem;
            display: flex;
            align-items: center;
                justify-content: space-between;
            .piao-title{
              color: #6B6B6B;
              font-size: 0.7rem;
            }
            .piao-anniu{
              color: #fff;
              font-size: 0.8rem;
              background: #B5B5B5;
              padding: 0.65rem 1.5rem;
              cursor: pointer;
              border-radius: 0.3125rem;

            }
            .js{
              background: #78A835;
            }
            .tp{
              background:#2D95D8;
            }

          }
        }
      }
      .one:nth-child(2n){
        margin-right: 0%;
      }
    }
  }
  .sousuo{
    padding: 0;
    margin: 0;
    font-size: 0;
    text-align: right;
    border: 0.1625rem solid #1989FA;
    padding-left: 0.825rem;
    background: #fff;
    display: flex;
    align-items: center;
    border-radius: 0.3125rem;
    position: absolute;
    top: 0;
    right: 0;
    input{
      border: 0;
      outline: 0;
      font-size: 0.8125rem;
      padding: 0.5rem 0;
      width: 10.875rem;
    }
    img{
      width: 0.825rem;
      margin-right: 0.625rem;
    }
    .annniu{
       background: #2D95D8;
       font-size: 0.8125rem;
       color: #FFFFFF;
       padding: 1rem 2rem;
       margin-top: -2px;
       margin-right: -2px;
       margin-left: 1.875rem;
    }

  }

  @media screen and (max-width: 1300px) {
    .h-top{
      .t-center{
        width: 100%!important;
      }

    }
    .cc-top{
      padding:0.8rem 1.625rem 0 1.625rem!important;
      box-shadow: 0px 0px 0px rgb(145 145 145 / 25%)!important;
      flex-wrap: wrap;
      .cc-top-one{
        font-size: 0.75rem!important;;
        .ttp{
              margin-bottom: 0.6rem!important;;
        }
      }
    }
    .cc-top1{
        width: 90%!important;;
        margin: 0 auto!important;;
        margin-bottom: 1rem!important;
        flex-wrap: wrap;
        padding: 0!important;
        padding:0.8rem 0rem!important;
        padding-top: 0.3rem!important;
        .cc-top1-one{
          cursor: pointer;
          padding: 0.825rem 0!important;;
          width: 33%!important;;
          text-align: center;
        }

    }
    .bman{
      margin-top: 1.125rem!important;;
      padding: 1.2rem 4rem!important;;
      font-size: 0.75rem!important;;
      width: 90%;
      text-align: center;

    }
    .shu{
      display: none!important;
    }

    .zj,.cen-t{
      width: 97%!important;
    }
    .zj{
      padding: 0.875rem !important;
      .zj-one{
        padding: 0.475rem!important;
        margin: 0 auto!important;


        .zj-left{
          width: 44%!important;
        }
        .zj-right{
          width: 52%!important;
          margin-top: 0.1875rem!important;

          .zj-right-title{
              font-size: 1rem!important;
              margin-bottom: 0.325rem!important;
          }
          .zj-right-nr{
              margin-top: 0.825rem!important;
              margin-bottom: 0rem!important;
          }
          .zj-right-sj{
              font-size: 0.9rem!important;
              margin-top: 1rem!important;
          }
        }
      }
    }

    .one{
      width: 100%!important;
      img{
        width: 44%!important;
      }
    }
    .zj-one{
       width: 100%!important;
    }
    .bot-one{
      width: 97%!important;
      margin: 0 auto!important;

    }
    .sousuo{
      right: 50%!important;
      transform: translate(50%, 0)!important;
      .annniu{
        text-align: center!important;
        font-size: 0.8125rem!important;

        padding: 0.8rem 0em!important;
        margin-left: 0.5rem!important;
        width: 5.25rem!important;
      }
    }
    .one-list{
      margin-top: 3.8rem!important;
    }
    .bjsp{
      width: 100%!important;
      font-size: 1rem!important;
      margin-bottom: 0.78rem!important;
    }

  }


</style>
