<template>
  <div class="all">
    <Head :top="top" :grxxff="grxxff"></Head>
    <div class="zong">
      <div class="a-top">

        <img src="~assets/download/banner.png" />
      </div>
    </div>
    <div class="zong1">
      <div class="z-top-title">资料下载</div>
      <div class="z-top">
        <div class="xuxian"></div>
        <img src="~assets/download/yuan.png" />
        <div class="xuxian"></div>
      </div>
      <div class="z-all">
        <div class="z-a-one" v-for="item in list">
            <div class="z-a-top">
              <img class="img-one1" src="~assets/download/xz1.png" />
              <img class="img-two1"  src="~assets/download/xz0.png" />
            </div>
            <div class="z-a-title">{{item.name}}</div>
            <div class="xz" v-show="item.z0file" @click="don(item.z0file)"><img class="img-one" src="../../assets/download/xz.png" /><img src="../../assets/download/0xz.png" class="img-two" />资料一下载</div>
            <div class="xz"  v-show="item.z1file" @click="don(item.z1file)"><img class="img-one" src="../../assets/download/xz.png" /><img src="../../assets/download/0xz.png" class="img-two" />资料二下载</div>
        </div>

       <!-- <div class="z-a-one">
            <div class="z-a-top">
              <img class="img-one1" src="~assets/download/xz1.png" />
              <img class="img-two1"  src="~assets/download/xz0.png" />
            </div>
            <div class="z-a-title">xxx会议报名资料</div>
            <div class="xz"><img class="img-one" src="../../assets/download/xz.png" /><img src="../../assets/download/0xz.png" class="img-two" />资料二下载</div>
            <div class="xz"><img class="img-one" src="../../assets/download/xz.png" /><img src="../../assets/download/0xz.png" class="img-two" />资料二下载</div>
        </div>

        <div class="z-a-one">
            <div class="z-a-top">
              <img class="img-one1" src="~assets/download/xz1.png" />
              <img class="img-two1"  src="~assets/download/xz0.png" />
            </div>
            <div class="z-a-title">xxx会议报名资料</div>
            <div class="xz"><img class="img-one" src="../../assets/download/xz.png" /><img src="../../assets/download/0xz.png" class="img-two" />资料二下载</div>
            <div class="xz"><img class="img-one" src="../../assets/download/xz.png" /><img src="../../assets/download/0xz.png" class="img-two" />资料二下载</div>
        </div>

        <div class="z-a-one">
            <div class="z-a-top">
              <img class="img-one1" src="~assets/download/xz1.png" />
              <img class="img-two1"  src="~assets/download/xz0.png" />
            </div>
            <div class="z-a-title">xxx会议报名资料</div>
            <div class="xz"><img class="img-one" src="../../assets/download/xz.png" /><img src="../../assets/download/0xz.png" class="img-two" />资料二下载</div>
            <div class="xz"><img class="img-one" src="../../assets/download/xz.png" /><img src="../../assets/download/0xz.png" class="img-two" />资料二下载</div>
        </div>

        <div class="z-a-one">
            <div class="z-a-top">
              <img class="img-one1" src="~assets/download/xz1.png" />
              <img class="img-two1"  src="~assets/download/xz0.png" />
            </div>
            <div class="z-a-title">xxx会议报名资料</div>
            <div class="xz"><img class="img-one" src="../../assets/download/xz.png" /><img src="../../assets/download/0xz.png" class="img-two" />资料二下载</div>
            <div class="xz"><img class="img-one" src="../../assets/download/xz.png" /><img src="../../assets/download/0xz.png" class="img-two" />资料二下载</div>
        </div>

        <div class="z-a-one">
            <div class="z-a-top">
              <img class="img-one1" src="~assets/download/xz1.png" />
              <img class="img-two1"  src="~assets/download/xz0.png" />
            </div>
            <div class="z-a-title">xxx会议报名资料</div>
            <div class="xz"><img class="img-one" src="../../assets/download/xz.png" /><img src="../../assets/download/0xz.png" class="img-two" />资料二下载</div>
            <div class="xz"><img class="img-one" src="../../assets/download/xz.png" /><img src="../../assets/download/0xz.png" class="img-two" />资料二下载</div>
        </div> -->


      </div>
    </div>
    <foot></foot>
    <left></left>
  </div>
</template>

<script>

import {ref,onMounted} from 'vue';
import {getHome} from 'network/home.js';
import {getdownload} from 'network/download.js';

import {useRouter} from 'vue-router';


import Head from "components/content/headder/Head.vue";
import Foot from "components/content/footer/Foot.vue";
import Left from "components/content/left/Left.vue";

export default {
  name: 'Download',
 data() {
      return {
        list:[]
      }
  },
  setup(){
    //第几个页面
    const top=1;
    const router=useRouter();
    const databa=ref([]);
    const ab='sb';
    console.log(ab);
    onMounted(()=>{

      getHome().then(res=>{
        console.log(res.data.data);
        databa.value=res.data;
        console.log(databa);

      }).catch(err=>{

      })

    })
    //跳转方法
    const goTo=(path,query)=>{
      router.push({
        path:path,
        query:query || {}
      })
    }

    return { databa,ab,goTo,top }
  },
  mounted() {
    this.getdownload1()
  },
  methods: {
    grxxff(grxxs){
      this.grxx=grxxs;
      console.log(grxxs);
      console.log('zhende');
    },
    don(donw){
      window.location.href='https://ymedicine.jiujingwulian.com/public'+donw;
    },
    getdownload1(){

      var that = this;
      var data = {

      };
      getdownload(data).then(res => {
        that.list = res.data.data;

      }).catch(err => {})
    }

  },
  components:{
    Head,
    Foot,
    Left
  },
}
</script>
<style lang="scss" scoped="scoped">
  .zong{
    width: 100vw;
    background: #453dcc;
   .a-top{
     width: var(--allWidth);
     margin: 0 auto;
     img{
       width: 100%;
     }
   }
  }
  .zong1{
    width: 100vw;

    .z-top-title{
      margin-top: 2.75rem;
      font-size: 1.4rem;
      font-weight: bold;
      text-align: center;
      margin-bottom: 1.875rem;
    }
    .z-top{
      display: flex;
      align-items: center;
      img{
        width: 1.125rem;
      }
      .xuxian{
          border-top: 0.125rem solid #E0E0E0;
          width: 60%;
      }
      margin-bottom: 1.875rem;
    }
    .z-all{
      width: var(--allWidth);
      margin: 0 auto;
      margin-bottom: 1.875rem;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      .z-a-one:hover{
         .z-a-top{
           background: #fff;
         }
        .img-two1{

          display: block;
        }
        .img-one1{
          display: none;
        }

        background: #2E9BD6;

        .z-a-title{
          color: #fff!important;
        }

        .xz{
          background: #2E9BD6;
          color: #fff;
          .img-one{
            display: none;
          }
          .img-two{
            display: block;
          }
           border: 0.0625rem solid #fff;

        }

      .xz:hover{
          background: #fff;
          color: #2E9BD6;

          .img-one{
            display: block;
          }
          .img-two{
            display: none;
          }


        }


      }
      .z-a-one{
        .img-two1{
          display: none;
        }
        width: 22%;
        min-height: 3.875rem;
        margin-right: 4%;
        background: 0;
        box-shadow: 0.125rem 0.125rem 0.8125rem rgb(0 0 0 / 30%);
        border-radius: 0.3125rem;
        margin-bottom: 1.25rem;
        text-align: center;
        padding-bottom: 1rem;
        .z-a-top{
          margin-top: 1.375rem;
          margin-bottom: 30px;
          padding: 0.625rem;
          border: 1px solid #A1A1A1;
          border-radius: 50%;
          display: inline-block;
          img{
            width: 2.75rem;

          }
        }
        .z-a-title{
          font-size: 1.1rem;
          font-weight: bold;
          color: #191919;
          margin-bottom: 1.875rem;
        }
        .xz{
          width: 70%;
          margin: 0 auto;
          background: #fff;
          border: 0.0625rem solid #2E98D6;
          padding: 0.875rem;
          margin-bottom: 0.675rem;
          font-size: 0.8125rem;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #2E9BD6;
          cursor: pointer;
          img{
            width: 1rem;
            margin-right: 0.5rem;
          }
          .img-two{
            display: none;
          }
        }


      }
      .z-a-one:nth-child(4n){
        margin-right: 0%;
      }

    }

  }
  @media screen and (max-width: 1300px) {
    .a-top{
      width: 100%!important;
    }
    .z-top-title{
      margin-top: 1rem!important;
      font-size: 1.2rem!important;
      margin-bottom: 1rem!important;
    }
    .z-all{
      width: 100%!important;
      .z-a-one{
        width: 90%!important;
        margin: 0px auto!important;
      }
    }
  }


</style>
