import { request } from "./request.js";

export function getfyqlj(params){
    return request(
      {
          url:'/public/index/index/fyqlj',
          method:'POST',
          params:params
      }
    )
}

