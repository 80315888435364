<template>
  <div class="all" v-show="is==1">
    <div class="a-cen">

      <div class="center">

        <div class="c-left">
            <div class="z-bt" @click="pdsqh()"><img src="~assets/about/fh.png" />{{newall.name}}</div>
            <div v-html="newall.content" ></div>

            <!--
             -->
        </div>
        <div class="c-right">
          <Notice :li="list" :pd="pd" :getChildInfo="getChildInfo" ></Notice>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import {
    ref,
    onMounted
  } from 'vue';
  import {
    getHome,
    getonenew,
    getffybnew
  } from 'network/home.js';
  import {
    useRouter
  } from 'vue-router';


  import Head from "components/content/headder/Head.vue";
  import Foot from "components/content/footer/Foot.vue";
  import Left from "components/content/left/Left.vue";
  import Bread from "components/content/breadcrumb/Breadcrumb.vue";
  import Notice from "components/content/notice/Notice.vue";


  export default {
    name: 'CloudNewIn',
    props:['is','pds','ids','newall','list'],
    data() {
      return {
        pd:3,
        news:{},
        bn: '~assets/new/banner.png',
        all: [{
            name: '您当前的位置:首页',
            url: '/'
          },
          {
            name: '资讯',
            url: '/New'
          },
          {
            name: '新闻详情名称',

          },
        ]
      }
    },
    setup() {

      const route = useRouter()
      console.log(route.query);

      const top = 3;
      const router = useRouter();
      const databa = ref([]);
      const ab = 'sb';
      console.log(ab);


      onMounted(() => {

        getHome().then(res => {
          console.log(res.data.data);
          databa.value = res.data;
          console.log(databa);

        }).catch(err => {

        })

      })
      //跳转方法
      const goTo = (path, query) => {
        router.push({
          path: path,
          query: query || {}
        })
      }

      return {
        databa,
        ab,
        goTo,
        top
      }
    },
    mounted() {
      console.log('ab');

      this.qh(this.ids);

    },
    methods: {
      pdsqh(){
        this.pds(0);
      },
      getChildInfo(ids){
        console.log('b');
        this.qh(ids);
      },
      qh(ids){
        var that = this;
        var data = {
          id:ids,
        };
        getffybnew(data).then(res => {
          that.news = res.data.data;
          console.log('zx');
          var all=that.all;
          all[2]['name']=that.news.name;
          that.all=all;
          // console.log();
        }).catch(err => {})
      }
    },
    components: {
      Head,
      Foot,
      Left,
      Bread,
      Notice
    },
  }
</script>
<style lang="scss" scoped="scoped">

  //列表
  .z-bt {
    cursor: pointer;
    border-bottom: 0.0625rem solid #DBDBDB;
    padding-bottom: 1.875rem;
    font-weight: bold;
    font-size: 1.2rem;
    img{
      width: 1.075rem;
      margin-right: 0.625rem;
    }
  }
  .r-bot{
    margin-top: 0!important;
  }
  .a-cen {
    width: 100vw;
    background: #F5F6FA;

    .center {
      width: var(--allWidth);
      margin: 0 auto;
    }
  }

  .bn {
    width: 100%;

    img {
      width: 100%;
    }
  }

  .center {
    padding: 1.25rem 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .c-left {
      width: 70%;
      background: #fff;
      padding: 1.25rem;
      min-height: 50vh;


    }

    .c-right {
      width: 28%;

      .r-ban {
        width: 100%;

        img {
          width: 100%;
        }
      }

      .r-bot {
        background: #fff;
        min-height: 6.25rem;
        margin-top: 0.625rem;
        padding: 1.225rem;

        .r-top-title {
          font-size: 1.175rem;
          font-weight: bold;
        }
      }

      .r-top {
        background: #fff;
        min-height: 6.25rem;
        margin-top: 0.625rem;
        padding: 1.225rem;

        .r-top-title {
          font-size: 1.175rem;
          font-weight: bold;
        }

        .r-top-list {
          margin-top: 1.225rem;

          .r-top-time {
            font-size: 0.875rem;
            color: #A7A7A7;
            display: flex;
            align-items: center;

            img {
              width: 0.8075rem;
              margin-right: 0.625rem;
            }
          }

          .r-top-cot {
            margin-left: 1.625rem;
            margin-top: 0.325rem;
            font-size: 0.875rem;
          }

        }

        .r-bot {}
      }
    }


  }

  .l-bot {
    margin-top: 1.875rem;

    .z-n-one:hover {
      background: linear-gradient(rgba(98, 186, 238, 0.1568627450980392) 0%, rgba(196, 196, 196, 0) 100%);

      .znor-title {
        color: #2E9BD6;
      }
    }

    .z-n-one {
      padding: 0.825rem;
      padding-bottom: 1rem;
      border-bottom: 0.0625rem solid #DBDBDB;
      display: flex;
      align-items: center;
      // margin-bottom: 1rem;
      background: #fff;
      cursor: pointer;

      .zno-left {
        margin-right: 1.875rem;

        img {
          width: 9.875rem;
        }
      }

      .zno-right {
        .znor-title {
          font-size: 1.075rem;
          font-weight: bold;
          margin-bottom: 0.675rem;
        }

        .znor-content {
          font-size: 0.7rem;
          color: #888888;
          line-height: 1rem;
        }

        .rq {
          font-size: 0.7rem;
          color: #888888;
          margin-top: 0.625rem;
        }
      }
    }



    .z-n-one1 {
      padding: 0;
      padding-bottom: 0.8rem;
      align-items: normal;

      .zno-left {
        margin-right: 0.675rem;

        img {
          width: 7rem;
        }
      }

      // .zon-left{
      //   img{
      //     width: 10%!important;
      //   }
      // }
    }



  }
  
  @media screen and (max-width: 1300px) {
    .zno-right {
      padding-top: 0.25rem;
    }
    .c-left{
      width: 97%!important;
    }
    .c-right{
      display: none!important;
    }
    .center{
      padding: 0!important;
    }
    .bot{
      padding: 0.6rem 0 !important;
    }
  }
</style>
