<template>
  <div class="all">
    <Head :top="top" :grxxff="grxxff"></Head>
    <div class="center">
      <div class="c-all">
        <MyLeft :topleft="topleft"></MyLeft>

        <div class="c-right">
          <div class="c-top" @click="fanhui()"><img src="~assets/about/fh.png" /><span>会议报名记录</span></div>


          <div class="center">
            <div class="c-bt">
              <div class="c-lt" @click="qh(0)" :class="zt==0?'zs':''">未开始</div>
              <div class="c-lt" @click="qh(1)" :class="zt==1?'zs':''">进行中</div>
              <div class="c-lt" @click="qh(2)" :class="zt==2?'zs':''">已完成</div>
            </div>
            <div class="dibu">

              <div class="one" v-for="item in fmeetingbm">
                <div class="o-title">记录编号：{{item.ddh}}</div>
                <div class="o-nr">
                  <img class="tbimg" :src="'https://ymedicine.jiujingwulian.com/public'+item.fimage" />
                  <div class="o-le">
                    <div class="o-l-title onehang">{{item.name}}</div>
                    <div class="o-l-bt">
                      <div class="ones">
                        <img src="../../assets/home/dws.png" />
                        <div class="ones-one">展出城市:{{item.cityname}}</div>
                      </div>
                      <div class="shu">|</div>
                      <div class="ones">
                        <div class="ones-fy">报名费用：￥{{item.price}}</div>
                      </div>
                      <div class="shu">|</div>
                      <div class="ones">
                        <img src="../../assets/my/sjs.png" />
                        <div class="ones-nrs">展出时间：{{item.ktime}}~{{item.stime}}</div>
                      </div>

                    </div>
                  </div>
                </div>
                <div class="zt">{{item.zt}}</div>
                <div class="dws">
                  <div class="down">
                  </div>
                  <el-button v-show="status!=2" class="bj2" @click="qiandao(item)">未签到<img
                      src="../../assets/my/qb.png" /></el-button>
                  <el-button type="primary" @click="jkzl(item)" class="bj1">+上传健康信息</el-button>
                  <el-button class="bj2" @click="xiangqing(item)">查看详情 >></el-button>
                </div>




              </div>


            <null :list='fmeetingbm' ></null>
            <page :items='items'
            :litems='litems'
            :total="total"
            :per_page="per_page"
            :current_page="current_page"
            :last_page="last_page"
            :listcount='listcount'></page>

              <!--

              <div class="one">
                <div class="o-title">记录编号：283273782838</div>
                <div class="o-nr">
                  <img src="../../assets/my/fk.png" />
                  <div class="o-le">
                    <div class="o-l-title onehang">会议标题会议标题会议标题会议标题会议标题会议标题</div>
                    <div class="o-l-bt">
                      <div class="ones">
                        <img src="../../assets/home/dws.png" />
                        <div class="ones-one">展出城市:苏州市</div>
                      </div>
                      <div class="shu">|</div>
                      <div class="ones">
                        <div class="ones-fy">报名费用：￥ 129.00</div>
                      </div>
                      <div class="shu">|</div>
                      <div class="ones">
                        <img src="../../assets/my/sjs.png" />
                        <div class="ones-nrs">展出时间：2022.09.11~2022.12.23</div>
                      </div>

                    </div>
                  </div>
                </div>
                <div class="zt">未开始</div>
                <div class="dws">
                  <div class="down">
                  </div>
                  <el-button class="bj2 yqd"><img src="../../assets/my/qd.png" />已签到</el-button>
                  <el-button type="primary" class="bj1">+上传健康信息</el-button>
                  <el-button class="bj2">查看详情 >></el-button>
                </div>
              </div>


              <div class="one">
                <div class="o-title">记录编号：283273782838</div>
                <div class="o-nr">
                  <img src="../../assets/my/fk.png" />
                  <div class="o-le">
                    <div class="o-l-title onehang">会议标题会议标题会议标题会议标题会议标题会议标题</div>
                    <div class="o-l-bt">
                      <div class="ones">
                        <img src="../../assets/home/dws.png" />
                        <div class="ones-one">展出城市:苏州市</div>
                      </div>
                      <div class="shu">|</div>
                      <div class="ones">
                        <div class="ones-fy">报名费用：￥ 129.00</div>
                      </div>
                      <div class="shu">|</div>
                      <div class="ones">
                        <img src="../../assets/my/sjs.png" />
                        <div class="ones-nrs">展出时间：2022.09.11~2022.12.23</div>
                      </div>

                    </div>
                  </div>
                </div>
                <div class="zt">未开始</div>
                <div class="dws">
                  <div class="down">
                  </div>
                  <el-button class="bj2">未签到></el-button>
                  <el-button type="primary" class="bj1">+上传健康信息</el-button>
                  <el-button class="bj2">查看详情 >></el-button>
                </div>
              </div>


              <div class="one">
                <div class="o-title">记录编号：283273782838</div>
                <div class="o-nr">
                  <img src="../../assets/my/fk.png" />
                  <div class="o-le">
                    <div class="o-l-title onehang">会议标题会议标题会议标题会议标题会议标题会议标题</div>
                    <div class="o-l-bt">
                      <div class="ones">
                        <img src="../../assets/home/dws.png" />
                        <div class="ones-one">展出城市:苏州市</div>
                      </div>
                      <div class="shu">|</div>
                      <div class="ones">
                        <div class="ones-fy">报名费用：￥ 129.00</div>
                      </div>
                      <div class="shu">|</div>
                      <div class="ones">
                        <img src="../../assets/my/sjs.png" />
                        <div class="ones-nrs">展出时间：2022.09.11~2022.12.23</div>
                      </div>

                    </div>
                  </div>
                </div>
                <div class="zt">未开始</div>
                <div class="dws">
                  <div class="down">
                  </div>
                  <el-button class="bj2">未签到></el-button>
                  <el-button type="primary" class="bj1">+上传健康信息</el-button>
                  <el-button class="bj2">查看详情 >></el-button>
                </div>
              </div>

 -->
            </div>

<!-- width="80%" -->
            <el-dialog class="zd"  :width="w50"  :show-close="false" align-center v-model="dialogTableVisible" title="">
              <template #header="{ close, titleId, titleClass }">
                <div class="qd-top">
                  <div class="xian"></div>
                  <div class="qd-bt">入场签到</div>
                  <div class="xian"></div>
                </div>
              </template>
              <div class="ewm1">
                <img src="../../assets/my/ewm.png" />
                <div class="e-bt">请微信扫码进入移动端完成签到操作</div>
              </div>
            </el-dialog>

<!-- width="80%" -->
            <el-dialog class="zd" :width="w50"   :show-close="false" align-center v-model="dialogTableVisible1" title="">
              <template #header="{ close, titleId, titleClass }">
                <div class="qd-top">
                  <div class="xian"></div>
                  <div class="qd-bt">上传健康信息</div>
                  <div class="xian"></div>
                </div>
              </template>
              <div class="ewm">
                <div class="lefts">

<!--
                  <el-upload
                        ref="uploadFile"
                        action='#'
                        :http-request="uploadAction"
                        :on-exceed="handleExceed"
                        :limit="1"
                        :show-file-list="false"
                        :auto-upload="true"
                    >
 -->
                    <!-- id="file-input"
                    name="image"
                     action="https://ymedicine.jiujingwulian.com/public/index/index/upimg"
                      accept=".jpg,.jpeg,.png,.gif,.JPG,.JPEG"
                      list-type="picture-card"
                      :limit="2"
                      :file-list="fileList"

                        :http-request="changeFile"
                        :show-file-list="true"

                        :auto-upload="false" -->

                    <!--
                     -->
                   <!-- <el-icon>
                      <Plus />
                    </el-icon>
                    <template #file="{ file }">
                      <div>
                        <img class="el-upload-list__item-thumbnail" :src="file.url" alt />
                        <span class="el-upload-list__item-actions">
                          <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                            <el-icon>
                              <zoom-in />
                            </el-icon>
                          </span>
                          <span class="el-upload-list__item-delete" @click="handleRemove(file)">
                            <el-icon>
                              <Delete />
                            </el-icon>
                          </span>
                        </span>
                      </div>
                    </template>

                  </el-upload>

                  <el-dialog v-model="dialogVisible">
                    <img w-full style="width:100%" :src="dialogImageUrl" alt="Preview Image" />
                  </el-dialog> -->
                  <input type="file" id="back" ref="backfile" @change="handleFile" />
                  <img :src="xcm" />
                  <div class="e-bt">健康码</div>
                </div>

                <div class="lefts">
                 <!-- <el-upload name="image" action="https://ymedicine.jiujingwulian.com/public/index/index/upimg" accept=".jpg,.jpeg,.png,.gif,.JPG,.JPEG"  :class="'upload_wrapper ' + (hideUpload ? 'hide' : '')" list-type="picture-card" :limit="2"
                    :file-list="fileList1" :show-file-list="true" :on-change="fileChange1" :auto-upload="false"
                    :before-upload="changeFile1s"
                    >
                    <el-icon>
                      <Plus />
                    </el-icon>
                    <template #file="{ file }">
                      <div>
                        <img class="el-upload-list__item-thumbnail" :src="file.url" alt />
                        <span class="el-upload-list__item-actions">
                          <span class="el-upload-list__item-preview" @click="handlePictureCardPreview1(file)">
                            <el-icon>
                              <zoom-in />
                            </el-icon>
                          </span>
                          <span class="el-upload-list__item-delete" @click="handleRemove1(file)">
                            <el-icon>
                              <Delete />
                            </el-icon>
                          </span>
                        </span>
                      </div>
                    </template>

                  </el-upload>

                  <el-dialog v-model="dialogVisible1">
                    <img w-full style="width:100%" :src="dialogImageUrl1" alt="Preview Image" />
                  </el-dialog> -->
                  <input type="file" id="back" ref="backfile" @change="handleFile1" />
                  <img :src="xcm1"  />
                  <div class="e-bt">行程码</div>
                </div>

              </div>
              <div class="db">
                <el-button @click="baocunxc()" type="primary" class="baocun">保存</el-button>
              </div>
            </el-dialog>

            <el-dialog class="ddb zd" :width="w50"  v-model="dialogTableVisible2" :show-close="false">
              <div class="top">
                <div class="">报名详情<div class="gb right"><img @click="dialogTableVisible2=false"
                      src="../../assets/my/guanbi.png" /></div>
                </div>
              </div>
              <div class="ddgy dbx">
                <div class="d-bt">订单概要</div>
                <div class="ddgy-one">
                  <div class="d-o-bt">订单编号：{{list.name}}</div>
                  <div class="d-o-bt">订单类型：{{list.lx}}</div>
                </div>
                <div class="ddgy-one">
                  <div class="d-o-bt">创建时间：{{list.time}}</div>
                  <div class="d-o-bt">支付时间：{{list.ztime}}</div>
                </div>
                <div class="ddgy-one">
                  <div class="d-o-bt">支付状态：{{list.status}}</div>
                  <div class="d-o-bt">支付方式：微信</div>
                </div>

              </div>

              <div class="ddgy">
                <div class="d-bt">订单概要</div>
                <div class="table">
                  <div class="tr">
                    <div class="td">会议名称</div>
                    <div class="td">起止时间</div>
                    <div class="td">所在城市</div>
                    <div class="td">当前状态</div>
                  </div>

                  <div class="tr1">
                    <div class="td">{{list.name}}</div>
                    <div class="td">{{list.ktime}}~{{list.stime}}</div>
                    <div class="td">{{list.cityname}}</div>
                    <div class="td">{{list.zt}}</div>
                  </div>
                </div>
              </div>

              <div class="ddgy">
                <div class="d-bt">报名信息</div>
                <div class="ddgy-one">
                  <div class="d-o-bt">姓名：{{list.uname}}</div>
                  <div class="d-o-bt">公司/单位名称：{{list.gsname}}</div>
                </div>
                <div class="ddgy-one">
                  <div class="d-o-bt">手机号：{{list.phone}}</div>
                  <div class="d-o-bt">邮箱：{{list.email}}</div>
                </div>
                <div class="ddgy-one">
                  <div class="d-o-bt">其他说明：{{list.qt}}</div>
                  <div class="d-o-bt">是否开票：{{list.kp}}</div>
                </div>
                <div class="ddgy-one" v-show="list.kp='是'">
                  <div class="d-o-bt">发票抬头：{{list.taitou}}</div>
                  <div class="d-o-bt">税号: {{list.sh}}</div>
                </div>
              </div>

              <div class="dban">
                <el-button class="anniu bs" type="primary" @click="qiandao(list)"><img src="../../assets/my/bis.png" />立即签到
                </el-button>
                <el-button class="anniu bs1" type="primary" @click="jkzl(list)">+上传健康信息</el-button>
              </div>


            </el-dialog>




          </div>





        </div>

      </div>
    </div>


    <foot></foot>
    <left></left>
  </div>
</template>

<script>
  import {
    ref,
    onMounted
  } from 'vue';
  import {
    getHome
  } from 'network/home.js';
  import {
    useRouter
  } from 'vue-router';

  import {
    getgz,
    upimg,
    getscjkm
  } from 'network/my.js';

  import {
    ElMessage,
    ElMessageBox
  } from 'element-plus';
  import {
    Action
  } from 'element-plus';

  import {
    getfmeetingbmMy,
  } from 'network/my.js';
    import axios from "axios";


  import Head from "components/content/headder/Head.vue";
  import Foot from "components/content/footer/Foot.vue";
  import Left from "components/content/left/Left.vue";
  import Page from "components/content/page/Page.vue";
  import Null from "components/content/errornull/null.vue";

  import MyLeft from "views/my/ChildComps/MyLeft.vue";

  import {
    Delete,
    Download,
    Plus,
    // ZoomIn,
    // zoomin
  } from '@element-plus/icons-vue'


  export default {
    name: 'My',
    data() {
      return {

        items:[
        ],
        litems:[
        ],
        page:0,
        total:'',
        per_page:'',
        current_page:'',
        last_page:'',
        w50:'50%',


        xcm:'https://ymedicine.jiujingwulian.com/public/img/jia.png',
        bcxcm:'',
        xcm1:'https://ymedicine.jiujingwulian.com/public/img/jia.png',
        bcxcm1:'',

        list:{},

        dt:'https://ymedicine.jiujingwulian.com/public/index/index/upimg',
        dialogImageUrl: '',
        dialogVisible: false,
        disabled: true,
        hideUpload :false,
        zid:0,

        dialogImageUrl1: '',
        dialogVisible1: false,
        disabled1: true,
        hideUpload1:false,

        jkmimage: '',
        xcmimage: '',
        fileList: [

        ],
        fileList1: [

        ],
        dialogTableVisible: false,
        dialogTableVisible1: false,
        dialogTableVisible2: false,
        fmeetingbm: [],
        zt: 0,
        shzt: 1, //shzt 0= 待审核,1=已驳回
        topleft: 1, //左边
        radio1: 1,
        textarea2: '',
        options: [{
          value: '城市',
          lable: '0'
        }, ],
        // file:{},
        name: '',
        grxx: [],
      }
    },
    setup() {
      const top = 0;
      const router = useRouter();
      const databa = ref([]);
      const ab = 'sb';
      console.log(ab);
      onMounted(() => {

        getHome().then(res => {
          console.log(res.data.data);
          databa.value = res.data;
          console.log(databa);

        }).catch(err => {

        })

      })
      //跳转方法
      const goTo = (path, query) => {
        router.push({
          path: path,
          query: query || {}
        })
      }

      return {
        databa,
        ab,
        goTo,
        top
      }
    },
    mounted() {
      this.getdata(this.zt);
    },
    methods: {
      updateUploadShown(){
         if (this.fileList.length == 2) {
            this.hideUpload = true;
          } else {
            this.hideUpload = false;
          }
      },

      fileChange1(file,fileList){

        //  let fileInput = document.getElementById("file-input");
        //  if (fileInput.files.length == 0) {
        //     return;
        // }

        // context.emit("aboutToUpload");
        // let file = fileInput.files[0];
        // setImageUrl(URL.createObjectURL(file));
        // console.log(file)
        // return false;

        console.log('file', file, fileList);
        console.log('2s');
        console.log(file);
        let data = new FormData();
         data.append('file', file);
         data.append("userName","laoliu");
        // var data = {
        //   data
        // };
         // axios({
         //    method:"post",
         //    url: 'https://ymedicine.jiujingwulian.com/public/index/index/upload',
         //    data:data,
         //    headers:{'Content-Type': 'multipart/form-data'},
         //  }).then((res) => {
         //        //console.log(res.data);
         //        // if (res.data.code == 0) {
         //        //   let data = res.data.data;
         //        //   console.log(data);
         //        //   let userName = data.userName;
         //        //   let imageUrl = data.imageurl;
         //        //   alert("success:提交的用户名:"+userName+";image:"+imageUrl);
         //        // } else {
         //        //   alert("error:"+res.data.msg);
         //        // }
         //      }
         //  ).catch(err=>{
         //    console.log(err);
         //    alert('网络错误:'+err.message);
         //  });

        var that = this;
        upimg(data).then(res => {
          console.log('zx');
          // that.fmeetingbm = res.data.fmeetingbm;
          console.log(res);


        }).catch(err => {})


      },
      handleFile(){
        let filePaths = window.event.target.files;
        console.log(filePaths);
        //清空原有缩略图
        if (filePaths.length === 0) {
           //未选择，则返回
           return
        } else {
           //清空数组中原有图片
           this.ewm = '';
        }
        let file = filePaths[0];
        var data = new FormData();
        data.append("file",file);
        data.append("userName","laoliu");
        let url = 'https://ymedicine.jiujingwulian.com/public/index/index/upload';
        var that=this;
        upimg(data).then(res => {
          console.log('zx');
          // that.fmeetingbm = res.data.fmeetingbm;
          console.log(res);
          that.xcm='https://ymedicine.jiujingwulian.com/public'+res.data;
          that.bcxcm=res.data;
          // that
        }).catch(err => {})
        this.updateUploadShown();
      },
      handleFile1(){
        let filePaths = window.event.target.files;
        console.log(filePaths);
        //清空原有缩略图
        if (filePaths.length === 0) {
           //未选择，则返回
           return
        } else {
           //清空数组中原有图片
           this.ewm = '';
        }
        let file = filePaths[0];
        var data = new FormData();
        data.append("file",file);
        data.append("userName","laoliu");
        let url = 'https://ymedicine.jiujingwulian.com/public/index/index/upload';
        var that=this;
        upimg(data).then(res => {
          console.log('zx');
          // that.fmeetingbm = res.data.fmeetingbm;
          console.log(res);
          that.xcm1='https://ymedicine.jiujingwulian.com/public'+res.data;
          that.bcxcm1=res.data;
          // that
        }).catch(err => {})
        this.updateUploadShown();
      },
      baocunxc(){
        if(!this.bcxcm){
          ElMessage({
            type: 'info',
            message: '请上传健康码',
          })
          return false;
        }
        if(!this.bcxcm1){
          ElMessage({
            type: 'info',
            message: '请上传行程码',
          })
          return false;
        }

        var that = this;
        var data = {
          jkmimage:this.bcxcm,
          xcmimage:this.bcxcm1,
          type:0,
          id: this.zid
        };
        getscjkm(data).then(res => {
          console.log('zx');
           ElMessage({
             type: 'success',
             message: '上传成功',
           })
          this.dialogTableVisible1 = false;
        }).catch(err => {})





      },

      // 文件改变
      fileChange(file,resfileList){
          // console.log(resfileList);
          // console.log(resfileList[0]);
          // console.log(resfileList.length);
          if(resfileList.length==1){
            this.fileList = [resfileList[0]];
          }else{
            this.fileList = [resfileList[1]];
          }
          console.log('1s');
          console.log(file);

          //直接上传




          var that = this;

          let param = new FormData();
          param.append('image',file);
          console.log(param);


          // var data = {
          //   image:file
          // };
          upimg(param).then(res => {
            console.log('zx');
            // that.fmeetingbm = res.data.fmeetingbm;
            console.log(res);


          }).catch(err => {})

          this.updateUploadShown();
      },

      // 移除图片
      handleRemove(file){
        const list = this.fileList;
        for (const i in list) {
          if (list[i].uid === file.uid) {
            list.splice(i, 1);
          }
        }
        this.fileList = list;
        updateUploadShown();
      },

      // 预览图片
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },



      updateUploadShown1(){
         if (this.fileList1.length == 2) {
            this.hideUpload1 = true;
          } else {
            this.hideUpload1 = false;
          }
      },
      // 文件改变
      fileChange1a(file,resfileList){
          console.log(resfileList);
          console.log(resfileList[0]);
          console.log(resfileList.length);
          if(resfileList.length==1){
            this.fileList1 = [resfileList[0]];
          }else{
            this.fileList1 = [resfileList[1]];
          }

          // this.updateUploadShown();
      },

      // 移除图片
      handleRemove1(file){
        const list = this.fileList1;
        for (const i in list) {
          if (list[i].uid === file.uid) {
            list.splice(i, 1);
          }
        }
        this.fileList1 = list;
        updateUploadShown();
      },
      // 预览图片
      handlePictureCardPreview1(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },


      // handleRemove(file) {
      //   console.log(file)
      // },
      // handlePictureCardPreview(uploadFile) {
      //   console.log(uploadFile);
      //   this.dialogImageUrl = uploadFile.url;
      //   this.dialogVisible = true;
      // },
      // handleDownload(uploadFile) {
      //   window.location.href = uploadFile.url;
      // },
      listcount(page){
        this.page=page;
        this.getdata(this.zt);
      },

      getdata(ids) {

        console.log(this.grxx);
        console.log('jll');

        var that = this;
        var data = {
          status: ids,
          uid: this.grxx.id,
          page:this.page,
          sl:5
        };
        getfmeetingbmMy(data).then(res => {
          console.log('zx');
          // that.fmeetingbm = res.data.fmeetingbm;


          that.fmeetingbm = res.data.fmeetingbm.table.data;
          that.total=res.data.fmeetingbm.table.total;
          that.per_page=res.data.fmeetingbm.table.per_page;
          that.current_page=res.data.fmeetingbm.table.current_page;
          that.last_page=res.data.fmeetingbm.table.last_page;
          that.items=res.data.fmeetingbm.items;
          that.litems=res.data.fmeetingbm.litems;




          console.log(that.fmeetingbm);


        }).catch(err => {})
      },
      grxxff(grxxs) {
        this.grxx = grxxs;
        console.log(grxxs);
        console.log('zhende');
        this.getdata(this.zt);
      },
      qh(zts) {
        this.zt = zts;
        this.page=0;
        this.getdata(this.zt);
      },
      fanhui() {
        window.history.go(-1);
      },
      isMobile() {
       　　let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
       　　return flag;
       },
      qiandao() {

        if (this.isMobile()) {
            // alert("移动端");
            //移动端跳转路由
            this.w50='90%';
          } else {
            // alert("pc端");
      　　　　//Pc端跳转路由
            this.w50='50%';
          }

        this.dialogTableVisible = true;
      },
      jkzl(item) {
        if (this.isMobile()) {
              // alert("移动端");
              //移动端跳转路由
              this.w50='90%';
            } else {
              // alert("pc端");
        　　　　//Pc端跳转路由
              this.w50='50%';
            }


        console.log(item);
        if(item.jkmimage){
          this.xcm='https://ymedicine.jiujingwulian.com/public'+item.jkmimage;
          this.bcxcm=item.jkmimage;
        }
        if(item.xcmimage){
          this.xcm1='https://ymedicine.jiujingwulian.com/public'+item.xcmimage;
          this.bcxcm1=item.xcmimage;
        }


        // this.jkmimage = item.jkmimage;
        // this.xcmimage = item.xcmimage;
        this.zid=item.id;
        this.dialogTableVisible1 = true;
      },
      xiangqing(item) {
        if (this.isMobile()) {
          // alert("移动端");
          //移动端跳转路由
          this.w50='90%';
        } else {
          // alert("pc端");
    　　　　//Pc端跳转路由
          this.w50='50%';
        }
        this.list=item;
        this.dialogTableVisible2 = true;
      }

    },
    components: {
      Delete,
      Download,
      Plus,
      // ZoomIn,
      // zoomin,
      Head,
      Foot,
      Left,
       Page,
       Null,
      MyLeft
    },
  }
</script>
<style lang="scss" scoped="scoped">
  .all {
    background: #f5f6fa;
  }

  .center {
    .c-all {
      width: var(--allWidth);
      margin: 0 auto;
      margin-top: 1.275rem;
      display: flex;

      .c-right {
        margin-left: 1.275rem;
        background: #fff;
        width: 90%;
        margin-bottom: 3.125rem;

        .c-top {
          font-weight: bold;
          padding: 1.275rem;
          border-bottom: 0.0625rem solid #E8E8E8;
          cursor: pointer;

          img {
            vertical-align: middle;
            width: 1.0875rem;
            margin-right: 0.625rem;
          }

          span {
            vertical-align: middle;
          }
        }

        .center {

          margin: 1.875rem;

          .c-bt {
            .c-lt {
              font-size: 1rem;
              padding: .675rem 2.5rem;
              border: 0.0625rem solid #E9E9E9;
              display: inline-block;
              cursor: pointer;
            }

            .zs {
              background: #2E9BD6;
              color: #fff;
            }
          }

          .dibu {
            padding: 0.8 25rem;
            border: 0.0625rem solid #E3E3E3;

            .one {
              position: relative;
              border-bottom: 0.0625rem solid #E3E3E3;

              .zt {
                color: #F31F1F;
                position: absolute;
                top: 1.325rem;
                right: 1.325rem;
                font-size: 1rem;
                font-weight: bold;
              }

              .dws {
                color: #F31F1F;
                position: absolute;
                bottom: 1.325rem;
                right: 0.925rem;
                font-size: 1rem;
                font-weight: bold;

                .down {
                  margin-bottom: 0.625rem;
                }

                .bj1 {
                  background: #2E9BD6;
                }

                .bj2 {
                  // background: #fff;
                  // color: #000000;
                  border: 0.0625rem solid #B3B3B3;
                }

                .yqd {
                  border: 0.0625rem solid #2E9BD6;
                  color: #2E9BD6;
                }
              }

              padding: 1.075rem;

              .o-title {
                margin-bottom: 0.925rem;
              }

              .o-nr {
                display: flex;

                img {
                  width: 5.275rem;
                  margin-right: 0.8125rem;
                }

                .o-le {
                  .o-l-title {
                    font-weight: bold;
                    margin-bottom: 1.075rem;
                    margin-top: 0.2rem;
                  }

                  .o-l-bt {
                    display: flex;
                    align-items: center;
                    color: #4E4E4E;
                    font-size: 0.3375rem;

                    .ones {
                      display: flex;
                      align-items: center;

                      img {
                        width: 0.875rem;
                        margin-right: 0.3125rem;
                      }

                      .ones-fy {
                        color: #FF2323;
                      }

                      .ones-nrs {
                        color: #2E9BD6;
                      }
                    }

                    .shu {
                      margin: 0 0.275rem;
                      color: #C0C0C0;
                    }
                  }
                }
              }
            }
          }
        }

      }
    }

  }

  .qd-top {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.3rem;
    font-weight: bold;

    .qd-bt {
      margin: 0 1.25rem;
      width: 11rem;
    }

    .xian {
      width: 40%;
      height: 0.125rem;
      background: #D2D2D2;
    }


  }

  .ewm {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    img {
      width: 100%;
      margin-bottom: 1.875rem;
    }
    .lefts{
      width: 20%;
      position:  relative;
      input{
        font-size: 0;
        width: 100%;
        position: absolute;
        height: 80%;
        top: 0;
        left: 0;
        background: rgba(0,0,0,0);
        border: 0;
        line-height: 0;
        outline: 0;
        opacity: 0;

      }
    }
    .e-bt {
      font-size: 1.125rem;
      color: #1B1B1B;
    }
  }

  .ewm1 {
    text-align: center;

    // display: flex;
    // align-items: center;
    // justify-content: space-evenly;
    img {
      width: 10.875rem;
      margin-bottom: 1.875rem;
    }

    .e-bt {
      font-size: 1.125rem;
      color: #1B1B1B;
    }
  }

  .db {
    text-align: center;
    margin-top: 2.875rem;
  }

  .baocun {
    margin: 0 auto;
    padding: 1.375rem 6rem;
    font-size: 1.1875rem;
    background: #2E9BD6;
  }

  .my-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #2E9BD6;
    color: #fff;
  }

  .ddb {
    position: relative;
    z-index: 9999999999999999999;

    .top {
      position: absolute;
      top: 0;
      left: 0;
      background: #2E9BD6;
      color: #FFFFFF;
      width: 100%;
      padding: 1.075rem;
      font-size: 1.2rem;
    }

    .dbx {
      border-bottom: 0.0625rem solid #E6E6E6;
    }

    .ddgy {
      margin-top: 1.075rem;

      .d-bt {
        font-weight: bold;
        font-size: 1rem;
        margin-bottom: 1.025rem;
        color: #000000;
      }

      .ddgy-one {
        margin-bottom: 0.75rem;

        .d-o-bt {
          width: 50%;
          display: inline-block;
          color: #383838;
        }
      }

      .table {
        border: 0.0625rem solid #D5D5D5;

        .tr {
          text-align: center;
          display: flex;
          background: #F8F8F8;
          padding: 0.675rem;

          .td {
            width: 40%;
            padding: 0 20px;
          }
        }

        .tr1 {
          text-align: center;
          display: flex;
          padding: 0.675rem;

          .td {
            width: 40%;
            padding: 0 20px;
          }
        }
      }
    }
  }

  .dban {
    text-align: center;
    margin-top: 2.175rem;

    .anniu {
      width: 30%;
      text-align: center;
      padding: 1.4rem 0;
      font-size: 1rem;
    }

    .bs {
      background: #AFC81D;
      border: 0.0625rem solid #AFC81D;
      margin-right: 2.275rem;

      img {
        width: 1rem;
        margin-right: 0.625rem;
      }
    }

    .bs1 {
      background: #2E9BD6;
    }
  }

  .gb {
    cursor: pointer;
  }

  .zd {
    z-index: 9999999999999999999999999999999999 !important;
  }
  // .el-dialog.is-align-center{
  //   width: 30%!important;
  // }

  @media screen and (max-width: 1300px) {
    // .el-dialog.is-align-center{
    //   width: 90%!important;
    // }
    .c-all{
      width: 95%!important;
      margin: 0 auto;
      flex-wrap: wrap;
    }
    .c-right{
      width: 100%!important;
      margin: 0 auto!important;
    }

    .lefts{
      width: 100%;

    }
    .c-right{
     .center{
          margin: 0rem !important;
          padding: 0.75rem!important;
     }
    }
    .c-bt{
      display: flex;
    }
    .c-lt{
      padding: 0.65rem 0!important;
      width: 36%;
      text-align: center;
      margin-bottom: 0.625rem;
    }

    .o-nr{
      flex-wrap: wrap;
      .tbimg{
        width: 100%!important;
        margin: 0;
        margin-top: 0.625rem;
      }
    }
    .o-l-title{
      margin-bottom: 0.75rem!important;
      margin-top: 0.75rem!important;
      font-size: 1rem!important;
    }
    .dws{
      position: relative!important;
      bottom: 0!important;
      right: 0!important;
      display: flex;
      font-size: 0.75rem;
      margin-top: 0.75rem;
      .el-button{
        font-size: 0.75rem;
      }
    }
    .o-l-bt{
      flex-wrap: wrap;
    }
    .ones{
      margin-bottom: 0.625rem;
    }
    .shu{
      margin-bottom: 0.725rem!important;
    }
    .el-button+.el-button {
        margin-left: 0.25rem;
    }
    .zd{
      width: 80%!important;
    }
    .qd-bt{
      font-size: 1rem;
    }
    .ewm{
     .lefts{
       width: 47%!important;
       img{
         margin-bottom: 0.5rem;
       }
     }

    }
    .db{
       margin-top: 1rem;
     }

     .d-o-bt{
       width: 100%!important;
       margin-bottom: 0.625rem;
     }
     .ddgy-one{
       margin-bottom: 0!important;
     }
     .td{
           width: 40%!important;
           padding: 0 0.25rem!important;
           font-size: 0.75rem!important;
     }
     .anniu {
       width: 45%!important;
       font-size: 1rem!important;
     }
     .bs{
       margin-right: 3%!important;
     }
     .dban{
       margin-top: 1rem;
     }

     .ddb{

       .top{
         font-size: 1rem;
         padding: 0.8rem;
       }
     }

  }
</style>
