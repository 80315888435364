<template>
    <div class="c-top">
     <!-- <div class="c-t-one bs">
        <img src="~assets/home/xw.png"><span>最新会议</span>
        <div class="hang"></div>
      </div> -->
      <!-- <div class="shu">|</div> -->
      <div class="c-t-one">
        <img src="~assets/home/hye.png"><span class="pp">{{title}}</span>
      </div>
      <div class="dixian"></div>
      <div class="gd" @click="goTo(tz1,cs1     )" >查看更多
      <img class="gd-one" src="~assets/meeting/gd.png" />
      <img class="gd-two" src="~assets/meeting/gd1.png" />
      </div>


    </div>


</template>

<script>
 import {
    useRouter
  } from 'vue-router';
  import {
    getHome,
    getnew
  } from 'network/home.js';
  export default {
    name: 'Title',
    props: ['tz1','title','cs1'],
    data() {
      return {
        news:[]
      }
    },
    setup() {
      //跳转方法
      const router = useRouter();
      const goTo = (path, cs) => {
        var query={id:cs}
        console.log(query);

        console.log(query);
        router.push({
          path: path,
          query: query || {}
        })
      }

      return {
        goTo
      }

    },
  }
</script>

<style lang="scss" scoped="scoped">
 .c-top {
   width: 100%;
   display: flex;
   align-items: center;
   position: relative;

   .dixian {
     position: absolute;
     bottom: -0.825rem;
     left: 0%;
     width: 100%;
     height: 0.125rem;
     background: #EFEFEF;
     z-index: 9;
   }

   .gd {
     position: absolute;
     top: 50%;
     right: 0%;
     z-index: 9;
     transform: translate(0, -50%);
     display: flex;
     align-items: center;
     font-size: 0.75rem;
     cursor: pointer;
     img {
       width: 0.75rem;
       margin-left: 0.325rem;
     }
   }

   .shu {
     margin: 0 1.275rem;
   }

   .c-t-one {
     display: flex;
     align-items: center;
     font-size: 0.975rem;
     position: relative;
     cursor: pointer;
     font-weight: bold;
     .hang {
       width: 100%;
       height: 0.125rem;
       background: #318FC6;
       position: absolute;
       bottom: -0.825rem;
       left: 50%;
       transform: translate(-50%, 0%);
       z-index: 9999;
     }

     img {
       width: 1.1rem;
       margin-right: 0.375rem;
     }

   }

   .bs {
     color: #318FC6;
   }


 }
  .pp:hover{
    color: #2E9BD6!important;
  }
  
  .gd-two{
    display: none;
  }
  .gd:hover{
    color: #2E9BD6;
    .gd-one{
      display:none;
    }
    .gd-two{
      display: block;
    }
  }
</style>
