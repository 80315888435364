<template>
  <div class="all">
    <Head :top="top" :grxxff="grxxff"></Head>
    <Banner :bn='bn'></Banner>
    <div class="a-cen">
      <div class="center">
        <div class="c-top">
          <div class="c-one">
            <div class="c-one-quan">
              <img src="../../assets/users/1.png" />
            </div>
            <div class="c-one-title">提交入会申请</div>
          </div>
          <img class="jtimg" src="../../assets/users/jt.png" />
          <div class="c-one">
            <div class="c-one-quan">
              <img src="../../assets/users/2.png" />
            </div>
            <div class="c-one-title">联合体审核申请</div>
          </div>
          <img class="jtimg" src="../../assets/users/jt.png" />
          <div class="c-one">
            <div class="c-one-quan">
              <img src="../../assets/users/3.png" />
            </div>
            <div class="c-one-title">缴纳会员费用</div>
          </div>
          <img class="jtimg" src="../../assets/users/jt.png" />
          <div class="c-one">
            <div class="c-one-quan">
              <img src="../../assets/users/4.png" />
            </div>
            <div class="c-one-title">享受会员权益</div>
          </div>
          <div class="c-rig">
            <div class="c-rig-title">BECOME A MEMBER</div>
            <div class="c-rig-ann" @click="sqhy()">申请成为会员</div>
          </div>


        </div>

        <div class="zhongjian">
          <div class="biaoti">
            <img src="../../assets/users/onw.png" />
            <div class="b-dw">
              <span class="qm">会员等级</span>
              <span class="xoap">Membership level</span>
            </div>
          </div>
          <div class="c-futop" >

            <div class="c-futop-one" v-for="item in ffellow">
              <img src="~assets/users/bk1.png" />
              <!-- <div class="dj">{{item.title}}</div> -->
             <div class="c-futop-yq">
                <img :src="'https://ymedicine.jiujingwulian.com/public'+item.logoimage" />
                <div class="dw">
                  <div class="dw-title">{{item.name}}</div>
                  <div class="dw-content">{{item.title}}</div>
                </div>

              </div>
            </div>
            <!--
                1.保湿类的洁面产品 温和低 无刺激的洁面产品 不要带有香料（选择拨水保湿类的护肤产品）
                2.洗脸要冷热交替洗脸 先用温水然后冷水洗，如果用面膜（护肤产品就只使用温水），要用流动的水洗脸
                护肤 1小时08分
                1.爽肤水 眼霜 精华 乳液 面霜 防晒
                2.爽肤水 （面膜）眼霜 精华 乳液 面霜 （睡眠面膜）
            -->
           <!-- <div class="c-futop-one">
              <img src="~assets/users/bk.png" />
              <div class="dj">等级一</div>
              <div class="c-futop-yq">
                <img src="~assets/users/zj.png" />
                <div class="dw">段唯一</div>
              </div>
            </div>

            <div class="c-futop-one">
              <img src="~assets/users/bk.png" />
              <div class="dj">等级一</div>
              <div class="c-futop-yq">
                <img src="~assets/users/zj.png" />
                <div class="dw">段唯一</div>
              </div>
            </div>

            <div class="c-futop-one">
              <img src="~assets/users/bk.png" />
              <div class="dj">等级一</div>
              <div class="c-futop-yq">
                <img src="~assets/users/zj.png" />
                <div class="dw">段唯一</div>
              </div>
            </div> -->

          </div>

          <!-- <div class="c-dengji"> -->

            <!-- <div class="dwzw">等级权益</div>
            <div class="c-dengji-list" v-for="item in ffellow">
              <div class="c-dengji-top">
                {{item.name}}
              </div>
              <div class="c-dengji-nr">
                {{item.quanyi}}
              </div>
            </div> -->

            <!-- <div class="c-dengji-list">
              <div class="c-dengji-top">
                等级权益一
              </div>
              <div class="c-dengji-nr">
                等级权益等级权益等级权益等级权益等级权益等级权益等级权益等级权益等级权益等级权益等级权益等级权益等级权益等级权益等级权益等级权益等级权益等级权益等级权益等级权益等级权益等级权益等级权益级权益等级权益等级权益等级权益等级权益等级权益等级权益等级权益等级权益等级权
              </div>
            </div>
            <div class="c-dengji-list">
              <div class="c-dengji-top">
                等级权益一
              </div>
              <div class="c-dengji-nr">
                等级权益等级权益等级权益等级权益等级权益等级权益等级权益等级权益等级权益等级权益等级权益等级权益等级权益等级权益等级权益等级权益等级权益等级权益等级权益等级权益等级权益等级权益等级权益级权益等级权益等级权益等级权益等级权益等级权益等级权益等级权益等级权益等级权
              </div>
            </div>
            <div class="c-dengji-list">
              <div class="c-dengji-top">
                等级权益一
              </div>
              <div class="c-dengji-nr">
                等级权益等级权益等级权益等级权益等级权益等级权益等级权益等级权益等级权益等级权益等级权益等级权益等级权益等级权益等级权益等级权益等级权益等级权益等级权益等级权益等级权益等级权益等级权益级权益等级权益等级权益等级权益等级权益等级权益等级权益等级权益等级权益等级权
              </div>
            </div> -->
          <!-- </div> -->
          <div class="biaoti">
            <img src="../../assets/users/tww.png" />
            <div class="b-dw">
              <span class="qm">会员权益</span>
              <span class="xoap">Membership Interests</span>
            </div>
          </div>

          <div class="c-hyqy">
            <!-- <div class="c-hyqy-top">会员权益</div> -->
            <div class="c-hyqy-list">
              <div class="c-h-one" v-for="(item,index) in ffellowshezhi">
                <div class="c-h-o-left">
                  <img src="../../assets/users/twwx.png" /><span>{{index+1}}</span>
                </div>
                <div class="c-h-o-right">
                  <div class="bts">{{item.name}}</div>
                  <div class="nr" v-html="item.qycontent"></div>
                </div>
              </div>
               <!-- <div class="c-h-one">
                 <div class="c-h-o-left"><img src="../../assets/users/bj1.png" /><span>1</span></div>
                 <div class="c-h-o-right">
                   <div class="bts">比挑剔</div>
                   <div class="nr">内容内容内容内容内内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容容内容</div>
                 </div>
               </div>
               <div class="c-h-one">
                 <div class="c-h-o-left"><img src="../../assets/users/bj1.png" /><span>1</span></div>
                 <div class="c-h-o-right">
                   <div class="bts">比挑剔</div>
                   <div class="nr">内容内容内容内容内内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容容内容</div>
                 </div>
               </div>

               <div class="c-h-one">
                 <div class="c-h-o-left"><img src="../../assets/users/bj1.png" /><span>1</span></div>
                 <div class="c-h-o-right">
                   <div class="bts">比挑剔</div>
                   <div class="nr">内容内容内容内容内内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容容内容</div>
                 </div>
               </div> -->

            </div>
            <!--
            <div class="c-hyqy-list">
              <div class="c-h-one">
                <div class="c-h-o-left"><img src="../../assets/users/bj1.png" /><span>1</span></div>
                <div class="c-h-o-right">
                  <div class="bts">比挑剔</div>
                  <div class="nr">内容内容内容内容内内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容容内容</div>
                </div>
              </div>
               <div class="c-h-one">
                 <div class="c-h-o-left"><img src="../../assets/users/bj1.png" /><span>1</span></div>
                 <div class="c-h-o-right">
                   <div class="bts">比挑剔</div>
                   <div class="nr">内容内容内容内容内内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容容内容</div>
                 </div>
               </div>
            </div>

            <div class="c-hyqy-list">
              <div class="c-h-one">
                <div class="c-h-o-left"><img src="../../assets/users/bj1.png" /><span>1</span></div>
                <div class="c-h-o-right">
                  <div class="bts">比挑剔</div>
                  <div class="nr">内容内容内容内容内内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容容内容</div>
                </div>
              </div>
               <div class="c-h-one">
                 <div class="c-h-o-left"><img src="../../assets/users/bj1.png" /><span>1</span></div>
                 <div class="c-h-o-right">
                   <div class="bts">比挑剔</div>
                   <div class="nr">内容内容内容内容内内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容容内容</div>
                 </div>
               </div>
            </div>


            <div class="c-hyqy-list">
              <div class="c-h-one">
                <div class="c-h-o-left"><img src="../../assets/users/bj1.png" /><span>1</span></div>
                <div class="c-h-o-right">
                  <div class="bts">比挑剔</div>
                  <div class="nr">内容内容内容内容内内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容容内容</div>
                </div>
              </div>
               <div class="c-h-one">
                 <div class="c-h-o-left"><img src="../../assets/users/bj1.png" /><span>1</span></div>
                 <div class="c-h-o-right">
                   <div class="bts">比挑剔</div>
                   <div class="nr">内容内容内容内容内内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容容内容</div>
                 </div>
               </div>
            </div> -->


          </div>


          <div class="biaoti">
            <img src="../../assets/users/threw.png" />
            <div class="b-dw">
              <span class="qm">会费标准</span>
              <span class="xoap">Membership fee label</span>
            </div>
          </div>


          <div class="c-hyqy">
            <div class="c-hyqy-top">会费标准</div>

              <div class="c-hyqybt" v-for="(item,index) in ffellowjs">
                <div class="hyleft">
                  <div class="qm lef">{{item.name}}</div>
                  <div class="qm rig">
                    <div  v-for="(item1,index) in item.fl">{{item1.name}}</div>
                    <!-- <div>会员类别</div> -->
                  </div>
                </div>
                <div class="hyright">
                  <div v-for="(item1,index) in item.fl" class="hyright-top">
                    <div class="ont1 ys1s">{{item1.qyprice}}</div>
                  </div>
                  <!-- <div class="hyright-top">会费标准</div> -->
                </div>
              </div>
              <!-- <div class="c-hyqybt">
                <div class="hyleft">
                  <div class="qm lef">会员类别</div>
                  <div class="qm rig">
                    <div>会员类别</div>
                    <div>会员类别</div>
                  </div>
                </div>
                <div class="hyright">
                  <div class="hyright-top">会费标准</div>
                </div>
              </div> -->



            <!-- <div class="c-hyqybt">
              <div class="hyleft">会员类别</div>
              <div class="hyright">
                <div class="hyright-top">会费标准</div>
                <div class="hyright-cen">
                  <div class="ont ys1s">企业</div>
                  <div class="ont ys2s">个人</div>
                </div>
              </div>
            </div>


            <div class="c-hyqybt" v-for="(item,index) in ffellowjs">
              <div class="hyleft">{{item.name}}</div>
              <div class="hyright">
                <div class="hyright-cen">
                  <div class="ont1 ys1s">{{item.qyprice}}<span v-show="item.qydw==0">元/年</span><span v-show="item.qydw==1">元/月</span></div>
                  <div class="ont1 ys2s">{{item.grprice}}<span v-show="item.grdw==0">元/年</span><span v-show="item.grdw==1">元/月</span></div>
                </div>
              </div>
            </div> -->
            <!-- <div class="c-hyqybt">
              <div class="hyleft">会员类别</div>
              <div class="hyright">
                <div class="hyright-cen">
                  <div class="ont1">企业</div>
                  <div class="ont1">个人</div>
                </div>
              </div>
            </div>
            <div class="c-hyqybt">
              <div class="hyleft">会员类别</div>
              <div class="hyright">
                <div class="hyright-cen">
                  <div class="ont1">企业</div>
                  <div class="ont1">个人</div>
                </div>
              </div>
            </div>
            <div class="c-hyqybt">
              <div class="hyleft">会员类别</div>
              <div class="hyright">
                <div class="hyright-cen">
                  <div class="ont1">企业</div>
                  <div class="ont1">个人</div>
                </div>
              </div>
            </div>
            <div class="c-hyqybt">
              <div class="hyleft">会员类别</div>
              <div class="hyright">
                <div class="hyright-cen">
                  <div class="ont1">企业</div>
                  <div class="ont1">个人</div>
                </div>
              </div>
            </div>
            <div class="c-hyqybt">
              <div class="hyleft">会员类别</div>
              <div class="hyright">
                <div class="hyright-cen">
                  <div class="ont1">企业</div>
                  <div class="ont1">个人</div>
                </div>
              </div>
            </div> -->



          </div>
        </div>




      </div>
    </div>
    <foot></foot>
    <left></left>
  </div>
</template>

<script>

import {ref,onMounted} from 'vue';
import {getffellow} from 'network/users.js';
import {useRouter} from 'vue-router';


import Head from "components/content/headder/Head.vue";
import Foot from "components/content/footer/Foot.vue";
import Left from "components/content/left/Left.vue";
import Bread from "components/content/breadcrumb/Breadcrumb.vue";
import Banner from "components/content/banner/Banner.vue";

import {
  ElMessage,
  ElMessageBox
} from 'element-plus';
import {
  Action
} from 'element-plus';


export default {
  name: 'Users',
  data() {
    return {
      ffellow:[],
      ffellowjs:[],
      ffellowshezhi:{},

      bn:'https://ymedicine.jiujingwulian.com/public/img/banner.png',
      all: [
        {
            name: '您当前的位置:首页',
            url: '/'
          },

          {
            name: '资讯',
            url: '/New'
          },
        ]
    }
  },
  setup(){
    const top=4;
    const router=useRouter();
    const databa=ref([]);
    const ab='sb';
    console.log(ab);
    onMounted(()=>{

      // getHome().then(res=>{
      //   console.log(res.data.data);
      //   databa.value=res.data;
      //   console.log(databa);

      // }).catch(err=>{

      // })

    })
    //跳转方法
    const goTo=(path,query)=>{
      router.push({
        path:path,
        query:query || {}
      })
    }

    return { databa,ab,goTo,top }
  },
  mounted() {
    this.listdata();
    // this.faboutdata();
    // window.scrollTo( 0, 0 );
  },
  methods: {
    sqhy(){
      if(!this.grxx.id){
        ElMessageBox.confirm(
            '您未登录是否登录?',
            '提示', {
              confirmButtonText: '确认',
              cancelButtonText: '取消',
              type: '提示',
            }
          )
          .then(() => {

             this.$router.push('/login')
          })
          .catch(() => {
            ElMessage({
              type: 'info',
              message: '取消成功',
            })
          })

          return false;
      }
      this.$router.push('/myRuhui');
    },
    grxxff(grxxs){
      this.grxx=grxxs;
      console.log(grxxs);
      console.log('zhende');
    },
    listdata(){
      var that = this;
      var data = {

      };
      getffellow(data).then(res => {
        that.ffellow=res.data.data.ffellow;
        that.ffellowjs=res.data.data.ffellowjsfl;
        that.ffellowshezhi=res.data.data.ffellowshezhi;
      }).catch(err => {})
    },
  },
  components:{
    Head,
    Foot,
    Left,
    Bread,
    Banner
  },
}
</script>
<style lang="scss" scoped="scoped">
  .a-cen{
    width: 100vw;
    background: #fff;
    min-height: 100vh;
    background: linear-gradient(rgba(255,255,255,1) 21%,rgba(245,245,245,1) 40%);
    // #F5F5F5
    background: #F5F5F5;
    .center{
      width: var(--allWidth);
      margin: 0 auto;
      background: #F5F5F5;
    }
  }
  .center{
    background: #fff;
    min-height: 10vh;
    position: relative;
    top: -11rem;
    border-radius: 0.625rem;
    padding-bottom: 2.25rem;
    .c-top{
      padding: 3rem 5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 0.0625rem solid #E4E4E4;
      background: #fff;
      box-shadow: 0px 0.25rem 0.9375rem rgba(168, 168, 168, 0.25);
      margin-bottom: 1.25rem;
      border-radius: 0.625rem;
      .jtimg{
        width: 1.85rem;
        margin: 0 2.225rem;
        margin-top: -1.36rem;
      }
      .c-one{
        .c-one-quan{
          width: 7rem;
          height: 7rem;
          line-height: 8.8rem;
          text-align: center;
          border-radius: 50%;
          border: 0.0625rem solid #0976B2;
          img{
              width: 3rem;
          }
        }
        text-align: center;
        .c-one-title{
          font-size: 1.1rem;
          margin-top: 1.125rem;
          font-weight: bold;

        }
      }

      .c-rig{
        width: 15rem;
        margin-left: 3.75rem;
        text-align: center;
        .c-rig-title{
          font-weight: bold;
          font-family: Arimo;
          font-size: 1.25rem;
          margin-bottom: 1.875rem;
          color: #707070;
        }
        .c-rig-ann{
          cursor: pointer;
          background: linear-gradient(rgba(139,201,246,1) 0%,rgba(43,158,219,1) 100%);
          box-shadow: 0rem 0.25rem 0.25rem rgba(73, 126, 150, 0.25);
          font-size: 1rem;
          color: #fff;
          text-align: center;
          display: inline-block;
          padding: 1.65rem 3.175rem;
          border-radius: 50rem;
          font-size: 1.325rem;

        }
      }
    }

    .zhongjian{
       background: #fff;
       border-radius: 0.625rem;
       padding: 0.825rem 0;
       box-shadow: 0px 0.25rem 0.9375rem rgba(168, 168, 168, 0.25);
    }
    .biaoti{
      position: relative;
      .b-dw{
        position: absolute;
        top: 50%;
        left: 0.825rem;
        transform: translate(0,-50%);
        color: #fff;
        .qm{
          font-size: 1.125rem;
          margin-right: 0.625rem;
          font-weight: bold;
        }
        .xoap{
          font-size: 0.5125rem;
          color: #dfdfdf;
        }
      }

    }
    .c-futop{
      padding: 1.875rem 3.125rem;
      display: flex;
      justify-content: space-evenly;
      .c-futop-one{
        position: relative;
        img{
          width: 13rem;
        }
        .dj{
          position: absolute;
          top: 1rem;
          left: 1.4rem;
          color: #FFFFFF;
          font-size: 1.2rem;
          font-weight: bold;
        }
         position: relative;
        .c-futop-yq{
          text-align: center;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          width: 90%;
          text-align: left;
          display: flex;
          align-items: center;
          img{
            width: 2.25rem;
            // margin-bottom: 1.2625rem;
          }
          .dw{
            font-size: 0.6125rem;
            color: #306488;
            margin-left: 0.625rem;
            .dw-title{
              font-size: 0.98rem;
              font-weight: bold;
              color: #000;
              margin-bottom: 0.575rem;
            }
          }
        }
      }
    }

    .c-dengji{
      width: 95%;
      margin: 0 auto;
      background: url(assets/users/djk.png) no-repeat;
      background-size: 100% 100%;
      padding:3rem 1.875rem;
      min-height: 43vh;

      display: flex;
      align-items: flex-start;
      justify-content: space-around;
      position: relative;
      padding-top: 7.25rem;
      .dwzw{
        position: absolute;
        top: 1.025rem;
        left: 50%;
        transform: translate(-50%,0);
        font-size: 1.5rem;
        color: #FFFFFF;
        font-weight: bold;
        letter-spacing: 0.3rem;
      }
      .c-dengji-list{
        width: 20%;
        .c-dengji-top{
          font-size: 1.3rem;
          text-align: center;
          font-weight: bold;
          padding-bottom: 1.25rem;
          border-bottom: 0.0625rem solid #97B1CB;
          margin-bottom: 1.25rem;
        }
        .c-dengji-nr{
          font-size: 0.9rem;
          line-height: 1.2rem;

        }
      }

    }

    .c-hyqy{
      // border: 0.0625rem solid #CACACA;
      // min-height: 30vh;
      width: 95%;
      margin: 0 auto;
      margin-top: 1.875rem;
      border-radius: 1.2rem;
      overflow: hidden;

      .c-hyqybt{
        display: flex;
        // align-items: flex-start;
        align-items: stretch;
        border: 1px solid #c9c5e8;
        border-top:0rem;
        .hyleft{
          width: 63%;
          // padding: 2.825rem ​ 0;
          padding:0rem 0;
          text-align: center;
          background:#F2F1FF;
          border-right: 0.0625rem solid #D5DCDF;
          border-bottom: 0.0625rem solid #D5DCDF;
          display: flex;
          justify-content: center;
          align-items: center;
          .qm{
              width: 50%;
              text-align: center;

          }
          .lef{
            width: 30%;
          }
          .rig{
            width: 70%;
            border-left: 1px solid #c9c5e8;
          }
          .rig>div{
            padding: 1.25rem;
            border-bottom: 1px solid #c9c5e8;
          }
          .rig>div:last-child {
             border-bottom: 0px solid #c9c5e8;
          }
        }
        .hyright{
          width: 80%;
          background:#E1DFF5;
          text-align: center;
          display: flex;
          flex-wrap: wrap;

          .hyright-top{
              background:#E1DFF5;
              padding: 1rem 0;

              width: 100%;
              border-bottom: 1px solid #c9c5e8;
              display: flex;
              align-items: center;
              justify-content: center;
          }
          .hyright-top:last-child {
            border-bottom: 0px solid #c9c5e8;
          }
          .hyright-cen{
            display: flex;
            .ys1s{
               background:#FBFAFF;
               color: #914F9F;
            }
            .ys2s{
               background:#FFFAF9;
               color: #F06341;
            }
            .ont{
              width: 50%;
              padding: 1.25rem;
              text-align: center;
               border-right: 0.0625rem solid #D5DCDF;
              border-bottom: 0.0625rem solid #D5DCDF;
            }
            .ont1{
              width: 50%;
              padding: 2.65rem;
              text-align: center;
               border-right: 0.0625rem solid #D5DCDF;
              border-bottom: 0.0625rem solid #D5DCDF;
              color: #714F1A;
              font-weight: bold;
              font-size: 1.325rem;
              span{
                font-size: 1.025rem;
              }
            }
          }
        }
      }

      .c-hyqy-top{
        background: #C9C5E8;
        // rgb(175, 200, 29);
        border-radius: 1.2rem 1.2rem 0px 0px;
        margin-top: 0.0625rem;
        text-align: center;
        padding: 1.25rem 0;
        // color: #fff;
        font-size: 1.425rem;
      }

      .c-hyqy-list{
        display: flex;
        flex-wrap: wrap;

        .c-h-one{
          width: 45.1%;
          border: 0.0625rem solid #B5CFE7;
          margin: 0 1.875rem 1.875rem 1.875rem;
          // margin-bottom: 1.875rem;
          padding: 2.05rem;
          border-radius:0.9rem;
          padding-left: 4.075rem;
          display: flex;
          position: relative;
          .c-h-o-left{
            position: absolute;
            margin-right: 0.625rem;
            top:0;
            left: 0;

            span{
             position: absolute;
             top: 1.65rem;
             left: 1.65rem;
             transform: translate(-50%,-50%);

            }

            font-size: 1.4rem;
            font-weight: bold;
            color: #4F8CCA;
            img{
              width: 5.875rem;
            }
          }
          .c-h-o-right{
            .bts{
              font-size: 1.2rem;
              font-weight: bold;
              margin-top: 0.625rem;
            }
            .nr{
              font-size: 0.875rem;
              margin-top: 0.625rem;
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
          }
        }
      }

    }

  }


  @media screen and (max-width: 1300px) {
    .center{
      width: 99%!important;
      margin: 0 auto;
      top: 1rem!important;
    }
    .c-top{
      padding: 1rem 1rem!important;
    }
    .c-one-quan{
      width: 3rem!important;
      height: 3rem!important;
      line-height: 3rem!important;
      margin: 0 auto!important;
      img{
          width: 1rem!important;
      }
    }
    .c-one-title{
      font-size: 0.6rem!important;
      margin-top: 0.825rem!important;
    }
    .jtimg{
      width: 1rem!important;
      margin: 0!important;
      margin-top: -1.36rem!important;
    }
    .c-rig{
        width: 80%!important;
        margin: 0 auto!important;
        .c-rig-title{
           font-size: 0.8rem!important;
           margin-top: 0.875rem!important;
           margin-bottom: 0.875rem!important;
       }
       .c-rig-ann{



         padding: 1rem 2.175rem!important;
         font-size: 1rem!important;
         width: 100%!important;
       }
    }
    .biaoti{
      text-align: center;
      .b-dw{
        left: 50%!important;
        transform: translate(-50%, -50%)!important;
      }
    }
    .c-futop{
      padding: 0.875rem 1.125rem!important;
      flex-wrap: wrap;
      .c-futop-one{
        width: 100%!important;
        img{
          width: 100%!important;
          margin-bottom: 10px!important;
          height: 85px!important;
        }
        .c-futop-yq{

          top: 43%!important;
          img{
            width: 2.25rem!important;
            height: auto!important;
            margin-bottom: 0!important;
          }

        }
      }
    }
    .b-dw{
      width: 100%!important;
      top:48%!important;
    }

    .c-hyqy{
      margin-top: 0.875rem!important;
      .c-h-one{
        width: 100%!important;
        margin: 0 0.675rem 0.675rem 0.675rem!important;
        padding: 1.05rem!important;
        padding-left: 4.075rem!important;

      }
      .bts{
        font-size: 1rem!important;
      }
      .nr{
            font-size: 0.675rem!important;
      }
    }

    .c-hyqy-top{
      padding: 0.85rem 0!important;
      font-size: 1rem!important;
    }
    .hyleft{
      font-size: 0.6rem!important;
      padding: 2.6rem 0!important;

    }
    .hyright-top{
      padding: 0.96rem 0!important;
      font-size: 0.6rem!important;
    }
    .ont{
      padding: 1.25rem!important;
      font-size: 0.6rem!important;
    }
    .ont1{
      font-size: 0.75rem!important;
      span{
        font-size: 0.75rem!important;

      }
      padding: 2.58rem 0rem!important;
    }
  }


</style>
